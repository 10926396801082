import { Card, Loader } from "ebs-design";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { stats } from "api";
import { format } from "utils";
import { AlertErrors } from "components";
import { RevenueStatsEntity } from "types";
import { QueryParamsProps } from "./types";

export const Chart = ({ queryParams }: QueryParamsProps) => {
  const {
    data: revenue,
    isLoading,
    error,
  } = useQuery<RevenueStatsEntity[], AxiosError>(
    ["revenue", queryParams],
    () => stats.getRevenue(queryParams),
    {
      select: (data) =>
        (data || []).map((item: { value: number; date: string }) => ({
          ...item,
          date: format(new Date(item.date), "DD MMM"),
        })),
    },
  );

  return (
    <>
      <AlertErrors error={error} />
      <Loader loading={isLoading}>
        <Card>
          <Card.Body>
            <div style={{ maxWidth: "100%", height: 285 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  width={730}
                  height={350}
                  data={revenue}
                  margin={{ top: 5, right: 30, left: 5, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#b49640"
                    isAnimationActive={false}
                    strokeWidth={2}
                    name="Revenue"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card.Body>
        </Card>
      </Loader>
    </>
  );
};
