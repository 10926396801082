import React, { CSSProperties } from "react";

export interface FlexProps extends React.HTMLProps<HTMLDivElement> {
  gap?: CSSProperties["gap"];
  align?: CSSProperties["alignItems"];
  justify?: CSSProperties["justifyContent"];
  direction?: CSSProperties["flexDirection"];
}

export const Flex: React.FC<FlexProps> = ({
  align,
  justify,
  direction,
  children,
  gap,
  style,
  ...props
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justify,
        alignItems: align,
        flexDirection: direction,
        gap,
        ...style,
      }}
      {...props}
    >
      {children}
    </div>
  );
};
