import { createContext } from "react";

// Data - is any data from steps, it can't be predefined
export interface FormData {
  [key: string]: any;
}

interface Context {
  data: FormData | null;
  currentStep: number | string;
  nextStep: (data?: FormData) => void;
  prevStep: (data?: FormData) => void;
  reset: () => void;
}

const FormWizardContext = createContext<Context>({
  data: null,
  currentStep: 0,
  nextStep: () => null,
  prevStep: () => null,
  reset: () => null,
});

export default FormWizardContext;
