import { Rule } from "rc-field-form/es/interface";

import { ProductCharacteristicsValue } from ".";

export const characteristicsValidatorRules: Rule[] = [
  {
    validateTrigger: "onSubmit",
    // empty field vaildator
    validator: (_, value: ProductCharacteristicsValue) => {
      let valid = true;

      value?.forEach?.(characteristic => {
        if (!characteristic.characteristic_title) valid = false;
        if (!characteristic.representation_type) valid = false;

        characteristic.choices?.forEach?.(choice => {
          if (!choice.value) valid = false;
          if (!choice.price) valid = false;
        })
      });
      if (valid) return Promise.resolve()
      else return Promise.reject("No fields should be empty")
    }
  },
  {
    validateTrigger: "onSubmit",
    // no choices field vaildator
    validator: (_, value: ProductCharacteristicsValue) => {
      let valid = true;

      value?.forEach?.(characteristic => {
        if (!characteristic.choices?.length) valid = false;
      });
      if (valid) return Promise.resolve()
      else return Promise.reject("There should be at least 1 choice")
    }
  },
]