import { LOCALE } from "app-constants";
import { UserProfileEntity } from "types";

export const getUserDetailsCols = (user: UserProfileEntity) => [
  [
    [user.phone, "Phone"],
    [user.email, "Email"],
    [new Date(user.birthday).toDateString(), "D.o.B"],
  ],
  [
    [user.stats?.sessions?.last_30_days, "Session last 30 days"],
    [
      user.stats?.notifications?.enabled ? "Yes" : "No",
      "User has notifications On",
    ],
    [user.stats?.friends?.count, "Close Friends In Calendar"],
  ],
  [
    [user.stats?.transactions?.revenue, "LTV"],
    [
      user.stats?.gifts?.avg_time_to_open_a_gift?.days,
      "Avg time from recieving to use gift",
    ],
    [
      user.stats?.transactions?.current_month_average_revenue,
      "Avg Spend Per Month",
    ],
  ],
  [
    [user.stats?.events?.count, "No. of special days"],
    [
      user?.last_login
        ? new Date(user?.last_login).toLocaleString(LOCALE)
        : "---",
      "Last active",
    ],
  ],
];
