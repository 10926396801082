import { useQuery } from "react-query";

import { cohorts } from "api";
import { CohortsStats } from "types";
import { AxiosError } from "axios";
import { Loader, Space } from "ebs-design";
import { AlertErrors } from "components";

export const CohortsDashboardStats = () => {
  const {
    data: dashboardStats,
    isLoading,
    error,
  } = useQuery<CohortsStats, AxiosError>(["cohorts", "stats"], () =>
    cohorts.getStats(),
  );

  return (
    <>
      {isLoading && <Loader loading />}
      <AlertErrors error={error} />
      <div className="pady-2"></div>
      {dashboardStats && (
        <div className="details-card__grid-2cols">
          <div className="details-card chart-hover">
            <Space justify="space-between" align="center">
              <span className="small-text fw-500">Number of cohorts</span>
              <h2>{dashboardStats?.cohorts_count}</h2>
            </Space>
          </div>
          <div className="details-card">
            <Space justify="space-between" align="center">
              <span className="fw-500">Total users</span>
              <h2>{dashboardStats?.total_users_count}</h2>
            </Space>
          </div>
        </div>
      )}
    </>
  );
};
