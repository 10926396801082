import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import { useQueryParam, withDefault, StringParam } from "use-query-params";
import { AxiosError } from "axios";

import { Space, SortBy, Card } from "ebs-design";

import { useQueryPagination } from "hooks";
import { gifts } from "api";
import { Results, UserGiftEntity } from "types";
import { extractOrderingOptions, resultsCount } from "utils";

import { getGiftsTableColumns } from "features/gifts/giftsTableColumns";
import {
  AlertErrors,
  Icon,
  Table,
  Pagination,
  TableRowLink,
  TableEmptyText,
  DebouncedInput,
} from "components";
import { GiftOperationStats } from "../GiftOperationView";

export const GiftOperationView = () => {
  const id = useParams<{ id: string }>().id;

  const pagination = useQueryPagination();
  const { limit, page, setPage } = pagination;

  const [search, setSearch] = useQueryParam(
    "search_products",
    withDefault(StringParam, ""),
  );

  const [ordering, setOrdering] = useQueryParam(
    "ordering",
    withDefault(StringParam, ""),
  );

  const queryParams = {
    page,
    limit,
    search,
    ordering,
    operation_id: id,
  };

  const giftsQuery = useQuery<Results<UserGiftEntity>, AxiosError>(
    ["gifts", queryParams],
    () => gifts.getList(queryParams),
    {
      enabled: !!page,
    },
  );

  const tableColumns = React.useMemo(() => getGiftsTableColumns({}), []);

  const sortOptions = extractOrderingOptions(tableColumns);

  useUpdateEffect(() => setPage(1), [search, ordering]);

  return (
    <>
      <AlertErrors error={giftsQuery.error} />
      {!giftsQuery.isError && (
        <>
          <GiftOperationStats gift={giftsQuery.data?.results[0]} />
          <div className="pady-2"></div>
          <div className="divider"></div>
          <div className="pady-2"></div>
          <Card>
            <Card.Body>
              <Space justify="space-between">
                <Space>
                  <div className="fw-500">
                    Gifts sent (
                    {resultsCount(giftsQuery.data, giftsQuery.isLoading)})
                  </div>
                  <DebouncedInput
                    placeholder="Search gift"
                    value={search}
                    onChange={(value: string) => setSearch(value || undefined)}
                    suffix={<Icon type="search" />}
                  />
                  <SortBy
                    value={ordering}
                    onChange={(value: string) =>
                      setOrdering(value || undefined)
                    }
                    options={sortOptions}
                  />
                </Space>
              </Space>
            </Card.Body>
          </Card>
          <div className="pady-3" />
          <Table
            data={giftsQuery.data?.results}
            columns={tableColumns}
            title={() => <h3 className="pady-4 padx-4">Gifts</h3>}
            footer={() => (
              <div className="padx-8 pady-4">
                <Space justify="end">
                  <Pagination
                    pagination={pagination}
                    results={giftsQuery.data}
                  />
                </Space>
              </div>
            )}
            emptyText={<TableEmptyText isLoading={giftsQuery.isLoading} />}
            variants={[
              "bordered",
              "elevated",
              "dense",
              "stripped-even",
              "highlight-hover",
            ]}
            scroll={{ x: true }}
            rowKey="id"
            components={{
              body: {
                row: (props: any) => (
                  <TableRowLink {...props} makeUrl={(id) => `/gifts/${id}/`} />
                ),
              },
            }}
          />
        </>
      )}
    </>
  );
};
