import { ProductEntity } from "types";

export interface FavouriteCardProps {
  product: ProductEntity;
}
export const FavouriteCard = ({ product }: FavouriteCardProps) => {
  return (
    <div className="product-card">
      <div className="product-card__image">
        <img src={product?.featured_image?.url} alt="Product" />
      </div>
      <div className="product-card__info">
        <div className="pady-4">
          <h3 className="product-card__title">{product?.title}</h3>
          <span className="small-text">{product?.brand.title}</span>
        </div>
      </div>
      <div className="product-card__brand">
        <img src={product?.brand?.image?.url} alt="Brand" />
      </div>
    </div>
  );
};
