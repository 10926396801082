import React from "react";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import { Loader, Space, InputSearch, Card, SortBy } from "ebs-design";
import { ColumnType } from "rc-table/lib/interface";
import { Results, OrderEntity } from "types";
import { useQueryPagination, useFilters } from "hooks";
import { orders } from "api";
import { capitalize, extractOrderingOptions, resultsCount } from "utils";
import {
  AlertErrors,
  Table,
  Pagination,
  TableRowLink,
  Filters,
  renderReciever,
} from "components";
import { ordersFilter } from "../ordersFilters";
import { LOCALE } from "app-constants";

const Orders = () => {
  const pagination = useQueryPagination();
  const { limit, page, setPage } = pagination;

  const [search, setSearch] = useQueryParam(
    "search",
    withDefault(StringParam, ""),
  );
  const [ordering, setOrdering] = useQueryParam(
    "ordering",
    withDefault(StringParam, ""),
  );

  const filtersResult = useFilters({
    filters: ordersFilter,
    onActiveFiltersChange: () => setPage(1),
  });

  const queryParams = {
    page,
    limit,
    search,
    ordering,
    ...filtersResult.activeFiltersValues,
  };

  const {
    data: ordersList,
    error,
    isLoading,
  } = useQuery<Results<OrderEntity>, AxiosError>(
    ["orders", queryParams],
    () => orders.getList(queryParams),
    {
      enabled: !!pagination.page,
    },
  );

  const tableColumns = React.useMemo<ColumnType<OrderEntity>[]>(
    () => [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "Sender",
        dataIndex: ["user", "name"],
      },
      {
        title: "Receiver",
        render: renderReciever,
      },
      {
        title: "Product",
        dataIndex: ["product", "title"],
      },
      {
        title: "Price",
        dataIndex: ["price"],
        // render: (_, order) =>
        //   order?.price
        //     ? `
        //     ${getCurrencySymbol(order?.product?.currency?.code_name)} ${
        //         order?.price
        //       }`
        //     : "---",
      },
      {
        title: "Cost",
        dataIndex: "cost",
        ordering: "cost",
        // render: (cost, record) =>
        //   cost != null
        //     ? `${getCurrencySymbol(
        //         record?.product?.currency?.code_name,
        //       )} ${cost}`
        //     : "---",
      },
      {
        title: "Profit after Discount",
        dataIndex: "fee",
        ordering: "fee",
        // render: (profit, record) =>
        //   profit != null
        //     ? `${getCurrencySymbol(
        //         record?.product?.currency?.code_name,
        //       )} ${profit}`
        //     : "---",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (status) => capitalize(status),
      },
      {
        title: "Date and Time",
        dataIndex: "timestamp",
        ordering: "timestamp",
        render: (value: string) => {
          return value && new Date(value).toLocaleString(LOCALE);
        },
      },
    ],
    [],
  );

  const sortOptions = extractOrderingOptions(tableColumns);

  useUpdateEffect(() => setPage(1), [search, ordering]);

  return (
    <>
      <AlertErrors error={error} />
      <Card>
        <Card.Body>
          <Space>
            <div className="fw-500">
              Orders ({resultsCount(ordersList, isLoading)})
            </div>
            <InputSearch
              placeholder="Search order"
              value={search}
              onSearch={(value: string) => setSearch(value || undefined)}
            />
            <SortBy
              value={ordering}
              onChange={(value: string) => setOrdering(value || undefined)}
              options={sortOptions}
            />
            <Filters filtersResult={filtersResult} />
          </Space>
        </Card.Body>
      </Card>
      <div className="pady-3"></div>
      <Table
        data={ordersList?.results}
        columns={tableColumns}
        footer={() => (
          <div className="padx-8 pady-4">
            <Space justify="end">
              <Pagination pagination={pagination} results={ordersList} />
            </Space>
          </div>
        )}
        emptyText={
          isLoading ? (
            <Loader loading />
          ) : (
            <Space className="pady-12" justify="center">
              No data
            </Space>
          )
        }
        variants={[
          "bordered",
          "elevated",
          "dense",
          "stripped-even",
          "highlight-hover",
        ]}
        scroll={{ x: true }}
        rowKey="id"
        components={{
          body: {
            row: (props: any) => (
              <TableRowLink
                {...props}
                makeUrl={(id) => `/transactions/${id}`}
              />
            ),
          },
        }}
      />
    </>
  );
};

export default Orders;
