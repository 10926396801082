import React from "react";
import { UseMutationResult, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import {
  Modal,
  Form,
  useForm,
  Button,
  Space,
  Input,
  Switch,
  Select,
} from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import * as api from "api";
import { mapToSelectOptions } from "utils";
import { AlertErrors, Icon, ProductSelect, Upload } from "components";
import { AttachmentType, FormControl, NetworkCardEntity } from "types";
import { useIsUploading } from "hooks";
import { NetworkCardPhotoUpload } from "./NetworkCardPhotoUpload";

export interface NetworkCardModalProps extends ModalProps {
  mutation: UseMutationResult<
    NetworkCardEntity,
    AxiosError,
    Partial<NetworkCardEntity>
  >;
  data?: NetworkCardEntity;
}

export const NetworkCardModal = ({
  data,
  mutation,
  ...modalProps
}: NetworkCardModalProps) => {
  const [form] = useForm();
  const queryClient = useQueryClient();

  const { data: campaigns, isLoading } = useQuery(["campaigns-list"], () =>
    api.campaigns.getList(),
  );

  React.useEffect(() => {
    const values = {
      ...data,
      product: data?.product?.id,
      campaign: data?.campaign_name,
      video: data?.video
        ? [
            {
              file: data?.video,
              data: data?.video,
            },
          ]
        : [],
      image: data?.image_url
        ? [
            {
              data: { url: data?.image_url },
              file: { url: data?.image_url },
              isSuccess: true,
            },
          ]
        : [],
    };
    form.setFieldsValue(values);
  }, [data, form]);

  const campaignsList = mapToSelectOptions(campaigns, "name");
  const { isUploading, checkFields } = useIsUploading(["video"]);

  const submitHandler = (values: any) => {
    mutation.mutate(
      {
        ...values,
        campaign: Number(values?.campaign) || data?.campaign,
        gift_validity_duration: values.gift_validity_duration || null,
        image_url: values.image?.[0]?.data?.url,
        product: values.product || null,
        video: (values.video && values.video[0]?.data?.id) || data?.video,
      },
      {
        onSuccess: () => {
          form.resetFields();
          modalProps.onClose?.();
          queryClient.invalidateQueries(["network-cards"]);
          queryClient.invalidateQueries(["campaigns-list"]);
        },
      },
    );
  };

  return (
    <Modal size="small" {...modalProps}>
      <Form form={form} onFinish={submitHandler} onFieldsChange={checkFields}>
        <Modal.Content>
          <h3>Image</h3>
          <div className="pady-2"></div>
          <NetworkCardPhotoUpload />
          <Form.Field
            name="title"
            label="Title"
            initialValue=""
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>

          <Form.Field
            name="gift_validity_duration"
            label="Gift validity duration (days)"
            rules={[{ required: true }]}
            initialValue={null}
          >
            <Input type="number" min="0" />
          </Form.Field>

          <Form.Field
            name="is_active"
            label="Active"
            rules={[{ required: true }]}
            initialValue={true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Field>

          <Form.Field
            name="is_paid"
            label="Is paid"
            rules={[{ required: true }]}
            initialValue={false}
            valuePropName="checked"
          >
            <Switch />
          </Form.Field>

          <Form.Field
            name="for_all_tiers"
            label="Paid purchase, free gift"
            rules={[{ required: true }]}
            initialValue={false}
            valuePropName="checked"
          >
            <Switch />
          </Form.Field>

          <Form.Field name="product" label="Product">
            {(_control) => {
              const control = _control as FormControl<number | undefined>;
              return (
                <ProductSelect
                  value={control.value}
                  onChange={(product) => control.onChange(product?.id)}
                />
              );
            }}
          </Form.Field>
          <Form.Field name="campaign" label="Campaign">
            <Select
              placeholder="Select campaign"
              loading={isLoading}
              options={campaignsList}
            />
          </Form.Field>
          <AlertErrors error={mutation.error} />
          <Form.Field name="video" label="Video">
            <Upload
              accept="video/*"
              name="files"
              customRequest={(o) =>
                api.attachments.post(o, {
                  attachmentType: AttachmentType.VIDEO,
                })
              }
            >
              <Button size="medium" className="border-dashed full-width">
                Upload
              </Button>
            </Upload>
          </Form.Field>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={modalProps.onClose}>Cancel</Button>
            <Button
              submit
              type="primary"
              loading={mutation.isLoading}
              prefix={<Icon type="edit" />}
              disabled={mutation.isLoading || isUploading}
            >
              Save
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
