import { NO_AVAILABLE_ERRORS_MESSAGE } from "app-constants";
import { UseNotifyResult } from "types/ebs-design";

import { extractErrorMessages } from ".";

export function notifyErrors(notify: UseNotifyResult, error: any) {
  const errorMessages = extractErrorMessages(error);
  errorMessages.forEach((e) =>
    notify.error({
      title: e.title || "",
      description: e.content,
    }),
  );
  if (!errorMessages.length && error.message)
    notify.error({ title: "", description: error.message });
  if (!error.message)
    notify.error({ title: "", description: NO_AVAILABLE_ERRORS_MESSAGE });
}
