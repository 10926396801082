import { Link } from "react-router-dom";
import differenceInYears from "date-fns/differenceInYears";

import { Checkbox, Label, Space, Tooltip } from "ebs-design";
import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";

import { Icon } from "components";
import { UserFlag, UserProfileEntity } from "types";
import { LOCALE } from "app-constants";

import { ModuleAction } from "features/modules-permissions/ModuleAction";

interface Args {
  onBlockSwitch: React.Dispatch<UserProfileEntity>;
  onDelete: React.Dispatch<UserProfileEntity>;
  onUndelete: React.Dispatch<UserProfileEntity>;
  onRecordSelect: React.Dispatch<UserProfileEntity>;
  selectedUsers: Set<number>;
  onPageSelect: () => void;
  selectable: boolean;
  isPageSelected: boolean;
}

const MAX_DISPLAYED_FLAGS = 2;

export const getUsersTableColumns = ({
  onBlockSwitch,
  onDelete,
  onUndelete,
  selectedUsers,
  onPageSelect,
  onRecordSelect,
  selectable,
  isPageSelected,
}: Args) =>
  [
    ...(selectable
      ? [
          {
            title: (
              <Checkbox
                checked={isPageSelected}
                onChange={() => onPageSelect()}
              />
            ),
            render: (_, user) => (
              <>
                <span
                  className="cell-overlay"
                  onClick={(e) => {
                    e.stopPropagation();
                    onRecordSelect(user);
                  }}
                >
                  <Checkbox checked={selectedUsers.has(user.id)} />
                </span>
              </>
            ),
          } as ColumnType<UserProfileEntity>,
        ]
      : []),
    {
      title: "Username",
      dataIndex: "username",
      ordering: "username",
    },
    {
      title: "Name",
      dataIndex: "name",
      ordering: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ordering: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      ordering: "email",
    },
    {
      title: "Flags",
      dataIndex: "flags",
      render: (flags: UserFlag[] | null) => (
        <div style={{ minWidth: 250 }}>
          <Space wrap size="small">
            {flags?.slice(0, MAX_DISPLAYED_FLAGS).map((flag) => (
              <Label type="fill" status="warning" text={flag.code} />
            ))}
            {!!flags?.length && (
              <Tooltip
                placement="auto"
                trigger="hover"
                tooltip={
                  <>
                    {flags?.map((flag) => (
                      <div>
                        <Label type="fill" status="warning" text={flag.code} />
                        <div>
                          <small className="color-secondary">
                            {flag.description || "(No description)"}
                          </small>
                        </div>
                        <div className="pady-2"></div>
                      </div>
                    ))}
                  </>
                }
                inline
                width={300}
              >
                <Icon className="rotate-90" type="moreactions" />
              </Tooltip>
            )}
          </Space>
        </div>
      ),
    },
    {
      title: "Age",
      dataIndex: "birthday",
      ordering: "birthday",
      render: (value: string) => {
        return differenceInYears(new Date(), new Date(value));
      },
    },
    {
      title: "Sessions",
      dataIndex: ["stats", "sessions", "count"],
      ordering: "stats_sessions_count",
    },
    {
      title: "Paid Gifts Sent",
      dataIndex: ["stats", "gifts", "total_paid_gifts"],
      ordering: "stats_gifts_total_paid_gifts",
    },
    {
      title: "Free Gifts Sent",
      dataIndex: ["stats", "gifts", "total_free_gifts"],
      ordering: "stats_gifts_total_free_gifts",
    },
    {
      title: "Gifts Recieved",
      dataIndex: ["stats", "gifts", "total_received_gifts"],
      ordering: "stats_gifts_total_received_gifts",
    },
    {
      title: "SignUp Date",
      dataIndex: "date_joined",
      render: (value: string) =>
        value && new Date(value).toLocaleDateString(LOCALE),
      ordering: "date_joined",
    },
    {
      title: "",
      dataIndex: "id",
      render: (id: number, user) => {
        return (
          <Space size="small">
            {user.has_delete_request && (
              <ModuleAction module="users">
                <span
                  className="with-title"
                  title={`Delete user`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(user);
                  }}
                >
                  <Icon type="delete" />
                </span>
              </ModuleAction>
            )}
            {user.has_delete_request && (
              <ModuleAction module="users">
                <span
                  className="with-title"
                  title={`Undelete user`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onUndelete(user);
                  }}
                >
                  <Icon type="skip" />
                </span>
              </ModuleAction>
            )}

            <ModuleAction module="users">
              <span
                className="with-title"
                title={`${user.is_suspended ? "Unblock" : "Block"} user`}
                onClick={(e) => {
                  e.stopPropagation();
                  onBlockSwitch(user);
                }}
              >
                <Icon type={user.is_suspended ? "invite-friends" : "lock"} />
              </span>
            </ModuleAction>
            <ModuleAction module="users">
              <Link
                className="with-title"
                title="Edit user"
                to={`users/${id}/edit/`}
                onClick={(e) => e.stopPropagation()}
              >
                <Icon type="edit" />
              </Link>
            </ModuleAction>
            <ModuleAction module="send_gift">
              <Link
                className="with-title"
                title="Send gift"
                to={`/gifts/send/?users_id=${id}`}
                onClick={(e) => e.stopPropagation()}
              >
                <Icon type="status-sent" />
              </Link>
            </ModuleAction>
          </Space>
        );
      },
    },
  ] as ColumnType<UserProfileEntity>[];
