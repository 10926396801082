import { Form, Button } from "ebs-design";
import { Upload } from "components";
import { attachments } from "api";
import { AttachmentType } from "types";

export const CouponWrappingField = () => {
  return (
    <>
      <Form.Field
        name="wrapping_image"
        label="Wrapping image"
        rules={[{ required: true }]}
      >
        <Upload
          accept="image/*"
          name="files"
          customRequest={(o) =>
            attachments.post(o, {
              attachmentType: AttachmentType.IMAGE,
            })
          }
        >
          <Button size={"medium"} className="border-dashed full-width">
            Upload
          </Button>
        </Upload>
      </Form.Field>
    </>
  );
};
