import { subYears } from "date-fns";

import { CohortParameters, NumRangeStr } from "types";
import { dateToInputFormat } from "utils";

export const queryParamsFromCohortParams = (parameters?: CohortParameters) =>
  filterQuery({
    gender: parameters?.gender?.map((g) => g.toLowerCase()),
    ...queryParamsFromRanges("birthday", parameters?.age, (a) =>
      dateToInputFormat(subYears(new Date(), Number(a))),
    ),
    ...queryParamsFromRanges("revenue", parameters?.financial_metrics?.revenue),
    ...queryParamsFromRanges(
      "revenue_monthly",
      parameters?.financial_metrics?.revenue_monthly,
    ),
    ...queryParamsFromRanges(
      "paid_gifts_last_12_months",
      parameters?.financial_metrics?.paid_gifts_last_12_months,
    ),
    location: parameters?.location?.map((n) => n?.name?.toLowerCase()),
    ...queryParamsFromRanges("free_gifts", parameters?.free_gifts),
    ...queryParamsFromRanges("invites", parameters?.invites),
    favourite_brands: parameters?.favourite_brands?.map((b) => b.toLowerCase()),
    ...queryParamsFromRanges(
      "close_friends",
      parameters?.in_app_metrics?.close_friends,
    ),
    ...queryParamsFromRanges("sessions", parameters?.in_app_metrics?.sessions),
    ...queryParamsFromRanges(
      "special_days",
      parameters?.in_app_metrics?.special_days,
    ),
    notifications: parameters?.in_app_metrics?.notifications,
    interests: parameters?.interests,
    companies: parameters?.business_companies?.map((c) => c.id),
  });

function queryParamsFromRanges(
  name: string,
  ranges?: NumRangeStr[],
  transform: (v: string) => string = (v) => v,
) {
  return {
    [`${name}__lt`]: ranges
      ?.filter((r) => !r.split("-")[0] && r.split("-")[1])
      .map((r) => r.split("-")[1])
      .map(transform),

    [`${name}__gt`]: ranges
      ?.filter((r) => r.split("-")[0] && !r.split("-")[1])
      .map((r) => r.split("-")[0])
      .map(transform),

    [`${name}__range`]: ranges
      ?.filter((r) => r.split("-")[0] && r.split("-")[1])
      .map((r) => r.split("-").map(transform).join("__")),
  };
}

function filterQuery(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => {
      if (!value) return false;
      if (value instanceof Array && !value.length) return false;
      if (value instanceof Object && !Object.keys(value).length) return false;
      return true;
    }),
  );
}
