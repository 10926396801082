import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { ProductEntity } from "types";
import { products } from "api";

import { ProductForm } from "../ProductForm";

interface ProductEditProps {
  isBusiness?: boolean;
}

const ProductEdit = ({ isBusiness }: ProductEditProps) => {
  const id = Number(useParams<{ id: string }>().id);
  // if (!id) history.push("/products/");

  const productMutation = useMutation<
    ProductEntity,
    AxiosError,
    Partial<ProductEntity>
  >((product) => products.patch(id, product));

  const productQuery = useQuery<ProductEntity, AxiosError>(
    ["products", id],
    () => products.getById(id),
  );

  const canMutate = !(
    productQuery.isLoading ||
    productQuery.isFetching ||
    productMutation.isLoading
  );

  return (
    <ProductForm
      isBusiness={isBusiness}
      title="Edit product"
      values={productQuery.data}
      isLoading={productQuery.isLoading}
      isFetching={!productQuery.isLoading && productQuery.isFetching}
      canMutate={canMutate}
      productMutation={productMutation}
      errors={[productQuery.error, productMutation.error]}
      showForm={!productQuery.isLoading && !productQuery.isError}
      onSuccessMessage="Product was successfully updated."
    />
  );
};

export default ProductEdit;
