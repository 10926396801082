export const cmsTabs = [
  {
    label: "Tutorials",
    route: "/cms/tutorials",
  },
  {
    label: "Gift covers",
    route: "/cms/gift-covers",
  },
  {
    label: "Sliders",
    route: "/cms/sliders",
  },
  {
    label: "Interests",
    route: "/cms/interests",
  },
  {
    label: "Face filters",
    route: "/cms/face-filters",
  },
  {
    label: "Network cards",
    route: "/cms/network-cards",
  },
];
