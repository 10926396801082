import React from "react";

import { useHasModuleActions } from "hooks";
import { PRSNTModuleCodeName } from "types";

export interface ModuleActionProps {
  module: PRSNTModuleCodeName;
}

export const ModuleAction: React.FC<ModuleActionProps> = ({
  module,
  children,
}) => {
  const hasModuleActions = useHasModuleActions(module);
  return <>{hasModuleActions && children}</>;
};
