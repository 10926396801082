import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";
import {
  Results,
  UserProfileEntity,
  ProductEntity,
  UserGiftEntity,
  ApiQueryType,
  UserStats,
} from "types";

export const users = {
  getStats: async () => {
    const { data } = await apiAxios.get<UserStats>("/users/stats/");
    return data;
  },
  getList: async (query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/users/list/`, query);
    const { data } = await apiAxios.get<Results<UserProfileEntity>>(url);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<UserProfileEntity>(`/users/${id}/`);
    return data;
  },
  getFavourites: async (id: number, query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/users/${id}/products/favourites/list/`, query);
    const { data } = await apiAxios.get<Results<ProductEntity>>(url);
    return data;
  },
  getGifts: async (id: number, query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/users/${id}/gifts/list/`, query);
    const { data } = await apiAxios.get<Results<UserGiftEntity>>(url);
    return data;
  },
  patch: async (id: number, user: Partial<UserProfileEntity>) => {
    const { data } = await apiAxios.patch<UserProfileEntity>(
      `/users/${id}/`,
      user,
    );
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/users/${id}/`);
    return data;
  },
  /**
   * @param ids If no ids provided will delete all entries
   */
  bulkDelete: async (ids?: number[]) => {
    const { data } = await apiAxios.post(`/users/bulk-delete/`, {
      users: ids || [],
    });
    return data;
  },
};
