import React, { createContext } from "react";
import cn from "classnames";

import { noop } from "utils";

export type SetActiveItemAction = React.Dispatch<React.SetStateAction<any>>;

export interface SelectListContextValue {
  active: any;
  setActive: SetActiveItemAction;
  activeItemClassName: string;
  clearable: boolean;
}

export const SelectListContext = createContext<SelectListContextValue>({
  active: undefined,
  setActive: noop,
  activeItemClassName: "active",
  clearable: false,
});

export interface SelectListProviderProps {
  defaultActive?: any;
  activeItemClassName?: string;
  value?: any;
  onChange?: (a: any) => void;
  clearable?: boolean;
}

interface PropsWithItemKey {
  itemKey: any;
}

export const SelectListProvider: React.FC<SelectListProviderProps> = ({
  children,
  defaultActive,
  value,
  onChange,
  activeItemClassName = "active",
  clearable = false,
}) => {
  const [_active, _setActive] = React.useState(defaultActive);

  const active = onChange ? value : _active;
  const setActive = onChange || _setActive;

  return (
    <SelectListContext.Provider
      value={{ active, setActive, activeItemClassName, clearable }}
    >
      {children}
    </SelectListContext.Provider>
  );
};

export const SelectListItem: React.FC<
  React.HTMLProps<HTMLDivElement> & PropsWithItemKey
> = ({ children, itemKey, className, ...props }) => {
  const {
    activeItemClassName: activeCls,
    active,
    setActive,
    clearable,
  } = React.useContext(SelectListContext);

  return (
    <div
      className={cn(className, itemKey === active && activeCls)}
      onClick={() =>
        active === itemKey
          ? clearable && setActive(undefined)
          : setActive(itemKey)
      }
      {...props}
    >
      {children}
    </div>
  );
};
