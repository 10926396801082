import React from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { parse, parseISO } from "date-fns";
import { AxiosError } from "axios";

import {
  useForm,
  Form,
  useNotify,
  Card,
  Loader,
  Button,
  Space,
} from "ebs-design";
import { FormProps } from "ebs-design/dist/components/organisms/Form";

import { AlertErrors, Container, Flex, Icon } from "components";
import {
  GenericObject,
  UserProfileEntity,
  NomenclatureSingular,
  NomenclatureIdKey,
} from "types";
import { users } from "api";
import {
  dateToInputFormat,
  deepProp,
  defaultDateFormat,
  nomenclatureSingulars,
  typedPickKeys,
} from "utils";
import { useAddressNomenclatures, useIsUploading } from "hooks";

import {
  UserAddressFields,
  UserPersonalInfoFields,
  UserPhotoUpload,
  UserPermissionsFields,
  CompanyForm,
} from "../UserEdit";

const UserEdit = () => {
  const id = Number(useParams<{ id: string }>().id);
  const history = useHistory();

  if (!id) history.push("/users/");

  const [form] = useForm();
  const notify = useNotify();

  const addressNomenclatures = useAddressNomenclatures();
  const { toClearWhenChanges, setQuery } = addressNomenclatures;

  const { isUploading, checkFields } = useIsUploading(["photo"]);

  const setUserFields = React.useCallback(
    (user: UserProfileEntity) => {
      form.setFieldsValue({
        ...typedPickKeys(user, [
          "first_name",
          "last_name",
          "username",
          "email",
          "phone",
          "birthday",
          "modules_permissions",
          "is_superuser",
        ]),
        address: {
          country_id: user?.address?.country?.id,
          region_id: user?.address?.region?.id,
          subregion_id: user?.address?.subregion?.id,
          city_id: user?.address?.city?.id,
          zip_code: user?.address?.zip_code,
        },
        birthday: parseISO(user?.birthday),
        photo: user?.photo
          ? [{ file: user.photo, data: user.photo, isSuccess: true }]
          : [],
      });
      setQuery({
        country_id: user?.address?.country?.id,
        region_id: user?.address?.region?.id,
        subregion_id: user?.address?.subregion?.id,
        city_id: user?.address?.city?.id,
      });
    },
    [form, setQuery],
  );

  const userQuery = useQuery<UserProfileEntity, AxiosError>(["user", id], () =>
    users.getById(id),
  );
  const user = userQuery.data;

  const userMutation = useMutation<
    UserProfileEntity,
    AxiosError,
    Partial<UserProfileEntity>
  >((user) => users.patch(id, user), {
    onSuccess: (data) => {
      setUserFields(data);
      notify.success({
        title: "",
        description: "User was successfully updated.",
      });
    },
  });

  React.useEffect(() => {
    user && setUserFields(user);
  }, [setUserFields, user]);

  const canMutate = !(
    userQuery.isLoading ||
    userQuery.isFetching ||
    userMutation.isLoading ||
    isUploading
  );

  const onFinishHandler = React.useCallback(
    (formValues: GenericObject<any>) => {
      const newUser: GenericObject = {
        ...formValues,
        modules_permissions: formValues.is_superuser
          ? formValues.modules_permissions
          : {},
        photo_id: deepProp(formValues, "photo", 0, "data", "id"),
      };
      delete newUser.photo;
      if (typeof newUser.birthday === "string") {
        newUser.birthday = dateToInputFormat(
          parse(newUser.birthday, defaultDateFormat, new Date()),
        );
      } else if (typeof newUser.birthday === "object") {
        newUser.birthday = dateToInputFormat(newUser.birthday);
      }
      userMutation.mutate(newUser);
    },
    [userMutation],
  );

  const onFieldsChangeHandler = React.useCallback<
    NonNullable<FormProps["onFieldsChange"]>
  >(
    (fields, allFields) => {
      checkFields(fields, allFields);
      if (!fields.length || fields.length > 1) return;

      const without_id = (s: string) => s.replace("_id", "");

      const field = fields.find(
        (field) =>
          field.name instanceof Array &&
          field.name[0] === "address" &&
          nomenclatureSingulars.includes(
            without_id(field.name[1] as string) as NomenclatureSingular,
          ),
      );
      if (!field) return;

      const idKey = (field.name as string[])[1];
      const key = without_id(idKey) as NomenclatureSingular;

      for (const change of toClearWhenChanges[key]) {
        const idKey = (change + "_id") as NomenclatureIdKey;

        setQuery({ [idKey]: undefined });
        form.setFieldsValue({ address: { [idKey]: undefined } });
      }

      setQuery({ [idKey]: field.value });
    },
    [checkFields, form, setQuery, toClearWhenChanges],
  );

  return (
    <Container>
      <Card collapsible>
        <Card.Header>
          <Flex justify="space-between" align="center">
            <h2>
              Edit user{" "}
              {!userQuery.isLoading && userQuery.isFetching && (
                <Loader.Inline> </Loader.Inline>
              )}
            </h2>
            {user?.company && (
              <div className="small-text cursor-pointer">Need copmany?</div>
            )}
          </Flex>
        </Card.Header>
        <Card.Body>
          <AlertErrors error={userMutation.error} />
          <AlertErrors error={userQuery.error} />

          <Loader loading={userQuery.isLoading}>
            {user && (
              <Form
                labelOptions={{ col: { size: 2 } }}
                controlOptions={{ col: { size: 10 } }}
                form={form}
                onFinish={onFinishHandler}
                onFieldsChange={onFieldsChangeHandler}
                preserve
              >
                <h3>Photo</h3>
                <div className="mary-4"></div>
                <UserPhotoUpload />
                <div className="mary-8"></div>
                <h3>Personal info</h3>
                <div className="mary-4"></div>
                <UserPersonalInfoFields />
                <div className="mary-8"></div>
                <h3>Address</h3>
                <div className="mary-4"></div>
                <UserAddressFields
                  addressNomenclatures={addressNomenclatures}
                  user={user}
                />
                <div className="mary-8"></div>
                <h3>Permissions</h3>
                <div className="mary-4"></div>
                <UserPermissionsFields is_superuser={user.is_superuser} />
              </Form>
            )}
          </Loader>
        </Card.Body>
        <Card.Footer>
          <Space justify="end">
            <Button
              type="primary"
              loading={userMutation.isLoading}
              prefix={<Icon type="edit" />}
              onClick={() => form.submit()}
              disabled={!canMutate}
            >
              Save
            </Button>
          </Space>
        </Card.Footer>
      </Card>
      {user?.company?.id && (
        <>
          <div className="pady-3"></div>
          <CompanyForm id={user.company.id} />
        </>
      )}
    </Container>
  );
};

export default UserEdit;
