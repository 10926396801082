import { Attachment } from "./attachments";
import { BrandEntity } from "./brands";
import { CategoryEntity } from "./category";
import { CurrencyType } from "./common";

export interface ProductEntity {
  id: number;
  title: string;
  cost: number;
  types: ProductType[];
  brand: BrandEntity;
  categories: CategoryEntity[];
  kinship: {};
  currency: Currency;
  featured_image: Attachment;
  images: Attachment[];
  tags: TagEntity[];
  genders: ProductGender[];
  timestamp: string;
  edited_timestamp: string;
  order: number;
  title_en: string;
  title_ru: string;
  title_ro: string;
  description: string;
  description_en: string;
  description_ru: string;
  description_ro: string;
  short_description: string;
  short_description_en: string;
  short_description_ru: string;
  short_description_ro: string;
  slug: string;
  price?: string | number;
  featured: boolean;
  nsfw: boolean;
  data: {};
  metadata: {};
  parameters: {};
  expiring_months: number;
  user: number;
  age_limit: number;
  is_active: boolean;
  in_business_panel?: boolean;
  characteristic_types?: ProductCharacteristic[];
  in_gamification?: boolean;
  is_free?: boolean;
  in_main_catalog?: boolean;
  is_discount: boolean;
  discount_price?: number;
}

export interface UpdateProductEntity extends ProductEntity {
  order_above_product_id: number;
  order_below_product_id: number;
}

export enum ProductType {
  STORE = "STORE",
  DELIVERY = "DELIVERY",
  OTHER = "OTHER",
}

export interface Data {
  vat: Vat;
  name: string;
  slug: string;
  type: string;
  detail: Detail;
  status: Status;
  gc_pool: boolean;
  currency: string;
  discount: number;
  async_only: boolean;
  categories: any[];
  last_updated: string;
  denominations: string[];
  countries_served: string[];
  delivery_methods: string[];
  transaction_types: string[];
  digital_denominations: number[];
}

export interface Detail {
  assets: Assets;
  expiry: string;
  barcode: string;
  description: string;
  website_url: string;
  redemption_methods: string[];
  terms_and_conditions_url: string;
}

export interface Assets {
  logo_url: string;
  gift_card_url: string;
}

export interface Status {
  code: string;
}

export interface Vat {
  rate: string;
  type: string;
  exempt: boolean;
}

export interface Currency {
  id: number;
  timestamp: string;
  edited_timestamp: string;
  code_name: CurrencyType;
  title: string;
  title_en: string;
  title_ru: string;
  title_ro: string;
  description: string;
}

export interface ProductCharacteristic {
  choices?: ProductCharacteristicChoice[];
  characteristic_title?: string;
  representation_type?: ProductCharacteristicRepresantationType;
}

export interface ProductCharacteristicChoice {
  value?: string;
  price?: number;
}
export enum ProductCharacteristicRepresantationType {
  horizontal_list = "horizontal_list",
  vertical_list = "vertical_list",
  dropdown = "dropdown",
}

export interface TagEntity {
  id?: number;
  title?: string;
}

export enum ProductGender {
  HER = "HER",
  HIM = "HIM",
  THEM = "THEM",
}
