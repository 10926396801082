import { Option as SelectOption } from "ebs-design/dist/components/molecules/Select/interfaces";
import { GenericObject, Results } from "types";

export function resultsCount(
  data: Results<unknown> | undefined,
  isLoading: boolean | undefined,
) {
  return data?.count !== undefined ? data?.count : isLoading ? "..." : 0;
}

export function mapToSelectOptions<TItem extends GenericObject>(
  items?: TItem[],
  textPropName: string = "title",
  valuePropsName: string = "id",
): SelectOption[] {
  return (
    items?.map((i) => ({
      text: i[textPropName],
      value: String(i[valuePropsName]),
    })) || []
  );
}
