import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { Loader, Space, Card, InputSearch, Button, SortBy } from "ebs-design";
import { AxiosError } from "axios";
import {
  Table,
  Pagination,
  AlertErrors,
  Icon,
  TableRowLink,
  ConfirmModal,
} from "components";
import { useQueryPagination } from "hooks";
import { Results, UserGiftEntity } from "types";
import { extractOrderingOptions } from "utils";
import { gifts } from "api";
import { getGiftsTableColumns } from "../giftsTableColumns";

import { ModuleAction } from "features/modules-permissions";

const Gifts = () => {
  let history = useHistory();
  const pagination = useQueryPagination();
  const queryClient = useQueryClient();
  const { limit, page } = pagination;

  const [giftToDelete, setGiftToDelete] = React.useState<number>();
  const [search, setSearch] = useQueryParam(
    "search_products",
    withDefault(StringParam, ""),
  );

  const [ordering, setOrdering] = useQueryParam(
    "ordering",
    withDefault(StringParam, ""),
  );

  const queryParams = {
    page,
    limit,
    search,
    ordering,
  };

  const { data: giftsList, isLoading, error } = useQuery<
    Results<UserGiftEntity>,
    AxiosError
  >(["gifts", queryParams], () => gifts.getList(queryParams), {
    enabled: !!page,
  });

  const {
    mutate: deleteMutate,
    isLoading: isLoadingDeleteMutation,
    error: deleteMutationError,
  } = useMutation<unknown, AxiosError, number>(
    (id: number) => gifts.delete(id),
    {
      onSuccess: () => {
        setGiftToDelete(undefined);
        queryClient.invalidateQueries("gifts");
      },
    },
  );

  const tableColumns = React.useMemo(
    () => getGiftsTableColumns({ onDeleteClick: setGiftToDelete }),
    [setGiftToDelete],
  );

  const sortOptions = extractOrderingOptions(tableColumns);

  return (
    <>
      <AlertErrors error={error} />
      <Card>
        <Card.Body>
          <Space justify="space-between">
            <Space>
              <div className="fw-500">Gifts sent ({giftsList?.count || 0})</div>
              <InputSearch
                placeholder="Search gift"
                value={search}
                onSearch={(value: string) => setSearch(value || undefined)}
              />
              <SortBy
                value={ordering}
                onChange={(value: string) => setOrdering(value || undefined)}
                options={sortOptions}
              />
            </Space>
            <ModuleAction module="send_gift">
              <Button
                type="primary"
                prefix={<Icon type="status-sent" />}
                onClick={() => history.push("/gifts/send")}
              >
                Send Gift
              </Button>
            </ModuleAction>
          </Space>
        </Card.Body>
      </Card>
      <div className="pady-3" />
      <Table
        data={giftsList?.results}
        columns={tableColumns}
        footer={() => (
          <div className="padx-8 pady-4">
            <Space justify="end">
              <Pagination pagination={pagination} results={giftsList} />
            </Space>
          </div>
        )}
        emptyText={
          isLoading ? (
            <Loader loading />
          ) : (
            <Space className="pady-12" justify="center">
              No data
            </Space>
          )
        }
        variants={[
          "bordered",
          "elevated",
          "dense",
          "stripped-even",
          "highlight-hover",
        ]}
        scroll={{ x: true }}
        rowKey="id"
        components={{
          body: {
            row: (props: any) => (
              <TableRowLink {...props} makeUrl={(id) => `/gifts/${id}/`} />
            ),
          },
        }}
      />

      <ConfirmModal
        open={!!giftToDelete}
        onCancel={() => setGiftToDelete(undefined)}
        onConfirm={() => giftToDelete && deleteMutate(giftToDelete)}
        onClose={() => setGiftToDelete(undefined)}
        loading={isLoadingDeleteMutation}
      >
        Are you sure to delete this gift?
        <AlertErrors error={deleteMutationError} />
      </ConfirmModal>
    </>
  );
};

export default Gifts;
