import { useMutation, useQueryClient } from "react-query";
import { Space } from "ebs-design";

import { ConfirmModal, AlertErrors } from "components";
import { campaignsTier } from "api";

interface ModalProps {
  visible: boolean;
  onCancel: () => void;
  tierId?: number | null;
}

const DeleteCampaignsTierModal = ({
  visible,
  onCancel,
  tierId,
}: ModalProps) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    campaignsTier.delete,
    {
      onSuccess: () => {
        onCancel();
        queryClient.invalidateQueries("campaigns-tier");
      },
    },
  );

  return visible ? (
    <ConfirmModal
      open={visible}
      loading={isLoading}
      onConfirm={() => mutate(tierId!)}
      onClose={onCancel}
      onCancel={onCancel}
    >
      {isError ? (
        <AlertErrors error={error} />
      ) : (
        <Space align="center" justify="center">
          Are you sure that you want to delete this tier?
        </Space>
      )}
    </ConfirmModal>
  ) : null;
};
export default DeleteCampaignsTierModal;
