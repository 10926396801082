import { CouponEntity } from "types";

export const formFieldsValueFromCopuon = (coupon: CouponEntity) => {
  return {
    ...coupon,
    name: coupon?.name,
    max_usage: String(coupon?.max_usage),
    total_amount: String(coupon?.total_amount),
    wrapping_image: coupon?.wrapping_image
      ? [
          {
            data: coupon?.wrapping_image,
            file: coupon?.wrapping_image,
            isSuccess: true,
          },
        ]
      : [],
    from_photo: coupon?.from_photo
      ? [
          {
            data: { url: coupon?.from_photo },
            file: { url: coupon?.from_photo },
            isSuccess: true,
          },
        ]
      : [],
    product: {
      ...coupon?.product,
      brand_id: String(coupon?.product?.brand?.id || ""),
      categories: coupon?.product?.categories?.map((c) => ({
        id: String(c.id),
        parent_id: String(c.parent?.id || c.parent_id || "") || undefined,
      })),
      images:
        coupon?.product?.images
          ?.map((image) => ({
            ...image,
            featured: image?.id === coupon?.product?.featured_image?.id,
          }))
          .map((i) => ({
            file: i,
            data: i,
            isSuccess: true,
            isFinished: true,
          })) || [],
    },
  };
};
