import $axios, { CancelToken } from "axios";

import { GenericObject } from "types";
import { authorizationHeadersInterceptor, cancelInterceptors } from "./utils";

export const apiAxios = $axios.create();

// eslint-disable-next-line import/no-named-as-default-member
export const cancelToken = $axios.CancelToken;

apiAxios.defaults.headers.post["Content-Type"] = "application/json";
apiAxios.defaults.baseURL = process.env.REACT_APP_BASE_API;

apiAxios.interceptors.response.use(...cancelInterceptors);
apiAxios.interceptors.request.use(authorizationHeadersInterceptor);

export const createCancelTokenHandler = (
  apiObject: GenericObject,
): GenericObject => {
  // initializing the cancel token handler object
  const cancelTokenHandler: GenericObject = {};

  // for each property in apiObject, i.e. for each request
  Object.getOwnPropertyNames(apiObject).forEach((propertyName) => {
    // initializing the cancel token of the request
    const cancelTokenRequestHandler: { cancelToken?: GenericObject } = {};

    // associating the cancel token handler to the request name
    cancelTokenHandler[propertyName] = {
      handleRequestCancellation: () => {
        // if a previous cancel token exists,
        // cancel the request
        cancelTokenRequestHandler.cancelToken &&
          cancelTokenRequestHandler.cancelToken.cancel(
            `${propertyName} canceled`,
          );

        // creating a new cancel token
        cancelTokenRequestHandler.cancelToken = cancelToken.source();

        // returning the new cancel token
        return cancelTokenRequestHandler.cancelToken;
      },
    };
  });

  return cancelTokenHandler;
};

// Create cancel token by name
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const cancelTokenHandler = (apiObject: GenericObject) => {
  const cancelTokenHandlerObject = createCancelTokenHandler(apiObject);

  return (name: string): CancelToken =>
    cancelTokenHandlerObject[name]?.handleRequestCancellation().token;
};
