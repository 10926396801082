import { Form, Input, Textarea } from "ebs-design";

import { BrandsSelect } from "components";
import { ProductGender } from "types";
import {
  FieldContent,
  ProductCategoriesFieldContent,
  ProductGendersField,
} from "features/products/ProductForm";

export const CouponProductFields = () => {
  return (
    <>
      <Form.Field
        label="Name"
        name={["product", "title"]}
        rules={[{ required: true }]}
        initialValue=""
      >
        <Input />
      </Form.Field>
      <Form.Field
        label="Description"
        name={["product", "description"]}
        rules={[{ required: true }]}
        initialValue=""
      >
        <Textarea />
      </Form.Field>
      <Form.Field
        label="Price"
        name={["product", "price"]}
        rules={[{ required: true }]}
        initialValue=""
      >
        <Input type="number" min="0" />
      </Form.Field>
      <Form.Field label="Age limit" name={["product", "age_limit"]}>
        <Input type="number" min="0" />
      </Form.Field>
      <Form.Field
        label="Brand"
        name={["product", "brand_id"]}
        rules={[{ required: true }]}
      >
        <BrandsSelect />
      </Form.Field>
      <Form.Field
        name={["product", "categories"]}
        label="Categories"
        initialValue={[]}
      >
        <ProductCategoriesFieldContent />
      </Form.Field>
      <Form.Field
        label="Genders"
        name={["product", "genders"]}
        initialValue={[ProductGender.THEM]}
        rules={[{ required: true }]}
      >
        <ProductGendersField />
      </Form.Field>
      <Form.Field
        name={["product", "images"]}
        label="Images"
        initialValue={[]}
        rules={[{ required: true }]}
      >
        <FieldContent />
      </Form.Field>
    </>
  );
};
