import { useState } from "react";
import { Loader, Button, ListGroup, Space } from "ebs-design";
import { useQuery } from "react-query";
import { useSetState } from "react-use";
import { AxiosError } from "axios";
import cn from "classnames";

import { faq } from "api";
import { AlertErrors, Icon } from "components";
import { faqDefaultQueryLimit } from "utils";
import { FaqCategoryEntity } from "types";

import {
  FaqConfirmModalState,
  FaqQuestionModalState,
  FaqCategoryModalState,
  FaqDeleteModalState,
} from "features/faq/types";

import FaqQuestions from "features/faq/FaqQuestions";
import FaqQuestionForm from "features/faq/FaqQuestionForm";
import FaqDeleteQuestionModal from "features/faq/FaqDeleteQuestionModal";
import FaqCategoryForm from "features/faq/FaqCategoryForm";
import FaqDeleteCategoryModal from "features/faq/FaqDeleteCategoryModal";
import { ModuleAction } from "features/modules-permissions";

const defaultModalState = {
  visible: false,
  activeQuestion: null,
  onCancel: () => {},
};

const defaultConfirmState = {
  visible: false,
  questionId: null,
};

const defaultDeleteCategoryModal = {
  visible: false,
  categoryId: null,
};

const defaultCategoryState = {
  visible: false,
  category: null,
};

const FaqCategories = () => {
  //set active Category
  const [activeCategory, setActiveCategory] = useState<number | null>(null);
  //set modal for edit add category
  const [categoryModal, setCategoryModal] =
    useSetState<FaqCategoryModalState>(defaultCategoryState);
  //set modal for edit add question
  const [questionModal, setQuestionModal] =
    useSetState<FaqQuestionModalState>(defaultModalState);
  //set confirm modal for question
  const [confirmModal, setConfirmModal] =
    useSetState<FaqConfirmModalState>(defaultConfirmState);
  // set confirm modal for delete  category
  const [deleteCategoryModal, setDeleteCategoryModal] =
    useSetState<FaqDeleteModalState>(defaultDeleteCategoryModal);

  //Fetch categories
  const { data, isLoading, isError, error } = useQuery<
    FaqCategoryEntity[],
    AxiosError
  >(
    "faq-categories",
    () => faq.getCategories({ limit: faqDefaultQueryLimit }),
    {
      onSuccess: (value) => {
        setActiveCategory(value[0].id);
      },
    },
  );

  return (
    <Loader loading={isLoading}>
      {isError ? (
        <AlertErrors error={error!} />
      ) : (
        <div className="faq">
          <div className="faq__container">
            <ListGroup className="faq__container-list">
              {data?.map((item) => (
                <ListGroup.Item key={item.id} className="faq__list-item">
                  <div
                    className="faq__list-item__wrapper"
                    onClick={() => {
                      setActiveCategory(item.id);
                    }}
                  >
                    <div
                      className={cn("faq__category", {
                        "faq__category--active": item.id === activeCategory,
                      })}
                    >
                      <span className="faq__category-name">{item.title}</span>
                      <div className="faq__category-controls">
                        <ModuleAction module="faq">
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setCategoryModal({
                                visible: true,
                                category: item,
                              });
                            }}
                          >
                            <Button
                              className="faq__category-edit ebs-button__action"
                              size="small"
                            >
                              <Icon type="edit" />
                            </Button>
                          </span>
                        </ModuleAction>
                        <ModuleAction module="faq">
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteCategoryModal({
                                visible: true,
                                categoryId: item.id,
                              });
                            }}
                          >
                            <Button
                              className="faq__category-delete ebs-button__action"
                              size="small"
                            >
                              <Icon type="delete" />
                            </Button>
                          </span>
                        </ModuleAction>
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>{" "}
            <ModuleAction module="faq">
              <Space className="faq__btn-space">
                <Button
                  className="faq__add"
                  onClick={() => setCategoryModal({ visible: true })}
                >
                  <Icon type="plus" />
                  Add category
                </Button>
              </Space>
            </ModuleAction>
          </div>
          <div className="faq__container">
            <div className="faq__container-list">
              <FaqQuestions
                categoryId={activeCategory}
                setQuestionModal={(value) => setQuestionModal(value)}
                setConfirmModal={(value) => setConfirmModal(value)}
              />
            </div>
            <ModuleAction module="faq">
              <Space className="faq__btn-space" justify="center">
                <Button
                  className="faq__add"
                  size="medium"
                  type="ghost"
                  onClick={() =>
                    setQuestionModal({
                      visible: true,
                    })
                  }
                >
                  <Icon type="plus" />
                  Add question
                </Button>
              </Space>
            </ModuleAction>
          </div>
          {questionModal.visible && (
            <FaqQuestionForm
              open={questionModal.visible}
              onCancel={() => setQuestionModal(defaultModalState)}
              categoryId={activeCategory || 0}
              activeQuestion={questionModal.activeQuestion}
            />
          )}
          {confirmModal.visible && (
            <FaqDeleteQuestionModal
              visible={confirmModal.visible}
              onCancel={() => setConfirmModal(defaultConfirmState)}
              questionId={confirmModal.questionId}
            />
          )}
          {categoryModal.visible && (
            <FaqCategoryForm
              open={categoryModal.visible}
              onCancel={() => setCategoryModal(defaultCategoryState)}
              activeCategory={categoryModal.category}
            />
          )}
          {deleteCategoryModal.visible && (
            <FaqDeleteCategoryModal
              visible={deleteCategoryModal.visible}
              onCancel={() =>
                setDeleteCategoryModal(defaultDeleteCategoryModal)
              }
              categoryId={deleteCategoryModal.categoryId}
            />
          )}
        </div>
      )}
    </Loader>
  );
};

export default FaqCategories;
