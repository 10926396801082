import { useQuery } from "react-query";
import { Collapse, Space, Loader, Button } from "ebs-design";
import { AxiosError } from "axios";
import "react-quill/dist/quill.snow.css";

import { faq } from "api";
import { AlertErrors, Icon } from "components";
import { faqDefaultQueryLimit } from "utils";
import { FaqEntity } from "types";
import {
  FaqQuestionModalState,
  FaqConfirmModalState,
} from "features/faq/types";
import { ModuleAction } from "features/modules-permissions";

interface Props {
  categoryId: number | null;
  setQuestionModal: (value: FaqQuestionModalState) => void;
  setConfirmModal: (value: FaqConfirmModalState) => void;
}

const FaqQuestions = ({
  categoryId,
  setQuestionModal,
  setConfirmModal,
}: Props) => {
  const { data, isFetching, isError, error } = useQuery<
    FaqEntity[],
    AxiosError
  >(
    ["faq-questions", { category_id: categoryId, limit: faqDefaultQueryLimit }],
    () =>
      faq.getQuestions({
        category_id: categoryId,
        limit: faqDefaultQueryLimit,
      }),
    {
      enabled: !!categoryId,
    },
  );

  return (
    <Loader loading={isFetching}>
      {isError && <AlertErrors error={error!} />}
      {data?.length && categoryId ? (
        <Collapse.Group className="faq__collapse">
          {data.map((question: FaqEntity) => {
            return (
              <Collapse
                collapsed
                className="faq__collapse-item"
                key={question.id}
              >
                <Collapse.Header className="faq__collapse__header">
                  <Space justify="space-between">
                    {question.title}
                    <div className="nowrap">
                      <ModuleAction module="faq">
                        <span
                          className="faq__btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setConfirmModal({
                              visible: true,
                              questionId: question.id,
                            });
                          }}
                        >
                          <Button size="small" className="ebs-button__action">
                            <Icon type="delete" />
                          </Button>
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            setQuestionModal({
                              visible: true,
                              activeQuestion: question,
                            });
                          }}
                        >
                          <Button
                            size="small"
                            className="faq__btn ebs-button__action"
                          >
                            <Icon type="edit" />
                          </Button>
                        </span>
                      </ModuleAction>
                    </div>
                  </Space>
                </Collapse.Header>
                <Collapse.Body className="faq__question-body">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.description || "",
                    }}
                  />
                </Collapse.Body>
              </Collapse>
            );
          })}
        </Collapse.Group>
      ) : null}
      {!data?.length && !isError ? (
        <p className="faq__no-question">There's no questions</p>
      ) : null}
    </Loader>
  );
};

export default FaqQuestions;
