import { useState } from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Loader } from "ebs-design";

import { TabsWithRoute, AlertErrors } from "components";
import { CategoryEntity, Results } from "types";

import { categories } from "api";
import CategoriesList from "features/categories/CategoriesList";
import SubcategoriesList from "features/categories/SubcategoriesList";

const tabs = [
  {
    label: "Categories",
    route: "/categories",
  },
  {
    label: "Brands",
    route: "/brands",
  },
];

const Categories = () => {
  const [activeCategory, setActiveCategory] = useState<CategoryEntity | null>(
    null,
  );

  const categoriesQueryParams = {
    parent__isnull: true,
  };

  const { data, isLoading, isError, error } = useQuery<
    Results<CategoryEntity>,
    AxiosError
  >(["categories", categoriesQueryParams], () =>
    categories.getCategoriesList(categoriesQueryParams),
  );

  return (
    <TabsWithRoute tabItems={tabs}>
      <Loader loading={isLoading}>
        {isError ? (
          <AlertErrors error={error} />
        ) : (
          <div className="categories">
            <CategoriesList
              categories={data}
              activeCategory={activeCategory}
              onClick={(category) => setActiveCategory(category)}
            />

            <SubcategoriesList activeCategory={activeCategory} />
          </div>
        )}
      </Loader>
    </TabsWithRoute>
  );
};

export default Categories;
