import { Form } from "ebs-design";

import { ProductCategoriesFieldContent } from "./ProductCategoriesFieldContent";

export interface ProductCategoriesFieldItem {
  id?: number;
  parent_id?: number;
}

export const ProductCategoriesField = () => {
  return (
    <>
      <Form.Field name="categories" label="Categories" initialValue={[]}>
        <ProductCategoriesFieldContent />
      </Form.Field>
    </>
  );
};
