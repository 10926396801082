import { Form, Input, Select, Textarea } from "ebs-design";
import { useQuery } from "react-query";
import { mapToSelectOptions } from "utils";
import * as api from "api";

export const CouponImportFields = () => {
  const { data: campaigns, isLoading } = useQuery(
    ["campaigns-list"],
    () => api.campaigns.getList(),
    {
      select: (campaigns) => mapToSelectOptions(campaigns, "name"),
    },
  );

  return (
    <>
      <Form.Field label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Field>

      <Form.Field
        label="Max usage"
        name="max_usage"
        rules={[{ required: true, min: 1 }]}
      >
        <Input type="number" />
      </Form.Field>

      <Form.Field label="Message" name="message" rules={[{ required: true }]}>
        <Textarea />
      </Form.Field>

      <Form.Field name="campaign" label="Campaign" rules={[{ required: true }]}>
        <Select
          placeholder="Select campaign"
          loading={isLoading}
          options={campaigns}
        />
      </Form.Field>
    </>
  );
};
