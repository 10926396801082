import { Checkbox, Form, Input } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import { Grid } from "components";

import { DISCOUNT_AND_CHARACTERISTICS_DISABLED_NOTICE } from "../constants";

export interface ProductDiscountedPriceFieldProps extends FormFieldProps {}

export const ProductDiscountedPriceField = ({
  ...props
}: ProductDiscountedPriceFieldProps) => {
  return (
    <Form.Field
      label="Discounted"
      name="is_discount"
      valuePropName="checked"
      rules={[{ required: true }]}
      initialValue={false}
      dependencies={["characteristic_types"]}
      extra={
        // Note: Cannot use dependencies as on initial render it doesn't properly react to the initial values
        <Form.Field shouldUpdate>
          {(_, __, form) => {
            if (form.getFieldValue("characteristic_types")?.length)
              return DISCOUNT_AND_CHARACTERISTICS_DISABLED_NOTICE;
            return null;
          }}
        </Form.Field>
      }
      {...props}
    >
      {(control, __, form) => {
        const isDiscounted = form.getFieldValue("is_discount");
        const hasCharacteristics = form.getFieldValue(
          "characteristic_types",
        )?.length;

        return (
          <>
            <Grid cols="1fr 4fr">
              <Checkbox
                {...(hasCharacteristics ? { disabled: true } : control)}
              />

              {isDiscounted && (
                <Form.Field
                  labelOptions={{ col: { size: 3 } }}
                  controlOptions={{ col: { size: 9 } }}
                  label="Discounted price"
                  name="discount_price"
                  dependencies={["price"]}
                  rules={[
                    { required: true },
                    (form) => ({
                      validator(_, value) {
                        if (
                          Number(value) >= Number(form.getFieldValue("price"))
                        ) {
                          return Promise.reject(
                            "Discounted price cannot be more or equal than actual price",
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input type="number" min="0" />
                </Form.Field>
              )}
            </Grid>
          </>
        );
      }}
    </Form.Field>
  );
};
