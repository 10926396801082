import { gifts } from "api";
import { LOCALE } from "app-constants";
import { AxiosError } from "axios";
import { Avatar, Card, Col, ListGroup, Modal, Row, Space } from "ebs-design";
import { useQuery } from "react-query";
import { GiftComments, Results, UserGiftEntity } from "types";

interface ModalProps {
  activeGift?: UserGiftEntity | null;
  open: boolean;
  onCancel: () => void;
}

const GiftCommentsModal = ({ activeGift, onCancel, open }: ModalProps) => {
  const { data: giftComments } = useQuery<Results<GiftComments>, AxiosError>(
    ["gifts-comments", activeGift?.id],
    () => gifts.getComments(activeGift?.id),
    {
      enabled: !!activeGift?.id,
    },
  );

  const target = activeGift?.target || activeGift?.contact;

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title="View all gift comments"
      className="faq-modal"
    >
      <Modal.Content>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Space justify="center">
                  <Avatar
                    circle
                    size="big"
                    img={activeGift?.user?.photo?.url}
                    alt={activeGift?.user?.first_name}
                    type="primary"
                  />

                  <h2>
                    {activeGift?.user?.first_name} {activeGift?.user?.last_name}
                  </h2>
                </Space>

                <ListGroup className="gift__list">
                  {giftComments?.results
                    ?.filter(
                      (gift) =>
                        gift.comment && gift?.user === activeGift?.user?.id,
                    )
                    ?.map((gift) => (
                      <ListGroup.Item key={gift.id} className="gift__item">
                        <Space size="small">
                          <span className="small-text color-secondary">
                            {new Date(gift?.timestamp).toLocaleString(LOCALE)}
                          </span>
                          {gift?.comment}
                        </Space>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                <Space justify="center">
                  <Avatar
                    circle
                    size="big"
                    img={target?.photo?.url}
                    alt={target?.first_name}
                    type="primary"
                  />

                  <h2>
                    {target?.first_name} {target?.last_name}
                  </h2>
                </Space>

                <ListGroup className="gift__list">
                  {giftComments?.results
                    ?.filter(
                      (gift) => gift.comment && gift?.user === target?.id,
                    )
                    ?.map((gift) => (
                      <ListGroup.Item key={gift.id} className="gift__item">
                        <Space size="small">
                          <span className="small-text color-secondary">
                            {new Date(gift?.timestamp).toLocaleString(LOCALE)}
                          </span>
                          {gift?.comment}
                        </Space>
                      </ListGroup.Item>
                    ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Content>
    </Modal>
  );
};

export default GiftCommentsModal;
