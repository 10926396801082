import React, { createContext } from "react";
import cn from "classnames";

import { noop } from "utils";
import { useControlledState } from "hooks";

export type SetActiveTabAction = React.Dispatch<React.SetStateAction<any>>;

export interface TabContextValue {
  active: any;
  setActive: SetActiveTabAction;
  activeTabClassName: string;
}

export const TabContext = createContext<TabContextValue>({
  active: undefined,
  setActive: noop,
  activeTabClassName: "active",
});

export interface TabProviderProps<T> {
  defaultValue?: T;
  activeTabClassName?: string;
  value?: T;
  onChange?: (a: T) => void;
  children: React.ReactNode;
}

interface TabProps<T> extends React.HTMLProps<HTMLDivElement> {
  tabKey: T;
}

export const TabProvider = <T,>({
  children,
  defaultValue,
  value,
  onChange,
  activeTabClassName = "active",
}: TabProviderProps<T>) => {
  const [active, setActive] = useControlledState(value, onChange, defaultValue);

  return (
    <TabContext.Provider value={{ active, setActive, activeTabClassName }}>
      {children}
    </TabContext.Provider>
  );
};

export const Tab = <T,>({
  children,
  tabKey,
  className,
  ...props
}: TabProps<T>) => {
  const {
    activeTabClassName: activeCls,
    active,
    setActive,
  } = React.useContext(TabContext);
  return (
    <div
      className={cn(className, tabKey === active && activeCls)}
      onClick={() => active !== tabKey && setActive(tabKey)}
      {...props}
    >
      {children}
    </div>
  );
};

export const TabContent = <T,>({ tabKey, children, ...props }: TabProps<T>) => {
  const { active } = React.useContext(TabContext);

  return (
    <div style={tabKey === active ? {} : { display: "none" }} {...props}>
      {children}
    </div>
  );
};
