import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";
import { Results, BrandEntity, ApiQueryType } from "types";
import { defaultQueryLimit } from "utils";

export const brands = {
  getList: async (query: ApiQueryType = {}) => {
    query.limit = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/brands/list/`, query);
    const { data } = await apiAxios.get<Results<BrandEntity>>(url);
    data?.results?.sort?.((a, b) => a?.title < b?.title ? -1 : 1);
    return data;
  },
  syncTillo: async () => {
    const { data } = await apiAxios.post(`/brands/sync/`);
    return data;
  },
};
