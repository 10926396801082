import { useMutation } from "react-query";
import { Modal, useForm, Form, Textarea, useNotify } from "ebs-design";

import * as api from "api";
import { Flex, LoadingButton } from "components";
import { useModalState } from "hooks";

export const FeaturedNotificationBtn = () => {
  const notify = useNotify();
  const modal = useModalState();
  const [form] = useForm();

  const mutation = useMutation(api.notifications.triggerFeaturedGifts, {
    onSuccess: () => {
      form.resetFields();
      modal.close();
      notify.success({ title: "Notification sent successfully" });
    },
  });

  return (
    <>
      <span className="color-primary cursor-pointer" onClick={modal.open}>
        Send featured products notifcation
      </span>
      <Modal
        title="Your message"
        open={modal.isOpen}
        onClose={modal.close}
        size="small"
      >
        <Form form={form} onFinish={(values) => mutation.mutate(values.body)}>
          <Modal.Content>
            <Form.Field
              name="body"
              initialValue=""
              hideLabel
              label="Message"
              rules={[{ required: true }]}
            >
              <Textarea />
            </Form.Field>
          </Modal.Content>
          <Modal.Footer>
            <Flex justify="end">
              <LoadingButton submit type="primary" loading={mutation.isLoading}>
                Send
              </LoadingButton>
            </Flex>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
