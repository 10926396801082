import { useQuery } from "react-query";
import { useSetState } from "react-use";
import { Button, Collapse, Loader, Space, Label } from "ebs-design";

import DeleteCampaignsTierModal from "features/campaigns-tier/DeleteCampaignsTierModal";
import CampaignsTierModalForm from "features/campaigns-tier/CampaignsTierModalForm";

import { AlertErrors, Icon } from "components";
import { campaignsTier } from "api";
import { CampaignsTier } from "types";

interface ModalState {
  visible: boolean;
  tierId?: number | null;
  activeTier?: CampaignsTier | null;
}

const defaultModal = {
  visible: false,
  activeTier: null,
};

const defaultDeleteModal = {
  visible: false,
  tierId: null,
};

const CampaignsTierList = () => {
  const [tierModal, setTierModal] = useSetState<ModalState>(defaultModal);

  const [deleteModal, setDeleteModal] = useSetState<ModalState>(
    defaultDeleteModal,
  );

  const { data, isFetching, isError, error } = useQuery(
    ["campaigns-tier"],
    () => campaignsTier.getList(),
  );

  return (
    <Loader loading={isFetching}>
      <div className="campaigns">
        {isError ? (
          <AlertErrors error={error} />
        ) : (
          <>
            <Button
              className="campaigns__add-campaign"
              prefix={<Icon type="plus" />}
              onClick={() => {
                setTierModal({
                  visible: true,
                });
              }}
            >
              Add Campaigns tier
            </Button>

            <div className="pady-2"></div>
            {data?.length ? (
              <Collapse.Group className="campaigns__collapse">
                {data?.map((tier: CampaignsTier) => {
                  return (
                    <Collapse collapsed key={tier.id}>
                      <Collapse.Header className="campaigns__collapse__header">
                        <Space justify="space-between">
                          <Space size="large">
                            {tier.campaign_name}

                            <Label
                              status={tier.is_paid ? "success" : "danger"}
                              text={tier.is_paid ? "paid" : "not paid"}
                              type="fill"
                            />

                            {`Tier: ${tier.tier}`}
                          </Space>

                          <div>
                            <span
                              className="campaigns__btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteModal({
                                  visible: true,
                                  tierId: tier.id,
                                });
                              }}
                            >
                              <Button
                                size="small"
                                className="ebs-button__action"
                              >
                                <Icon type="delete" />
                              </Button>
                            </span>

                            <span
                              className="campaigns__btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setTierModal({
                                  visible: true,
                                  activeTier: tier,
                                });
                              }}
                            >
                              <Button
                                size="small"
                                className="ebs-button__action"
                              >
                                <Icon type="edit" />
                              </Button>
                            </span>
                          </div>
                        </Space>
                      </Collapse.Header>

                      <Collapse.Body>{tier.description || ""}</Collapse.Body>
                    </Collapse>
                  );
                })}
              </Collapse.Group>
            ) : (
              <p className="no-items">No campaigns tier</p>
            )}

            {tierModal.visible && (
              <CampaignsTierModalForm
                open={tierModal.visible}
                onCancel={() => setTierModal(defaultModal)}
                activeTier={tierModal.activeTier}
              />
            )}

            {deleteModal.visible && (
              <DeleteCampaignsTierModal
                tierId={deleteModal.tierId}
                visible={deleteModal.visible}
                onCancel={() => {
                  setDeleteModal(defaultDeleteModal);
                }}
              />
            )}
          </>
        )}
      </div>
    </Loader>
  );
};

export default CampaignsTierList;
