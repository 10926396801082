import imageFallbackSrc from "assets/images/image-fallback.png";

export const paginationDefault = {
  limit: 25,
  page: 1,
};

export const defaultLimitChoice = [10, 25, 50, 75, 100];

export const defaultDateFormat = "dd/MM/yyyy";

export const defaultQueryLimit = 500;

export const defaultDebouncedInputTimeout = 500;

export const faqDefaultQueryLimit = 100;

export const defaultImageFallback = imageFallbackSrc;
// "https://fs.prsnt.devebs.net/media/f39c2a768e26f8f05bb70f084876e7f1.png";
