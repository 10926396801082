import queryString from "query-string";

import { GenericObject } from "types";

export const stringifyUrl = (url: string, query: GenericObject = {}): string =>
  queryString.stringifyUrl(
    {
      url,
      query,
    },
    { skipEmptyString: true, skipNull: true },
  );
