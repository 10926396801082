import { MapType } from "types";

export interface MappedListParamProps {
  map: MapType<string, string>;
  title: string;
  values?: string[];
}

export const MappedListParam = ({
  map,
  title,
  values = [],
}: MappedListParamProps) =>
  values.length ? (
    <div>
      <div className="color-secondary">{title}</div>
      <div>{values.map((v) => map[v]).join(", ")}</div>
      <div className="pady-2"></div>
    </div>
  ) : null;

export interface ListParamProps {
  title: string;
  values?: string[];
}

export const ListParam = ({ title, values = [] }: ListParamProps) =>
  values?.length ? (
    <div>
      <div className="color-secondary">{title}</div>
      <div>{values.join(", ")}</div>
      <div className="pady-2"></div>
    </div>
  ) : null;

export interface MappedListPackParamProps {
  title: string;
  valuesPack?: MapType<string, string[] | undefined>;
  map: MapType<string, MapType<string, string>>;
  keyMap: MapType<string, string>;
}

const validateValuePack = (v?: MapType<string, string[] | undefined>) =>
  v && Object.values(v).some((i) => i && i.length);

export const MappedListPackParam = ({
  title,
  valuesPack = {},
  map,
  keyMap,
}: MappedListPackParamProps) =>
  validateValuePack(valuesPack) ? (
    <div>
      <div className="color-secondary">{title}</div>
      {Object.keys(valuesPack).map((key) => {
        let values = valuesPack[key];

        return values?.length ? (
          <div key={key}>
            <span className="small-text">{keyMap[key]}</span>:{" "}
            {values.map((v) => map[key][v]).join(", ")}
          </div>
        ) : null;
      })}
      <div className="pady-2"></div>
    </div>
  ) : null;
