import { TabsWithRoute } from "components";
import { cmsTabs } from "features/cms/config";
import SlidersList from "../SliderList";

const Sliders = () => {
  return (
    <TabsWithRoute tabItems={cmsTabs}>
      <SlidersList />
    </TabsWithRoute>
  );
};

export default Sliders;
