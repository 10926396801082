import React from "react";
import { useMutation, useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { AxiosError } from "axios";

import { Button, Card, Loader, Space, useNotify } from "ebs-design";

import {
  AlertErrors,
  ConfirmModal,
  DebouncedInput,
  Icon,
  Pagination,
  TabsWithRoute,
} from "components";
import { useModalState, useQueryPagination, useSearch } from "hooks";
import { notifyErrors, resultsCount } from "utils";
import { NomenclatureItem, NomenclatureItemType } from "types";
import * as api from "api";

import { cmsTabs } from "features/cms/config";
import { ModuleAction } from "features/modules-permissions";

import { FaceFilterCard, FaceFilterModal } from "..";

export const FaceFilters = () => {
  const notify = useNotify();

  const [search, setSearch] = useSearch();
  const pagination = useQueryPagination();

  const addModal = useModalState();
  const [editModalData, setEditModalData] = React.useState<NomenclatureItem>();
  const [deleteModalData, setDeleteModalData] =
    React.useState<NomenclatureItem>();

  useUpdateEffect(() => pagination.setPage(1), [search]);

  const queryParams = {
    page: pagination.page,
    limit: pagination.limit,
    search,
    type: NomenclatureItemType.FACE_FILTER,
  };

  const query = useQuery(
    ["nomenclatures", NomenclatureItemType.FACE_FILTER, queryParams],
    () => api.nomenclatures.getKeyList(queryParams),
  );

  const faceFilters = query.data?.results || [];

  const postMutation = useMutation<
    NomenclatureItem,
    AxiosError,
    Partial<NomenclatureItem>
  >(
    (values) =>
      api.nomenclatures.postKey({
        ...values,
        type: NomenclatureItemType.FACE_FILTER,
      }),
    {
      onSuccess: () => {
        notify.success({
          title: "",
          description: "Face filter successfully created.",
        });
      },
    },
  );

  const patchMutation = useMutation<
    NomenclatureItem,
    AxiosError,
    Partial<NomenclatureItem>
  >(
    (interest) => api.nomenclatures.patchKey(editModalData?.id || 0, interest),
    {
      onSuccess: () => {
        notify.success({
          title: "",
          description: "Face filter successfully updated.",
        });
      },
    },
  );

  const deleteMutation = useMutation(
    () => api.nomenclatures.deleteKey(deleteModalData?.id || 0),
    {
      onSuccess: () => {
        setDeleteModalData(undefined);
        query.refetch();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <TabsWithRoute tabItems={cmsTabs}>
      <div className="pady-5"></div>
      <Card>
        <Card.Body>
          <Space justify="space-between">
            <Space align="center" size={30}>
              <div className="fw-500">
                Face filters ({resultsCount(query.data, query.isLoading)})
              </div>
              <div style={{ display: "inline-block", width: 300 }}>
                <DebouncedInput
                  placeholder="Search in face filters"
                  value={search}
                  onChange={setSearch}
                  suffix={<Icon type="search" />}
                />
              </div>
            </Space>
            <ModuleAction module="cms">
              <Button
                type="primary"
                onClick={addModal.open}
                prefix={<Icon type="plus" />}
              >
                Add face filter
              </Button>
            </ModuleAction>
          </Space>
        </Card.Body>
      </Card>
      <div className="pady-3"></div>

      <AlertErrors error={query.error as any} />
      {query.isLoading && <Loader loading />}

      <div className="face-filters__grid">
        {faceFilters.map((faceFilter) => (
          <React.Fragment key={faceFilter.id}>
            <FaceFilterCard
              {...{
                faceFilter,
                setEditModalData,
                setDeleteModalData,
              }}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="pady-3"></div>

      <Card>
        <Card.Body>
          <Space justify="end">
            <Pagination pagination={pagination} results={query.data} />
          </Space>
        </Card.Body>
      </Card>
      <FaceFilterModal
        title="Add face filter"
        open={addModal.isOpen}
        onClose={addModal.close}
        mutation={postMutation}
      />
      <FaceFilterModal
        title="Edit face filter"
        data={editModalData}
        open={!!editModalData}
        onClose={() => setEditModalData(undefined)}
        mutation={patchMutation}
      />
      <ConfirmModal
        open={!!deleteModalData}
        onClose={() => setDeleteModalData(undefined)}
        onCancel={() => setDeleteModalData(undefined)}
        onConfirm={deleteMutation.mutate}
        loading={deleteMutation.isLoading}
      >
        Are you sure to delete <b>{deleteModalData?.title}</b>?
      </ConfirmModal>
    </TabsWithRoute>
  );
};
