import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Loader } from "ebs-design";

import { companies } from "api";
import {
  AlertErrors,
  CheckListFormField,
  Container,
  DebouncedInput,
  Icon,
} from "components";
import { Company, Results } from "types";

export const BusinessCompaniesTab = () => {
  const [search, setSearch] = React.useState("");

  const companiesQuery = useQuery<Results<Company>, AxiosError>(
    ["companies", { search }],
    () => companies.getList({ search }),
  );

  const options = React.useMemo(
    () =>
      companiesQuery.data?.results?.map((company) => ({
        value: company,
        text: (
          <h4>
            {company?.name} ({company.owner?.email})
          </h4>
        ),
      })) || [],
    [companiesQuery.data?.results],
  );

  return (
    <div className="padx-10 pady-8">
      <Container size={300} fixed mauto={false}>
        <DebouncedInput
          placeholder="Search company"
          value={search}
          onChange={setSearch}
          suffix={<Icon type="search" />}
        />
      </Container>
      <div className="pady-1"></div>
      <AlertErrors error={companiesQuery.error} />
      <Loader loading={companiesQuery.isLoading} />
      <div className="pady-2"></div>
      {companiesQuery.data && (
        <CheckListFormField
          name={["parameters", "business_companies"]}
          options={options}
          component={({ children }) => (
            <div className="grid-2cols grid-gap-4">{children}</div>
          )}
          getValue={(company: Company) => company.id}
        />
      )}
    </div>
  );
};
