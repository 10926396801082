import { useEffect } from "react";
import { Button, Modal, Space, Icon, Form, Input, useForm } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { faq } from "api";
import { FaqCategoryEntity } from "types";
import { FaqCategoryBodyValues } from "features/faq/types";
import { AlertErrors } from "components";

interface Props {
  open: boolean;
  onCancel: () => void;
  activeCategory: FaqCategoryEntity | null;
}

const FaqCategoryForm = ({ open, onCancel, activeCategory }: Props) => {
  const [form] = useForm();
  const queryClient = useQueryClient();

  const mutateFn = async (values: FaqCategoryBodyValues) =>
    activeCategory
      ? faq.updateCategory(activeCategory?.id || 0, values)
      : faq.addCategory(values);

  const { mutate, isLoading, isError, error } = useMutation<
    FaqCategoryBodyValues,
    AxiosError,
    FaqCategoryBodyValues
  >((category: FaqCategoryBodyValues) => mutateFn(category), {
    onSuccess: () => {
      onCancel();
      queryClient.invalidateQueries("faq-categories");
    },
  });

  useEffect(() => {
    if (activeCategory) {
      form.setFieldsValue(activeCategory);
    }
  }, [activeCategory, form]);

  const handleForm = ({ title }: FaqCategoryBodyValues) => {
    mutate({
      title,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title={`${activeCategory ? "Edit" : "Add"} category`}
      size="small"
    >
      <Modal.Content>
        {isError && <AlertErrors error={error!} />}
        <Form form={form} onFinish={handleForm}>
          <Form.Field
            name="title"
            label="Category name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>Cancel</Button>

          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            prefix={<Icon type="check" />}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default FaqCategoryForm;
