import { UseQueryResult } from "react-query";
import { Link } from "react-router-dom";

import { Button, Card, Space } from "ebs-design";

import { Container, DebouncedInput, Icon } from "components";
import { Results } from "types";
import { resultsCount } from "utils";

export interface CouponsFilterCardProps {
  couponsQuery: UseQueryResult<Results<unknown>>;
  search: string;
  setSearch: (value?: string) => void;
}

export const CouponsFilterCard = ({
  couponsQuery,
  search,
  setSearch,
}: CouponsFilterCardProps) => {
  return (
    <Card>
      <Card.Body>
        <Space justify="space-between">
          <div onClick={(e) => e.stopPropagation()}>
            <Space align="center" size={30}>
              <div className="fw-500">
                Coupons (
                {resultsCount(couponsQuery.data, couponsQuery.isLoading)})
              </div>
              <Container size={300} mauto={false}>
                <DebouncedInput
                  placeholder="Search in coupons"
                  value={search}
                  onChange={(value: string) => setSearch(value || undefined)}
                  suffix={<Icon type="search" />}
                />
              </Container>
            </Space>
          </div>
          <Space>
            <Link to="/coupons/import/">
              <Button prefix={<Icon type="plus" />}>Import coupon</Button>
            </Link>
            <Link to="/coupons/new/">
              <Button prefix={<Icon type="plus" />}>Add coupon</Button>
            </Link>
          </Space>
        </Space>
      </Card.Body>
    </Card>
  );
};
