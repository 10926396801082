import { Dispatch, SetStateAction } from "react";
import { Space, Button, Modal } from "ebs-design";
import { Balance } from "types";
import { getCurrencySymbol } from "utils";

interface BalanceProps {
  showBalance: boolean;
  setShowBalance: Dispatch<SetStateAction<boolean>>;
  balance?: Balance;
  error: unknown;
}

const CheckBalance = ({
  showBalance,
  setShowBalance,
  balance,
  error,
}: BalanceProps) => {
  const balanceError = () => {
    return <div>Check Balance is not supported</div>;
  };

  const balanceValue = () => {
    return (
      <h4 className="fw-400">
        <span className="color-secondary">Current Balance: </span>
        {getCurrencySymbol(balance?.face_value.currency!)}
        {balance?.face_value.amount}
      </h4>
    );
  };

  return (
    <>
      <Modal
        open={showBalance}
        onClose={() => setShowBalance(false)}
        size="small"
      >
        <Modal.Content>
          <Space justify="center">
            {error ? balanceError() : balanceValue()}
          </Space>
        </Modal.Content>
        <Modal.Footer>
          <Space justify="end">
            <Button type="primary" onClick={() => setShowBalance(false)}>
              Confirm
            </Button>
          </Space>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CheckBalance;
