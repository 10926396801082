import React from "react";

import { LanguageKeyEditContext } from ".";

export interface LangaugeKeyEditItemProps {
  value: React.ReactNode;
}
export const LangaugeKeyEditItem: React.FC<LangaugeKeyEditItemProps> = ({
  value,
  children,
}) => {
  const { isEditing } = React.useContext(LanguageKeyEditContext);
  return <>{isEditing ? children : value}</>;
};
