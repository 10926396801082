import React from "react";

import { Button, Checkbox, Form } from "ebs-design";
import ImageViewer from "react-simple-image-viewer";

import {
  CircularProgress,
  Icon,
  RenderUploadItemProps,
  Upload,
  UploadItem,
} from "components";
import {
  AttachmentType,
  ImageViewerState,
  ProductImageAttachment,
} from "types";
import { deepProp } from "utils";
import { attachments } from "api";

type UploadImageItem = UploadItem<ProductImageAttachment>;

export interface FieldContentProps {
  value?: UploadImageItem[];
  onChange?: (value?: UploadImageItem[]) => void;
}

export const FieldContent = ({ value: items, onChange }: FieldContentProps) => {
  const [imgViewerState, setImgViewerState] = React.useState<ImageViewerState>({
    isOpen: false,
    currentIndex: 0,
  });

  const imagesSrc = React.useMemo(
    () =>
      items?.map((item) => deepProp<string>(item, "data", "url") || "") || [],
    [items],
  );

  const renderUploadItem = React.useMemo(
    () =>
      ({
        item,
        idx,
        onRemoveClick,
        progress,
      }: RenderUploadItemProps<ProductImageAttachment, Error>) => {
        const image = item.data;

        const onChangeHandler = (checked: boolean) =>
          onChange &&
          onChange(
            items?.map((fitem) =>
              deepProp<number>(fitem, "data", "id") === image?.id
                ? {
                    ...fitem,
                    data: {
                      ...((fitem.data || {}) as ProductImageAttachment),
                      featured: checked,
                    },
                  }
                : {
                    ...fitem,
                    data: {
                      ...((fitem.data || {}) as ProductImageAttachment),
                      featured: false,
                    },
                  },
            ),
          );
        return (
          <div key={idx} className="product-image-list__item">
            <div
              onClick={() =>
                setImgViewerState({ isOpen: true, currentIndex: idx || 0 })
              }
              className="product-image-list__item-image"
            >
              {item.isSuccess && <img src={image?.url} alt="" />}
              {item.isError && (
                <div className="color-danger">{item.error?.message}</div>
              )}
              {!item.isFinished && (
                <div className="fw-500">
                  <CircularProgress padding="0.5rem" value={progress || 0} />
                </div>
              )}
            </div>
            <div className="product-image-list__item-control">
              <span className="with-title" title="Remove">
                <Icon
                  className="cursor-pointer"
                  type="cross"
                  onClick={onRemoveClick}
                />
              </span>
              <span className="with-title" title="Thumbnail">
                <Checkbox
                  checked={!!image?.featured}
                  onChange={onChangeHandler}
                />
              </span>
            </div>
          </div>
        );
      },
    [items, onChange],
  );

  return (
    <>
      <div className="product-image-list">
        <Upload
          className="product-image-list__upload"
          name="files"
          multiple
          accept="image/*"
          customRequest={(o) =>
            attachments.post(o, { attachmentType: AttachmentType.IMAGE })
          }
          value={items}
          onChange={onChange}
          renderItem={renderUploadItem}
        >
          <Button type="ghost" className="border-dashed">
            Add images
          </Button>
        </Upload>
      </div>

      <div className="pady-2"></div>
      {imgViewerState.isOpen && (
        <ImageViewer
          onClose={() => setImgViewerState({ isOpen: false, currentIndex: 0 })}
          src={imagesSrc}
          currentIndex={imgViewerState.currentIndex}
        />
      )}
    </>
  );
};

export const ProductImagesField = () => {
  return (
    <Form.Field label="Images" name="images">
      <FieldContent />
    </Form.Field>
  );
};
