import { Container } from "ebs-design";
import SettingsForm from "../SettingsForm";
const Settings = () => {
  return (
    <Container className="settings">
      <SettingsForm />
    </Container>
  );
};

export default Settings;
