import { Switch, Redirect, Route } from "react-router-dom";

import { Loader } from "ebs-design";

import { useAuth } from "hooks";
import { ContentLayout, LayoutRoute } from "components";

import Login from "features/auth/pages/Login";
import { routes } from "./routes";

export const AppRouter = () => {
  const auth = useAuth();

  return (
    <>
      {auth?.isLoading ? (
        <Loader loading />
      ) : (
        <Switch>
          {auth?.user && <Redirect from="/login" to="/" />}
          {auth?.user &&
            routes.map((route, i) => (
              <LayoutRoute key={i} {...route} layout={ContentLayout} />
            ))}

          <Route exact path="/login" component={Login}></Route>
          <Redirect to="/login" />
        </Switch>
      )}
    </>
  );
};
