import { CategoryEntity, ProductGender } from "types";
import { withoutKeyDublicates } from "utils";

import { CategoryListItem, ProductCategoriesFieldValue } from "./ProductForm";

export const getProductCategoriesNames = (categories?: CategoryEntity[]) => {
  const ret = new Set<string | undefined>();
  categories?.forEach((c) => {
    ret.add(c?.parent?.title);
    !c?.parent?.id && ret.add(c?.title);
  });
  return Array.from(ret.values()).filter((_) => _) as string[];
};

export const getProductSubcategoriesNames = (categories?: CategoryEntity[]) => {
  const ret = new Set<string | undefined>();
  categories?.forEach((c) => {
    c?.parent?.id && ret.add(c?.title);
  });
  return Array.from(ret.values()).filter((_) => _) as string[];
};

export const deserializeFormValue = (value?: ProductCategoriesFieldValue) => {
  let ret: CategoryListItem[] = [];

  value?.forEach((vi) => !vi.parent_id && ret.push({ id: vi.id, subs: [] }));

  value?.forEach(
    (vi) =>
      vi.parent_id &&
      (ret = ret.map((ri) =>
        ri.id === vi.parent_id ? { ...ri, subs: [...ri.subs, vi.id || 0] } : ri,
      )),
  );

  return withoutKeyDublicates(ret, (i) => i.id);
};

export const serializeToFormValue = (list: CategoryListItem[]) => {
  let ret: ProductCategoriesFieldValue = [];

  list.forEach((li) => {
    ret.push({ id: li.id });
    li.subs.forEach((lsi) => ret.push({ id: lsi, parent_id: li.id }));
  });

  return withoutKeyDublicates(ret, (i) => i.id);
};

export const productGendersOptions = [
  {
    text: "Her",
    value: ProductGender.HER,
  },
  {
    text: "Him",
    value: ProductGender.HIM,
  },
  {
    text: "Them",
    value: ProductGender.THEM,
  },
];
