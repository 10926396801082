import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";

import { UserProfileEntity } from "types";

export const couponUsersTableColumns = () =>
  [
    {
      title: "Name",
      dataIndex: "first_name",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Age",
      dataIndex: "birthday",

      render: (value: string) => {
        return new Date().getFullYear() - new Date(value).getFullYear();
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Sessions",
      dataIndex: ["stats", "sessions", "count"],
    },
    {
      title: "Paid Gifts Sent",
      dataIndex: ["stats", "gifts", "total_paid_gifts"],
    },
    {
      title: "Free Gifts Sent",
      dataIndex: ["stats", "gifts", "total_free_gifts"],
    },
    {
      title: "Gifts Recieved",
      dataIndex: ["stats", "gifts", "total_received_gifts"],
    },
  ] as ColumnType<UserProfileEntity>[];
