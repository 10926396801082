import { capitalize } from "utils";
import { LOCALE } from "app-constants";
import { renderReciever, Icon } from "components";
import { ModuleAction } from "features/modules-permissions";

import { toYesOrNo } from "./utils";

export interface GiftsTableColumnsProps {
  onDeleteClick?: (id: number) => void;
}

export const getGiftsTableColumns = ({
  onDeleteClick,
}: GiftsTableColumnsProps) => [
  {
    title: "Sender",
    dataIndex: ["user", "name"],
  },
  {
    title: "Receiver",
    render: renderReciever,
  },
  {
    title: "Product",
    dataIndex: ["product", "title"],
  },
  {
    title: "Price",
    dataIndex: ["order", "price"],
    ordering: "order_price",
  },
  {
    title: "Order Cost",
    dataIndex: ["order", "cost"],
    ordering: "order_cost",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (status: string) => capitalize(status),
  },
  {
    title: "Date and Time",
    dataIndex: "timestamp",
    ordering: "timestamp",
    render: (value: string) => {
      return value && new Date(value).toLocaleString(LOCALE);
    },
  },
  {
    title: "Unwrapped",
    dataIndex: "unwrapped",
    render: toYesOrNo,
  },
  {
    title: "Redeemed",
    dataIndex: "redeemed",
    render: toYesOrNo,
  },
  {
    title: "",
    dataIndex: "id",
    render: (id: number) => {
      return (
        <ModuleAction module="send_gift">
          <span
            onClick={(e) => e.stopPropagation()}
            className="cursor-pointer with-title"
            title="Delete"
          >
            <Icon
              onClick={() => onDeleteClick && onDeleteClick(id)}
              type="delete"
            />
          </span>
        </ModuleAction>
      );
    },
  },
];
