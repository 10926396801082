import React from "react";
import { Card, Space } from "ebs-design";

import { NomenclatureItem } from "types";
import { Icon } from "components";

import { ModuleAction } from "features/modules-permissions";

export interface InterestCardProps {
  interest: NomenclatureItem;
  setEditModalData: React.Dispatch<NomenclatureItem>;
  setDeleteModalData: React.Dispatch<NomenclatureItem>;
}

export const InterestCard = ({
  interest,
  setEditModalData,
  setDeleteModalData,
}: InterestCardProps) => {
  return (
    <Card key={interest.id}>
      <Card.Body>
        <Space size={4} align="center" justify="space-between">
          <h4>
            <div className="pb-3">{interest.title}</div>
          </h4>
          <Space className="mt-3" size={4} align="center">
            <ModuleAction module="cms">
              <span>
                <Icon
                  onClick={() => setEditModalData(interest)}
                  className="cursor-pointer"
                  type="edit"
                  size="1.5rem"
                />
              </span>
              <span>
                <Icon
                  onClick={() => setDeleteModalData(interest)}
                  className="cursor-pointer"
                  type="delete"
                  size="1.5rem"
                />
              </span>
            </ModuleAction>
          </Space>
        </Space>
        {/* <div className="pady-1"></div>
        <Space align="center">
          {displayLanguages.map((language) => (
            <Space size={2} align="center">
              <Label type="ghost" text={language.code}></Label>
              {interest[("title_" + language.code) as keyof NomenclatureItem] ||
                "(No title)"}
            </Space>
          ))}
        </Space> */}
      </Card.Body>
    </Card>
  );
};
