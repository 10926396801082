import React from "react";

import { Checkbox } from "ebs-design";

import { Grid } from "components";
import { ProductGender } from "types";
import { productGendersOptions } from "../utils";

export type ProductGendersFieldValue = ProductGender[];

export interface ProductGendersFieldProps {
  value?: ProductGendersFieldValue;
  onChange?: React.Dispatch<ProductGendersFieldValue>;
}

export const ProductGendersField = ({
  value,
  onChange,
}: ProductGendersFieldProps) => {
  const changeHandler = (type: ProductGender, checked: boolean) => {
    const s = new Set<ProductGender>(value);
    checked ? s.add(type) : s.delete(type);
    onChange?.(Array.from(s.values()));
  };

  return (
    <>
      <Grid cols={`repeat(${productGendersOptions.length}, 1fr)`} gap="1rem">
        {productGendersOptions.map((option) => (
          <Checkbox
            key={option.value}
            checked={value?.includes(option.value)}
            text={option.text}
            onChange={(checked) => changeHandler(option.value, checked)}
          />
        ))}
      </Grid>
    </>
  );
};
