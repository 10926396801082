import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { Button, Loader, Space } from "ebs-design";
import { AxiosError } from "axios";
import { ColumnType } from "rc-table/lib/interface";
import { Results, UserProfileEntity } from "types";
import { Table, TableRowLink, Icon, AlertErrors } from "components";
import { users } from "api";
import { QueryParamsProps } from "./types";

import { LOCALE } from "app-constants";
import { ModuleAction } from "features/modules-permissions";

const DEFAULT_LIMIT = 10; // default limit per page

export const SignsUps = ({ queryParams }: QueryParamsProps) => {
  const {
    isLoading,
    error,
    data: userSignUps,
  } = useQuery<Results<UserProfileEntity>, AxiosError>(
    ["users-signUps", queryParams],
    () =>
      users.getList({
        limit: DEFAULT_LIMIT,
        ordering: "-date_joined",
        date_joined__gte: queryParams.date_from,
        date_joined__lte: queryParams.date_to,
      }),
  );

  const tableColumns = React.useMemo<ColumnType<UserProfileEntity>[]>(
    () => [
      {
        title: "Name",
        dataIndex: "first_name",
      },
      {
        title: "Username",
        dataIndex: "username",
      },
      {
        title: "Date and Time",
        dataIndex: "date_joined",
        render: (value: string) =>
          value && new Date(value).toLocaleString(LOCALE),
      },
      {
        title: null,
        dataIndex: "id",
        render: (id) => (
          <ModuleAction module="send_gift">
            <Link
              className="with-title"
              title="Send gift"
              to={`/gifts/send/?users_id=${id}`}
              onClick={(e) => e.stopPropagation()}
            >
              <Icon type="status-sent" />
            </Link>
          </ModuleAction>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <AlertErrors error={error} />
      <Loader loading={isLoading}>
        <Table
          data={userSignUps?.results}
          columns={tableColumns}
          variants={[
            "bordered",
            "elevated",
            "dense",
            "stripped-even",
            "highlight-hover",
          ]}
          rowKey="id"
          components={{
            body: {
              row: (props: any) => (
                <TableRowLink {...props} makeUrl={(id) => `/users/${id}`} />
              ),
            },
          }}
          emptyText={
            isLoading ? (
              <Loader loading />
            ) : (
              <Space className="pady-12" justify="center">
                No data
              </Space>
            )
          }
          title={() => <h3 className="pady-4 padx-4">Sign ups</h3>}
          footer={() => (
            <Space justify="end">
              <Link to="/users/?ordering=-date_joined">
                <Button className="m-10">See more</Button>
              </Link>
            </Space>
          )}
        />
      </Loader>
    </>
  );
};
