import { Grid } from "components";
import { Card } from "ebs-design";
import { Company } from "types";

export interface UserCompanyDetailsProps {
  company: Company;
}

export const UserCompanyDetails = ({ company }: UserCompanyDetailsProps) => {
  return (
    <Card>
      <Card.Header>
        <h3>Company details</h3>
      </Card.Header>
      <Card.Body>
        <Grid cols="1fr 1fr" gap="1rem">
          <div>
            <div className="color-secondary">Name</div>
            <div>{company.name}</div>
          </div>
          <div>
            <div className="color-secondary">Fee</div>
            <div>{company.fee}</div>
          </div>
        </Grid>
        <div className="pady-4"></div>
        <h4>Address</h4>
        <div className="pady-1"></div>
        <Grid cols="1fr 1fr" gap="1rem">
          <div>
            <div className="color-secondary">Country</div>
            <div>{company.address?.country?.name ?? "---"}</div>
          </div>
          <div>
            <div className="color-secondary">Region</div>
            <div>{company.address?.region?.name ?? "---"}</div>
          </div>
          <div>
            <div className="color-secondary">Subregion</div>
            <div>{company.address?.subregion?.name ?? "---"}</div>
          </div>
          <div>
            <div className="color-secondary">City</div>
            <div>{company.address?.city?.name ?? "---"}</div>
          </div>
        </Grid>
      </Card.Body>
    </Card>
  );
};
