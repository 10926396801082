import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import { Button, Space, Loader, Icon, InputSearch, Card } from "ebs-design";
import { AxiosError } from "axios";
import { ColumnType } from "rc-table/lib/interface";
import { TabsWithRoute, AlertErrors, Table, Pagination } from "components";
import { brands } from "api";
import { useQueryPagination } from "hooks";
import { Results, BrandEntity } from "types";

const tabs = [
  {
    label: "Categories",
    route: "/categories",
  },
  {
    label: "Brands",
    route: "/brands",
  },
];

const Brands = () => {
  const pagination = useQueryPagination();
  const { limit, page, setPage } = pagination;

  const [search, setSearch] = useQueryParam(
    "search",
    withDefault(StringParam, ""),
  );

  const queryParams = {
    page,
    limit,
    search,
  };

  const { data, isLoading, refetch, error } = useQuery<
    Results<BrandEntity>,
    AxiosError
  >(["brands", queryParams], () => brands.getList(queryParams), {
    enabled: !!pagination.page,
  });

  const syncMutation = useMutation(() => brands.syncTillo(), {
    onSuccess: () => refetch(),
  });

  const syncListTillo = () => syncMutation.mutate();

  const tableColumns = useMemo<ColumnType<BrandEntity>[]>(
    () => [
      {
        title: "Tillo ID",
        dataIndex: "id",
      },
      {
        title: "Brand Name",
        dataIndex: "title",
      },
      {
        title: "Brand Image",
        dataIndex: "image",
        render: (image) => {
          return (
            <>
              {image?.url ? (
                <img
                  src={`${image?.url}`}
                  className="brand-image"
                  alt="brand"
                />
              ) : (
                <div>---</div>
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  useUpdateEffect(() => setPage(1), [search]);

  return (
    <TabsWithRoute tabItems={tabs}>
      <div className="pady-3"></div>
      <Card>
        <Card.Body>
          <Space justify="space-between">
            <Space>
              <div className="fw-500">Brands ({data?.count || 0})</div>
              <InputSearch
                placeholder="Search brand"
                value={search}
                onSearch={(value: string) => setSearch(value || undefined)}
              />
            </Space>
            <Button
              onClick={syncListTillo}
              type="primary"
              prefix={
                <Icon type="refresh" model="bold" className="icon-size" />
              }
            >
              Sync with Tillo
            </Button>
          </Space>
        </Card.Body>
      </Card>
      <div className="pady-3"></div>
      <AlertErrors error={error} />

      <Loader loading={syncMutation.isLoading || isLoading}>
        <Table
          data={data?.results}
          columns={tableColumns}
          footer={() => (
            <div className="padx-8 pady-4">
              <Space justify="end">
                <Pagination pagination={pagination} results={data} />
              </Space>
            </div>
          )}
          variants={[
            "bordered",
            "elevated",
            "dense",
            "stripped-even",
            "highlight-hover",
          ]}
          scroll={{ x: true }}
          rowKey="id"
        />
      </Loader>
    </TabsWithRoute>
  );
};

export default Brands;
