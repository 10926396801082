import { Label, Space } from "ebs-design";
import { IdName } from "types";

export const cohortUsersTableColumns = [
  {
    title: "Username",
    dataIndex: "username",
    ordering: "username",
  },
  {
    title: "Name",
    dataIndex: "name",
    ordering: "name",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    ordering: "phone",
  },
  {
    title: "Email",
    dataIndex: "email",
    ordering: "email",
  },
  {
    title: "Companies",
    dataIndex: "companies",
    render: (companies: IdName[]) =>
      companies?.length ? (
        <Space size={4}>
          {companies.map((company, idx) => (
            <Label type="ghost" text={company.name} key={idx} />
          ))}
        </Space>
      ) : (
        <>-</>
      ),
  },
  {
    title: "Age",
    dataIndex: "birthday",
    ordering: "birthday",
    render: (value: string) => {
      return new Date().getFullYear() - new Date(value).getFullYear();
    },
  },
];
