import React from "react";
import ImgViewer from "react-simple-image-viewer";

import { Card } from "ebs-design";

import {
  getProductCategoriesNames,
  getProductSubcategoriesNames,
} from "features/products/utils";
import { ImageViewerState, ProductEntity } from "types";

export interface CouponProductDetailsProps {
  product?: ProductEntity;
}

export const CouponProductDetails = ({
  product,
}: CouponProductDetailsProps) => {
  const [imgViewerState, setImgViewerState] = React.useState<ImageViewerState>({
    currentIndex: 0,
    isOpen: false,
  });
  const detailsPairs = [
    ["Name", product?.title],
    ["Price", product?.price],
    ["Categories", getProductCategoriesNames(product?.categories).join(", ")],
    [
      "Subcategories",
      getProductSubcategoriesNames(product?.categories).join(", "),
    ],
    ["Brand", product?.brand?.title],
    [
      "Thumbnail",
      <img
        height="30"
        className="cursor-pointer"
        src={product?.featured_image?.url}
        onClick={() => setImgViewerState({ isOpen: true, currentIndex: 0 })}
        alt="Product"
      />,
    ],
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <h3>Product details</h3>
        </Card.Header>
        <Card.Body>
          <div className="grid-2cols grid-gap-4">
            {detailsPairs.map((item, i) => (
              <div key={i}>
                <span className="small-text color-secondary">{item[0]}</span>
                <h4 className="fw-400">{item[1] ?? "---"}</h4>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
      {imgViewerState.isOpen && (
        <ImgViewer
          src={[product?.featured_image?.url || ""]}
          currentIndex={imgViewerState.currentIndex}
          onClose={() => setImgViewerState({ isOpen: false, currentIndex: 0 })}
        />
      )}
    </>
  );
};
