import { useMemo, useCallback } from "react";
import { useSetState } from "react-use";

import { ListGroup, Button, Space } from "ebs-design";

import { CategoryEntity, Results } from "types";
import { Icon } from "components";
import CategoryFormModal from "features/categories/CategoryFormModal";
import CategoryItem from "features/categories/CategoryItem";
import CategoryDeleteModal from "features/categories/CategoryDeleteModal";
import { ModalCategoryState } from "features/categories/types";
import { ModuleAction } from "features/modules-permissions";

interface Props {
  activeCategory: null | CategoryEntity;
  categories?: Results<CategoryEntity>;
  onClick: (value: CategoryEntity | null) => void;
}

const defaultState: ModalCategoryState = {
  title: "",
  visible: false,
  category: null,
  onCancel: () => {},
};

const defaultConfirmState: {
  visible: boolean;
  category: CategoryEntity | null;
} = {
  visible: false,
  category: null,
};

const CategoriesList = ({ categories, onClick, activeCategory }: Props) => {
  const [modal, setModal] = useSetState(defaultState);
  const [confirmModal, setConfirmModal] = useSetState(defaultConfirmState);

  const categoriesSelect = useMemo(
    () =>
      categories?.results.map((item) => {
        return {
          value: item.id,
          text: item.title,
        };
      }),
    [categories?.results],
  );

  const handleEdit = useCallback(
    (category) => {
      setModal({
        category,
        visible: true,
        title: "Edit category",
      });
    },
    [setModal],
  );

  const handleDelete = useCallback(
    (category) => setConfirmModal({ visible: true, category }),
    [setConfirmModal],
  );

  return (
    <div className="categories__wrapper">
      <ListGroup className="categories__list">
        {categories?.results.map((category: CategoryEntity) => {
          return (
            <CategoryItem
              key={category.id}
              category={category}
              onClick={(category) => onClick(category)}
              onEdit={handleEdit}
              onDelete={handleDelete}
              isActive={category?.id === activeCategory?.id}
            />
          );
        })}
      </ListGroup>
      <ModuleAction module="categories">
        <Space className="categories__add">
          <Button
            type="ghost"
            className="categories__add__button"
            onClick={() => {
              setModal({
                visible: true,
                title: "Add category",
              });
            }}
          >
            <Icon type="plus" /> Add category
          </Button>
        </Space>
      </ModuleAction>
      {modal.visible && (
        <CategoryFormModal
          title={modal.title}
          visible={modal.visible}
          category={modal.category}
          categoriesSelect={categoriesSelect}
          onCancel={() => setModal(defaultState)}
        />
      )}

      {confirmModal.visible && (
        <CategoryDeleteModal
          category={confirmModal.category}
          visible={confirmModal.visible}
          onSuccess={() => onClick(null)} // Reset active category
          onCancel={() => {
            setConfirmModal(defaultConfirmState);
          }}
        />
      )}
    </div>
  );
};
export default CategoriesList;
