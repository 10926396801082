import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Loader } from "ebs-design";

import { brands } from "api";
import {
  AlertErrors,
  CheckListFormField,
  Container,
  DebouncedInput,
  Icon,
} from "components";
import { BrandEntity, Results } from "types";

export const BrandLikesTab = () => {
  const [search, setSearch] = React.useState("");

  const brandsQuery = useQuery<Results<BrandEntity>, AxiosError>(
    ["brands", { search }],
    () => brands.getList({ search }),
  );

  const options = React.useMemo(
    () =>
      brandsQuery.data?.results?.map((b) => ({
        value: b?.title,
        text: <h4>{b?.title}</h4>,
      })) || [],
    [brandsQuery.data?.results],
  );
  return (
    <div className="padx-10 pady-8">
      <Container size={300} fixed mauto={false}>
        <DebouncedInput
          placeholder="Search brand"
          value={search}
          onChange={setSearch}
          suffix={<Icon type="search" />}
        />
      </Container>
      <div className="pady-1"></div>
      <AlertErrors error={brandsQuery.error} />
      <Loader loading={brandsQuery.isLoading} />
      <div className="pady-2"></div>
      {brandsQuery.data && (
        <CheckListFormField
          name={["parameters", "favourite_brands"]}
          options={options}
          component={({ children }) => (
            <div className="grid-3cols grid-gap-4">{children}</div>
          )}
        />
      )}
    </div>
  );
};
