import React from "react";
import { useMutation } from "react-query";

import { nomenclatures } from "api";
import { AxiosError } from "axios";
import { AlertErrors, ConfirmModal } from "components";
import { NomenclatureItem } from "types";

export interface DeleteLanguageKeyModalProps {
  toDelete: NomenclatureItem | undefined;
  setToDelete: React.Dispatch<NomenclatureItem | undefined>;
  onSuccess: () => void;
}

export const DeleteLanguageKeyModal = ({
  toDelete,
  setToDelete,
  onSuccess,
}: DeleteLanguageKeyModalProps) => {
  const deleteMutation = useMutation<unknown, AxiosError, number>(
    (id) => nomenclatures.deleteKey(id),
    {
      onSuccess: () => {
        setToDelete(undefined);
        onSuccess();
      },
    },
  );

  return (
    <ConfirmModal
      open={!!toDelete}
      onCancel={() => setToDelete(undefined)}
      onConfirm={() => deleteMutation.mutate(toDelete?.id || 0)}
      onClose={() => setToDelete(undefined)}
      loading={deleteMutation.isLoading}
    >
      <AlertErrors error={deleteMutation.error} />
      Are you sure to delete{" "}
      <span className="fw-500">{toDelete?.code_name}</span>?
    </ConfirmModal>
  );
};
