import { Avatar, Button, Collapse, Loader, Space, Label } from "ebs-design";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useSetState } from "react-use";

import { tutorials } from "api";
import { NomenclatureItem, NomenclatureItemType } from "types";
import { AlertErrors, Icon } from "components";

import TutorialModalForm from "features/tutorials/TutorialModalForm";
import DeleteTutorialModal from "features/tutorials/DeleteTutorialModal";

import { ModuleAction } from "features/modules-permissions";

interface ModalState {
  visible: boolean;
  tutorialId?: number | null;
  activeTutorial?: NomenclatureItem | null;
}

const tutorialsQueryParams = {
  type: NomenclatureItemType.TUTORIALS,
};

const defaultTutorialModal = {
  visible: false,
  activeTutorial: null,
};

const defaultDeleteTutorial = {
  visible: false,
  tutorialId: null,
};

const TutorialsList = () => {
  const [tutorialModal, setTutorialModal] =
    useSetState<ModalState>(defaultTutorialModal);

  const [deleteTutorial, setDeleteTutorial] = useSetState<ModalState>(
    defaultDeleteTutorial,
  );

  const { data, isFetching, isError, error } = useQuery<
    NomenclatureItem[],
    AxiosError
  >(["tutorials-list", tutorialsQueryParams], () =>
    tutorials.get(tutorialsQueryParams),
  );

  return (
    <Loader loading={isFetching}>
      <div className="tutorials">
        {isError ? (
          <AlertErrors error={error} />
        ) : (
          <>
            <ModuleAction module="cms">
              <Button
                className="tutorials__add-tutorial"
                prefix={<Icon type="plus" />}
                onClick={() => {
                  setTutorialModal({
                    visible: true,
                  });
                }}
              >
                Add Tutorial
              </Button>
            </ModuleAction>
            <div className="pady-2"></div>
            {data?.length ? (
              <Collapse.Group className="tutorials__collapse">
                {data?.map((item: NomenclatureItem) => {
                  return (
                    <Collapse collapsed key={item.id}>
                      <Collapse.Header className="tutorials__collapse__header">
                        <Space justify="space-between">
                          <Space>
                            {item.image && (
                              <Avatar
                                size="big"
                                img={item.image.url}
                                alt="tutorial"
                                type="light"
                              />
                            )}
                            <span>{item.title}</span>
                            <span>
                              <Label
                                status={item.is_active ? "success" : "danger"}
                                text={item.is_active ? "active" : "inactive"}
                                type="fill"
                              />
                            </span>
                          </Space>

                          <div>
                            <ModuleAction module="cms">
                              <span
                                className="tutorials__btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteTutorial({
                                    visible: true,
                                    tutorialId: item.id,
                                  });
                                }}
                              >
                                <Button
                                  size="small"
                                  className="ebs-button__action"
                                >
                                  <Icon type="delete" />
                                </Button>
                              </span>
                            </ModuleAction>
                            <ModuleAction module="cms">
                              <span
                                className="tutorials__btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setTutorialModal({
                                    visible: true,
                                    activeTutorial: item,
                                  });
                                }}
                              >
                                <Button
                                  size="small"
                                  className=" ebs-button__action"
                                >
                                  <Icon type="edit" />
                                </Button>
                              </span>
                            </ModuleAction>
                          </div>
                        </Space>
                      </Collapse.Header>
                      <Collapse.Body>{item.description || ""}</Collapse.Body>
                    </Collapse>
                  );
                })}
              </Collapse.Group>
            ) : (
              <p className="no-items">No tutorials</p>
            )}
            {tutorialModal.visible && (
              <TutorialModalForm
                open={tutorialModal.visible}
                onCancel={() => setTutorialModal(defaultTutorialModal)}
                activeTutorial={tutorialModal.activeTutorial}
              />
            )}
            {deleteTutorial.visible && (
              <DeleteTutorialModal
                tutorialId={deleteTutorial.tutorialId}
                visible={deleteTutorial.visible}
                onCancel={() => {
                  setDeleteTutorial(defaultDeleteTutorial);
                }}
              />
            )}
          </>
        )}
      </div>
    </Loader>
  );
};

export default TutorialsList;
