import React from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { Button, Card, Loader, Space } from "ebs-design";

import { cohorts } from "api";
import { CohortEntity } from "types";
import { AlertErrors, ConfirmModal, Container, Icon } from "components";
import { LOCALE } from "app-constants";

import { CohortStats, GohortGiftsTable } from "../CohortView";

import { PotentialUsersTable } from "../PotentialUsersTable";
import { CohortParametersDetails } from "../CohortParametersDetails";

import { ModuleAction } from "features/modules-permissions/ModuleAction";

export const CohortView = () => {
  const history = useHistory();
  const id = Number(useParams<{ id: string }>().id);

  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const cohortQuery = useQuery<CohortEntity, AxiosError>(
    ["cohorts", id],
    () => cohorts.getById(id || 0),
    {
      enabled: !!id,
    },
  );
  const cohort = cohortQuery.data;

  const deleteMutation = useMutation<unknown, AxiosError>(
    () => cohorts.delete(id),
    {
      onSuccess: () => {
        history.push("/cohorts/");
      },
    },
  );

  return (
    <Container size="lg">
      <AlertErrors error={cohortQuery.error} />
      <Loader loading={cohortQuery.isLoading} />
      <div className="pady-2"></div>
      {cohort && (
        <>
          <CohortStats cohort={cohort} />
          <div className="pady-2"></div>
          <Card className="cohorts__card-fix">
            <Card.Header>
              <h3>{cohort.title}</h3>
            </Card.Header>
            <Card.Body className="cohorts__details-layout bg-grey-200">
              <Card className="cohorts__card-fix">
                <Card.Header>
                  <h4>Details</h4>
                </Card.Header>
                <Card.Body>
                  <div className="color-secondary">Date created</div>
                  <div>
                    {new Date(cohort.timestamp).toLocaleDateString(LOCALE)}
                  </div>
                  <div className="pady-2"></div>
                  <div className="color-secondary">Created by</div>
                  <div>{cohort.user?.name}</div>
                  <div className="pady-2"></div>
                  <div className="color-secondary">Description</div>
                  <div>{cohort.description}</div>
                </Card.Body>
              </Card>
              <Card className="cohorts__card-fix">
                <Card.Header>
                  <h4>Parameters</h4>
                </Card.Header>
                <Card.Body>
                  <div className="cohorts__view-parameters-layout">
                    <CohortParametersDetails parameters={cohort.parameters} />
                  </div>
                </Card.Body>
              </Card>
            </Card.Body>
            <Card.Footer>
              <Space justify="space-between">
                <Space>
                  <ModuleAction module="send_gift">
                    <Link to={`/gifts/send/?cohort_id=${id}`}>
                      <Button type="ghost" prefix={<Icon type="status-sent" />}>
                        Send Gift
                      </Button>
                    </Link>
                  </ModuleAction>

                  {/* <Button type="ghost" prefix={<Icon type="share" />}>
                      Send Notification
                    </Button> */}
                </Space>
                <Space>
                  <ModuleAction module="cohorts">
                    <Button
                      type="primary"
                      prefix={<Icon type="delete" />}
                      onClick={() => setDeleteOpen(true)}
                    >
                      Delete
                    </Button>
                  </ModuleAction>
                  {/** Dublicate because of <Space /> */}
                  <ModuleAction module="cohorts">
                    <Link to={`/cohorts/${id}/edit`}>
                      <Button type="primary" prefix={<Icon type="edit" />}>
                        Edit
                      </Button>
                    </Link>
                  </ModuleAction>
                </Space>
              </Space>
            </Card.Footer>
          </Card>
          <div className="pady-1"></div>

          <GohortGiftsTable id={id} />
          <div className="pady-2"></div>

          <PotentialUsersTable parameters={cohort.parameters} />

          <ConfirmModal
            open={deleteOpen}
            onClose={() => setDeleteOpen(false)}
            onCancel={() => setDeleteOpen(false)}
            onConfirm={() => deleteMutation.mutate()}
            loading={deleteMutation.isLoading}
          >
            <AlertErrors error={deleteMutation.error} />
            <div className="pady-1"></div>
            Are you sure to delete this cohort?
          </ConfirmModal>
        </>
      )}
    </Container>
  );
};
