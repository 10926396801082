import LoginForm from "../LoginForm";
import GiftImage from "assets/images/Illustration.png";

const Login = () => {
  return (
    <div className="login">
      <div className="login__card">
        <div className="login__card__box">
          <img className="login__card__image" src={GiftImage} alt="gifts" />
        </div>
        <div className="login__wrapper">
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;
