import React from "react";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";

import { Button, Card, Loader, Space, useNotify } from "ebs-design";

import {
  AlertErrors,
  ConfirmModal,
  DebouncedInput,
  Icon,
  TabsWithRoute,
} from "components";
import { useModalState, useSearch } from "hooks";
import { notifyErrors } from "utils";
import { NetworkCardEntity } from "types";
import * as api from "api";

import { cmsTabs } from "features/cms/config";
import { ModuleAction } from "features/modules-permissions";

import { NetworkCard } from "../NetworkCard";
import { NetworkCardModal } from "../NetworkCardModal";

export const NetworkCards = () => {
  const notify = useNotify();

  const [search, setSearch] = useSearch();

  const addModal = useModalState();
  const [editModalData, setEditModalData] = React.useState<NetworkCardEntity>();
  const [deleteModalData, setDeleteModalData] =
    React.useState<NetworkCardEntity>();

  const queryParams = {
    search,
  };

  const query = useQuery(["network-cards", queryParams], () =>
    api.networkCards.getList(queryParams),
  );

  const networkCards = query.data || [];

  const postMutation = useMutation<
    NetworkCardEntity,
    AxiosError,
    Partial<NetworkCardEntity>
  >(
    (values) =>
      api.networkCards.post({
        ...values,
      }),
    {
      onSuccess: () => {
        notify.success({
          title: "",
          description: "Network card successfully created.",
        });
      },
    },
  );

  const patchMutation = useMutation<
    NetworkCardEntity,
    AxiosError,
    Partial<NetworkCardEntity>
  >((interest) => api.networkCards.patch(editModalData?.id || 0, interest), {
    onSuccess: () => {
      notify.success({
        title: "",
        description: "Network card successfully updated.",
      });
    },
  });

  const deleteMutation = useMutation(
    () => api.networkCards.delete(deleteModalData?.id || 0),
    {
      onSuccess: () => {
        setDeleteModalData(undefined);
        query.refetch();
      },
      onError: (error) => notifyErrors(notify, error),
    },
  );

  return (
    <TabsWithRoute tabItems={cmsTabs}>
      <div className="pady-5"></div>
      <Card>
        <Card.Body>
          <Space justify="space-between">
            <Space align="center" size={30}>
              <div className="fw-500">
                Network cards ({networkCards.length})
              </div>
              <div style={{ display: "inline-block", width: 300 }}>
                <DebouncedInput
                  placeholder="Search in network cards"
                  value={search}
                  onChange={setSearch}
                  suffix={<Icon type="search" />}
                />
              </div>
            </Space>
            <ModuleAction module="cms">
              <Button
                type="primary"
                onClick={addModal.open}
                prefix={<Icon type="plus" />}
              >
                Add network card
              </Button>
            </ModuleAction>
          </Space>
        </Card.Body>
      </Card>
      <div className="pady-3"></div>

      <AlertErrors error={query.error} />
      {query.isLoading && <Loader loading />}

      {query.isSuccess && !networkCards.length && (
        <div className="center-text">No items</div>
      )}

      <div className="network-cards__grid">
        {networkCards.map((networkCard) => (
          <React.Fragment key={networkCard.id}>
            <NetworkCard
              {...{
                networkCard,
                setEditModalData,
                setDeleteModalData,
              }}
            />
          </React.Fragment>
        ))}
      </div>

      <NetworkCardModal
        title="Add network card"
        open={addModal.isOpen}
        onClose={addModal.close}
        mutation={postMutation}
      />
      <NetworkCardModal
        title="Edit network card"
        data={editModalData}
        open={!!editModalData}
        onClose={() => setEditModalData(undefined)}
        mutation={patchMutation}
      />
      <ConfirmModal
        open={!!deleteModalData}
        onClose={() => setDeleteModalData(undefined)}
        onCancel={() => setDeleteModalData(undefined)}
        onConfirm={deleteMutation.mutate}
        loading={deleteMutation.isLoading}
      >
        Are you sure to delete <b>{deleteModalData?.title}</b>?
      </ConfirmModal>
    </TabsWithRoute>
  );
};
