import * as React from "react";

import RCUpload, { UploadProps as RcUploadProps } from "rc-upload";

import { GenericObject } from "types";
import { typedOmitKeys } from "utils";
import { useControlledState } from "hooks";

import { UploadItem, UploadProps } from "./types";
import { defaultUploadRenderItem } from "./defaultUploadRenderItem";

export const Upload = <TData extends unknown, TError extends Error = Error>({
  showItems = true,
  ...props
}: UploadProps<TData, TError>) => {
  const [items, setItems] = useControlledState(props.value, props.onChange, []);
  const [progress, setProgress] = React.useState<GenericObject<number>>({});

  React.useEffect(() => {
    if (items && items.some((i) => !i.file?.uid))
      setItems(
        items.map((i) => ({
          isFinished: true,
          ...i,
          file: { ...i.file, uid: i.file?.uid || performance.now() + "" },
        })),
      );
  }, [items, setItems]);

  const uploadProps: RcUploadProps = typedOmitKeys(props, [
    "onSuccess",
    "value",
    "onChange",
    "onRemove",
    "onError",
  ]);

  // Handlers
  uploadProps.onStart = (file): void => {
    setItems(props.multiple ? [...(items || []), { file }] : [{ file }]);
    setProgress((prev) => ({ ...prev, [file.uid]: 0 }));

    props.onStart && props.onStart(file);
  };

  uploadProps.onError = (error, ret, file): void => {
    let updatedItem: UploadItem<TData, TError> | undefined;
    setItems(
      items?.map((item) =>
        item.file?.uid === file.uid
          ? (updatedItem = {
              file,
              error: error as TError,
              // ? data: ret as TData, // maybe?
              isError: true,
              isFinished: true,
            })
          : item,
      ),
    );

    props.onError && props.onError(updatedItem, ret);
  };

  uploadProps.onSuccess = (response, file, xhr): void => {
    let updatedItem: UploadItem<TData, TError> | undefined;
    setItems(
      items?.map((item) =>
        item.file?.uid === file.uid
          ? (updatedItem = {
              file,
              data: response as TData,
              isSuccess: true,
              isFinished: true,
            })
          : item,
      ),
    );

    props.onSuccess && props.onSuccess(updatedItem, xhr);
  };

  uploadProps.onProgress = (event, file): void => {
    setProgress((prevState) => ({
      ...prevState,
      [file.uid]: Math.round(event.percent),
    }));

    props.onProgress && props.onProgress(event, file);
  };

  // Handle remove file
  const handleRemove = (item: UploadItem<TData, TError>, idx: number): void => {
    setItems(items?.filter((_, i) => idx !== i));
    props.onRemove && props.onRemove(item, idx);
  };

  return (
    <>
      {showItems &&
        items?.map((item, idx) => {
          return (
            <React.Fragment key={`${item.file?.name}-${idx}`}>
              {(props.renderItem || defaultUploadRenderItem)({
                item,
                idx,
                progress: progress[item.file?.uid || ""],
                onRemoveClick: () => handleRemove(item, idx),
              })}
            </React.Fragment>
          );
        })}
      <RCUpload {...uploadProps}>{props.children}</RCUpload>
    </>
  );
};
