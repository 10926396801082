import { UseMutationResult } from "react-query";
import React from "react";
import { AxiosError } from "axios";

import {
  Form,
  useNotify,
  Card,
  Loader,
  Button,
  Space,
  Input,
  Checkbox,
  useForm,
  Textarea,
  Switch,
} from "ebs-design";
import { FormProps } from "ebs-design/dist/components/organisms/Form";

import {
  AlertErrors,
  BrandsSelect,
  Container,
  Icon,
  UploadItem,
} from "components";
import {
  GenericObject,
  ProductEntity,
  ProductGender,
  ProductImageAttachment,
  ProductType,
} from "types";
import { typedPickKeys } from "utils";
import { useIsUploading } from "hooks";

import {
  ProductCategoriesField,
  ProductImagesField,
  ProductCategoriesFieldItem,
  ProductCharacteristics,
  characteristicsValidatorRules,
  ProductReedemTypes,
  ProductTags,
  ProductGendersField,
  ProductDiscountedPriceField,
} from "./";

export interface ProductFormProps {
  isBusiness?: boolean;
  title: string;
  values?: ProductEntity;
  productMutation: UseMutationResult<
    ProductEntity,
    AxiosError,
    Partial<ProductEntity>
  >;
  canMutate?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  showForm?: boolean;
  errors?: (AxiosError | undefined | null)[];
  onSuccessMessage?: string;
}

export const ProductForm = ({
  isBusiness,
  title,
  productMutation,
  values,
  canMutate = true,
  isLoading,
  isFetching,
  errors,
  showForm = true,
  onSuccessMessage,
}: ProductFormProps) => {
  const [form] = useForm();
  const notify = useNotify();

  const { isUploading, checkFields } = useIsUploading(["images"]);
  canMutate = canMutate && !isUploading;

  const setProductFields = React.useCallback(
    (data: ProductEntity) => {
      form.setFieldsValue({
        ...typedPickKeys(data, [
          "title",
          "price",
          "featured",
          "description",
          "is_active",
          "age_limit",
          "types",
          "in_gamification",
          "in_main_catalog",
          "is_free",
          "genders",
          "is_discount",
          "discount_price",
        ]),
        images:
          data.images
            ?.map((image) => ({
              ...image,
              featured: image?.id === data?.featured_image?.id,
            }))
            .map((i) => ({ file: i, data: i, isSuccess: true })) || [],
        brand_id: String(data.brand?.id || ""),
        categories: data.categories?.map((c) => ({
          id: String(c.id),
          parent_id: String(c.parent?.id || c.parent_id || "") || undefined,
        })),
        characteristic_types: data.characteristic_types || [],
        tags: data.tags?.map?.((t) => t.id),
      });
    },
    [form],
  );

  React.useEffect(() => {
    values && setProductFields(values);
  }, [values, setProductFields]);

  const onFinishHandler = (
    formValues: GenericObject<any> & {
      images: UploadItem<ProductImageAttachment>[];
    },
  ) => {
    const newProduct: GenericObject = {
      ...formValues,
      tags_id: formValues.tags,
      price: formValues.price,
      categories_id: formValues.categories
        ?.map((c: ProductCategoriesFieldItem) => c.id)
        .filter((_: number) => _),
      age_limit: formValues.age_limit || null,
      images: formValues.images
        ?.map((i) =>
          typedPickKeys(i.data || ({} as ProductImageAttachment), [
            "id",
            "featured",
          ]),
        )
        .filter((i) => i.id),
    };

    delete newProduct.subcategory_id;
    delete newProduct.tags;

    canMutate &&
      productMutation.mutate(newProduct, {
        onSuccess: (data) => {
          setProductFields(data);
          notify.success({
            title: "",
            description: onSuccessMessage,
          });
        },
      });
  };

  const onFieldsChangeHandler: FormProps["onFieldsChange"] = (
    fields,
    allFields,
  ) => {
    checkFields(fields, allFields);
    if (!fields.length || fields.length > 1) return;
  };

  return (
    <Container>
      <Card>
        <Card.Header>
          <h2>
            {title} {isFetching && <Loader.Inline> </Loader.Inline>}
          </h2>
        </Card.Header>
        <Card.Body>
          {errors?.map((e, i) => (
            <AlertErrors key={i} error={e} />
          ))}

          <Loader loading={!!isLoading} />
          {showForm && (
            <Form
              labelOptions={{ col: { size: 2 } }}
              controlOptions={{ col: { size: 10 } }}
              form={form}
              onFinish={onFinishHandler}
              onFieldsChange={onFieldsChangeHandler}
            >
              <Form.Field
                label="Name"
                name="title"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Field>
              <Form.Field
                label="Description"
                name="description"
                rules={[{ required: true }]}
              >
                <Textarea />
              </Form.Field>
              <Form.Field
                label="Price"
                name="price"
                rules={[{ required: true }]}
              >
                <Input type="number" min="0" />
              </Form.Field>
              {!isBusiness && (
                <Form.Field
                  label="Featured"
                  name="featured"
                  valuePropName="checked"
                  rules={[{ required: true }]}
                  initialValue={false}
                >
                  <Checkbox />
                </Form.Field>
              )}
              {!isBusiness && (
                <Form.Field
                  label="Free"
                  name="is_free"
                  valuePropName="checked"
                  rules={[{ required: true }]}
                  initialValue={false}
                >
                  <Checkbox />
                </Form.Field>
              )}
              {!isBusiness && (
                <Form.Field
                  label="Referral"
                  name="in_gamification"
                  valuePropName="checked"
                  rules={[{ required: true }]}
                  initialValue={false}
                >
                  <Checkbox />
                </Form.Field>
              )}
              {!isBusiness && (
                <Form.Field
                  label="In main catalog"
                  name="in_main_catalog"
                  valuePropName="checked"
                  rules={[{ required: true }]}
                  initialValue={false}
                >
                  <Checkbox />
                </Form.Field>
              )}

              {!isBusiness && <ProductDiscountedPriceField />}

              <Form.Field label="Age limit" name="age_limit">
                <Input type="number" min="0" />
              </Form.Field>
              <Form.Field
                label="Brand"
                name="brand_id"
                rules={[{ required: true }]}
              >
                <BrandsSelect />
              </Form.Field>
              <Form.Field
                name="is_active"
                label="Active"
                rules={[{ required: true }]}
                initialValue={false}
                valuePropName="checked"
              >
                <Switch />
              </Form.Field>
              <Form.Field
                name="types"
                label="Reedem type"
                initialValue={[ProductType.STORE]}
                rules={[{ required: true }]}
              >
                <ProductReedemTypes />
              </Form.Field>

              <Form.Field
                label="Genders"
                name="genders"
                initialValue={[ProductGender.THEM]}
                rules={[{ required: true }]}
              >
                <ProductGendersField />
              </Form.Field>

              <ProductCategoriesField />

              <div className="pady-3"></div>
              <Form.Field
                name="characteristic_types"
                label="Characteristics"
                initialValue={[]}
                dependencies={["is_discount"]}
                rules={characteristicsValidatorRules}
              >
                {(control, _, form) => (
                  <ProductCharacteristics {...control} form={form} />
                )}
              </Form.Field>

              {!isBusiness && (
                <>
                  <div className="pady-3"></div>
                  <Form.Field name="tags" label="Tags" initialValue={[]}>
                    <ProductTags additional={values?.tags} />
                  </Form.Field>
                </>
              )}
              <div className="pady-3"></div>

              <ProductImagesField />
            </Form>
          )}
        </Card.Body>
        <Card.Footer>
          <Space justify="end">
            <Button
              type="primary"
              loading={productMutation.isLoading}
              prefix={<Icon type="edit" />}
              onClick={() => form.submit()}
              disabled={!canMutate}
            >
              Save
            </Button>
          </Space>
        </Card.Footer>
      </Card>
    </Container>
  );
};
