import { Alert, Button, Container } from "ebs-design";
import { FallbackProps } from "react-error-boundary";

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <Container size="lg" className="error-boundary">
      <h3 className="error-boundary__title">Oops, Something Went Wrong!</h3>
      <h4>Please contact support for more information.</h4>
      <Alert
        message={error.message}
        type="error"
        className="error-boundary__message"
      />
      <Button
        className="error-boundary__try-again"
        onClick={resetErrorBoundary}
      >
        Try again
      </Button>
    </Container>
  );
};
