import {
  ApiQueryType,
  ProductEntity,
  ProductStats,
  Results,
  UpdateProductEntity,
} from "types";
import { defaultQueryLimit } from "utils";

import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

export const products = {
  getStats: async () => {
    const { data } = await apiAxios.get<ProductStats>("/products/stats/");
    return data;
  },
  getList: async (query: ApiQueryType = {}) => {
    query.limit = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/products/list/`, query);
    const { data } = await apiAxios.get<Results<ProductEntity>>(url);
    return data;
  },
  getById: async (id: number) => {
    const url = stringifyUrl(`/products/${id}/`);
    const { data } = await apiAxios.get<ProductEntity>(url);
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/products/${id}/`);
    return data;
  },
  patch: async (id: number, product: Partial<UpdateProductEntity>) => {
    const { data } = await apiAxios.patch<ProductEntity>(
      `/products/${id}/`,
      product,
    );
    return data;
  },
  toBusiness: async (id: number) => {
    const { data } = await apiAxios.post(`/products/${id}/to-business`);
    return data;
  },
  post: async (product: Partial<ProductEntity>) => {
    const { data } = await apiAxios.post<ProductEntity>(`/products/`, product);
    return data;
  },
  getTags: async () => {
    const {
      data: { tags },
    } = await apiAxios.get<{ tags: string[] }>(`/products/tags/`);
    return tags;
  },
};
