import { Button, Form, Upload } from "ebs-design";

export const CouponImportFile = () => {
  return (
    <Form.Field name="file" label="Import coupon" rules={[{ required: true }]}>
      <Upload
        directory={false}
        accept=" .xlsx,  .xls"
        name="file"
        customRequest={(o) => o}
      >
        <Button size="medium" className="border-dashed full-width">
          Import Coupon
        </Button>
      </Upload>
    </Form.Field>
  );
};
