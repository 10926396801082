import React from "react";
import { useInfiniteQuery } from "react-query";
import { useSetState } from "react-use";

import { nomenclatures } from "api";
import { GenericObject, NomenclatureIdKey } from "types";
import { paginationDefault } from "utils";

export type UseAddressNomenclaturesResult = ReturnType<
  typeof useAddressNomenclatures
>;

export function useInfiniteNomenclature(
  entity: string,
  additionalParams: GenericObject = {},
) {
  const [search, setSearch] = React.useState<string>("");

  const params = {
    limit: paginationDefault.limit,
    search,
    ...additionalParams,
  };

  const { data, ...rest } = useInfiniteQuery(
    ["nomenclatures", entity, params],
    async ({ pageParam: page = undefined }) => {
      return await nomenclatures.getList(entity, { ...params, page });
    },
    {
      getNextPageParam: (lastPage) => lastPage.next,
      keepPreviousData: true,
    },
  );

  return {
    ...rest,
    data: data?.pages.map((p) => p.results).flat(),
    search,
    setSearch,
  };
}

const toClearWhenChanges = {
  country: ["region", "subregion", "city"] as const,
  region: ["subregion", "city"] as const,
  subregion: ["city"] as const,
  city: [] as const,
};

export function useAddressNomenclatures() {
  const [query, setQuery] = useSetState<
    {
      [key in NomenclatureIdKey]: number | null | undefined;
    }
  >();

  const canShow = {
    country: true,
    region: !!query.country_id,
    subregion: !!query.region_id,
    city: !!query.subregion_id,
  };

  return {
    query,
    setQuery,
    canShow,
    toClearWhenChanges,
    data: {
      countries: useInfiniteNomenclature("countries", {}),
      regions: useInfiniteNomenclature("regions", {
        country_id: query.country_id,
      }),
      divisions: useInfiniteNomenclature("divisions", {
        region_id: query.region_id,
      }),
      cities: useInfiniteNomenclature("cities", {
        subregion_id: query.subregion_id,
      }),
    },
  };
}
