import React from "react";
import ImgViewer from "react-simple-image-viewer";

import { Card } from "ebs-design";

import { Icon } from "components";
import { CouponEntity, ImageViewerState } from "types";
import { LOCALE } from "app-constants";

export interface CouponDetailsProps {
  coupon: CouponEntity;
}

export const CouponDetails = ({ coupon }: CouponDetailsProps) => {
  const [imgViewerState, setImgViewerState] = React.useState<ImageViewerState>({
    currentIndex: 0,
    isOpen: false,
  });

  const detailsPairs = [
    ["Who created", coupon?.created_by?.name],
    ["Amount redeemed", coupon?.users_count],
    ["Price to prsnt", coupon?.product?.price],
    ["Max usage", coupon?.max_usage],
    ["Amount of redeems allowed total", coupon?.total_amount],
    [
      "Wrapping image",
      <img
        height="30"
        className="cursor-pointer"
        src={coupon?.wrapping_image?.url}
        onClick={() => setImgViewerState({ isOpen: true, currentIndex: 0 })}
        alt="Product"
      />,
    ],
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <h3 className="grid-2cols">
            <div>
              Code - <b className="small-text fw-200">{coupon?.code}</b>
            </div>
            <div className="flex-align-center">
              <Icon style={{ color: "#444444" }} type="clock" />
              <div className="padx-1"></div>
              <small className="fw-200">
                {coupon?.timestamp &&
                  new Date(coupon?.timestamp).toLocaleString(LOCALE)}
              </small>
            </div>
          </h3>
        </Card.Body>
      </Card>
      <div className="pady-2"></div>
      <Card>
        <Card.Header>
          <h3>Coupon details</h3>
        </Card.Header>
        <Card.Body>
          <div className="grid-2cols grid-gap-4">
            {detailsPairs.map((item, i) => (
              <div key={i}>
                <span className="small-text color-secondary">{item[0]}</span>
                <h4 className="fw-400">{item[1] ?? "---"}</h4>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
      {imgViewerState.isOpen && (
        <ImgViewer
          src={[coupon.wrapping_image?.url || ""]}
          currentIndex={imgViewerState.currentIndex}
          onClose={() => setImgViewerState({ isOpen: false, currentIndex: 0 })}
        />
      )}
    </>
  );
};
