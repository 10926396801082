import { FiltersSource, FilterType } from "types";

export const userFilters: FiltersSource = [
  { key: "is_active", name: "Active", type: FilterType.boolean },
  { key: "is_superuser", name: "Super user", type: FilterType.boolean },
  { key: "email_verified", name: "Email verified", type: FilterType.boolean },
  { key: "is_staff", name: "Staff", type: FilterType.boolean },
  { key: "terms_agreement", name: "Terms agreement", type: FilterType.boolean },
  {
    key: "privacy_agreement",
    name: "Privacy agreement",
    type: FilterType.boolean,
  },
];
