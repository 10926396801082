import { Switch } from "ebs-design";
import { useHasModuleActions } from "hooks";

export interface ProductTableActiveSwitchProps {
  onChange: (v: boolean) => void;
  checked: boolean;
}

export const ProductTableActiveSwitch = ({
  checked,
  onChange,
}: ProductTableActiveSwitchProps) => {
  const hasModuleActions = useHasModuleActions("products");
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      disabled={!hasModuleActions}
    />
  );
};
