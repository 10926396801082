import { AccountInfo } from "./svgs/AccountInfo"
import { Alert } from "./svgs/Alert"
import { Back } from "./svgs/Back"
import { Birthday } from "./svgs/Birthday"
import { Birthday2 } from "./svgs/Birthday2"
import { Block } from "./svgs/Block"
import { CalendarStandard } from "./svgs/CalendarStandard"
import { CalendarValid } from "./svgs/CalendarValid"
import { Calendar } from "./svgs/Calendar"
import { Camera } from "./svgs/Camera"
import { Card } from "./svgs/Card"
import { Check } from "./svgs/Check"
import { Clock } from "./svgs/Clock"
import { Delete } from "./svgs/Delete"
import { Delivery } from "./svgs/Delivery"
import { Droparrow } from "./svgs/Droparrow"
import { Edit } from "./svgs/Edit"
import { Ellipse } from "./svgs/Ellipse"
import { Filters } from "./svgs/Filters"
import { Friends } from "./svgs/Friends"
import { Heart } from "./svgs/Heart"
import { Help } from "./svgs/Help"
import { Home } from "./svgs/Home"
import { Home2 } from "./svgs/Home2"
import { Idea } from "./svgs/Idea"
import { Info } from "./svgs/Info"
import { InviteFriends } from "./svgs/InviteFriends"
import { Left } from "./svgs/Left"
import { Like } from "./svgs/Like"
import { Lock } from "./svgs/Lock"
import { Logout } from "./svgs/Logout"
import { Message } from "./svgs/Message"
import { Moreactions } from "./svgs/Moreactions"
import { MyPrsnts2 } from "./svgs/MyPrsnts2"
import { Notifications } from "./svgs/Notifications"
import { Plus } from "./svgs/Plus"
import { Prsnts2 } from "./svgs/Prsnts2"
import { Record } from "./svgs/Record"
import { Search } from "./svgs/Search"
import { Settings } from "./svgs/Settings"
import { Share } from "./svgs/Share"
import { Skip } from "./svgs/Skip"
import { StatusRedeemed } from "./svgs/StatusRedeemed"
import { StatusSent } from "./svgs/StatusSent"
import { StatusUnwrapped } from "./svgs/StatusUnwrapped"
import { Store } from "./svgs/Store"
import { Swipe } from "./svgs/Swipe"
import { Text } from "./svgs/Text"
import { User } from "./svgs/User"
import { Verify } from "./svgs/Verify"
import { Cross } from "./svgs/Cross"

export const iconList = {
  "account-info": AccountInfo,
  alert: Alert,
  back: Back,
  birthday: Birthday,
  birthday2: Birthday2,
  block: Block,
  "calendar-standard": CalendarStandard,
  "calendar-valid": CalendarValid,
  calendar: Calendar,
  camera: Camera,
  card: Card,
  check: Check,
  clock: Clock,
  delete: Delete,
  delivery: Delivery,
  droparrow: Droparrow,
  edit: Edit,
  ellipse: Ellipse,
  filters: Filters,
  friends: Friends,
  heart: Heart,
  help: Help,
  home: Home,
  home2: Home2,
  idea: Idea,
  info: Info,
  "invite-friends": InviteFriends,
  left: Left,
  like: Like,
  lock: Lock,
  logout: Logout,
  message: Message,
  moreactions: Moreactions,
  "my-prsnts2": MyPrsnts2,
  notifications: Notifications,
  plus: Plus,
  prsnts2: Prsnts2,
  record: Record,
  search: Search,
  settings: Settings,
  share: Share,
  skip: Skip,
  "status-redeemed": StatusRedeemed,
  "status-sent": StatusSent,
  "status-unwrapped": StatusUnwrapped,
  store: Store,
  swipe: Swipe,
  text: Text,
  user: User,
  verify: Verify,
  cross: Cross,
}
