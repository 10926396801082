import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { nomenclatures } from "api";
import { ConfirmModal, AlertErrors } from "components";

interface Props {
  visible: boolean;
  onCancel: () => void;
  sliderId?: number | null;
  sliderTitle?: string | null;
}

const DeleteSliderModal = ({
  visible,
  onCancel,
  sliderId,
  sliderTitle,
}: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation<
    unknown,
    AxiosError,
    number
  >(nomenclatures.deleteKey, {
    onSuccess: () => {
      onCancel();
      queryClient.invalidateQueries("sliders-list");
    },
  });

  return visible ? (
    <ConfirmModal
      open={visible}
      loading={isLoading}
      onConfirm={() => mutate(sliderId!)}
      onClose={onCancel}
      onCancel={onCancel}
    >
      {isError ? (
        <AlertErrors error={error} />
      ) : (
        <p>
          Are you sure that you want to delete <strong>{sliderTitle}</strong>{" "}
          slider?
        </p>
      )}
    </ConfirmModal>
  ) : null;
};
export default DeleteSliderModal;
