import React from "react";
import { UseMutationResult, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { Modal, Form, useForm, Button, Space, Input, Switch } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { NomenclatureItem, NomenclatureItemType } from "types";
import { AlertErrors, Icon, Upload } from "components";
import { attachments } from "api";

import { FaceFilterPhotoUpload } from ".";

export interface FaceFilterModalProps extends ModalProps {
  mutation: UseMutationResult<
    NomenclatureItem,
    AxiosError,
    Partial<NomenclatureItem>
  >;
  data?: NomenclatureItem;
}

export const FaceFilterModal = ({
  data,
  mutation,
  ...modalProps
}: FaceFilterModalProps) => {
  const [form] = useForm();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    const values = {
      ...data,
      image_en: data?.image
        ? [{ data: data?.image, file: data?.image, isSuccess: true }]
        : [],
      link: data?.link
        ? [
            {
              data: { url: data?.link },
              file: { url: data?.link, isSuccess: true },
            },
          ]
        : [],
    };
    form.setFieldsValue(values);
  }, [data, form]);

  const submitHandler = (values: any) => {
    mutation.mutate(
      {
        ...values,
        image_en: values.image_en?.[0]?.data?.id,
        link: values.link?.[0]?.data?.url,
      },
      {
        onSuccess: () => {
          form.resetFields();
          modalProps.onClose?.();
          queryClient.invalidateQueries([
            "nomenclatures",
            NomenclatureItemType.FACE_FILTER,
          ]);
        },
      },
    );
  };

  return (
    <Modal size="small" {...modalProps}>
      <Form form={form} onFinish={submitHandler}>
        <Modal.Content>
          <h3>Image</h3> <div className="pady-2"></div>
          <FaceFilterPhotoUpload />
          <Form.Field
            name="title_en"
            label="Title"
            initialValue=""
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>
          <Form.Field
            name="code_name"
            label="Code name"
            initialValue=""
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>
          <Form.Field
            name="is_active"
            label="Active"
            rules={[{ required: true }]}
            initialValue={true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Field>
          <Form.Field
            name="link"
            label="Face asset"
            rules={[{ required: true }]}
            initialValue={[]}
          >
            <Upload name="files" customRequest={(o) => attachments.post(o, {})}>
              <Button>Upload</Button>
            </Upload>
          </Form.Field>
          <AlertErrors error={mutation.error} />
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={modalProps.onClose}>Cancel</Button>
            <Button
              submit
              type="primary"
              loading={mutation.isLoading}
              prefix={<Icon type="edit" />}
              disabled={mutation.isLoading}
            >
              Save
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
