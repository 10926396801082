import React from "react";
import ImgViewer from "react-simple-image-viewer";

import { Card } from "ebs-design";

import { CouponEntity, ImageViewerState } from "types";

export interface CouponSenderDetailsProps {
  coupon: CouponEntity;
}

export const CouponSenderDetails = ({ coupon }: CouponSenderDetailsProps) => {
  const [imgViewerState, setImgViewerState] = React.useState<ImageViewerState>({
    currentIndex: 0,
    isOpen: false,
  });

  const detailsPairs = [
    ["First name", coupon?.from_first_name],
    ["Last name", coupon?.from_last_name],
    ["message", coupon?.message],
    [
      "Photo",
      <img
        height="30"
        className="cursor-pointer"
        src={coupon?.from_photo}
        onClick={() => setImgViewerState({ isOpen: true, currentIndex: 0 })}
        alt="Product"
      />,
    ],
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <h3>Sender details</h3>
        </Card.Header>
        <Card.Body>
          <div className="grid-2cols grid-gap-4">
            {detailsPairs.map((item, i) => (
              <div key={i}>
                <span className="small-text color-secondary">{item[0]}</span>
                <h4 className="fw-400">{item[1] ?? "---"}</h4>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
      {imgViewerState.isOpen && (
        <ImgViewer
          src={[coupon?.from_photo || ""]}
          currentIndex={imgViewerState.currentIndex}
          onClose={() => setImgViewerState({ isOpen: false, currentIndex: 0 })}
        />
      )}
    </>
  );
};
