import React from "react";

import { Button, ButtonGroup, Space } from "ebs-design";

import { ConfirmModal, ConfirmModalProps, ProductSelect } from "components";
import { ProductEntity } from "types";

export interface ReorderModalProps
  extends Omit<ConfirmModalProps, "onConfirm" | "children"> {
  onConfirm: (where: string, pivot: ProductEntity) => void;
  toReorder?: ProductEntity;
}

export const ReorderModal = ({
  onConfirm,
  toReorder,
  ...props
}: ReorderModalProps) => {
  const [where, setWhere] = React.useState<"below" | "above">("above");
  const [pivot, setPivot] = React.useState<ProductEntity>();

  return (
    <ConfirmModal
      title="Reorder product position"
      {...props}
      onConfirm={() => {
        if (pivot) {
          onConfirm(where, pivot);
          setPivot(undefined);
        }
      }}
    >
      <Space>
        <div className="icon-image">
          <img
            src={toReorder?.featured_image?.url}
            alt={toReorder?.title || ""}
          />
        </div>
        <h3 className="fw-600">{toReorder?.title}</h3>
      </Space>

      <div className="pady-2"></div>
      <ButtonGroup>
        <Button
          size="medium"
          type={where === "above" ? "primary" : "ghost"}
          onClick={() => setWhere("above")}
        >
          Above
        </Button>
        <Button
          size="medium"
          type={where === "below" ? "primary" : "ghost"}
          onClick={() => setWhere("below")}
        >
          Below
        </Button>
      </ButtonGroup>
      <div className="pady-2"></div>
      <ProductSelect
        value={pivot?.id}
        onChange={setPivot}
        queryEnabled={props.open}
      />
    </ConfirmModal>
  );
};
