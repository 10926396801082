import { Form, Checkbox, Space, Loader, Button } from "ebs-design";

import { DebouncedInput, Icon } from "components";
import { useInfiniteNomenclature } from "hooks";
import { IdName } from "types";

export const LocationField = () => {
  const {
    search,
    setSearch,
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteNomenclature("cities");

  return (
    <Form.Field name={["parameters", "location"]} initialValue={[]}>
      {({ value: _value, onChange: _onChange }) => {
        const value = _value as IdName[];
        const onChange = (l: IdName, checked: boolean) =>
          checked
            ? _onChange([...(value || []), l])
            : _onChange(value?.filter((v: any) => v !== l) || []);

        let options =
          data?.map((l) => (
            <Checkbox
              key={l.id}
              checked={!!value?.find((l1) => l.id === l1.id)}
              onChange={(checked) => onChange(l, checked)}
              text={l.name}
              className="mary-2"
            />
          )) || [];

        let additionalOptions =
          value
            ?.filter((l) => !data?.find((c) => c.id === l.id))
            .map((l) => (
              <Checkbox
                key={l.id}
                checked
                onChange={(checked) => onChange(l, checked)}
                text={l.name}
                className="mary-2"
              />
            )) || [];

        return (
          <>
            <DebouncedInput
              value={search}
              onChange={setSearch}
              prefix={<Icon type="search" />}
              placeholder="Search city"
            />

            <div className="pady-2"></div>
            {additionalOptions}
            {options}

            {!isLoading && (!data || !data.length) && (
              <Space className="pady-4" justify="center">
                Nothing found
              </Space>
            )}

            {(isLoading || isFetching) && <Loader.Inline />}

            {hasNextPage && (
              <>
                <div className="pady-2"></div>
                <Button
                  className="mary-2"
                  size="small"
                  disabled={isFetching}
                  onClick={() => fetchNextPage()}
                >
                  Load more
                </Button>
              </>
            )}
          </>
        );
      }}
    </Form.Field>
  );
};
