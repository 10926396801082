import { UserProfileEntity } from "types";

import { Space } from "ebs-design";
import { differenceInDays } from "date-fns";

export interface UserStatsProps {
  user: UserProfileEntity;
}
export const UserStats = ({ user }: UserStatsProps) => {
  const numberOfDays = differenceInDays(
    new Date(),
    new Date(user?.date_joined || new Date()),
  );

  const frequencyOfSendingGift = (
    numberOfDays / (user?.stats?.gifts?.count || 1)
  ).toFixed(2);

  return (
    <div className="details-card__grid">
      <div className="details-card">
        <Space justify="space-between" align="center">
          <h4>Paid Gifts Sent</h4>
          <h2>{user?.stats?.gifts?.total_paid_gifts}</h2>
        </Space>
      </div>
      <div className="details-card">
        <Space justify="space-between" align="center">
          <h4>Free gifts / Invites sent</h4>
          <h2>{user?.stats?.gifts?.total_free_gifts}</h2>
        </Space>
      </div>
      <div className="details-card">
        <Space justify="space-between" align="center">
          <h4>Total Revenue from user</h4>
          <h2>{user?.stats?.transactions?.revenue}</h2>
        </Space>
      </div>
      <div className="details-card">
        <Space justify="space-between" align="center">
          <h4>User Frequency of sending gift</h4>
          <h2>{frequencyOfSendingGift} days</h2>
        </Space>
      </div>
    </div>
  );
};
