export interface Attributes {
  width: string | number;
  height: string | number;
}

export enum AttachmentType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  OTHER = "OTHER",
}
export enum AttachmentSubType {
  SENDER = "SENDER",
  RECEIVER = "RECEIVER",
}

export interface Attachment<TData = any> extends Attributes {
  id: number;
  file_name: string;
  timestamp: string;
  edited_timestamp: string;
  name: string;
  url: string;
  mime_type: string;
  size: string;
  type: AttachmentType;
  subtype: AttachmentSubType;
  data: TData;
}

export interface ProductImageAttachment extends Attachment {
  featured?: boolean;
}

export interface ImageAttachment {
  id: number;
  url: string;
  name: string;
  path: string;
  size: number;
  type: string;
  old_url: null;
  file_hash: string;
  attributes: Attributes;
  created_at: string;
  content_type: string;
  deletion_msg: null;
  is_processed: boolean;
  processed_at: null;
  deletion_status: null;
}
