import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";

import { cohorts } from "api";
import { CohortEntity } from "types";

import { CohortForm } from "../CohortForm";

export const CohortEdit = () => {
  const id = Number(useParams<{ id: string }>().id);

  const cohortMutation = useMutation<
    CohortEntity,
    AxiosError,
    Partial<CohortEntity>
  >((cohort) => cohorts.patch(id, cohort));

  const cohortQuery = useQuery<CohortEntity, AxiosError>(["cohorts", id], () =>
    cohorts.getById(id),
  );

  const canMutate = !(cohortQuery.isLoading || cohortMutation.isLoading);

  return (
    <>
      <CohortForm
        values={cohortQuery.data}
        cohortMutation={cohortMutation}
        canMutate={canMutate}
        showForm={!cohortQuery.isLoading && !cohortQuery.isError}
        isLoading={cohortQuery.isLoading}
        errors={[cohortQuery.error, cohortMutation.error]}
        onSuccessMessage="Cohort updated successfully"
      />
    </>
  );
};
