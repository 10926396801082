export enum Period {
  YEARS = "YEARS",
  MONTHS = "MONTHS",
  DAYS = "DAYS",
  HOURS = "HOURS",
  MINUTES = "MINUTES",
  SECONDS = "SECONDS",
  MICROSECONDS = "MICROSECONDS",
}

export type SettingValueType =
  | "boolean"
  | "number"
  | "string"
  | "product_select";

export interface SettingEntity {
  id: number;
  code_name: string;
  value: string;
  values: (string | number)[];
  value_type: SettingValueType;
  period: Period;
  state: boolean;
  timestamp?: string;
  edited_timestamp?: string;
  title?: string;
  description?: string;
}
