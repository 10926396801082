import React from "react";

import Home from "features/home/pages/Home";
import { Users, UserProfile, UserEdit } from "features/users/pages";
import { Products, ProductEdit, ProductAdd } from "features/products/pages";
import {
  Cohorts,
  CohortAdd,
  CohortEdit,
  CohortView,
} from "features/cohorts/pages";
import Gifts from "features/gifts/pages/Gifts";
import Settings from "features/settings/pages/Settings";
import { Languages } from "features/languages/pages/Languages";
import Analytics from "features/analytics/pages/Analytics";
import Notifications from "features/notifications/pages/Notifications";
import Faq from "features/faq/pages/Faq";
import { Orders, OrderDetails } from "features/transactions/pages";
import Tutorials from "features/tutorials/pages/Tutorials";
import Categories from "features/categories/pages/Categories";
import { Brands } from "features/brands/pages";
import NotFound from "features/404/pages/NotFound";
import { GiftDetails, GiftForm } from "features/gifts/pages";
import Profile from "features/profile/pages/Profile";
import GiftCovers from "features/gift-covers/pages/GiftCovers";
import {
  Coupons,
  CouponAdd,
  CouponEdit,
  CouponView,
  CouponImport,
} from "features/coupons/pages";
import Sliders from "features/sliders/pages/Sliders";
import { GiftOperationView } from "features/gift-operations/pages";
import { Interests } from "features/interests/pages";
import { FaceFilters } from "features/face-filters/pages";
import { NetworkCards } from "features/network-cards/pages";
import { Campaigns } from "features/campaigns/pages";
import { CampaignsTier } from "features/campaigns-tier/pages";

export interface RouteType {
  name: string;
  path: string | string[];
  component: any;
  exact?: boolean;
}

export const routes: RouteType[] = [
  { name: "Home", path: ["/", "/home"], exact: true, component: Home },
  { name: "Profile", path: "/profile", exact: true, component: Profile },
  {
    name: "View user",
    path: "/users/:id/",
    exact: true,
    component: UserProfile,
  },
  {
    name: "Edit user",
    path: "/users/:id/edit",
    exact: true,
    component: UserEdit,
  },
  { name: "Users", path: "/users", exact: true, component: Users },
  { name: "Products", path: "/products", exact: true, component: Products },
  {
    name: "Product add",
    path: "/products/new/",
    exact: true,
    component: ProductAdd,
  },
  {
    name: "Product edit",
    path: "/products/:id/edit",
    exact: true,
    component: ProductEdit,
  },
  { name: "Cohorts", path: "/cohorts", exact: true, component: Cohorts },
  {
    name: "Cohort add",
    path: "/cohorts/new",
    exact: true,
    component: CohortAdd,
  },
  {
    name: "Cohort edit",
    path: "/cohorts/:id/edit",
    exact: true,
    component: CohortEdit,
  },
  {
    name: "View cohort",
    path: "/cohorts/:id",
    exact: true,
    component: CohortView,
  },
  { name: "Gifts", path: "/gifts", exact: true, component: Gifts },
  {
    name: "Send gift",
    path: "/gifts/send",
    exact: true,
    component: GiftForm,
  },
  {
    name: "Gift Details",
    path: "/gifts/:id",
    exact: true,
    component: GiftDetails,
  },
  { name: "Settings", path: "/settings", exact: true, component: Settings },
  {
    name: "Language",
    path: "/language",
    exact: true,
    component: Languages,
  },
  {
    name: "Analytics",
    path: "/analytics",
    exact: true,
    component: Analytics,
  },
  {
    name: "Notifications",
    path: "/notifications",
    exact: true,
    component: Notifications,
  },
  { name: "Faq", path: "/faq", exact: true, component: Faq },
  {
    name: "Transactions",
    path: "/transactions",
    exact: true,
    component: Orders,
  },
  {
    name: "Transactions",
    path: "/transactions/:id/",
    exact: true,
    component: OrderDetails,
  },
  { name: "CMS", path: "/cms/tutorials", exact: true, component: Tutorials },
  {
    name: "gift-covers",
    path: "/cms/gift-covers",
    exact: true,
    component: GiftCovers,
  },
  {
    name: "sliders",
    path: "/cms/sliders",
    exact: true,
    component: Sliders,
  },
  {
    name: "interests",
    path: "/cms/interests",
    exact: true,
    component: Interests,
  },
  {
    name: "face-filters",
    path: "/cms/face-filters",
    exact: true,
    component: FaceFilters,
  },
  {
    name: "network-cards",
    path: "/cms/network-cards",
    exact: true,
    component: NetworkCards,
  },
  {
    name: "Categories",
    path: "/categories",
    exact: true,
    component: Categories,
  },
  {
    name: "Brands",
    path: "/brands",
    exact: true,
    component: Brands,
  },
  {
    name: "Coupons",
    path: "/coupons",
    exact: true,
    component: Coupons,
  },
  {
    name: "Coupon Add",
    path: "/coupons/new",
    exact: true,
    component: CouponAdd,
  },
  {
    name: "Coupon Import",
    path: "/coupons/import",
    exact: true,
    component: CouponImport,
  },
  {
    name: "Coupon View",
    path: "/coupons/:id",
    exact: true,
    component: CouponView,
  },
  {
    name: "Coupon Edit",
    path: "/coupons/:id/edit",
    exact: true,
    component: CouponEdit,
  },
  {
    name: "Gift Operation View",
    path: "/gift-operation/:id",
    exact: true,
    component: GiftOperationView,
  },
  {
    name: "Business products",
    path: "/business-products/",
    exact: true,
    component: () =>
      React.createElement(Products, {
        isBusiness: true,
        basePath: "/business-products",
      }),
  },
  {
    name: "Business products Add",
    path: "/business-products/new",
    exact: true,
    component: () =>
      React.createElement(ProductAdd, {
        isBusiness: true,
        redirectTo: "/business-products/",
      }),
  },
  {
    name: "Business products Edit",
    path: "/business-products/:id/edit",
    exact: true,
    component: () => React.createElement(ProductEdit, { isBusiness: true }),
  },
  {
    name: "Campaigns",
    path: "/campaigns/",
    exact: true,
    component: Campaigns,
  },
  {
    name: "Campaigns tier",
    path: "/campaigns-tier",
    exact: true,
    component: CampaignsTier,
  },
  {
    name: "404",
    path: "*",
    exact: true,
    component: NotFound,
  },
];
