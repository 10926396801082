import React from "react";

import { Space } from "ebs-design";
import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";

import { NomenclatureItem } from "types";
import { Icon } from "components";

import { ModuleAction } from "features/modules-permissions";

import {
  LangaugeKeyEditControl,
  languageEditKeyItemRenderer,
} from "./language-key-edit-tools";

import { displayLanguages } from "../config";

interface Args {
  onDeleteClick: React.Dispatch<NomenclatureItem>;
}

export const getLanguagesTableColumns = ({
  onDeleteClick,
}: Args): ColumnType<NomenclatureItem>[] => [
  {
    title: "code_name",
    dataIndex: "code_name",
  },
  {
    title: "Description",
    dataIndex: "description",
    render: languageEditKeyItemRenderer("description"),
  },
  ...displayLanguages.map((l) => ({
    title: l.title,
    dataIndex: "title_" + l.code,
    render: languageEditKeyItemRenderer("title_" + l.code),
  })),
  {
    dataIndex: "id",
    render: (id: number, record: NomenclatureItem) => (
      <ModuleAction module="languages">
        <Space>
          <LangaugeKeyEditControl id={id} />
          <span
            onClick={() => onDeleteClick(record)}
            title="Delete"
            className="cursor-pointer with-title"
          >
            <Icon type="delete" />
          </span>
        </Space>
      </ModuleAction>
    ),
  },
];
