import React from "react";

import { Select } from "ebs-design";
import { UseProductCategoryFiltersResult } from "./useProductCategoryFilters";

export interface ProductCategoryFiltersProps {
  useProductCategoryFiltersResult: UseProductCategoryFiltersResult;
}

export const ProductCategoryFilters = ({
  useProductCategoryFiltersResult: { categories, subCategories },
}: ProductCategoryFiltersProps) => {
  return (
    <div className="filters">
      <div className="filters__item">
        <Select
          placeholder="Category"
          isClearable={!!categories.current}
          value={categories.current}
          options={categories.data.map((e) => ({ text: e.title, value: e.id }))}
          onChange={(o) => !(o instanceof Array) && categories.set(o)}
          loading={categories.isLoading}
        />
      </div>
      {categories.current && (
        <div className="filters__item">
          <Select
            placeholder="Subcategory"
            isClearable={!!subCategories.current}
            value={subCategories.current}
            options={subCategories.data.map((e) => ({
              text: e.title,
              value: e.id,
            }))}
            onChange={(o) => !(o instanceof Array) && subCategories.set(o)}
            loading={subCategories.isLoading}
          />
        </div>
      )}
    </div>
  );
};
