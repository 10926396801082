import { apiAxios } from "./axios/apiAxios";

import {
  Results,
  ApiQueryType,
  FaqEntity,
  FaqCategoryEntity,
  AttachmentType,
} from "types";
import {
  QuestionFormBodyValues,
  FaqCategoryBodyValues,
} from "features/faq/types";
import { stringifyUrl } from "api/utils";

export const faq = {
  getCategories: async (query?: ApiQueryType) => {
    const url = stringifyUrl("/faqs/categories/list/", query);
    const { data } = await apiAxios.get<Results<FaqCategoryEntity>>(url);
    return data.results;
  },
  getQuestions: async (query?: ApiQueryType) => {
    const url = stringifyUrl("/faqs/list/", query);
    const { data } = await apiAxios.get<FaqEntity[]>(url);
    return data;
  },
  createQuestion: async (bodyData: QuestionFormBodyValues) => {
    const { data } = await apiAxios.post<FaqEntity>("/faqs/", bodyData);
    return data;
  },
  updateQuestion: async (id: number, bodyData: QuestionFormBodyValues) => {
    const { data } = await apiAxios.patch<FaqEntity>(`/faqs/${id}/`, bodyData);
    return data;
  },
  deleteQuestion: async (id: number) => {
    const { data } = await apiAxios.delete(`/faqs/${id}/`);
    return data;
  },
  addCategory: async (bodyData: FaqCategoryBodyValues) => {
    const { data } = await apiAxios.post<FaqCategoryEntity>(
      "/faqs/categories/",
      bodyData,
    );
    return data;
  },
  updateCategory: async (id: number, bodyData: FaqCategoryBodyValues) => {
    const { data } = await apiAxios.patch<FaqCategoryEntity>(
      `/faqs/categories/${id}/`,
      bodyData,
    );
    return data;
  },
  deleteCategory: async (id: number) => {
    const { data } = await apiAxios.delete(`/faqs/categories/${id}/`);
    return data;
  },
  uploadImage: async (bodyData: string) => {
    const formData = new FormData();
    formData.append("files", bodyData);
    formData.append("image", AttachmentType.IMAGE);

    const { data } = await apiAxios.post("/attachments/", formData);

    return data[0];
  },
};
