import { Col } from "ebs-design";

import { CircularProgress, RenderUploadItemProps } from "components";
import { Attachment } from "types";

export const PhotoUploadItem = ({
  item,
  progress,
}: RenderUploadItemProps<Attachment, Error>) => {
  const photo = item?.data;
  return (
    <Col size={2}>
      {item.isSuccess && (
        <img src={photo?.url} style={{ width: "100%" }} alt="" />
      )}
      {item.isError && (
        <div className="color-danger">{item.error?.message}</div>
      )}
      {!item.isFinished && (
        <div className="fw-500">
          <CircularProgress padding="0.5rem" value={progress || 0} />
        </div>
      )}
    </Col>
  );
};
