import {
  Attachment,
  ImageAttachment,
  IdName,
  Address,
  Stats,
  SettingEntity,
  Interest,
  Company,
} from "types";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
  NON_BINARY = "NON_BINARY",
  PREFER_NOT_TO_SAY = "PREFER_NOT_TO_SAY",
  OTHER = "OTHER",
}

export enum Preferences {
  FULLNAME = "FULLNAME",
  USERNAME = "USERNAME",
}

export interface UserProfileEntity {
  gifts: any;
  id: number;
  email: string;
  email_verified: boolean;
  name: string;
  date_joined: string;
  username: string;
  first_name: string;
  last_name: string;
  birthday: string;
  phone: string;
  bio: string | null;
  photo: Attachment<ImageAttachment>;
  roles: IdName[];
  roles_id?: number[];
  stats: Stats;
  interests: Interest[];
  preference: Preferences;
  settings: SettingEntity[];
  favourites_ids: number[];
  referral_code: string;
  tokens: string[];
  address: Address;
  gender: Gender;

  company?: Company;

  is_superuser: boolean;
  modules_permissions: Record<string, PRSNTModulePermission>;
  last_login?: string;
  is_suspended?: boolean;
  is_active?: boolean;
  has_delete_request?: boolean;

  flags: UserFlag[];
}

export interface UserFlag {
  code: string;
  description?: string;
}

export enum PRSNTModulePermission {
  view = "view",
  full = "full",
}

export type PRSNTModuleCodeName =
  | "users"
  | "cohorts"
  | "products"
  | "send_gift"
  | "app_settings"
  | "faq"
  | "transactions"
  | "languages"
  | "cms"
  | "categories"
  | "coupons";

export interface PRSNTModuleEntity {
  title: string;
  code_name: string;
}
