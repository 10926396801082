import { useHistory } from "react-router-dom";
import { GenericObject } from "types";

export interface TableRowLinkProps extends GenericObject {
  makeUrl: (key: string) => string;
}

export const TableRowLink = ({ makeUrl, ...props }: TableRowLinkProps) => {
  const history = useHistory();

  return (
    <tr
      {...props}
      onClick={() =>
        props["data-row-key"] && history.push(makeUrl(props["data-row-key"]))
      }
      style={{
        cursor: "pointer",
      }}
    ></tr>
  );
};
