import { SettingEntity } from "types";
import { apiAxios } from "./axios/apiAxios";

export const settings = {
  getSettings: async () => {
    const { data } = await apiAxios.get<SettingEntity[]>("/settings/list/");
    return data;
  },
  updateSettings: async (formData: Partial<SettingEntity>[]) => {
    const { data } = await apiAxios.patch("/settings/bulk/", {
      settings: formData,
    });
    return data;
  },
};
