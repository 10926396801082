import React from "react";
import { useQuery } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import {
  Loader,
  Space,
  Checkbox,
  Card,
  InputSearch,
  Button,
  SortBy,
  Icon as SVGIcon,
} from "ebs-design";
import { ColumnType } from "rc-table/lib/interface";
import { userFilters } from "features/users/Users";
import FormWizardContext from "components/FormWizard/FormWizardContext";
import { useFilters, usePagination } from "hooks";
import { Results, UserProfileEntity } from "types";
import { users } from "api";
import { Table, Pagination, Icon, AlertErrors, Filters } from "components";
import { extractOrderingOptions } from "utils";

const UsersTarget = () => {
  const ctx = React.useContext(FormWizardContext);
  const [checked, setChecked] = React.useState<number[]>([]);

  const history = useHistory();
  const pagination = usePagination();
  const { limit, page, setPage, setLimit } = pagination;

  const [usersId] = useQueryParam("users_id", withDefault(StringParam, ""));
  const [cohortId] = useQueryParam("cohort_id", withDefault(StringParam, ""));

  const [search, setSearch] = React.useState("");
  const [ordering, setOrdering] = React.useState("");

  const filtersResult = useFilters({
    filters: userFilters,
    onActiveFiltersChange: () => setPage(1),
    storeInQuery: false,
  });

  const queryParams = {
    page,
    limit,
    search,
    ordering,
    ...filtersResult.activeFiltersValues,
  };

  const {
    data: usersList,
    isLoading,
    error,
  } = useQuery<Results<UserProfileEntity>, AxiosError>(
    ["users", queryParams],
    () => users.getList(queryParams),
    {
      enabled: !!page,
    },
  );

  const tableColumns = React.useMemo<ColumnType<UserProfileEntity>[]>(
    () => [
      {
        title: (
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              indeterminate={!!checked.length}
              checked={checked.length === usersList?.results.length}
              onChange={(newChecked) =>
                newChecked
                  ? setChecked(
                      usersList!.results.map((item: any) => {
                        return item.id;
                      }),
                    )
                  : setChecked(() => [])
              }
            />
          </div>
        ),
        dataIndex: "id",
        render: (value: number) => {
          return (
            <>
              <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checked={checked.includes(value)}
                  onChange={(newChecked) =>
                    newChecked
                      ? setChecked((prevChecked) => [...prevChecked, value])
                      : setChecked((prevChecked) =>
                          prevChecked.filter((val) => val !== value),
                        )
                  }
                />
              </div>
            </>
          );
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        ordering: "name",
      },
      {
        title: "Username",
        dataIndex: "username",
        ordering: "username",
      },
      {
        title: "Age",
        dataIndex: "birthday",
        ordering: "birthday",
        render: (value: string) => {
          return new Date().getFullYear() - new Date(value).getFullYear();
        },
      },
      {
        title: "Phone",
        dataIndex: "phone",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Sessions",
        dataIndex: ["stats", "sessions", "count"],
      },
      {
        title: "Paid Gifts Sent",
        dataIndex: ["stats", "gifts", "total_paid_gifts"],
      },
      {
        title: "Free Gifts Sent",
        dataIndex: ["stats", "gifts", "total_free_gifts"],
      },
      {
        title: "Gifts Recieved",
        dataIndex: ["stats", "gifts", "total_received_gifts"],
      },
      {
        dataIndex: "id",
        render: (id) => (
          <Link
            className="with-title"
            title="See user"
            to={`/users/${id}`}
            onClick={(e) => e.stopPropagation()}
          >
            <SVGIcon type="eye" className="eye-icon" />
          </Link>
        ),
      },
    ],
    [checked, usersList],
  );

  const saveUsers = () => {
    ctx.nextStep({ users_id: checked.map((id) => id) });
    setPage(1);
    setLimit(25);
  };

  const orderingOptions = React.useMemo(
    () => extractOrderingOptions(tableColumns),
    [tableColumns],
  );

  React.useEffect(() => {
    if (usersId) {
      ctx.nextStep({ ...ctx.data, users_id: [parseInt(usersId)] });
    }
    if (cohortId) {
      ctx.nextStep({ ...ctx.data, users_id: [parseInt(cohortId)] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersId, cohortId]);

  React.useEffect(() => {
    if (ctx?.data?.users_id) {
      setChecked([...ctx?.data?.users_id]);
    }
  }, [ctx?.data?.users_id]);

  return (
    <>
      <AlertErrors error={error} /> <div className="pady-3" />
      <Card collapsible collapsed>
        <Card.Header>
          <Space justify="space-between">
            <div onClick={(e) => e.stopPropagation()}>
              <Space>
                <div className="fw-500">Users ({usersList?.count || 0})</div>
                <InputSearch
                  placeholder="Search user"
                  value={search}
                  onSearch={(value: string) => setSearch(value)}
                />
                <SortBy
                  value={ordering}
                  onChange={(value: string) => setOrdering(value)}
                  options={orderingOptions}
                />
              </Space>
            </div>
            <div className="fw-500 cursor-pointer">Filters</div>
          </Space>
        </Card.Header>
        <Card.Body>
          <Filters filtersResult={filtersResult} />
        </Card.Body>
      </Card>
      <div className="pady-3" />
      <Table
        data={usersList?.results}
        columns={tableColumns}
        footer={() => (
          <div className="padx-8 pady-4">
            <Space justify="end">
              <Pagination pagination={pagination} results={usersList} />
            </Space>
          </div>
        )}
        emptyText={
          isLoading ? (
            <Loader loading />
          ) : (
            <Space className="pady-12" justify="center">
              No data
            </Space>
          )
        }
        variants={["bordered", "elevated", "dense", "highlight-hover"]}
        scroll={{ x: true }}
        rowKey="id"
        onRow={(record) => {
          return {
            onClick: () => {
              checked.includes(record.id)
                ? setChecked(checked.filter((i) => i !== record.id))
                : setChecked([...checked, record.id]);
            },
          };
        }}
      />
      <div className="pady-3" />
      <Space justify="center">
        <Button
          type="ghost"
          prefix={<Icon type="back" />}
          onClick={() => history.push("/gifts")}
        >
          Gifts Sent
        </Button>
        <Button
          type="primary"
          disabled={!(checked.length > 0)}
          prefix={<Icon type="prsnts2" />}
          onClick={saveUsers}
        >
          Select Gift
        </Button>
      </Space>
    </>
  );
};

export default UsersTarget;
