export const nomenclatureSingulars = [
  "country",
  "region",
  "subregion",
  "city",
] as const;

export const nomenclaturePlurals = [
  "countries",
  "regions",
  "divisions",
  "cities",
] as const;
