import { Link } from "react-router-dom";

import { Space } from "ebs-design";
import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";
import { ModuleAction } from "features/modules-permissions";

import { CouponEntity } from "types";
import { Icon } from "components";
import { LOCALE } from "app-constants";

interface Args {
  onDeleteClick: (id: number) => void;
}

export const getCouponsTableColumns = ({
  onDeleteClick,
}: Args): ColumnType<CouponEntity>[] => [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Coupon name",
    dataIndex: "name",
  },
  {
    title: "Product",
    dataIndex: ["product", "title"],
  },
  {
    title: "Code",
    dataIndex: "code",
  },
  {
    title: "Creator",
    dataIndex: ["created_by", "name"],
  },
  {
    title: "Date created",
    dataIndex: "timestamp",
    render: (t: string) => new Date(t).toLocaleString(LOCALE),
  },
  {
    title: "Amount Redeemed",
    dataIndex: "users_count",
  },
  {
    title: "Campaign",
    dataIndex: ["campaign", "name"],
  },
  {
    title: "",
    dataIndex: "id",
    render: (id: number) => {
      return (
        <ModuleAction module="coupons">
          <Space>
            <Link
              className="with-title"
              title="Edit"
              to={`/coupons/${id}/edit/`}
              onClick={(e) => e.stopPropagation()}
            >
              <Icon type="edit" />
            </Link>
            <span
              onClick={(e) => e.stopPropagation()}
              className="cursor-pointer with-title"
              title="Delete"
            >
              <Icon onClick={() => onDeleteClick(id)} type="delete" />
            </span>
          </Space>
        </ModuleAction>
      );
    },
  },
];
