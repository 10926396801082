export interface CircularProgressProps {
  value: number;
  color?: string;
  circleWidth?: number;
  fontSize?: number | string;
  padding?: number | string;
}

export const CircularProgress = ({
  value,
  color = "#b49640",
  circleWidth = 8,
  fontSize = "1em",
  padding = 0,
}: CircularProgressProps) => {
  const r = 50 - circleWidth / 2;

  return (
    <div
      className="circular-progress"
      style={{ fontSize, display: "inline-block", padding }}
    >
      <svg viewBox="0 0 100 100">
        <circle
          cx={50}
          cy={50}
          r={r}
          strokeWidth={circleWidth}
          stroke="#d4d4d4"
          fill="none"
        />
        <circle
          cx={50}
          cy={50}
          r={r}
          strokeWidth={circleWidth}
          stroke={color}
          strokeDasharray={`${Math.PI * 2 * r * (value / 100)} 500`}
          fill="none"
        />
      </svg>
      <div className="circular-progress__text">{value.toFixed(2)}%</div>
    </div>
  );
};
