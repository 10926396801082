import React from "react";
import { useUpdateEffect } from "react-use";
import { useQuery } from "react-query";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";

import { Button, Card, Space } from "ebs-design";

import { useQueryPagination } from "hooks";
import { cohorts } from "api";
import { resultsCount } from "utils";
import {
  AlertErrors,
  DebouncedInput,
  Icon,
  Pagination,
  Table,
  TableEmptyText,
  TableRowLink,
} from "components";
import { CohortEntity, Results } from "types";

import { CohortsDashboardStats, cohortsTableColumns } from "../Cohorts";

import { ModuleAction } from "features/modules-permissions/ModuleAction";

export const Cohorts = () => {
  const [search, setSearch] = useQueryParam(
    "search",
    withDefault(StringParam, ""),
  );

  const pagination = useQueryPagination();
  const { limit, page, setPage } = pagination;

  const queryParams = {
    page,
    limit,
    search,
  };

  const cohortsQuery = useQuery<Results<CohortEntity>, AxiosError>(
    ["cohorts", queryParams],
    () => cohorts.getList(queryParams),
    {
      enabled: !!page,
    },
  );

  useUpdateEffect(() => setPage(1), [search]);

  return (
    <>
      <CohortsDashboardStats />
      <div className="pady-2"></div>
      <Card>
        <Card.Body>
          <Space justify="space-between">
            <Space align="center" size={30}>
              <div className="fw-500">
                Cohorts (
                {resultsCount(cohortsQuery.data, cohortsQuery.isLoading)})
              </div>
              <div style={{ display: "inline-block", width: 300 }}>
                <DebouncedInput
                  placeholder="Search in cohorts"
                  value={search}
                  onChange={(value: string) => setSearch(value || undefined)}
                  suffix={<Icon type="search" />}
                />
              </div>
            </Space>
            <ModuleAction module="cohorts">
              <Link to="/cohorts/new/">
                <Button type="primary" prefix={<Icon type="plus" />}>
                  Add cohort
                </Button>
              </Link>
            </ModuleAction>
          </Space>
        </Card.Body>
      </Card>
      <div className="pady-2"></div>
      <AlertErrors error={cohortsQuery.error} />
      <div className="pady-2"></div>
      {!cohortsQuery.isError && (
        <Table
          data={cohortsQuery.data?.results}
          columns={cohortsTableColumns}
          footer={() => (
            <div className="padx-8 pady-4">
              <Space justify="end">
                <Pagination
                  pagination={pagination}
                  results={cohortsQuery.data}
                />
              </Space>
            </div>
          )}
          emptyText={<TableEmptyText isLoading={cohortsQuery.isLoading} />}
          variants={[
            "bordered",
            "elevated",
            "dense",
            "stripped-even",
            "highlight-hover",
          ]}
          scroll={{ x: true }}
          rowKey="id"
          components={{
            body: {
              row: ({ ...props }: any) => {
                return (
                  <TableRowLink
                    makeUrl={(key: string) => `/cohorts/${key}/`}
                    {...props}
                  />
                );
              },
            },
          }}
        />
      )}
    </>
  );
};
