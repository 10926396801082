import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import * as api from "api";
import React from "react";
import { ConfirmLoginCredentinals, UserProfileEntity } from "types";

export const useAuthProvider = () => {
  const [user, setUser] = React.useState<UserProfileEntity | null>(null);

  const history = useHistory();

  const userQuery = useQuery("loggedUser", api.auth.getUser, {
    retry: false,
    onSuccess: (user) => {
      if (user.is_superuser) setUser(user);
      else setUser(null);
    },
    onError: () => {
      if (user) setUser(null);
    },
  });

  const signOut = () => {
    api.auth.logout();
    setUser(null);
    history.push("/login");
  };

  const confirmLoginMutation = useMutation(
    async (credentials: ConfirmLoginCredentinals) => {
      await api.auth.confirmLogin(credentials);
      await userQuery.refetch();
    },
    {
      onSuccess: () => {
        history.push("/");
      },
    },
  );

  return {
    user,
    isLoading: userQuery.isLoading,
    confirmLoginMutation,
    signOut,
  };
};
