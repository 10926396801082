import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { Loader } from "ebs-design";
import { AlertErrors } from "components";
import { Stats, UserProfileEntity } from "types";
import { profile } from "api";
import { UserStats } from "../../users/UserProfile/UserStats";
import { ProfileDetails } from "../ProfileDetails";
import { UserCompanyDetails } from "features/users/UserProfile";

const Profile = () => {
  const profileStatsQuery = useQuery<Stats, AxiosError>("profile-stats", () =>
    profile.getStats(),
  );
  const profileQuery = useQuery<UserProfileEntity, AxiosError>("profile", () =>
    profile.getUser(),
  );

  const user = profileQuery.data;
  const stats = profileStatsQuery.data;

  return (
    <>
      <AlertErrors error={profileQuery.error} />
      <AlertErrors error={profileStatsQuery.error} />
      <Loader loading={profileQuery.isLoading || profileStatsQuery.isLoading} />
      {user && stats && (
        <>
          <UserStats user={{ ...user, stats }} />
          <div className="pady-4" />
          <ProfileDetails user={{ ...user, stats }} />
          {user.company && (
            <>
              <div className="pady-4"></div>
              <UserCompanyDetails company={user.company} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Profile;
