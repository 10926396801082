import React from "react";
import { useQuery } from "react-query";

import { Select, useNotify } from "ebs-design";
import { ComponentProps as SelectProps } from "ebs-design/dist/components/molecules/Select/components";

import { brands } from "api";
import { mapToSelectOptions, notifyErrors } from "utils";

export const BrandsSelect = ({ ...props }: SelectProps) => {
  const notify = useNotify();

  const [search, setSearch] = React.useState("");
  const queryParams = { search };

  const brandsQuery = useQuery(
    ["brands", { queryParams }],
    () => brands.getList(queryParams),
    { onError: (error) => notifyErrors(notify, error) },
  );

  return (
    <Select
      loading={brandsQuery.isLoading}
      options={mapToSelectOptions(brandsQuery.data?.results)}
      {...props}
    >
      <Select.Search onSearch={setSearch} />
    </Select>
  );
};
