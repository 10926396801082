import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Loader, Space } from "ebs-design";

import { products } from "api";
import { AlertErrors } from "components";
import { ProductStats } from "types";

export interface CouponsDashboardStatsProps {}

export const CouponsDashboardStats = ({
  ...props
}: CouponsDashboardStatsProps) => {
  const {
    // data: dashboardStats,
    isLoading,
    isError,
    error,
  } = useQuery<ProductStats, AxiosError>(["dashboard-stats"], () =>
    products.getStats(),
  );

  const dashboardStats: any = {};

  return (
    <>
      {isLoading && <Loader loading />}
      {isError && <AlertErrors error={error!} />}
      {dashboardStats && (
        <div className="details-card__grid">
          <div className="details-card">
            <Space justify="space-between" align="center">
              <h2>?</h2>
            </Space>
          </div>
          <div className="details-card">
            <h2>?</h2>
          </div>
          <div className="details-card">
            <h2>?</h2>
          </div>
          <div className="details-card">
            <h2>?</h2>
          </div>
        </div>
      )}
    </>
  );
};
