import { Chips, Space } from "ebs-design";

import { Interest } from "types";

export interface UserInterestsProps {
  interests: Interest[];
}
export const UserInterests = ({ interests }: UserInterestsProps) => {
  return (
    <Space size={5} align="center" wrap>
      {interests && interests.length ? (
        interests.map((i) => <Chips checked key={i.id} text={i.title} />)
      ) : (
        <div className="pady-2">No interests</div>
      )}
    </Space>
  );
};
