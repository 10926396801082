import React from "react";
import { useMutation, useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { useQueryParam, withDefault, StringParam } from "use-query-params";
import { AxiosError } from "axios";

import { Link } from "react-router-dom";
import { Button, Space } from "ebs-design";
import ImgViewer from "react-simple-image-viewer";

import { products } from "api";
import { AlertErrors, ConfirmModal, Icon } from "components";
import { ImageViewerState, ProductEntity, ReorderState, Results } from "types";
import { useQueryPagination } from "hooks";
import { defaultImageFallback, extractOrderingOptions } from "utils";

import {
  ProductsDashboardStats,
  getProductsTableColumns,
  useProductsFilters,
  ProductsFilterCard,
  useProductCategoryFilters,
  ProductsDisplay,
  ReorderModal,
} from "../Products";

import { ModuleAction } from "features/modules-permissions";

interface ProductsProps {
  isBusiness?: boolean;
  basePath?: string;
}

const Products = ({
  isBusiness = false,
  basePath = "/products",
}: ProductsProps) => {
  const [productToDelete, setProductToDelete] = React.useState<number>();
  const [productToBusiness, setProductToBusiness] = React.useState<number>();
  const [canSelect, setCanSelect] = React.useState(false);
  const [reorderState, setReorderState] = React.useState<ReorderState>({});
  const [toSwitchIsActive, setToSwitchIsActive] =
    React.useState<ProductEntity>();

  const pagination = useQueryPagination();
  const { limit, page, setPage } = pagination;

  const [search, setSearch] = useQueryParam(
    "search",
    withDefault(StringParam, ""),
  );
  const [ordering, setOrdering] = useQueryParam(
    "ordering",
    withDefault(StringParam, ""),
  );

  const { useFiltersResult: filtersResult, errors } = useProductsFilters(
    () => setPage(1),
    { isBusiness },
  );

  const productCategoryFiltersResult = useProductCategoryFilters(() =>
    setPage(1),
  );

  errors.push(...productCategoryFiltersResult.errors);

  const queryParams = {
    page,
    limit,
    search,
    ordering,
    in_business_panel: isBusiness,
    ...filtersResult.activeFiltersValues,
    ...productCategoryFiltersResult.params,
  };

  const productsQuery = useQuery<Results<ProductEntity>, AxiosError>(
    ["products", queryParams],
    () => products.getList(queryParams),
    {
      enabled: !!page,
    },
  );

  const deleteMutation = useMutation<unknown, AxiosError, number>(
    (id: number) => products.delete(id),
    {
      onSuccess: () => {
        setProductToDelete(undefined);
        productsQuery.refetch();
      },
    },
  );

  const patchMutation = useMutation<
    unknown,
    AxiosError,
    Partial<ProductEntity>
  >(({ id, ...rest }) => products.patch(id || 0, rest), {
    onSuccess: () => {
      productsQuery.refetch();
      // setCanSelect(false);
    },
  });

  const toBusinsessMutation = useMutation<unknown, AxiosError, number>(
    (id) => products.toBusiness(id || 0),
    {
      onSuccess: () => {
        productsQuery.refetch();
        setProductToBusiness(undefined);
      },
    },
  );

  useUpdateEffect(() => setPage(1), [search, ordering]);

  const images = React.useMemo(
    () =>
      productsQuery.data?.results.map(
        (p) => p.featured_image?.url || defaultImageFallback,
      ) || [],
    [productsQuery.data?.results],
  );
  const [imgViewerState, setImgViewerState] = React.useState<ImageViewerState>({
    isOpen: false,
    currentIndex: 0,
  });

  const tableColumns = React.useMemo(
    () =>
      getProductsTableColumns({
        isBusiness,
        pathname: basePath,
        onDeleteClick: setProductToDelete,
        canReorder: !!reorderState.toReorder,
        onReorderBottomClick: (p) =>
          setReorderState((prev) => ({
            ...prev,
            where: "below",
            pivot: p,
          })),
        onReorderTopClick: (p) =>
          setReorderState((prev) => ({
            ...prev,
            where: "above",
            pivot: p,
          })),
        onFeaturedImgClick: (idx) =>
          setImgViewerState({ isOpen: true, currentIndex: idx }),
        onReorderClick: (p) => setReorderState({ toReorder: p }),
        onIsActiveSwitchClick: setToSwitchIsActive,
        onAddToBusinessClick: setProductToBusiness,
      }),
    [basePath, isBusiness, reorderState.toReorder],
  );

  const orderingOptions = React.useMemo(
    () => extractOrderingOptions(tableColumns),
    [tableColumns],
  );

  // TEMPORARY reorder method may be replaced by this
  // const onReorderModalConfirmHandler = () => {
  //   patchMutation.mutate({
  //     id: reorderState.toReorder?.id || 0,
  //     [`order_${reorderState.where}_product_id`]: reorderState.pivot?.id || 0,
  //   });
  // };

  const onReorderModalConfirmHandler = (
    where: string,
    pivot: ProductEntity,
  ) => {
    patchMutation.mutate(
      {
        id: reorderState.toReorder?.id || 0,
        [`order_${where}_product_id`]: pivot?.id || 0,
      },
      { onSuccess: () => setReorderState({}) },
    );
  };

  const onSwitchIsActiveConfirmHandler = () => {
    patchMutation.mutate(
      {
        id: toSwitchIsActive?.id,
        is_active: !toSwitchIsActive?.is_active,
      },
      {
        onSuccess: () => setToSwitchIsActive(undefined),
      },
    );
  };

  return (
    <>
      <ProductsDashboardStats />
      <div className="pady-4"></div>

      {errors.map((e, i) => (
        <AlertErrors key={i} error={e} />
      ))}

      <ProductsFilterCard
        productsQuery={productsQuery}
        ordering={ordering}
        setOrdering={setOrdering}
        search={search}
        setSearch={setSearch}
        orderingOptions={orderingOptions}
        productCategoryFiltersResult={productCategoryFiltersResult}
        filtersResult={filtersResult}
      />
      <div className="pady-4"></div>

      <Space justify="end">
        <ModuleAction module="products">
          <Link to={`${basePath}/new`}>
            <Button type="primary" prefix={<Icon type="plus" />}>
              Add product
            </Button>
          </Link>
        </ModuleAction>
      </Space>

      <div className="pady-4"></div>

      <ProductsDisplay
        productsQuery={productsQuery}
        canSelect={canSelect}
        setCanSelect={setCanSelect}
        reorderState={reorderState}
        setProductToReorder={setReorderState}
        patchMutation={patchMutation}
        tableColumns={tableColumns}
        pagination={pagination}
      />

      <ConfirmModal
        open={!!productToDelete}
        onCancel={() => setProductToDelete(undefined)}
        onConfirm={() => deleteMutation.mutate(productToDelete || 0)}
        onClose={() => setProductToDelete(undefined)}
        loading={deleteMutation.isLoading}
      >
        Are you sure to delete this product?
        <AlertErrors error={deleteMutation.error} />
      </ConfirmModal>

      <ConfirmModal
        open={!!productToBusiness}
        onCancel={() => setProductToBusiness(undefined)}
        onConfirm={() => toBusinsessMutation.mutate(productToBusiness || 0)}
        onClose={() => setProductToBusiness(undefined)}
        loading={toBusinsessMutation.isLoading}
      >
        Are you sure to add to business catalogue this product?
        <AlertErrors error={toBusinsessMutation.error} />
      </ConfirmModal>

      {/*
      // TEMPORARY reorder method may be replaced by this
      <ConfirmModal
        open={!!reorderState.pivot}
        onCancel={onReorderModalCloseHandler}
        onConfirm={onReorderModalConfirmHandler}
        onClose={onReorderModalCloseHandler}
        loading={patchMutation.isLoading}
      >
        <AlertErrors error={patchMutation.error} />
        Are you sure to order <b>{reorderState.toReorder?.title}</b>{" "}
        {reorderState.where} <b>{reorderState.pivot?.title}</b>?
      </ConfirmModal>
      */}

      <ReorderModal
        toReorder={reorderState.toReorder}
        open={!!reorderState.toReorder}
        onClose={() => setReorderState({})}
        onConfirm={onReorderModalConfirmHandler}
        onCancel={() => setReorderState({})}
        loading={patchMutation.isLoading}
      />

      <ConfirmModal
        open={!!toSwitchIsActive}
        onClose={() => setToSwitchIsActive(undefined)}
        onCancel={() => setToSwitchIsActive(undefined)}
        onConfirm={onSwitchIsActiveConfirmHandler}
        loading={patchMutation.isLoading}
      >
        Are you sure to set <b>{toSwitchIsActive?.title}</b> as{" "}
        <i>{!toSwitchIsActive?.is_active ? "active" : "inactive"}</i>?
        <AlertErrors error={patchMutation.error} />
      </ConfirmModal>

      {imgViewerState.isOpen && (
        <ImgViewer
          src={images}
          currentIndex={imgViewerState.currentIndex}
          onClose={() => setImgViewerState({ isOpen: false, currentIndex: 0 })}
        />
      )}
    </>
  );
};

export default Products;
