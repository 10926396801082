import { Checkbox, Form } from "ebs-design";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";

import React from "react";

export interface CheckListFormFieldProps extends FormFieldProps {
  options?: { text: React.ReactNode; value: any }[];
  getValue?: (value: any) => any;
  component?: React.FC;
}

export const CheckListFormField = ({
  options,
  component: Component = ({ children }) => (
    <div className="display-grid grid-gap-4">{children}</div>
  ),
  getValue = (value) => value,
  ...props
}: CheckListFormFieldProps) => {
  return (
    <Form.Field {...props}>
      {({ value, onChange }) => {
        return (
          <Component>
            {options?.map((option) => (
              <Checkbox
                key={getValue(option.value)}
                text={option.text}
                checked={
                  !!value?.find(
                    (checkedValue: any) =>
                      getValue(checkedValue) === getValue(option.value),
                  )
                }
                onChange={(checked) =>
                  checked
                    ? onChange([...(value || []), option.value])
                    : onChange(
                        value?.filter(
                          (v: any) => getValue(v) !== getValue(option.value),
                        ) || [],
                      )
                }
              />
            ))}
          </Component>
        );
      }}
    </Form.Field>
  );
};
