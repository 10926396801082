import { useMutation, useQueryClient } from "react-query";
import {
  Space,
  Icon,
  Modal,
  useForm,
  Form,
  Input,
  Textarea,
  Button,
  Switch,
} from "ebs-design";

import { AlertErrors, ProductSelect, Upload } from "components";
import { attachments, campaigns } from "api";

import {
  AttachmentType,
  Campaign,
  CampaignFormValue,
  FormControl,
} from "types";
import { useIsUploading } from "hooks";

interface ModalProps {
  activeCampaign?: Campaign | null;
  open: boolean;
  onCancel: () => void;
}

const CampaignModalForm = ({ activeCampaign, onCancel, open }: ModalProps) => {
  const queryClient = useQueryClient();
  const [form] = useForm();

  const mutateFc = (bodyData: CampaignFormValue) =>
    activeCampaign && activeCampaign.id
      ? campaigns.patch(activeCampaign.id, bodyData)
      : campaigns.post(bodyData);

  const { mutate, isLoading, error } = useMutation(
    (values: CampaignFormValue) => mutateFc(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns-list");
        onCancel();
      },
    },
  );

  const { isUploading, checkFields } = useIsUploading(["video"]);

  const handleForm = ({
    name,
    description,
    utm_source,
    is_active,
    users_limit,
    product,
    video,
  }: CampaignFormValue) => {
    const formObj: CampaignFormValue = {
      name,
      description,
      utm_source,
      is_active,
      users_limit,
      product,
      video: (video && video[0]?.data?.id) || null,
    };

    mutate(formObj);
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title={`${activeCampaign ? "Edit" : "Add"} campaign`}
      size="small"
      className="faq-modal"
    >
      <Modal.Content>
        <AlertErrors error={error} />

        <Form
          form={form}
          onFinish={handleForm}
          onFieldsChange={checkFields}
          initialValues={
            activeCampaign
              ? {
                  ...activeCampaign,
                  video: activeCampaign?.video
                    ? [
                        {
                          file: activeCampaign?.video,
                          data: activeCampaign?.video,
                        },
                      ]
                    : [],
                }
              : {}
          }
        >
          <Form.Field name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Field>

          <Form.Field name="description" label="Description">
            <Textarea />
          </Form.Field>

          <Form.Field
            name="utm_source"
            label="UTM source"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>

          <Form.Field
            name="is_active"
            label="Active"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch />
          </Form.Field>

          <Form.Field label="Users limit" name="users_limit">
            <Input type="number" min="0" />
          </Form.Field>

          <Form.Field name="product" label="Product">
            {(_control) => {
              const control = _control as FormControl<number | undefined>;
              return (
                <ProductSelect
                  value={control.value}
                  onChange={(product) => control.onChange(product?.id)}
                />
              );
            }}
          </Form.Field>

          <Form.Field name="video" label="Video">
            <Upload
              accept="video/*"
              name="files"
              customRequest={(o) =>
                attachments.post(o, {
                  attachmentType: AttachmentType.VIDEO,
                })
              }
            >
              <Button size="medium" className="border-dashed full-width">
                Upload
              </Button>
            </Upload>
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>Cancel</Button>

          <Button
            type="primary"
            disabled={isLoading || isUploading}
            loading={isLoading}
            prefix={<Icon type="check" />}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default CampaignModalForm;
