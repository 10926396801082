import { useMutation } from "react-query";
import { AxiosError } from "axios";

import {
  Modal,
  Button,
  Form,
  Input,
  Space,
  useForm,
  Textarea,
} from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { nomenclatures } from "api";
import { AlertErrors, Icon } from "components";
import { NomenclatureItem, NomenclatureItemType } from "types";

import { displayLanguages } from "../config";

export interface AddLanaguageKeyModalProps extends ModalProps {
  onSuccess: () => void;
}

export const AddLanaguageKeyModal = ({
  open,
  onClose,
  onSuccess,
  ...props
}: AddLanaguageKeyModalProps) => {
  const [form] = useForm();

  const langaugeMutation = useMutation<
    unknown,
    AxiosError,
    Partial<NomenclatureItem>
  >(
    (data) =>
      nomenclatures.postKey({
        ...data,
        type: NomenclatureItemType.TRANSLATION,
      }),
    {
      onSuccess: () => {
        form.resetFields();
        onSuccess();
        onClose && onClose();
      },
    },
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Add language key"
      size="small"
      {...props}
    >
      <Modal.Content>
        <AlertErrors error={langaugeMutation.error} />
        <Form form={form} onFinish={langaugeMutation.mutate}>
          <Form.Field
            name="code_name"
            label="Code name"
            rules={[{ required: true }]}
            initialValue=""
          >
            <Input />
          </Form.Field>
          <Form.Field name="description" label="Description" initialValue="">
            <Textarea />
          </Form.Field>
          {displayLanguages.map((lang) => (
            <Form.Field
              key={lang.code}
              name={"title_" + lang.code}
              label={lang.title}
              initialValue=""
            >
              <Textarea />
            </Form.Field>
          ))}
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="end">
          <Button onClick={onClose}>Cancel</Button>

          <Button
            type="primary"
            disabled={langaugeMutation.isLoading}
            loading={langaugeMutation.isLoading}
            prefix={<Icon type="check" />}
            onClick={() => form.submit()}
          >
            Add
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};
