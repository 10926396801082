import React from "react";
import { UseMutationResult, UseQueryResult } from "react-query";
import { AxiosError } from "axios";

import { Loader, Space } from "ebs-design";
import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";

import { AlertErrors, Pagination, Table } from "components";
import { UsePaginationResult } from "hooks";
import { ProductEntity, ReorderState, Results } from "types";

export interface ProductsDisplayProps {
  canSelect: boolean;
  setCanSelect: React.Dispatch<React.SetStateAction<boolean>>;
  reorderState: ReorderState;
  setProductToReorder: React.Dispatch<React.SetStateAction<ReorderState>>;
  productsQuery: UseQueryResult<Results<ProductEntity>, AxiosError>;
  patchMutation: UseMutationResult<unknown, AxiosError, Partial<ProductEntity>>;
  tableColumns: ColumnType<ProductEntity>[];
  pagination: UsePaginationResult;
}

export const ProductsDisplay = ({
  canSelect,
  setCanSelect,
  reorderState,
  setProductToReorder,
  productsQuery,
  patchMutation,
  tableColumns,
  pagination,
}: ProductsDisplayProps) => {
  return (
    <>
      {/* {reorderState.toReorder ? (
        <>
          <div className="chip">
            <Space align="center">
              <span>{reorderState.toReorder.title}</span>
              <Icon
                onClick={() => {
                  setCanSelect(false);
                  setProductToReorder({});
                }}
                className="cursor-pointer"
                type="cross"
              />
            </Space>
          </div>
        </>
      ) : (
        <Button
          type={canSelect ? "primary" : "ghost"}
          onClick={() => setCanSelect((v) => !v)}
        >
          Select product to reorder
        </Button>
      )}
      <AlertErrors error={patchMutation.error} />
      <div className="pady-4"></div> */}

      <AlertErrors error={productsQuery.error} />
      {!productsQuery.isError && (
        <>
          <Table
            data={productsQuery.data?.results}
            columns={tableColumns}
            footer={() => (
              <div className="padx-8 pady-4">
                <Space justify="end">
                  <Pagination
                    pagination={pagination}
                    results={productsQuery.data}
                  />
                </Space>
              </div>
            )}
            emptyText={
              productsQuery.isLoading ? (
                <Loader loading />
              ) : (
                <Space className="pady-12" justify="center">
                  No data
                </Space>
              )
            }
            variants={[
              "bordered",
              "elevated",
              "dense",
              "stripped-even",
              ...(canSelect ? (["highlight-hover"] as const) : []),
            ]}
            scroll={{ x: true }}
            rowKey="id"
            components={{
              body: {
                row: ({ ...props }: any) => {
                  return (
                    <tr
                      {...props}
                      className={
                        props.className +
                        (canSelect && !reorderState.toReorder
                          ? " cursor-pointer"
                          : "")
                      }
                      onClick={() =>
                        canSelect &&
                        !reorderState.toReorder &&
                        setProductToReorder({
                          toReorder: productsQuery.data?.results?.find(
                            (p) => p.id === props["data-row-key"],
                          ),
                        })
                      }
                    />
                  );
                },
              },
            }}
          />
        </>
      )}
    </>
  );
};
