import React from "react";
import { useQuery } from "react-query";

import { Checkbox, Form, Loader, Radio } from "ebs-design";

import { commons } from "api";
import { PRSNTModulePermission } from "types";

export interface UserPermissionsFieldsProps {
  is_superuser?: boolean;
}

export const UserPermissionsFields = ({
  is_superuser,
}: UserPermissionsFieldsProps) => {
  const [isAdmin, setIsAdmin] = React.useState(is_superuser);

  React.useEffect(() => setIsAdmin(is_superuser), [is_superuser]);

  const modulesQuery = useQuery(
    ["commons", "dashboard", "modules"],
    () => commons.getDashboardModules(),
    { enabled: !!isAdmin },
  );

  return (
    <>
      <Form.Field
        label="Is admin"
        name="is_superuser"
        valuePropName="checked"
        initialValue={false}
        rules={[{ required: true }]}
      >
        {({ onChange, ...props }) => (
          <Checkbox
            {...props}
            onChange={(v) => {
              onChange(v);
              setIsAdmin(v);
            }}
          />
        )}
      </Form.Field>
      {isAdmin && (
        <>
          <div className="mary-4"></div>
          <h4>Module permissions</h4>
          <div className="mary-2"></div>
          {modulesQuery.isLoading && <Loader.Inline></Loader.Inline>}
          {modulesQuery.data?.map((m) => (
            <Form.Field
              name={["modules_permissions", m.code_name]}
              initialValue={PRSNTModulePermission.view}
              label={m.title}
              rules={[{ required: true }]}
            >
              <Radio
                className="grid-5cols"
                options={[
                  { text: "View", value: PRSNTModulePermission.view },
                  { text: "Full", value: PRSNTModulePermission.full },
                ]}
              />
            </Form.Field>
          ))}
        </>
      )}
    </>
  );
};
