import { useSetState } from "react-use";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

import { paginationDefault } from "utils";

export interface UsePaginationResult {
  page: number;
  limit: number;
  next: () => void;
  prev: () => void;
  setPage: (p: number | undefined) => void;
  setLimit: (l: number | undefined) => void;
}

export interface UsePaginationArgs {
  defaultLimit?: number;
  defaultPage?: number;
}

export function useQueryPagination({
  defaultLimit = paginationDefault.limit,
  defaultPage = paginationDefault.page,
}: UsePaginationArgs = {}): UsePaginationResult {
  const [{ page, limit }, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, defaultPage),
    limit: withDefault(NumberParam, defaultLimit),
  });

  return {
    page: page,
    limit: limit,
    next: () => (page ? setQueryParams({ page: page + 1 }) : undefined),
    prev: () => (page ? setQueryParams({ page: page - 1 }) : undefined),
    setPage: (p) => setQueryParams({ page: p }),
    setLimit: (l) => {
      setQueryParams({ limit: l, page: 1 });
    },
  };
}

export function usePagination({
  defaultLimit = paginationDefault.limit,
  defaultPage = paginationDefault.page,
}: UsePaginationArgs = {}): UsePaginationResult {
  const [{ page, limit }, setState] = useSetState({
    page: defaultPage,
    limit: defaultLimit,
  });

  return {
    page: page,
    limit: limit,
    next: () => (page ? setState({ page: page + 1 }) : undefined),
    prev: () => (page ? setState({ page: page - 1 }) : undefined),
    setPage: (p) => setState({ page: p }),
    setLimit: (l) => {
      setState({ limit: l, page: 1 });
    },
  };
}
