import React from "react";

import { Button, Form, Input, Select, Space } from "ebs-design";

import { UseAddressNomenclaturesResult } from "hooks";
import {
  UserProfileEntity,
  NomenclatureSingular,
  NomenclaturePlural,
  IdName,
} from "types";
import { deepProp } from "utils";

export interface UserAddressFieldsProps {
  user: UserProfileEntity;
  addressNomenclatures: UseAddressNomenclaturesResult;
}

export const UserAddressFields = ({
  user,
  addressNomenclatures,
}: UserAddressFieldsProps) => {
  const { canShow } = addressNomenclatures;

  const fieldArray = React.useMemo(
    () =>
      [
        ["country", "Country", "countries"],
        ["region", "Region", "regions"],
        ["subregion", "Subregion", "divisions"],
        ["city", "City", "cities"],
      ] as [NomenclatureSingular, string, NomenclaturePlural][],
    [],
  );

  return (
    <>
      {fieldArray.map((item) => {
        const {
          data,
          hasNextPage,
          isLoading,
          isFetching,
          fetchNextPage,
          search,
          setSearch,
        } = addressNomenclatures.data[item[2]];

        let options =
          data?.map((c: IdName) => (
            <Select.Options.Item key={c.id} value={c.id}>
              {c.name}
            </Select.Options.Item>
          )) || [];

        return (
          <Form.Field
            key={item[0]}
            label={item[1]}
            name={["address", item[0] + "_id"]}
          >
            {({ value, onChange }) => {
              if (
                deepProp(user, "address", item[0], "id") &&
                deepProp(user, "address", item[0], "id") === value &&
                (!data ||
                  !data.length ||
                  !data.find(
                    (d) => d?.id === deepProp(user, "address", item[0], "id"),
                  ))
              ) {
                options.push(
                  <Select.Options.Item
                    className="hidden"
                    key={user.address[item[0]].id}
                    value={user.address[item[0]].id}
                  >
                    {user?.address[item[0]].name}
                  </Select.Options.Item>,
                );
              }

              return (
                <Select
                  /* TODO: (in ebs-design): disabled and normal select look confusing */
                  isClearable={!!value}
                  value={value}
                  onChange={onChange}
                  disabled={!canShow[item[0]]}
                  emptyLabel=""
                >
                  <Select.Search value={search} onSearch={setSearch} />

                  {options.length ? (
                    <Select.Options>{options}</Select.Options>
                  ) : null}

                  {!isLoading && (!data || !data.length) && (
                    <Space className="pady-4" justify="center">
                      Nothing found
                    </Space>
                  )}

                  {hasNextPage && (
                    <>
                      <div className="pady-2"></div>
                      <div className="divider"></div>
                      <Button
                        className="mary-2 marx-1"
                        size="small"
                        disabled={isFetching}
                        onClick={() => fetchNextPage()}
                      >
                        Load more
                      </Button>
                    </>
                  )}
                </Select>
              );
            }}
          </Form.Field>
        );
      })}
      <Form.Field
        name={["address", "zip_code"]}
        label="Zip code"
        initialValue={user?.address?.zip_code}
      >
        <Input />
      </Form.Field>
    </>
  );
};
