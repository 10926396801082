import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import { cohorts } from "api";
import { CohortEntity } from "types";

import { CohortForm } from "../CohortForm";

export const CohortAdd = () => {
  const history = useHistory();

  const cohortMutation = useMutation<
    CohortEntity,
    AxiosError,
    Partial<CohortEntity>
  >((cohort) => cohorts.post(cohort), {
    onSuccess: () => {
      history.push(`/cohorts/`);
    },
  });

  return (
    <CohortForm
      cohortMutation={cohortMutation}
      errors={[cohortMutation.error]}
      canMutate={!cohortMutation.isLoading}
      onSuccessMessage="Product was successfully created."
    />
  );
};
