import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { useCopyToClipboard, useSetState } from "react-use";
import {
  Button,
  Card,
  Col,
  Icon,
  Loader,
  Row,
  Space,
  Icon as SvgIcon,
} from "ebs-design";
import { UserGiftEntity } from "types";
import { capitalize, getCurrencySymbol } from "utils";
import { gifts } from "api";
import { AlertErrors } from "components";
import { LOCALE } from "app-constants";

import OrderProfiles from "features/transactions/OrderProfiles";
import GiftDetailForm from "../GiftDetailForm";
import GiftCommentsModal from "../GiftCommentsModal";

interface ModalState {
  visible: boolean;
  activeGift: UserGiftEntity | null;
}

const defaultModalState = {
  visible: false,
  activeGift: null,
};

const GiftDetails = () => {
  const id = Number(useParams<{ id: string }>().id);
  const [state, copyToClipboard] = useCopyToClipboard();

  const [giftDetailModal, setGiftDetailModal] = useSetState<ModalState>(
    defaultModalState,
  );
  const [giftCommentsModal, setGiftCommentsModal] = useSetState<ModalState>(
    defaultModalState,
  );

  const { data: gift, isLoading, isError, error } = useQuery<
    UserGiftEntity,
    AxiosError
  >(["gifts", id], () => gifts.getById(id), {
    enabled: !!id,
  });

  const details = React.useMemo(
    () => [
      [
        [
          gift?.expiring_timestamp &&
            new Date(gift?.expiring_timestamp).toLocaleString(LOCALE, {
              dateStyle: "short",
            }),
          "Expired Date",
        ],
        [gift?.comments?.[0]?.comment, "Comment"],
        [gift?.status && capitalize(gift.status), "Status"],
      ],
      [
        [gift?.expired ? "Yes" : "No", "Expired"],
        [gift?.unwrapped ? "Yes" : "No", "Unwrapped"],
        [gift?.free ? "Yes" : "No", "Free Gift"],
      ],
      [
        [gift?.product?.brand?.title, "Brand"],
        [gift?.product?.title, "Product"],
        [gift?.order?.url, "Order URL"],
      ],
      [
        [
          gift?.order?.price &&
            `${getCurrencySymbol(undefined)}
               ${gift?.order?.price!}`,
          "Price",
        ],
        [
          gift?.order?.cost != null &&
            gift?.product &&
            `${getCurrencySymbol(gift?.product?.currency?.code_name)} ${gift
              ?.order.cost!}`,
          "Order cost",
        ],
        [
          gift?.order?.fee != null &&
            gift?.product &&
            `${getCurrencySymbol(gift?.product?.currency?.code_name)} ${
              gift?.order.fee
            }`,
          "Discount",
        ],
      ],
    ],
    [gift],
  );

  return (
    <>
      <AlertErrors error={error} />
      <Loader loading={isLoading} />
      {!isLoading && !isError && (
        <>
          <Card>
            <Card.Body>
              <Space justify="space-between">
                <Space>
                  <h3>
                    Gift Sent ID: <span className="ft-200">{gift?.id}</span>
                  </h3>
                  <h3>
                    Date:{" "}
                    <span className="ft-200">
                      {gift?.timestamp &&
                        new Date(gift?.timestamp).toLocaleString(LOCALE)}
                    </span>
                  </h3>
                </Space>
                {gift?.order?.url && (
                  <Button
                    type="ghost"
                    onClick={() => copyToClipboard(gift?.order?.url!)}
                    prefix={<SvgIcon type="link" />}
                  >
                    {state.value ? "Copied" : "Copy Gift URL"}
                  </Button>
                )}
              </Space>
            </Card.Body>
          </Card>
          <div className="pady-3"></div>
          <OrderProfiles order={gift} />
          <div className="pady-3"></div>
          <Card>
            <Card.Header>
              <h3>Details</h3>
            </Card.Header>
            <Card.Body className="pt-49">
              <Row>
                {details.map((col, index) => (
                  <Col key={index}>
                    {col.map((item, index) => (
                      <Space key={index}>
                        <div className="my-4">
                          <span className="small-text color-secondary">
                            {item[1]}
                          </span>
                          <h4 className="fw-400">{item[0] || "---"}</h4>
                        </div>
                        {item[1] === "Expired Date" && (
                          <Button
                            onClick={() =>
                              setGiftDetailModal({
                                visible: true,
                                activeGift: gift,
                              })
                            }
                            size="small"
                            className="ebs-button__action"
                          >
                            <Icon type="edit" />
                          </Button>
                        )}
                        {item[1] === "Comment" && (
                          <Button
                            onClick={() =>
                              setGiftCommentsModal({
                                visible: true,
                                activeGift: gift,
                              })
                            }
                            size="small"
                            className="ebs-button__action"
                          >
                            <Space size="small">
                              <Icon type="message" />
                              View all
                            </Space>
                          </Button>
                        )}
                      </Space>
                    ))}
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
      {giftDetailModal.visible && (
        <GiftDetailForm
          open={giftDetailModal.visible}
          onCancel={() => setGiftDetailModal(defaultModalState)}
          activeGift={giftDetailModal?.activeGift}
        />
      )}
      {giftCommentsModal.visible && (
        <GiftCommentsModal
          open={giftCommentsModal.visible}
          onCancel={() => setGiftCommentsModal(defaultModalState)}
          activeGift={giftCommentsModal?.activeGift}
        />
      )}
    </>
  );
};

export default GiftDetails;
