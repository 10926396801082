import { PRSNTModuleCodeName, PRSNTModulePermission } from "types";
import { useAuth } from "hooks";

// check if logged user has FULL permissions on current module
export function useHasModuleActions(module: PRSNTModuleCodeName) {
  const auth = useAuth();

  return (
    auth?.user?.modules_permissions?.[module] === PRSNTModulePermission.full
  );
}
