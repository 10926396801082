import { coupons } from "api/coupons";
import { AxiosError } from "axios";
import { Container, AlertErrors } from "components";
import { Loader } from "ebs-design";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { CouponEntity } from "types";
import {
  CouponDetails,
  CouponUsersTable,
  CouponProductDetails,
  CouponSenderDetails,
} from "../CouponView";

export const CouponView = () => {
  const id = Number(useParams<{ id: string }>().id);

  const couponQuery = useQuery<CouponEntity, AxiosError>(["coupons", id], () =>
    coupons.getById(id),
  );
  const { data: coupon } = couponQuery;

  return (
    <>
      <Container size="lg">
        <AlertErrors error={couponQuery.error} />
        <Loader loading={couponQuery.isLoading} />
        <div className="pady-2"></div>
        {coupon && <CouponDetails coupon={coupon} />}
        <div className="pady-2"></div>
        {coupon && <CouponSenderDetails coupon={coupon} />}
        <div className="pady-2"></div>
        {coupon?.product && <CouponProductDetails product={coupon.product} />}
        <div className="pady-2"></div>
        <CouponUsersTable couponId={id} />
      </Container>
    </>
  );
};
