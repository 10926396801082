import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";

import { ProductEntity } from "types";
import { products } from "api";

import { ProductForm } from "../ProductForm";

interface ProductAddProps {
  isBusiness?: boolean;
  redirectTo?: string;
}

const ProductAdd = ({
  isBusiness = false,
  redirectTo = "/products",
}: ProductAddProps) => {
  const history = useHistory();

  const productMutation = useMutation<
    ProductEntity,
    AxiosError,
    Partial<ProductEntity>
  >((product) => products.post({ ...product, in_business_panel: isBusiness }), {
    onSuccess: () => {
      history.push(redirectTo);
    },
  });

  return (
    <ProductForm
      isBusiness={isBusiness}
      title="Add product"
      productMutation={productMutation}
      errors={[productMutation.error]}
      canMutate={!productMutation.isLoading}
      onSuccessMessage="Product was successfully created."
    />
  );
};

export default ProductAdd;
