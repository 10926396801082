import { ColumnType } from "rc-table/lib/interface";
import { SortByProps } from "ebs-design/dist/components/organisms/SortBy/SortBy";

interface Column<T> extends ColumnType<T> {
  ordering?: string;
}

export const extractOrderingOptions = <T = any>(
  columns: Column<T>[],
): SortByProps["options"] =>
  columns
    .filter((column) => column.ordering)
    .map((column) => ({
      title: column.title,
      value: column.ordering as string,
    }));
