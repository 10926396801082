import { apiAxios } from "./axios/apiAxios";
import { PRSNTModuleEntity } from "types";

export const commons = {
  getDashboardModules: async () => {
    const { data } = await apiAxios.get<PRSNTModuleEntity[]>(
      `/commons/dashboard/modules/`,
    );
    return data;
  },
};

// /commons/dashboard/modules
// { title: "Home page", code_name: "home_page" },
// { title: "Users", code_name: "users" },
// { title: "Cohorts", code_name: "cohorts" },
// { title: "Products", code_name: "products" },
// { title: "Send gift", code_name: "send_gift" },
// { title: "App settings", code_name: "app_settings" },
// { title: "FAQ", code_name: "faq" },
// { title: "Transactions", code_name: "transactions" },
// { title: "CMS", code_name: "cms" },
// { title: "Categories", code_name: "categories" },
