import { Card, Input, Textarea, Form } from "ebs-design";

import { CohortParameters } from "types";
import { CohortParametersDetails } from "../CohortParametersDetails";

export interface CohortFormSummaryProps {
  parameters?: CohortParameters;
  footer: React.ReactNode;
}

export const CohortFormSummary = ({
  parameters,
  footer,
}: CohortFormSummaryProps) => {
  return (
    <Card className="cohorts__card-fix">
      <Card.Header>
        <Form.Field
          label="Name"
          name="title"
          rules={[{ required: true }]}
          initialValue=""
        >
          <Input />
        </Form.Field>
      </Card.Header>
      <Card.Body>
        <CohortParametersDetails parameters={parameters} />

        <div className="color-secondary">
          Description <span className="color-danger">*</span>
        </div>
        <Form.Field
          name="description"
          rules={[{ required: true }]}
          initialValue=""
        >
          <Textarea />
        </Form.Field>
      </Card.Body>
      <Card.Footer>{footer}</Card.Footer>
    </Card>
  );
};
