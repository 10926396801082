import { UseMutationResult } from "react-query";

import { Modal, Button } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { Flex, LoadingButton } from "components";

export interface MutationConfirmModalProps extends ModalProps {
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose: () => void;
  mutation: UseMutationResult<any, any, any>;
  children: React.ReactNode;
}

export const MutationConfirmModal = ({
  children,
  onConfirm,
  onCancel,
  onClose,
  mutation,
  ...props
}: MutationConfirmModalProps) => {
  return (
    <Modal size="small" title="Confirm" onClose={onClose} {...props}>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Flex className="nowrap" justify="space-between" align="center">
          <Button type="ghost" onClick={onCancel || onClose}>
            Cancel
          </Button>
          <div className="padx-2" />
          <LoadingButton
            loading={mutation.isLoading}
            type="primary"
            onClick={
              onConfirm || (() => mutation.mutate({}, { onSuccess: onClose }))
            }
          >
            Confirm
          </LoadingButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
