import React from "react";
import {
  QueryParamConfig,
  SetQuery,
  StringParam,
  useQueryParams,
} from "use-query-params";

import { FiltersSource } from "types";

export type ActiveFiltersChangeHandler = SetQuery<{
  [k: string]: QueryParamConfig<string | null | undefined>;
}>;

export interface UseFiltersArgs {
  filters: FiltersSource;
  onActiveFiltersChange?: ActiveFiltersChangeHandler;
  storeInQuery?: boolean;
}

export type UseFiltersResult = ReturnType<typeof useFilters>;

export function useFilters({
  filters,
  onActiveFiltersChange = () => {},
  storeInQuery = true,
}: UseFiltersArgs) {
  const queryParamsConfig = React.useMemo(
    () => Object.fromEntries(filters.map((f) => [f.key, StringParam])),
    [filters],
  );

  const [queryActiveFiltersValues, setQueryActiveFiltersValues] =
    useQueryParams(queryParamsConfig);

  const [stateActiveFiltersValues, setStateActiveFiltersValues] =
    React.useState({});

  return {
    activeFiltersValues: storeInQuery
      ? queryActiveFiltersValues
      : stateActiveFiltersValues,
    setActiveFiltersValues: ((args) => {
      storeInQuery
        ? setQueryActiveFiltersValues(args)
        : setStateActiveFiltersValues(args);

      onActiveFiltersChange(args);
    }) as typeof setQueryActiveFiltersValues,
    filters,
  };
}
