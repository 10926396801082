import { ApiQueryType, NetworkCardEntity } from "types";
import { defaultQueryLimit } from "utils";

import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

export const networkCards = {
  getList: async (query: ApiQueryType = {}) => {
    query.limit = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/management/network-cards/`, query);
    const { data } = await apiAxios.get<NetworkCardEntity[]>(url);
    return data;
  },
  getById: async (id: number) => {
    const url = stringifyUrl(`/management/network-cards/${id}/`);
    const { data } = await apiAxios.get<NetworkCardEntity>(url);
    return data;
  },
  post: async (cohort: Partial<NetworkCardEntity>) => {
    const { data } = await apiAxios.post<NetworkCardEntity>(
      `/management/network-cards/`,
      cohort,
    );
    return data;
  },
  patch: async (id: number, cohort: Partial<NetworkCardEntity>) => {
    const { data } = await apiAxios.patch<NetworkCardEntity>(
      `/management/network-cards/${id}/`,
      cohort,
    );
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/management/network-cards/${id}/`);
    return data;
  },
};
