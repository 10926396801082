import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";
import { GenericObject, Results, Transaction } from "types";

export const transactions = {
  getList: async (query: GenericObject<string | number> = {}) => {
    const url = stringifyUrl(`/transactions/list/`, query);
    const { data } = await apiAxios.get<Results<Transaction>>(url);

    return data;
  },
  getById: async (id: string) => {
    const url = stringifyUrl(`/transactions/${id}/`);
    const { data } = await apiAxios.get<Transaction>(url);

    return data;
  },
};
