import { Select } from "ebs-design";
import { useQuery } from "react-query";

import { categories } from "api";
import { AxiosError } from "axios";
import { Results, CategoryEntity } from "types";
import { mapToSelectOptions } from "utils";

export interface SubcategorySelectProps {
  categoryId: number;
  value: number[];
  onChange: (v: number[]) => void;
}

export const SubcategorySelect = ({
  categoryId,
  value,
  onChange,
}: SubcategorySelectProps) => {
  //
  const subCategoriesQuery = useQuery<Results<CategoryEntity>, AxiosError>(
    ["categories", categoryId, "children"],
    () => categories.getChildren(categoryId),
  );

  return (
    <Select
      className="max-width-10 min-width-7"
      value={value}
      onChange={onChange as any}
      options={mapToSelectOptions(subCategoriesQuery.data?.results)}
      loading={subCategoriesQuery.isLoading}
      mode="multiple"
      placeholder="Subcategory"
    />
  );
};
