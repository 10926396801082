import { Label, Button, Card, Loader, Space } from "ebs-design";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useSetState } from "react-use";

import { nomenclatures } from "api";
import { NomenclatureItem, NomenclatureItemType, Results } from "types";
import { AlertErrors, Icon } from "components";

import SlidersForm from "features/sliders/SliderForm/SliderForm";
import DeleteHomeHeadersModal from "features/sliders/DeleteSliderModal";

import { ModuleAction } from "features/modules-permissions";

interface ModalState {
  visible: boolean;
  sliderId?: number | null;
  activeSlider?: NomenclatureItem | null;
  sliderTitle?: string | null;
}

const homeSlidersQueryParams = {
  type: NomenclatureItemType.CARD,
};

const defaultSliderModal = {
  visible: false,
  activeSlider: null,
};

const defaultDeleteSlider = {
  visible: false,
  sliderId: null,
};

const SliderList = () => {
  const [sliderModal, setSliderModal] = useSetState<ModalState>(
    defaultSliderModal,
  );

  const [deleteSlider, setDeleteSlider] = useSetState<ModalState>(
    defaultDeleteSlider,
  );

  const { data, isFetching, isError, error } = useQuery<
    Results<NomenclatureItem>,
    AxiosError
  >(["sliders-list", homeSlidersQueryParams], () =>
    nomenclatures.getKeyList(homeSlidersQueryParams),
  );

  return (
    <Loader loading={isFetching}>
      <div className="sliders">
        {isError ? (
          <AlertErrors error={error} />
        ) : (
          <>
            <ModuleAction module="cms">
              <Button
                className="sliders__add-sliders"
                prefix={<Icon type="plus" />}
                onClick={() => {
                  setSliderModal({
                    visible: true,
                  });
                }}
              >
                Add slider
              </Button>
            </ModuleAction>
            {data?.results?.length ? (
              <div className="sliders__wrapper">
                {data?.results?.map((item: NomenclatureItem) => {
                  return (
                    <Card key={item.id} className="sliders__card card-grid-fix">
                      <Card.Body>
                        {item.image && (
                          <img
                            className="sliders__image"
                            src={item.image.url}
                            alt="sliders-covers"
                          />
                        )}
                        <h3>{item.title}</h3>
                        <p>
                          <span className="fw-500">Button: </span>
                          {item.action ?? "-"}
                        </p>
                        <p>
                          <span className="fw-500">Link:</span>{" "}
                          {item.link ?? "-"}
                        </p>
                        {(item.is_network_card ||
                          item.is_paid_network_card ||
                          item?.is_for_all_tiers_card) && (
                          <div className="mt-12" />
                        )}
                        {item.is_network_card && (
                          <Label
                            className="mr-4"
                            status="info"
                            text="Network cards"
                            type="ghost"
                          />
                        )}
                        {item.is_paid_network_card && (
                          <Label
                            status="warning"
                            text="Paid network cards"
                            type="ghost"
                          />
                        )}

                        {item?.is_for_all_tiers_card && (
                          <Label
                            status="success"
                            text="GO-GO network card"
                            type="ghost"
                          />
                        )}
                      </Card.Body>
                      <ModuleAction module="cms">
                        <Card.Footer bordered>
                          <Space justify="space-between">
                            <Label
                              status={item.is_active ? "success" : "danger"}
                              text={item.is_active ? "active" : "inactive"}
                              type="fill"
                            />
                            <Space>
                              <Button
                                prefix={<Icon type="delete" />}
                                type="ghost"
                                onClick={() =>
                                  setDeleteSlider({
                                    visible: true,
                                    sliderId: item.id,
                                    sliderTitle: item.title,
                                  })
                                }
                              >
                                Delete
                              </Button>
                              <Button
                                prefix={<Icon type="edit" />}
                                onClick={() =>
                                  setSliderModal({
                                    visible: true,
                                    activeSlider: item,
                                  })
                                }
                              >
                                Edit
                              </Button>
                            </Space>
                          </Space>
                        </Card.Footer>
                      </ModuleAction>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <p className="no-items">No slider</p>
            )}
            {sliderModal.visible && (
              <SlidersForm
                open={sliderModal.visible}
                onCancel={() => setSliderModal(defaultSliderModal)}
                activeSlider={sliderModal.activeSlider}
              />
            )}
            {deleteSlider.visible && (
              <DeleteHomeHeadersModal
                sliderTitle={deleteSlider.sliderTitle}
                sliderId={deleteSlider.sliderId}
                visible={deleteSlider.visible}
                onCancel={() => {
                  setDeleteSlider(defaultDeleteSlider);
                }}
              />
            )}
          </>
        )}
      </div>
    </Loader>
  );
};

export default SliderList;
