import { apiAxios } from "./axios/apiAxios";
import { ApiQueryType, DashboardStatsEntity, RevenueStatsEntity } from "types";
import { stringifyUrl } from "./utils";

export const stats = {
  getDashboard: async (query?: ApiQueryType) => {
    const url = stringifyUrl(`/stats/dashboard/`, query);
    const { data } = await apiAxios.get<DashboardStatsEntity>(url);
    return data;
  },
  getRevenue: async (query: ApiQueryType) => {
    const url = stringifyUrl(`/stats/dashboard/revenue-chart/`, query);
    const { data } = await apiAxios.get<RevenueStatsEntity[]>(url);
    return data;
  },
};
