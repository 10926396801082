import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Space, Button, Chips } from "ebs-design";

import { Icon } from "components";
import { UserProfileEntity } from "types";

import { ModuleAction } from "features/modules-permissions";
import { getUserDetailsCols } from "features/users/UserProfile/userDetailsCols";

export interface UserDetailsProps {
  user: UserProfileEntity;
}

export const ProfileDetails = ({ user }: UserDetailsProps) => {
  const details = useMemo(() => getUserDetailsCols(user), [user]);

  return (
    <Card>
      <Card.Header>
        <Space size={50}>
          <Space align="center" size="large">
            <div className="image-70x50">
              <img src={user.photo?.url} alt="Profile" />
            </div>
            <div>
              <h2>
                {user.first_name} {user.last_name}
              </h2>
              <span className="small-text">{user.username}</span>
            </div>
          </Space>
        </Space>
      </Card.Header>
      <Card.Body>
        <h4 className="mb-5">Interests</h4>
        <Space size={5} align="center" wrap>
          {user.interests && user.interests.length ? (
            user.interests.map((i) => (
              <Chips key={i.id} checked text={i.title} />
            ))
          ) : (
            <div className="pady-2">No interests</div>
          )}
        </Space>
        <div className="pady-2" />

        <h3>Details</h3>
        <Row>
          {details.map((col, index) => (
            <Col key={index}>
              {col.map((item, index) => (
                <div className="my-4" key={index}>
                  <span className="small-text color-secondary">{item[1]}</span>
                  <h4 className="fw-400">{item[0]}</h4>
                </div>
              ))}
            </Col>
          ))}
        </Row>
      </Card.Body>
      <ModuleAction module="users">
        <Card.Footer>
          <Space justify="end">
            <Link to={`/users/${user.id}/edit`}>
              <Button type="primary" prefix={<Icon type="edit" />}>
                Edit
              </Button>
            </Link>
          </Space>
        </Card.Footer>
      </ModuleAction>
    </Card>
  );
};
