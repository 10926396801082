import { Link } from "react-router-dom";

import { Space } from "ebs-design";
import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";

import { Flex, Icon, renderCheckbox } from "components";
import { Attachment, BrandEntity, CategoryEntity, ProductEntity } from "types";
import { defaultImageFallback } from "utils";
import { LOCALE } from "app-constants";

import { ModuleAction } from "features/modules-permissions";

import { ProductTableActiveSwitch } from "./";

import {
  getProductCategoriesNames,
  getProductSubcategoriesNames,
} from "../utils";

interface Args {
  pathname: string;
  isBusiness: boolean;
  canReorder?: boolean;
  onDeleteClick: (id: number) => void;
  onReorderTopClick: (p: ProductEntity) => void;
  onReorderBottomClick: (p: ProductEntity) => void;
  onFeaturedImgClick: (idx: number) => void;
  onReorderClick: (p: ProductEntity) => void;
  onAddToBusinessClick?: (id: number) => void;
  onIsActiveSwitchClick: (p: ProductEntity) => void;
}

export const getProductsTableColumns = ({
  pathname,
  onDeleteClick,
  canReorder,
  onReorderTopClick,
  onReorderBottomClick,
  onFeaturedImgClick,
  onReorderClick,
  onIsActiveSwitchClick,
  isBusiness,
  onAddToBusinessClick,
}: Args) =>
  [
    // TEMPORARY reorder method may be replaced by this
    // ...(canReorder
    //   ? [
    //       {
    //         title: "Select position",
    //         dataIndex: "id",
    //         render: (_: any, product: ProductEntity) => {
    //           return (
    //             <ReorderBox
    //               onTopClick={() => onReorderTopClick(product)}
    //               onBottomClick={() => onReorderBottomClick(product)}
    //             />
    //           );
    //         },
    //       },
    //     ]
    //   : []),
    ...(isBusiness
      ? []
      : [
          {
            title: "Order",
            dataIndex: "order",
            render: (value: any, product: ProductEntity) => {
              return (
                <Space>
                  <ModuleAction module="products">
                    <span title="Reorder" className="with-rtitle">
                      <Icon
                        className="cursor-pointer"
                        onClick={() => onReorderClick(product)}
                        type="swipe"
                      />
                    </span>
                  </ModuleAction>
                  <span>{value}</span>
                </Space>
              );
            },
          },
        ]),
    {
      title: "Thumbnail",
      dataIndex: "featured_image",
      width: 100,
      render: (image: Attachment, _, idx) => {
        return (
          <span
            onClick={() => onFeaturedImgClick(idx)}
            className="table-cell-image cursor-pointer"
          >
            <img src={image?.url || defaultImageFallback} alt="Product" />
          </span>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "title",
      ordering: "title",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      render: (brand: BrandEntity) => {
        return (
          <Space align="center" justify="space-between">
            <span>{brand?.title}</span>
            <span className="icon-image">
              <img
                src={brand?.image?.url || defaultImageFallback}
                alt="Brand"
              />
            </span>
          </Space>
        );
      },
    },
    {
      title: "Categories",
      dataIndex: ["categories"],
      render: (categories: CategoryEntity[]) => (
        <>{getProductCategoriesNames(categories)?.join(", ")}</>
      ),
    },
    {
      title: "Subcategories",
      dataIndex: ["categories"],
      render: (categories: CategoryEntity[]) => (
        <>{getProductSubcategoriesNames(categories)?.join(", ")}</>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      ordering: "price",
    },
    {
      title: "Cost",
      dataIndex: "cost",
      ordering: "cost",
    },
    ...(isBusiness
      ? []
      : [
          {
            title: "Featured",
            dataIndex: "featured",
            render: renderCheckbox,
          },
          {
            title: "Free",
            dataIndex: "is_free",
            render: renderCheckbox,
          },
          {
            title: "In main catalog",
            dataIndex: "in_main_catalog",
            render: renderCheckbox,
          },
          {
            title: "Referral",
            dataIndex: "in_gamification",
            render: renderCheckbox,
          },
        ]),

    {
      title: "Created at",
      dataIndex: "timestamp",
      ordering: "timestamp",
      render: (date: string) =>
        date && new Date(date).toLocaleDateString(LOCALE),
    },
    {
      title: "Active",
      dataIndex: "is_active",
      render: (isActive: boolean, product) => {
        return (
          <ProductTableActiveSwitch
            onChange={() => onIsActiveSwitchClick(product)}
            checked={!!isActive}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      render: (id: number) => {
        return (
          <ModuleAction module="products">
            <Flex gap="0.5rem" align="center">
              {!isBusiness && (
                <span
                  style={{ lineHeight: "1rem" }}
                  className="cursor-pointer with-title"
                  title="Add to business catalogue"
                >
                  <Icon
                    onClick={() => onAddToBusinessClick?.(id)}
                    type="skip"
                  />
                </span>
              )}

              <Link
                className="with-title"
                title="Edit"
                to={`${pathname}/${id}/edit/`}
              >
                <Icon type="edit" />
              </Link>

              <span className="cursor-pointer with-title" title="Delete">
                <Icon onClick={() => onDeleteClick(id)} type="delete" />
              </span>
            </Flex>
          </ModuleAction>
        );
      },
    },
  ] as ColumnType<ProductEntity>[];
