import { TabsWithRoute } from "components";
import { campaignsTabs } from "features/campaign/config";
import CampaignsTierList from "features/campaigns-tier/CampaignsTierList";

export const CampaignsTier = () => {
  return (
    <TabsWithRoute tabItems={campaignsTabs}>
      <CampaignsTierList />
    </TabsWithRoute>
  );
};
