import { useMutation, useQuery } from "react-query";
import {
  Button,
  Card,
  Form,
  Input,
  Loader,
  useForm,
  useNotify,
} from "ebs-design";

import { companies } from "api";
import { Company, GenericObject } from "types";
import { AlertErrors, Flex, Icon } from "components";

export interface CompanyFormProps {
  id: number;
}

export const CompanyForm = ({ id }: CompanyFormProps) => {
  const [form] = useForm();
  const notify = useNotify();

  const setCompanyFields = (company: Company) => {
    form.setFieldsValue(company);
  };

  const companyQuery = useQuery(
    ["companies", id],
    () => companies.getById(id),
    { onSuccess: setCompanyFields },
  );
  const company = companyQuery.data;

  const companyMutation = useMutation(
    (company: Company) => companies.patch(id, company),
    {
      onSuccess: (data) => {
        setCompanyFields(data);
        notify.success({
          title: "",
          description: "Company was successfully updated.",
        });
      },
    },
  );

  const onFinishHandler = (formValues: GenericObject) => {
    if (canMutate) companyMutation.mutate(formValues);
  };

  const canMutate = !(
    companyQuery.isLoading ||
    companyQuery.isFetching ||
    companyMutation.isLoading
  );

  return (
    <Form
      form={form}
      onFinish={onFinishHandler}
      labelOptions={{ col: { size: 2 } }}
      controlOptions={{ col: { size: 10 } }}
    >
      <Card>
        <Card.Header>
          <h3>Company</h3>
        </Card.Header>
        <Card.Body>
          <AlertErrors error={companyMutation.error} />
          <AlertErrors error={companyQuery.error} />
          <Loader loading={companyQuery.isLoading} />
          {company && (
            <>
              <Form.Field name="name" label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Field>
              <Form.Field name="fee" label="Fee">
                <Input type="number" />
              </Form.Field>
            </>
          )}
        </Card.Body>
        <Card.Footer>
          <Flex justify="end">
            <Button
              submit
              type="primary"
              loading={companyMutation.isLoading}
              prefix={<Icon type="edit" />}
              disabled={!canMutate}
            >
              Save
            </Button>
          </Flex>
        </Card.Footer>
      </Card>
    </Form>
  );
};
