import React from "react";
import { DatePicker, InputPhone, Input, Form } from "ebs-design";

import { UserProfileEntity } from "types";
import { defaultDateFormat } from "utils";

export const UserPersonalInfoFields = () => {
  const fieldArray = React.useMemo(
    () =>
      [
        ["first_name", "First name"],
        ["last_name", "Last name"],
        ["username", "Username"],
        ["email", "Email", "email"],
        ["phone", "Phone", "phone"],
        ["birthday", "Birthday", "date"],
      ] as [keyof UserProfileEntity, string, string | undefined][],
    [],
  );

  return (
    <>
      {fieldArray.map((item) => (
        <Form.Field
          key={item[0]}
          name={item[0]}
          label={item[1]}
          rules={[{ required: true }]}
          initialValue=""
        >
          {item[2] === "phone" ? (
            <InputPhone />
          ) : item[2] === "date" ? (
            <DatePicker dateFormat={defaultDateFormat} />
          ) : (
            <Input type={item[2]} />
          )}
        </Form.Field>
      ))}
    </>
  );
};
