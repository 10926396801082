import { TabContent, Tab, TabProvider, CheckListFormField } from "components";

import { LocationField } from "./";

import {
  genderOptions,
  ageOptions,
  financialMetricsOptions,
  freeGiftsOptions,
  invitesOptions,
  inAppMetricsOptions,
} from "./paramsOptions";

export const UserMetricsTab = () => {
  return (
    <>
      <TabProvider defaultValue="gender">
        <div className="cohorts__parameters-tabs">
          <Tab tabKey="gender" className="cohorts__parameters-tab">
            Gender
          </Tab>
          <Tab tabKey="age" className="cohorts__parameters-tab">
            Age
          </Tab>
          <Tab tabKey="location" className="cohorts__parameters-tab">
            Location
          </Tab>
          <Tab tabKey="financial_metrics" className="cohorts__parameters-tab">
            Financial Metrics
          </Tab>
          <Tab tabKey="free_gifts" className="cohorts__parameters-tab">
            Free Gifts
          </Tab>
          <Tab tabKey="invites" className="cohorts__parameters-tab">
            Invites
          </Tab>
          <Tab tabKey="in_app_metrics" className="cohorts__parameters-tab">
            In App Metrics
          </Tab>
        </div>

        <TabContent tabKey="gender" className="cohorts__parameters-column">
          <CheckListFormField
            name={["parameters", "gender"]}
            options={genderOptions}
          />
        </TabContent>
        <TabContent tabKey="age" className="cohorts__parameters-column">
          <CheckListFormField
            name={["parameters", "age"]}
            options={ageOptions}
          />
        </TabContent>
        <TabContent tabKey="location" className="cohorts__parameters-column">
          <LocationField />
        </TabContent>
        <TabContent
          tabKey="financial_metrics"
          className="cohorts__parameters-column-span"
        >
          <TabProvider defaultValue="revenue_monthly">
            <div className="cohorts__parameters-column">
              <Tab
                tabKey="revenue_monthly"
                className="select-list__item pady-3"
              >
                Monthly User Spend
              </Tab>

              <Tab
                tabKey="paid_gifts_last_12_months"
                className="select-list__item pady-3"
              >
                Paid Gifts Sent Over the last 12 months
              </Tab>

              <Tab tabKey="revenue" className="select-list__item pady-3">
                Life Time Value
              </Tab>
            </div>
            <div className="cohorts__parameters-column">
              {Object.keys(financialMetricsOptions).map((key) => (
                <TabContent key={key} tabKey={key}>
                  <CheckListFormField
                    name={["parameters", "financial_metrics", key]}
                    options={financialMetricsOptions[key]}
                  />
                </TabContent>
              ))}
            </div>
          </TabProvider>
        </TabContent>
        <TabContent tabKey="free_gifts" className="cohorts__parameters-column">
          <CheckListFormField
            name={["parameters", "free_gifts"]}
            options={freeGiftsOptions}
          />
        </TabContent>
        <TabContent tabKey="invites" className="cohorts__parameters-column">
          <CheckListFormField
            name={["parameters", "invites"]}
            options={invitesOptions}
          />
        </TabContent>
        <TabContent
          tabKey="in_app_metrics"
          className="cohorts__parameters-column-span"
        >
          <TabProvider defaultValue="close_friends">
            <div className="cohorts__parameters-column">
              <Tab tabKey="close_friends" className="select-list__item pady-3">
                Close friends
              </Tab>
              <div className="divider"></div>
              <Tab tabKey="special_days" className="select-list__item pady-3">
                Special days
              </Tab>
              <div className="divider"></div>
              <Tab tabKey="sessions" className="select-list__item pady-3">
                Sessions
              </Tab>
              <div className="divider"></div>
              <Tab tabKey="notifications" className="select-list__item pady-3">
                Notifications
              </Tab>
            </div>
            <div className="cohorts__parameters-column">
              {Object.keys(inAppMetricsOptions).map((key) => (
                <TabContent key={key} tabKey={key}>
                  <CheckListFormField
                    name={["parameters", "in_app_metrics", key]}
                    options={inAppMetricsOptions[key]}
                  />
                </TabContent>
              ))}
            </div>
          </TabProvider>
        </TabContent>
      </TabProvider>
    </>
  );
};
