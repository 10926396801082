import $axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { ACCESS_TOKEN_KEY } from "app-constants";

export const authorizationHeadersInterceptor = (config: AxiosRequestConfig) => {
  const authToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (authToken) config.headers["Authorization"] = `Bearer ${authToken}`;

  return config;
};

export const cancelInterceptors = [
  (response: AxiosResponse) => response,
  (error: any) => {
    if ($axios.isCancel(error)) {
      return Promise.reject({
        cancel: true,
        message: "The endpoint was cancelled",
      });
    }

    if (error.response) {
      // handle errors
    }

    return Promise.reject(error);
  },
];

// RECYCLE
// export const createRefreshTokenInterceptors = (axios: AxiosInstance) => [
//   (res: any) => res,
//   async (err: any) => {
//     const originalConfig = err.config;

//     if (err.response) {
//       // Access Token was expired or invalid
//       if (!originalConfig._retry) {
//         originalConfig._retry = true;
//         const resfreshToken = load(REFRESH_TOKEN_KEY);
//         if (resfreshToken)
//           try {
//             const { access: accessToken } = await auth.refresh({
//               refresh: resfreshToken,
//             });
//             save(ACCESS_TOKEN_KEY, accessToken, { path: "/" });

//             return axios(originalConfig);
//           } catch (_error) {
//             if (_error.response && _error.response.data) {
//               return Promise.reject(_error.response.data);
//             }

//             return Promise.reject(_error);
//           }
//       }
//     }

//     return Promise.reject(err);
//   },
// ];
