import { useHistory } from "react-router-dom";

export type GenericObject<T = any> = { [key in string | number | symbol]: T };

export interface IdName {
  id: number;
  name: string;
}

export type CurrencyType = "GBP" | "EUR" | "USD";

export enum CurrencySign {
  GBP = "£",
  EUR = "€",
  USD = "$",
}

export interface SelectOption {
  value: number | string;
  text: string;
}

export type MapType<TKey extends string, TValue> = { [key in TKey]: TValue };

export type NumRangeStr<
  Separator extends string | number | bigint | boolean | null | undefined = "-",
> = `${number | ""}${Separator}${number | ""}`;

export type UseHistoryResult = ReturnType<typeof useHistory>;

export interface FormControl<T> {
  value: T;
  onChange: (value: T) => void;
}
