import { UploadItem } from "components";
import { FormProps } from "ebs-design/dist/components/organisms/Form";
import { FormFieldProps } from "ebs-design/dist/components/organisms/Form/FormField";
import React from "react";
import { isArrEqual } from "utils";

type NamePath = FormFieldProps["name"];

export interface UseIsUploadingResult {
  isUploading: boolean;
  checkFields: Exclude<FormProps["onFieldsChange"], undefined>;
}

export function useIsUploading(fieldsPaths: NamePath[]): UseIsUploadingResult {
  const [isUploading, setIsUploading] = React.useState(false);

  return {
    isUploading,
    checkFields: React.useCallback(
      (fields) => {
        for (const field of fields) {
          if (
            fieldsPaths.find((f) =>
              isArrEqual(f instanceof Array ? f : [f], field.name as []),
            )
          ) {
            const value = field.value as UploadItem[];
            const newIsUploading = !value?.every((i) => i.isFinished);
            newIsUploading !== isUploading && setIsUploading(newIsUploading);
          }
        }
      },
      [fieldsPaths, isUploading],
    ),
  };
}
