import { useQuery, useMutation } from "react-query";
import {
  Card,
  Form,
  Input,
  Button,
  Loader,
  useForm,
  useNotify,
  Switch,
} from "ebs-design";

import { AxiosError } from "axios";
import { FormControl, GenericObject, SettingEntity } from "types";
import { settings } from "api";
import { Icon, AlertErrors, Flex, ProductSelect } from "components";
import { useHasModuleActions } from "hooks";

import { ModuleAction } from "features/modules-permissions";

import { FeaturedNotificationBtn } from ".";

const SettingsForm = () => {
  const hasModuleActions = useHasModuleActions("app_settings");

  const [form] = useForm();
  const notify = useNotify();

  const { data, isLoading, error } = useQuery<SettingEntity[], AxiosError>(
    "settings",
    settings.getSettings,
  );

  const {
    mutate,
    isLoading: submitLoading,
    error: submitError,
  } = useMutation<any, AxiosError, Partial<SettingEntity>[]>(
    settings.updateSettings,
    {
      onSuccess: () => {
        notify.success({
          title: "Settings was successfully updated",
        });
      },
    },
  );

  const handleFormUpdate = (formValues: GenericObject<string>) => {
    // create an array of objecs for patch request
    const newValues = Object.keys(formValues).map((item) => {
      return { id: Number(item), value: String(formValues[item]) };
    });

    // filter empty values
    const filteredEmptyValues = newValues.filter(
      (element) => element.value !== "",
    );

    // send data
    mutate(filteredEmptyValues);
  };

  return (
    <Card>
      <Card.Body>
        <AlertErrors error={error} />
        <AlertErrors error={submitError} />
        <Loader loading={isLoading}>
          <Form
            form={form}
            onFinish={handleFormUpdate}
            className="settings__form"
          >
            {data?.map((item) => {
              return (
                <Form.Field
                  key={item.id}
                  name={item.id}
                  label={item.title}
                  initialValue={item.value}
                  extra={item.description}
                  valuePropName={
                    item.value_type === "boolean" ? "checked" : "value"
                  }
                >
                  {item.value_type === "product_select" ? (
                    (_control) => {
                      const control = _control as FormControl<
                        number | undefined
                      >;
                      return (
                        <ProductSelect
                          value={control.value}
                          onChange={(product) => control.onChange(product?.id)}
                        />
                      );
                    }
                  ) : item.value_type === "boolean" ? (
                    <Switch disabled={!hasModuleActions} />
                  ) : (
                    <Input
                      type={item.value_type === "string" ? "text" : "number"}
                      disabled={!hasModuleActions}
                    />
                  )}
                </Form.Field>
              );
            })}
          </Form>
        </Loader>
      </Card.Body>
      <ModuleAction module="app_settings">
        <Card.Footer>
          <Flex justify="space-between" align="center">
            <FeaturedNotificationBtn />
            <Button
              type="primary"
              loading={submitLoading}
              prefix={<Icon type="edit" />}
              onClick={() => form.submit()}
            >
              Save
            </Button>
          </Flex>
        </Card.Footer>
      </ModuleAction>
    </Card>
  );
};

export default SettingsForm;
