import React from "react";
import ImgViewer from "react-simple-image-viewer";

import { Card, Label, Space } from "ebs-design";

import { ImageViewerState, NetworkCardEntity } from "types";
import { Flex, Icon } from "components";

import { ModuleAction } from "features/modules-permissions";

export interface NetworkCardProps {
  networkCard: NetworkCardEntity;
  setEditModalData: React.Dispatch<NetworkCardEntity>;
  setDeleteModalData: React.Dispatch<NetworkCardEntity>;
}

export const NetworkCard = ({
  networkCard,
  setEditModalData,
  setDeleteModalData,
}: NetworkCardProps) => {
  const [imgViewerState, setImgViewerState] = React.useState<ImageViewerState>({
    currentIndex: 0,
    isOpen: false,
  });

  return (
    <>
      <Card>
        <Card.Body>
          <Flex align="center">
            <img
              className="cursor-pointer"
              style={{ objectFit: "contain" }}
              width="50"
              height="50"
              src={networkCard.image_url}
              onClick={() =>
                setImgViewerState({ isOpen: true, currentIndex: 0 })
              }
              alt=""
            />
            <div className="px-3"></div>
            <h4 className="flex-1">
              <Space wrap>
                <Flex align="center">
                  <div>{networkCard.title}</div>
                  <div className="px-3"></div>
                  <Label
                    status={networkCard.is_active ? "success" : "danger"}
                    text={networkCard.is_active ? "active" : "inactive"}
                    type="fill"
                  />
                </Flex>
                {networkCard?.campaign_name}
              </Space>
            </h4>
            <div className="px-2"></div>
            <Flex className="mt-3" align="center">
              <ModuleAction module="cms">
                <span>
                  <Icon
                    onClick={() => setEditModalData(networkCard)}
                    className="cursor-pointer"
                    type="edit"
                    size="1.5rem"
                  />
                </span>
                <span>
                  <Icon
                    onClick={() => setDeleteModalData(networkCard)}
                    className="cursor-pointer"
                    type="delete"
                    size="1.5rem"
                  />
                </span>
              </ModuleAction>
            </Flex>
          </Flex>
        </Card.Body>
      </Card>
      {imgViewerState.isOpen && (
        <ImgViewer
          src={[networkCard.image_url || ""]}
          currentIndex={imgViewerState.currentIndex}
          onClose={() => setImgViewerState({ isOpen: false, currentIndex: 0 })}
        />
      )}
    </>
  );
};
