import { useMutation, useQueryClient } from "react-query";
import { Space } from "ebs-design";
import { AxiosError } from "axios";

import { faq } from "api";
import { ConfirmModal, AlertErrors } from "components";

interface Props {
  visible: boolean;
  onCancel: () => void;
  questionId: number | null;
}

const FaqDeleteQuestionModal = ({ visible, onCancel, questionId }: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation<
    unknown,
    AxiosError,
    number
  >(faq.deleteQuestion, {
    onSuccess: () => {
      onCancel();
      queryClient.invalidateQueries("faq-questions");
    },
  });

  return visible ? (
    <ConfirmModal
      open={visible}
      loading={isLoading}
      onConfirm={() => mutate(questionId!)}
      onClose={onCancel}
      onCancel={onCancel}
    >
      {isError ? (
        <AlertErrors error={error!} />
      ) : (
        <Space align="center" justify="center">
          <p>Are you sure that you want to delete this question ?</p>
        </Space>
      )}
    </ConfirmModal>
  ) : null;
};
export default FaqDeleteQuestionModal;
