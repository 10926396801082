import { authAxios } from "./axios/authAxios";
import {
  ConfirmLoginCredentinals,
  LoginCredentinals,
  TokensResponse,
} from "types";
import { ACCESS_TOKEN_KEY } from "app-constants";
import { profile } from "./profile";

export const auth = {
  getUser: async () => {
    await auth.getTokens();
    const user = await profile.getUser();
    return user;
  },
  getTokens: async () => {
    const { data: tokens } = await authAxios.get<TokensResponse>(
      "/authentication/cookie",
    );
    localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access);
    return tokens;
  },

  login: async (credentials: LoginCredentinals) => {
    const { data } = await authAxios.post<{ success: boolean }>(
      `/authentication/login/admin`,
      credentials,
    );
    return data;
  },

  confirmLogin: async (credentials: ConfirmLoginCredentinals) => {
    const { data: tokens } = await authAxios.post<TokensResponse>(
      `/users/confirm`,
      credentials,
    );
    localStorage.setItem(ACCESS_TOKEN_KEY, tokens?.access);
    return tokens;
  },

  logout: async () => {
    await authAxios.get(`/authentication/logout`);
  },

  // DEPRECATED
  refresh: async (bodyData: { refresh: string }) => {
    const { data } = await authAxios.post<{ access: string }>(
      `/authentication/refresh`,
      bodyData,
    );
    return data;
  },
};
