import React from "react";

export const Like: React.FC<React.SVGAttributes<SVGSVGElement>> = ({
  stroke = "currentColor",
  fill = "currentColor",
  ...svgProps
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M11.4666 24L7.99993 24.4C7.19993 24.5333 6.39993 23.8666 6.26659 23.0666L5.73326 17.2C5.59993 16.4 6.26659 15.6 7.06659 15.4667L10.6666 15.0667L11.4666 24Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
      <path
        d="M19.7334 11.8668L19.0667 5.73345C18.8001 5.06678 18.1334 4.53345 17.3334 4.53345H16.5334C15.8667 4.53345 15.3334 4.93345 15.2001 5.60011L14.4001 11.0668C14.4001 12.0001 13.8667 12.8001 13.0667 13.2001L10.6667 15.0668L11.4667 23.6001C11.4667 24.1335 12.0001 24.6668 12.5334 24.6668C15.8667 25.0668 19.2001 24.9335 22.4001 24.2668C23.7334 24.0001 24.8001 22.9335 25.0667 21.6001L26.1334 15.3334C26.5334 13.3334 24.8001 11.4668 22.8001 11.7334L19.7334 11.8668Z"
        stroke={stroke}
        strokeMiterlimit="10"
      />
    </svg>
  );
};
