import { UseQueryResult } from "react-query";

import { Card, SortBy, Space } from "ebs-design";
import { SortByProps } from "ebs-design/dist/components/organisms/SortBy/SortBy";

import { DebouncedInput, Filters, Icon } from "components";
import { UseFiltersResult } from "hooks";
import { Results } from "types";
import { resultsCount } from "utils";

import { UseProductCategoryFiltersResult } from "./useProductCategoryFilters";
import { ProductCategoryFilters } from "./ProductCategoryFilters";

export interface ProductsFilterCardProps {
  productsQuery: UseQueryResult<Results<unknown>>;
  search: string;
  ordering: string;
  setSearch: (value?: string) => void;
  setOrdering: (value?: string) => void;
  filtersResult: UseFiltersResult;
  productCategoryFiltersResult: UseProductCategoryFiltersResult;
  orderingOptions: SortByProps["options"];
}

export const ProductsFilterCard = ({
  productsQuery,
  search,
  ordering,
  setSearch,
  setOrdering,
  filtersResult,
  productCategoryFiltersResult,
  orderingOptions,
}: ProductsFilterCardProps) => {
  return (
    <Card collapsible collapsed>
      <Card.Header>
        <Space justify="space-between">
          <div onClick={(e) => e.stopPropagation()}>
            <Space align="center" size={30}>
              <div className="fw-500">
                Products (
                {resultsCount(productsQuery.data, productsQuery.isLoading)})
              </div>
              <div style={{ display: "inline-block", width: 300 }}>
                <DebouncedInput
                  placeholder="Search in products"
                  value={search}
                  onChange={(value: string) => setSearch(value || undefined)}
                  suffix={<Icon type="search" />}
                />
              </div>
              <SortBy
                value={ordering}
                onChange={(value: string) => setOrdering(value || undefined)}
                options={orderingOptions}
              />
            </Space>
          </div>
          <div className="fw-500 cursor-pointer">Filters</div>
        </Space>
      </Card.Header>
      <Card.Body>
        <ProductCategoryFilters
          useProductCategoryFiltersResult={productCategoryFiltersResult}
        />
        <div className="pady-4"></div>
        <Filters filtersResult={filtersResult} />
      </Card.Body>
    </Card>
  );
};
