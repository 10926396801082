import { TabsWithRoute } from "components";
import { cmsTabs } from "features/cms/config";
import GiftCoversList from "features/gift-covers/GiftCoversList";

const giftCovers = () => {
  return (
    <TabsWithRoute tabItems={cmsTabs}>
      <GiftCoversList />
    </TabsWithRoute>
  );
};

export default giftCovers;
