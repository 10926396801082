import { Space, Button, Avatar, Label } from "ebs-design";
import { Link, useHistory } from "react-router-dom";
import { OrderEntity, UserGiftEntity } from "types";
import { Flex, Icon } from "components";

import { ModuleAction } from "features/modules-permissions";

export interface Props {
  order?: OrderEntity | UserGiftEntity;
}

const OrderProfiles = ({ order }: Props) => {
  const history = useHistory();

  const target = order?.target || order?.contact;

  return (
    <div className="profile-card__grid">
      <div className="profile-card">
        <h3 className="m-10">Sender</h3>
        <Avatar
          circle
          size="big"
          img={order?.user?.photo?.url}
          alt={order?.user?.first_name}
          type="light"
        />
        <div className="m-10 center-text">
          <h2>
            {order?.user?.first_name} {order?.user?.last_name}
          </h2>
          <Label text={order?.user?.phone} type="ghost" />
        </div>
        <Link to={`/users/${order?.user?.id}/`} className="profile-card__link">
          View profile
        </Link>
        <Space justify="center">
          <ModuleAction module="send_gift">
            <Button
              type="primary"
              prefix={<Icon type="status-sent" />}
              onClick={() => {
                history.push(`/gifts/send/?users_id=${order?.user?.id}`);
              }}
            >
              Send Gift
            </Button>
          </ModuleAction>

          <a
            href={`mailto:${order?.user?.email}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button type="primary" prefix={<Icon type="share" />}>
              Email
            </Button>
          </a>
        </Space>
      </div>

      <Space
        justify="center"
        className="profile-card__icons"
        direction="horizontal"
      >
        <Icon type="prsnts2" />
        <Icon type="back" className="profile-card__icons--rotate" />
      </Space>

      <div className="profile-card">
        <Flex align="center">
          <h3 className="m-10">Receiver</h3>
          {!order?.target && (
            <Label type="fill" status="danger" text="Not registered" />
          )}
        </Flex>

        <Avatar
          circle
          size="big"
          img={target?.photo?.url}
          alt={target?.first_name}
          type={target?.photo?.url ? "light" : "primary"}
        />
        <div className="m-10 center-text">
          <h2>
            {target?.first_name} {target?.last_name}
          </h2>
          {order?.target?.phone && (
            <Label text={order?.target?.phone} type="ghost" />
          )}
        </div>

        {order?.target && (
          <Link
            to={`/users/${order.target.id}/`}
            className="profile-card__link"
          >
            View profile
          </Link>
        )}

        <Space justify="center">
          <ModuleAction module="send_gift">
            <Button
              type="primary"
              prefix={<Icon type="status-sent" />}
              onClick={() => {
                history.push(`/gifts/send/?users_id=${target?.id}`);
              }}
            >
              Send Gift
            </Button>
          </ModuleAction>
          <a href={`mailto:${target?.email}`} target="_blank" rel="noreferrer">
            <Button type="primary" prefix={<Icon type="share" />}>
              Email
            </Button>
          </a>
        </Space>
      </div>
    </div>
  );
};

export default OrderProfiles;
