import { CurrencyType, CurrencySign } from "types";

type Currency = {
  [Property in CurrencyType]?: string;
};

export const currencySymbols: Currency = {
  GBP: CurrencySign.GBP,
  EUR: CurrencySign.EUR,
  USD: CurrencySign.USD,
};

export const getCurrencySymbol = (currency?: CurrencyType) =>
  currency ? currencySymbols[currency] : currencySymbols.GBP;
