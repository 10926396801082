import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";
import {
  Form,
  useNotify,
  Card,
  Loader,
  Button,
  Space,
  useForm,
} from "ebs-design";
import { FormProps } from "ebs-design/dist/components/organisms/Form";
import { AlertErrors, Container, Icon } from "components";
import {
  CouponSenderFields,
  CouponWrappingField,
} from "features/coupons/CouponForm";
import {
  CouponImportFields,
  CouponImportFile,
} from "features/coupons/CouponImportForm";
import { CouponImportType, CouponImportValue } from "types";
import { useIsUploading } from "hooks";

export interface CouponImportFormProps {
  title: string;
  couponMutation: UseMutationResult<CouponImportType, AxiosError, any>;
  canMutate?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  showForm?: boolean;
  errors?: (AxiosError | undefined | null)[];
  onSuccessMessage?: string;
}

export const CouponImportForm = ({
  title,
  couponMutation,
  canMutate = true,
  isLoading,
  errors,
  onSuccessMessage,
}: CouponImportFormProps) => {
  const [form] = useForm();
  const notify = useNotify();

  const { isUploading, checkFields } = useIsUploading([
    ["from_photo"],
    ["wrapping_image"],
  ]);

  canMutate = canMutate && !isUploading;

  const onFinishHandler = (formValues: CouponImportValue) => {
    const coupon: CouponImportValue = {
      ...formValues,
      max_usage: Number(formValues.max_usage),
      wrapping_image: formValues?.wrapping_image?.[0]?.data?.id,
      from_photo: formValues?.from_photo?.[0]?.data?.url,
    };

    const formData = new FormData();

    formValues?.file && formData.append("file", formValues?.file[0]);
    formData.append("coupon", JSON.stringify(coupon));

    canMutate &&
      couponMutation.mutate(formData, {
        onSuccess: () => {
          notify.success({
            title: "",
            description: onSuccessMessage,
          });
        },
      });
  };

  const onFieldsChangeHandler: FormProps["onFieldsChange"] = (
    fields,
    allFields,
  ) => {
    checkFields(fields, allFields);
    if (!fields.length || fields.length > 1) return;
  };

  return (
    <Container>
      <Card>
        <Card.Header>
          <h2>{title}</h2>
        </Card.Header>
        <Card.Body>
          {errors?.map((e, i) => (
            <AlertErrors key={i} error={e} />
          ))}

          <Loader loading={!!isLoading} />
          <Form
            labelOptions={{ col: { size: 2 } }}
            controlOptions={{ col: { size: 10 } }}
            form={form}
            onFinish={onFinishHandler}
            onFieldsChange={onFieldsChangeHandler}
          >
            <h3>Sender Details</h3>
            <div className="pady-2"></div>
            <CouponSenderFields />
            <h3>Coupon Details</h3>
            <div className="pady-2"></div>
            <CouponImportFields />
            <div className="pady-2"></div>
            <CouponWrappingField />
            <div className="pady-2"></div>
            <CouponImportFile />
          </Form>
        </Card.Body>

        <Card.Footer>
          <Space justify="end">
            <Button
              type="primary"
              loading={couponMutation.isLoading}
              prefix={<Icon type="edit" />}
              onClick={() => form.submit()}
              disabled={!canMutate}
            >
              Save
            </Button>
          </Space>
        </Card.Footer>
      </Card>
    </Container>
  );
};
