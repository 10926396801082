import { coupons } from "api/coupons";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { CouponEntity } from "types";

import { CouponForm } from "../CouponForm";

export const CouponEdit = () => {
  const history = useHistory();
  const id = Number(useParams<{ id: string }>().id);
  if (!id) history.push("/coupons/");

  const couponMutation = useMutation<
    CouponEntity,
    AxiosError,
    Partial<CouponEntity>
  >((coupon) => coupons.patch(id, coupon), {
    onSuccess: () => history.push("/coupons/"),
  });

  const couponQuery = useQuery<CouponEntity, AxiosError>(["coupons", id], () =>
    coupons.getById(id),
  );

  return (
    <CouponForm
      title="Edit coupon"
      onSuccessMessage="Coupon successfull edited"
      values={couponQuery.data}
      isLoading={couponQuery.isLoading}
      isFetching={!couponQuery.isLoading && couponQuery.isFetching}
      showForm={!couponQuery.isLoading && !couponQuery.isError}
      couponMutation={couponMutation}
      canMutate={!couponMutation.isLoading}
      errors={[couponMutation.error]}
    />
  );
};
