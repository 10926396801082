import { Button, Col, Form, Input, Row } from "ebs-design";

import { attachments } from "api";
import { PhotoUploadItem, Upload } from "components";
import { AttachmentType } from "types";

export const CouponSenderFields = () => {
  return (
    <>
      <Form.Field
        label="First name"
        name="from_first_name"
        rules={[{ required: true }]}
        initialValue=""
      >
        <Input />
      </Form.Field>
      <Form.Field
        label="Last name"
        name="from_last_name"
        rules={[{ required: true }]}
        initialValue=""
      >
        <Input />
      </Form.Field>
      <Form.Field
        labelOptions={{ col: 0 }}
        controlOptions={{ col: 12 }}
        name="from_photo"
        label="Photo"
        hideLabel
        rules={[{ required: true }]}
      >
        {({ ...props }) => (
          <Row>
            <Upload
              {...props}
              renderItem={PhotoUploadItem}
              accept="image/*"
              name="files"
              customRequest={(o) =>
                attachments.post(o, {
                  attachmentType: AttachmentType.IMAGE,
                })
              }
              component={({ children, ...props }) => (
                <Col size={10} {...props}>
                  {children}
                </Col>
              )}
            >
              <Button>Upload Photo</Button>
            </Upload>
          </Row>
        )}
      </Form.Field>
    </>
  );
};
