export const displayLanguages = [
  {
    code: "en",
    title: "English",
  } as const,
  {
    code: "ru",
    title: "Russian",
  } as const,
  {
    code: "ro",
    title: "Romanian",
  } as const,
] as const;
