import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Loader } from "ebs-design";

import { products } from "api";
import { AlertErrors, CheckListFormField } from "components";

export const InterestsTab = () => {
  const tagsQuery = useQuery<string[], AxiosError>(["products", "tags"], () =>
    products.getTags(),
  );

  const options = React.useMemo(
    () => tagsQuery.data?.map((t) => ({ text: t, value: t })),
    [tagsQuery.data],
  );

  return (
    <div className="padx-10 pady-8">
      <AlertErrors error={tagsQuery.error} />
      <Loader loading={tagsQuery.isLoading} />
      <div className="pady-2"></div>
      {tagsQuery.data && (
        <CheckListFormField
          name={["parameters", "interests"]}
          options={options}
          component={({ children }) => (
            <div className="grid-3cols grid-gap-4">{children}</div>
          )}
        />
      )}
    </div>
  );
};
