import {
  Space,
  Icon,
  Modal,
  useForm,
  Form,
  Input,
  Switch,
  Button,
} from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { AlertErrors } from "components";
import { nomenclatures } from "api";
import { deepProp } from "utils";
import {
  NomenclatureFormValue,
  NomenclatureItem,
  NomenclatureItemType,
} from "types";
import { useIsUploading } from "hooks";
import { SliderPhotoUpload } from "./SliderPhotoUpload";

interface Props {
  activeSlider?: NomenclatureItem | null;
  open: boolean;
  onCancel: () => void;
}

const SlidersForm = ({ activeSlider, onCancel, open }: Props) => {
  const queryClient = useQueryClient();
  const [form] = useForm();

  const mutateFc = (bodyData: Partial<NomenclatureItem>) =>
    activeSlider
      ? nomenclatures.patchKey(activeSlider.id || 0, bodyData)
      : nomenclatures.postKey(bodyData);

  const { mutate, isLoading, error } = useMutation<
    NomenclatureFormValue,
    AxiosError,
    Partial<NomenclatureItem>
  >((values) => mutateFc(values), {
    onSuccess: () => {
      queryClient.invalidateQueries("sliders-list");
      onCancel();
    },
  });

  const { isUploading, checkFields } = useIsUploading(["image"]);

  const handleForm = ({
    title,
    action,
    link,
    is_active,
    is_network_card,
    is_paid_network_card,
    is_for_all_tiers_card,
    image,
  }: NomenclatureFormValue) => {
    const formObj: Partial<NomenclatureItem> = {
      title,
      action,
      link,
      is_active,
      is_network_card,
      is_paid_network_card,
      is_for_all_tiers_card,
      image_id: deepProp(image, 0, "data", "id"),
      type: NomenclatureItemType.CARD,
    };

    mutate(formObj);
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title={`${activeSlider ? "Edit" : "Add"} slider`}
      size="small"
      className="faq-modal"
    >
      <Modal.Content>
        <AlertErrors error={error} />
        <Form
          form={form}
          onFinish={handleForm}
          onFieldsChange={checkFields}
          initialValues={
            activeSlider
              ? {
                  title: activeSlider?.title,
                  action: activeSlider?.action,
                  link: activeSlider?.link,
                  is_active: activeSlider?.is_active,
                  is_network_card: activeSlider?.is_network_card,
                  is_paid_network_card: activeSlider?.is_paid_network_card,
                  is_for_all_tiers_card: activeSlider?.is_for_all_tiers_card,
                  image: activeSlider?.image
                    ? [
                        {
                          file: activeSlider?.image,
                          data: activeSlider?.image,
                          isSuccess: true,
                        },
                      ]
                    : [],
                }
              : {}
          }
        >
          <Form.Field name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Field>
          <Form.Field name="action" label="Button">
            <Input />
          </Form.Field>
          <Form.Field name="link" label="Link">
            <Input />
          </Form.Field>
          <Form.Field name="is_active" label="Active" valuePropName="checked">
            <Switch />
          </Form.Field>
          <Form.Field
            name="is_network_card"
            label="Network cards slider"
            valuePropName="checked"
          >
            <Switch />
          </Form.Field>
          <Form.Field
            name="is_paid_network_card"
            label="Is paid network card"
            valuePropName="checked"
          >
            <Switch />
          </Form.Field>

          <Form.Field
            name="is_for_all_tiers_card"
            label="GO-GO network card"
            valuePropName="checked"
          >
            <Switch />
          </Form.Field>

          <SliderPhotoUpload />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>Cancel</Button>

          <Button
            type="primary"
            disabled={isLoading || isUploading}
            loading={isLoading}
            prefix={<Icon type="check" />}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default SlidersForm;
