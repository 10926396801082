import { memo } from "react";
import { ListGroup, Avatar, Space, Button, Label } from "ebs-design";
import cn from "classnames";

import { Icon } from "components";
import { CategoryEntity } from "types";

import { ModuleAction } from "features/modules-permissions";

interface Props {
  category: CategoryEntity;
  onClick?: (value: CategoryEntity) => void;
  onEdit: (value: CategoryEntity) => void;
  onDelete: (value: CategoryEntity) => void;
  isActive: boolean;
}

const CategoryItem = ({
  category,
  onClick,
  onEdit,
  onDelete,
  isActive,
}: Props) => {
  return (
    <ListGroup.Item
      key={category.id}
      className={cn("categories__item", {
        "categories__item--active": isActive,
      })}
    >
      <div
        className="categories__item__wrapper"
        onClick={() => onClick && onClick(category)}
      >
        <Space align="center" justify="space-between">
          <div className="categories__item__details">
            <Space>
              <Avatar
                img={category.image ? category.image.url : ""}
                alt={category.title}
                type="light"
              />
              <h4>{category.title}</h4>
              <span>
                <Label
                  status={category.is_active ? "success" : "danger"}
                  text={category.is_active ? "active" : "inactive"}
                  type="fill"
                />
              </span>
            </Space>
          </div>
          <Space>
            <ModuleAction module="categories">
              <span
                className="category-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(category);
                }}
              >
                <Button
                  size="small"
                  type="ghost"
                  className="ebs-button__action"
                >
                  <Icon type="edit"></Icon>
                </Button>
              </span>
            </ModuleAction>
            <ModuleAction module="categories">
              <span
                className="category-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(category);
                }}
              >
                <Button
                  size="small"
                  type="ghost"
                  className="ebs-button__action"
                >
                  <Icon type="delete" />
                </Button>
              </span>
            </ModuleAction>
          </Space>
        </Space>
      </div>
    </ListGroup.Item>
  );
};

export default memo(CategoryItem);
