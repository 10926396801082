import React from "react";
import cn from "classnames";
import { Tooltip, Icon } from "ebs-design";

export interface StepProps {
  count?: number;
  title: string;
  status?: string;
  description?: string;
  className?: string;
  onClick?: () => void;
}

const Step: React.FC<StepProps> = ({
  title,
  status,
  count,
  description,
  className,
  onClick,
}) => {
  const statusIcon = status === "completed" ? <Icon type="success" /> : count;

  return (
    <div
      className={cn(
        "step-item",
        { [`step-item--${status}`]: status },
        className,
      )}
      onClick={onClick}
    >
      <div className="step-item__count">{statusIcon}</div>
      <div className="step-item__title">{title}</div>
      {description && (
        <div className="step-item__info">
          <Tooltip
            bodyClass="step-item__desc"
            placement="bottom"
            trigger="hover"
            tooltip={description}
            offset={[-8, -2]}
          >
            <Icon type="info" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Step;
