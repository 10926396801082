import { getCurrencySymbol } from "utils";
import { CohortGiftEntity, ProductEntity } from "types";

export const giftsTableColumns = [
  {
    title: "Sender",
    dataIndex: ["user", "name"],
  },
  {
    title: "Receiver count",
    dataIndex: "targets_count",
  },
  {
    title: "Product",
    dataIndex: ["product", "title"],
  },
  {
    title: "Price",
    dataIndex: "product",
    render: (product: ProductEntity) =>
      product?.price &&
      ` ${getCurrencySymbol(product?.currency?.code_name)} ${product?.price}`,
  },
  {
    title: "Order Cost",
    dataIndex: ["order", "cost"],
    render: (cost: number, record: CohortGiftEntity) =>
      cost &&
      `${getCurrencySymbol(record.product?.currency?.code_name)} ${cost}`,
  },
  {
    title: "Date and Time",
    dataIndex: "date",
  },
];
