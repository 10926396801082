import { Loader, Space } from "ebs-design";

export interface TableEmptyTextProps {
  isLoading: boolean;
}
export const TableEmptyText = ({ isLoading }: TableEmptyTextProps) => {
  return (
    <>
      {isLoading ? (
        <Loader loading />
      ) : (
        <Space className="pady-12" justify="center">
          No data
        </Space>
      )}
    </>
  );
};
