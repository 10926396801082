import React from "react";
import { useInfiniteQuery } from "react-query";

import { Button, Select, Space } from "ebs-design";

import { SelectOption, TagEntity } from "types";
import * as api from "api";

export type ProductTagsValue = number[];

export interface ProductTagsProps {
  value?: ProductTagsValue;
  onChange?: React.Dispatch<ProductTagsValue>;
  additional?: TagEntity[];
}

export const ProductTags = ({
  value,
  onChange,
  additional,
}: ProductTagsProps) => {
  const [search, setSearch] = React.useState("");
  const queryParams = { search, limit: 15 };

  const query = useInfiniteQuery(
    ["tags", queryParams],
    ({ pageParam = 1 }) => api.tags.get({ ...queryParams, page: pageParam }),
    {
      getNextPageParam: ({ next }) => next,
    },
  );

  const tags = query.data?.pages.map((p) => p.results).flat();
  if (additional?.length) {
    additional.forEach(
      (additionalTag) =>
        !tags?.find((t) => t.id === additionalTag.id) &&
        tags?.push(additionalTag),
    );
  }

  const options: SelectOption[] =
    tags?.map?.((t) => ({ text: t.title || "", value: t.id || 0 })) || [];

  return (
    <Select
      mode="multiple"
      value={value}
      onChange={(newValue) =>
        onChange?.(
          newValue instanceof Array
            ? (newValue as number[])
            : [newValue as number],
        )
      }
    >
      <Select.Search value={search} onSearch={setSearch} />

      {options.length ? (
        <Select.Options>
          {options.map((o) => (
            <Select.Options.Item key={o.value} value={o.value}>
              {o.text}
            </Select.Options.Item>
          ))}
        </Select.Options>
      ) : null}

      {!query.isLoading && !options.length && (
        <Space className="pady-4" justify="center">
          Nothing found
        </Space>
      )}

      {query.hasNextPage && (
        <>
          <div className="pady-2"></div>
          <div className="divider"></div>
          <Button
            className="mary-2 marx-1"
            size="small"
            disabled={query.isFetching}
            onClick={() => query.fetchNextPage()}
          >
            Load more
          </Button>
        </>
      )}
    </Select>
  );
};
