import { Layout } from "ebs-design";
import TopBar from "components/TopBar";
import Menu from "components/Menu";

const ContentLayout: React.FC = ({ children }) => {
  return (
    <Layout>
      <TopBar />
      <Menu />
      <Layout.Content>{children}</Layout.Content>
      <Layout.Footer />
    </Layout>
  );
};

export default ContentLayout;
