import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";
import { Results, IdName, ApiQueryType, NomenclatureItem } from "types";
import { defaultQueryLimit } from "utils";

export const nomenclatures = {
  getList: async (entity: string, query: ApiQueryType = {}) => {
    query.limit = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/nomenclatures/${entity}/list/`, query);
    const { data } = await apiAxios.get<Results<IdName>>(url);
    return data;
  },
  getKeyList: async (query: ApiQueryType = {}) => {
    query.limit = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/nomenclatures/keys/`, query);
    const { data } = await apiAxios.get<Results<NomenclatureItem>>(url);
    return data;
  },
  deleteKey: async (id: number) => {
    const { data } = await apiAxios.delete(`/nomenclatures/keys/${id}/`);
    return data;
  },
  postKey: async (bodyData: Partial<NomenclatureItem>) => {
    const { data } = await apiAxios.post("/nomenclatures/keys/", bodyData);
    return data;
  },
  patchKey: async (id: number, bodyData: Partial<NomenclatureItem>) => {
    const { data } = await apiAxios.patch(
      `/nomenclatures/keys/${id}/`,
      bodyData,
    );
    return data;
  },
};
