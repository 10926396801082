import React from "react";
import { UseQueryResult } from "react-query";
import { AxiosError } from "axios";

import { Form, Loader, Space, useForm } from "ebs-design";

import { NomenclatureItem, Results } from "types";
import { UsePaginationResult } from "hooks";
import { AlertErrors, Pagination, Table } from "components";

import { LanguageKeyEditProvider, getLanguagesTableColumns } from ".";

export interface LanguagesTableProps {
  languagesQuery: UseQueryResult<Results<NomenclatureItem>, AxiosError>;
  pagination: UsePaginationResult;
  onDeleteClick: React.Dispatch<NomenclatureItem>;
}

export const LanguagesTable = ({
  languagesQuery,
  pagination,
  onDeleteClick,
}: LanguagesTableProps) => {
  const [form] = useForm();

  const tableColumns = React.useMemo(
    () => getLanguagesTableColumns({ onDeleteClick }),
    [onDeleteClick],
  );

  return (
    <>
      <AlertErrors error={languagesQuery.error} />
      {!languagesQuery.isError && (
        <Form form={form}>
          <Table
            data={languagesQuery.data?.results}
            columns={tableColumns}
            footer={() => (
              <div className="padx-8 pady-4">
                <Space justify="end">
                  <Pagination
                    pagination={pagination}
                    results={languagesQuery.data}
                  />
                </Space>
              </div>
            )}
            emptyText={
              languagesQuery.isLoading ? (
                <Loader loading />
              ) : (
                <Space className="pady-12" justify="center">
                  No data
                </Space>
              )
            }
            variants={["bordered", "elevated", "stripped-even"]}
            scroll={{ x: true }}
            rowKey="id"
            components={{
              body: {
                row: ({ ...props }: any) => {
                  return (
                    <LanguageKeyEditProvider
                      onSuccess={languagesQuery.refetch}
                      form={form}
                    >
                      <tr {...props}></tr>
                    </LanguageKeyEditProvider>
                  );
                },
              },
            }}
          />
        </Form>
      )}
    </>
  );
};
