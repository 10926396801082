import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";
import { Stats, UserProfileEntity } from "types";

export const profile = {
  getStats: async () => {
    const { data } = await apiAxios.get<Stats>(`/stats/profile/`);
    return data;
  },
  getUser: async () => {
    const url = stringifyUrl(`/users/profile/`);
    const { data } = await apiAxios.get<UserProfileEntity>(url);
    return data;
  },
  editUser: async () => {
    const { data } = await apiAxios.patch<UserProfileEntity>("/users/profile/");
    return data;
  },
};
