import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

import { defaultQueryLimit } from "utils";
import { Results, CategoryEntity, CategoryBodyData, ApiQueryType } from "types";

export const categories = {
  getList: async (query: ApiQueryType = {}) => {
    query.limit = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/categories/list/`, query);
    const { data } = await apiAxios.get<Results<CategoryEntity>>(url);
    return data;
  },
  getChildren: async (id: number, query: ApiQueryType = {}) => {
    query.limit = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/categories/${id}/children/list/`, query);
    const { data } = await apiAxios.get<Results<CategoryEntity>>(url);
    return data;
  },
  getCategoriesList: async (
    query: ApiQueryType = { limit: defaultQueryLimit },
  ) => {
    const url = stringifyUrl(`/categories/list/`, query);
    const { data } = await apiAxios.get<Results<CategoryEntity>>(url);
    return data;
  },
  getCategoriesChildren: async (
    id: number,
    query: ApiQueryType = { limit: defaultQueryLimit },
  ) => {
    const url = stringifyUrl(`/categories/${id}/children/list/`, query);
    const { data } = await apiAxios.get<Results<CategoryEntity>>(url);
    return data;
  },
  getCategory: async (id: number | null) => {
    const { data } = await apiAxios.get<CategoryEntity>(`/categories/${id}/`);
    return data;
  },
  updateCategory: async (id: number | null, bodyData: CategoryBodyData) => {
    const { data } = await apiAxios.patch<CategoryEntity>(
      `/categories/${id}/`,
      bodyData,
    );
    return data;
  },
  createCategory: async (bodyData: CategoryBodyData) => {
    const { data } = await apiAxios.post<CategoryEntity>(
      `/categories/`,
      bodyData,
    );
    return data;
  },
  deleteCategory: async (id: number) => {
    const { data } = await apiAxios.delete<void>(`/categories/${id}/`);
    return data;
  },
};
