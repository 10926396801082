import { TabsWithRoute } from "components";
import { cmsTabs } from "features/cms/config";
import TutorialsList from "features/tutorials/TutorialsList";

const Tutorials = () => {
  return (
    <TabsWithRoute tabItems={cmsTabs}>
      <TutorialsList />
    </TabsWithRoute>
  );
};

export default Tutorials;
