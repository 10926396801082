import { UseQueryResult } from "react-query";
import { AxiosError } from "axios";

import { Space } from "ebs-design";

import {
  Table,
  Pagination,
  TableEmptyText,
  AlertErrors,
  TableRowLink,
} from "components";
import { UsePaginationResult } from "hooks";
import { CouponEntity, Results } from "types";

import { getCouponsTableColumns } from ".";

export interface CouponsTableProps {
  onDeleteClick: (id: number) => void;
  couponsQuery: UseQueryResult<Results<CouponEntity>, AxiosError>;
  pagination: UsePaginationResult;
}

export const CouponsTable = ({
  onDeleteClick,
  couponsQuery,
  pagination,
}: CouponsTableProps) => {
  return (
    <>
      <AlertErrors error={couponsQuery.error} />
      {!couponsQuery.isError && (
        <Table
          data={couponsQuery.data?.results}
          columns={getCouponsTableColumns({ onDeleteClick })}
          footer={() => (
            <div className="padx-8 pady-4">
              <Space justify="end">
                <Pagination
                  pagination={pagination}
                  results={couponsQuery.data}
                />
              </Space>
            </div>
          )}
          emptyText={<TableEmptyText isLoading={couponsQuery.isLoading} />}
          variants={[
            "bordered",
            "elevated",
            "stripped-even",
            "highlight-hover",
          ]}
          scroll={{ x: true }}
          rowKey="id"
          components={{
            body: {
              row: (props: any) => (
                <TableRowLink {...props} makeUrl={(id) => `/coupons/${id}/`} />
              ),
            },
          }}
        />
      )}
    </>
  );
};
