import { Space } from "ebs-design";

import { CohortEntity } from "types";

export interface CohortStatsProps {
  cohort: CohortEntity;
}

export const CohortStats = ({ cohort }: CohortStatsProps) => {
  return (
    <>
      <div className="details-card__grid-2cols">
        <div className="details-card chart-hover">
          <Space justify="space-between" align="center">
            <span className="small-text fw-500">Number of users</span>
            <h2>{cohort?.users_count}</h2>
          </Space>
        </div>
        <div className="details-card">
          <Space justify="space-between" align="center">
            <span className="fw-500">Gifts sent to cohort</span>
            <h2>{cohort?.gifts_count}</h2>
          </Space>
        </div>
      </div>
    </>
  );
};
