import { Layout, AvatarInline, Tooltip } from "ebs-design";
import { useAuth } from "hooks";
import { UserProfileTooltip } from "./UserProfileTooltip";

const TopBar = () => {
  const auth = useAuth();

  return (
    <Layout.Topbar>
      <Layout.Topbar.Toggler />

      <Layout.Topbar.Title>PRSNT</Layout.Topbar.Title>

      <Layout.Topbar.RightSide>
        <Tooltip
          placement="bottom"
          trigger="click"
          tooltip={<UserProfileTooltip />}
        >
          {auth?.user && (
            <AvatarInline
              alt={`${auth?.user?.first_name} ${auth?.user?.last_name}`}
              status="active"
              img={auth?.user?.photo?.url || ""}
              type="light"
              reversed
            />
          )}
        </Tooltip>
      </Layout.Topbar.RightSide>
    </Layout.Topbar>
  );
};

export default TopBar;
