import { useQuery } from "react-query";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";
import { AxiosError } from "axios";

import { categories } from "api";
import { Results, CategoryEntity } from "types";

export type UseProductCategoryFiltersResult = ReturnType<
  typeof useProductCategoryFilters
>;

export function useProductCategoryFilters(onChange: () => void) {
  const [queryParams, setQueryParams] = useQueryParams({
    category_id: withDefault(NumberParam, undefined), // subcategory
    parent_category_id: withDefault(NumberParam, undefined), // category
  });

  let categoryId = queryParams.parent_category_id,
    subCategoryId = queryParams.parent_category_id
      ? queryParams.category_id
      : undefined;

  const categoriesQueryParams = {
    parent__isnull: true,
  };

  const categoriesQuery = useQuery<Results<CategoryEntity>, AxiosError>(
    ["categories", categoriesQueryParams],
    () => categories.getList(categoriesQueryParams),
  );

  const subCategoriesQuery = useQuery<Results<CategoryEntity>, AxiosError>(
    ["categories", categoryId, "children"],
    () => categories.getChildren(categoryId || 0),
    {
      enabled: !!categoryId,
    },
  );

  return {
    params: {
      category_id: queryParams.category_id || queryParams.parent_category_id,
    },
    categories: {
      current: categoryId || queryParams.category_id,
      set: (value: number | string) => {
        setQueryParams({
          parent_category_id: Number(value || 0) || undefined,
          category_id: undefined,
        });
        onChange();
      },
      data: categoriesQuery.data?.results || [],
      isLoading: categoriesQuery.isLoading,
    },
    subCategories: {
      current: subCategoryId,
      set: (value: number | string) => {
        setQueryParams({
          category_id: Number(value || 0) || undefined,
        });
        onChange();
      },
      data: subCategoriesQuery.data?.results || [],
      isLoading: subCategoriesQuery.isLoading,
    },
    errors: [categoriesQuery.error, subCategoriesQuery.error],
  };
}
