import { apiAxios } from "api";
import { ApiQueryType, NomenclatureItem, Results } from "types";

import { stringifyUrl } from "./utils";

export const tags = {
  get: async (query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/tags/`, query);
    const { data } = await apiAxios.get<Results<NomenclatureItem>>(url);
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/tags/${id}/`);
    return data;
  },
  patch: async (id: number, tag: NomenclatureItem) => {
    const { data } = await apiAxios.patch<NomenclatureItem>(
      `/tags/${id}/`,
      tag,
    );
    return data;
  },
  post: async (tag: NomenclatureItem) => {
    const { data } = await apiAxios.post<NomenclatureItem>(`/tags/`, tag);
    return data;
  },
};
