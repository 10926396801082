import { useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { AxiosError } from "axios";

import { Space } from "ebs-design";

import {
  AlertErrors,
  Pagination,
  Table,
  TableEmptyText,
  TableRowLink,
} from "components";
import { CohortParameters, Results, UserProfileEntity } from "types";
import { cohorts } from "api";
import { usePagination } from "hooks";

import { cohortUsersTableColumns } from "./CohortForm/cohortUsersTableColumns";

import { queryParamsFromCohortParams } from "./utils";

export interface PotentialUsersTableProps {
  parameters?: CohortParameters;
}

export const PotentialUsersTable = ({
  parameters,
}: PotentialUsersTableProps) => {
  const pagination = usePagination({ defaultLimit: 10 });
  const { limit, page, setPage } = pagination;

  const queryParams = {
    ...queryParamsFromCohortParams(parameters),
    limit,
    page,
  };

  const usersQuery = useQuery<Results<UserProfileEntity>, AxiosError>(
    ["cohorts", "users", queryParams],
    () => cohorts.getUsers(queryParams),
  );

  useUpdateEffect(() => setPage(1), [parameters]);

  return (
    <>
      <AlertErrors error={usersQuery.error} />
      <div className="pady-2"></div>
      {!usersQuery.isError && (
        <Table
          variants={["bordered", "elevated", "highlight-hover", "dense"]}
          columns={cohortUsersTableColumns}
          data={usersQuery.data?.results}
          emptyText={<TableEmptyText isLoading={usersQuery.isLoading} />}
          rowKey="id"
          components={{
            body: {
              row: (props: any) => (
                <TableRowLink makeUrl={(id) => `/users/${id}/`} {...props} />
              ),
            },
          }}
          title={() => <h3 className="padx-4 pady-4">Potential users</h3>}
          footer={() => (
            <div className="padx-8 pady-4">
              <Space justify="end">
                <Pagination pagination={pagination} results={usersQuery.data} />
              </Space>
            </div>
          )}
        />
      )}
    </>
  );
};
