import React from "react";
import { UseMutationResult, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { Modal, Form, useForm, Button, Space, Input } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

import { NomenclatureItem, NomenclatureItemType } from "types";
import { AlertErrors, Icon } from "components";

export interface InterestModalProps extends ModalProps {
  mutation: UseMutationResult<
    NomenclatureItem,
    AxiosError,
    Partial<NomenclatureItem>
  >;
  data?: NomenclatureItem;
}

export const InterestModal = ({
  data,
  mutation,
  ...modalProps
}: InterestModalProps) => {
  const [form] = useForm();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  const submitHandler = (values: NomenclatureItem) => {
    mutation.mutate(values, {
      onSuccess: () => {
        form.resetFields();
        modalProps.onClose?.();
        queryClient.invalidateQueries([
          "nomenclatures",
          NomenclatureItemType.INTEREST,
        ]);
      },
    });
  };

  return (
    <Modal size="small" {...modalProps}>
      <Form form={form} onFinish={submitHandler}>
        <Modal.Content>
          <Form.Field name={"title"} label={"Title"} initialValue="">
            <Input />
          </Form.Field>

          <AlertErrors error={mutation.error} />
          {/* {displayLanguages.map((language) => (
            <Form.Field
              name={"title_" + language.code}
              label={"In " + language.title}
              initialValue=""
            >
              <Input />
            </Form.Field>
          ))} */}
        </Modal.Content>
        <Modal.Footer>
          <Space justify="space-between">
            <Button onClick={modalProps.onClose}>Cancel</Button>
            <Button
              submit
              type="primary"
              loading={mutation.isLoading}
              prefix={<Icon type="edit" />}
              disabled={mutation.isLoading}
            >
              Save
            </Button>
          </Space>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
