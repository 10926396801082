import { ApiQueryType, CampaignsTier, CampaignsTierFormValues } from "types";
import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

export const campaignsTier = {
  getList: async (query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/management/campaign-tiers/`, query);
    const { data } = await apiAxios.get<CampaignsTier[]>(url);
    return data;
  },

  post: async (bodyData: CampaignsTierFormValues) => {
    const { data } = await apiAxios.post(
      "/management/campaign-tiers/",
      bodyData,
    );
    return data;
  },

  getById: async (id: number | null) => {
    const { data } = await apiAxios.get<CampaignsTier>(
      `/management/campaign-tiers/${id}/`,
    );
    return data;
  },

  patch: async (id: number, bodyData: CampaignsTierFormValues) => {
    const { data } = await apiAxios.patch(
      `/management/campaign-tiers/${id}/`,
      bodyData,
    );
    return data;
  },

  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/management/campaign-tiers/${id}/`);
    return data;
  },
};
