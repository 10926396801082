import { Card, Col, Row, Space } from "ebs-design";
import { DashboardStatsEntity } from "types";
import { Icon } from "components";

interface Props {
  dashboardStats?: DashboardStatsEntity;
}

export const HeaderCard = ({ dashboardStats }: Props) => {
  return (
    <>
      <Card>
        <Card.Body>
          <Row g={3}>
            <Col lg={3}>
              <Space>
                <div className="circle-wrap">
                  <Icon type="invite-friends" />
                </div>
                <div>
                  <p className="small-text color-secondary">Sign ups</p>
                  <h4 className="size-l fw-600">{dashboardStats?.sign_ups}</h4>
                </div>
              </Space>
            </Col>
            <Col lg={3}>
              <Space>
                <div className="circle-wrap">
                  <Icon type="friends" />
                </div>
                <div>
                  <p className="small-text color-secondary">Total users</p>
                  <h4 className="size-l fw-600">
                    {dashboardStats?.total_users}
                  </h4>
                </div>
              </Space>
            </Col>
            <Col lg={3}>
              <Space>
                <div className="circle-wrap">
                  <Icon type="prsnts2" />
                </div>
                <div>
                  <p className="small-text color-secondary">Total Gifts</p>
                  <h4 className="size-l fw-600">
                    {dashboardStats?.total_gifts}
                  </h4>
                </div>
              </Space>
            </Col>
            <Col lg={3}>
              <Space>
                <div className="circle-wrap">
                  <Icon type="status-redeemed" />
                </div>
                <div>
                  <p className="small-text color-secondary">Free gifts sent</p>
                  <h4 className="size-l fw-600">
                    {dashboardStats?.free_gifts_sent}
                  </h4>
                </div>
              </Space>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
