export const campaignsTabs = [
  {
    label: "Campaigns",
    route: "/campaigns",
  },
  {
    label: "Campaigns tier",
    route: "/campaigns-tier",
  },
];
