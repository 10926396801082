import dayjs from "dayjs";

export function dateToInputFormat(date?: Date | string) {
  date = new Date(date as Date);
  if (isNaN((date as unknown) as number)) return "";

  return [
    ("0000" + date.getFullYear()).slice(-4),
    ("00" + (date.getMonth() + 1)).slice(-2),
    ("00" + date.getDate()).slice(-2),
  ].join("-");
}

export const today = dayjs();
export const timeFormat = "HH:mm";
export const dateFormat = "YYYY-MM-DD";
export const dateFormatAPI = "YYYY-MM-DD";
export const dateTimeFormatAPI = "YYYY-MM-DD HH:mm:ss";
export const dateTimeFormat = "YYYY/MM/DD HH:mm";

export const format = (date?: Date | string, format?: string): string =>
  dayjs(date).format(format || dateFormat);
