import React from "react";

import { FormInstance } from "ebs-design/dist/components/organisms/Form";

export interface LanguageKeyEditContextValue {
  isEditing: boolean;
  setIsEditing: React.Dispatch<boolean>;
  form?: FormInstance;
  onSuccess?: () => void;
}

export const LanguageKeyEditContext =
  React.createContext<LanguageKeyEditContextValue>({
    isEditing: false,
    setIsEditing: () => {},
  });

export interface LanguageKeyEditProviderProps
  extends Pick<LanguageKeyEditContextValue, "form" | "onSuccess"> {}

export const LanguageKeyEditProvider: React.FC<LanguageKeyEditProviderProps> =
  ({ form, onSuccess, children }) => {
    const [isEditing, setIsEditing] = React.useState(false);
    return (
      <LanguageKeyEditContext.Provider
        value={{ isEditing, setIsEditing, form, onSuccess }}
      >
        {children}
      </LanguageKeyEditContext.Provider>
    );
  };
