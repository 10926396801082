import {
  Space,
  Icon,
  Modal,
  useForm,
  Form,
  Input,
  Textarea,
  Button,
  Switch,
} from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { AlertErrors, Upload } from "components";
import { attachments, tutorials } from "api";
import { deepProp } from "utils";
import {
  AttachmentType,
  NomenclatureFormValue,
  NomenclatureItem,
  NomenclatureItemType,
} from "types";
import { useIsUploading } from "hooks";

interface Props {
  activeTutorial?: NomenclatureItem | null;
  open: boolean;
  onCancel: () => void;
}

const TutorialModalForm = ({ activeTutorial, onCancel, open }: Props) => {
  const queryClient = useQueryClient();
  const [form] = useForm();

  const mutateFc = (bodyData: NomenclatureFormValue) =>
    activeTutorial
      ? tutorials.patch(activeTutorial.id || 0, bodyData)
      : tutorials.post(bodyData);

  const { mutate, isLoading, error } = useMutation<
    NomenclatureFormValue,
    AxiosError,
    NomenclatureFormValue
  >((values) => mutateFc(values), {
    onSuccess: () => {
      queryClient.invalidateQueries("tutorials-list");
      onCancel();
    },
  });

  const { isUploading, checkFields } = useIsUploading(["image"]);

  const handleForm = ({
    title,
    description,
    image,
    is_active,
  }: NomenclatureFormValue) => {
    const formObj: NomenclatureFormValue = {
      title,
      description,
      image_id: deepProp(image, 0, "data", "id"),
      is_active,
      type: NomenclatureItemType.TUTORIALS,
    };

    mutate(formObj);
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title={`${activeTutorial ? "Edit" : "Add"} tutorial`}
      size="small"
      className="faq-modal"
    >
      <Modal.Content>
        <AlertErrors error={error} />
        <Form
          form={form}
          onFinish={handleForm}
          onFieldsChange={checkFields}
          initialValues={
            activeTutorial
              ? {
                  title: activeTutorial?.title,
                  description: activeTutorial?.description,
                  is_active: activeTutorial?.is_active,
                  image: activeTutorial?.image
                    ? [
                        {
                          file: activeTutorial?.image,
                          data: activeTutorial?.image,
                        },
                      ]
                    : [],
                }
              : {}
          }
        >
          <Form.Field name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Field>
          <Form.Field
            name="description"
            label="Description"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Textarea />
          </Form.Field>
          <Form.Field name="image" label="Image" rules={[{ required: true }]}>
            <Upload
              accept="image/*"
              name="files"
              customRequest={(o) =>
                attachments.post(o, {
                  attachmentType: AttachmentType.IMAGE,
                })
              }
            >
              <Button size={"medium"} className="border-dashed full-width">
                Upload
              </Button>
            </Upload>
          </Form.Field>
          <Form.Field
            name="is_active"
            label="Active"
            rules={[{ required: true }]}
            valuePropName="checked"
            initialValue={false}
          >
            <Switch />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>Cancel</Button>

          <Button
            type="primary"
            disabled={isLoading || isUploading}
            loading={isLoading}
            prefix={<Icon type="check" />}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default TutorialModalForm;
