import { GenericObject } from "../types/common";

/**
 * clsMods("class", { "foo": true, "bar": false } ) => "class class--foo"
 */
export function clsMods(
  baseClass: string,
  ...modifiersArgs: (GenericObject | string[])[]
): string {
  let result: string[] = [baseClass];

  modifiersArgs.forEach((modifiers) => {
    if (modifiers instanceof Array) {
      modifiers.forEach((m) => result.push(`${baseClass}--${m}`));
    } else {
      Object.entries(modifiers).forEach((entry) =>
        result.push(entry[1] ? baseClass + "--" + entry[0] : ""),
      );
    }
  });

  return result.filter((_) => _).join(" ");
}
