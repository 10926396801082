import { ReactNode } from "react";
import cn from "classnames";
import { Tabs } from "ebs-design";

import { useRouter } from "hooks";

interface TabItem {
  label: string;
  route: string;
  className?: string;
}
interface Props {
  children: ReactNode;
  tabItems: TabItem[];
  className?: string;
}

export const TabsWithRoute = ({ children, tabItems, className }: Props) => {
  const { push, pathname } = useRouter();

  return (
    <Tabs activeTab={pathname} className={cn(`router-tabs`, className)}>
      {tabItems.length &&
        tabItems.map((item: TabItem) => {
          return (
            <Tabs.Tab
              className={item.className}
              label={item.label}
              tabKey={item.route}
              onClick={() => push(item.route)}
              key={item.route}
            />
          );
        })}
      <Tabs.Panel tabKey={pathname}>{children}</Tabs.Panel>
    </Tabs>
  );
};

export default TabsWithRoute;
