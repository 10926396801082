import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Loader, Card, Space } from "ebs-design";

import { AlertErrors, Pagination } from "components";
import { users } from "api";
import { ProductEntity, Results } from "types";

import { FavouriteCard } from "./FavouriteCard";
import { usePagination } from "hooks";

export interface UserFavouritesProps {
  userId: number;
}

export const UserFavourites = ({ userId }: UserFavouritesProps) => {
  const pagination = usePagination();
  const { page, limit } = pagination;

  const { data, isLoading, error } = useQuery<
    Results<ProductEntity>,
    AxiosError
  >(["users", "favourites", userId, { page, limit }], () =>
    users.getFavourites(userId, { page, limit }),
  );

  const products = data?.results;

  return (
    <>
      <Card collapsible>
        <Card.Header>
          <h3 onClick={(e) => e.stopPropagation()}>Favourites</h3>
        </Card.Header>
        <Card.Body>
          <AlertErrors error={error!} />
          {isLoading ? (
            <Loader loading={true} />
          ) : products && products.length ? (
            <div className="product-card__grid pady-2">
              {products.map((product) => (
                <FavouriteCard key={product.id} product={product} />
              ))}
            </div>
          ) : (
            <Space justify="center" className="pady-10">
              No favourites
            </Space>
          )}
        </Card.Body>
        <Card.Footer>
          <Space justify="end">
            <Pagination pagination={pagination} results={data} />
          </Space>
        </Card.Footer>
      </Card>
    </>
  );
};
