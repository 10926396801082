import { Button, Col, Form, Row } from "ebs-design";

import { Upload, PhotoUploadItem } from "components";
import { AttachmentType } from "types";
import { attachments } from "api";

export const FaceFilterPhotoUpload = () => {
  return (
    <Form.Field name="image_en">
      {({ ...props }) => (
        <Row>
          <Upload
            {...props}
            renderItem={PhotoUploadItem}
            accept="image/*"
            name="files"
            customRequest={(o) =>
              attachments.post(o, {
                attachmentType: AttachmentType.IMAGE,
              })
            }
            component={({ children, ...props }) => (
              <Col size={10} {...props}>
                {children}
              </Col>
            )}
          >
            <Button>Upload</Button>
          </Upload>
        </Row>
      )}
    </Form.Field>
  );
};
