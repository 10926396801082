export function nextValue<T>(arr: T[], currentValue: T) {
  return arr[(arr.indexOf(currentValue) + 1) % arr.length];
}

export function lastInArr<T>(arr?: T[]) {
  return arr?.length ? arr[arr.length - 1] : undefined;
}

export function isArrEqual<T extends unknown, P extends unknown>(
  a?: T[],
  b?: P[],
) {
  if (a === b) return true;
  if (!a || !b) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i++) {
    if (a[i] instanceof Array && b[i] instanceof Array) {
      if (!isArrEqual(a[i] as any, b[i] as any)) return false;
    } else if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function withoutKeyDublicates<T, TKey = T>(
  arr: T[],
  getKey: (v: T) => TKey,
) {
  const s = new Set<TKey>();
  return arr.filter((i) => !s.has(getKey(i)) && s.add(getKey(i)));
}

export const tuple = <T extends string[]>(...args: T) => args;
