import React from "react";
import { useMutation } from "react-query";

import { Form, Input, Space, Button, useNotify } from "ebs-design";

import { AlertErrors, Icon, TabContent, TabProvider } from "components";
import { ConfirmLoginCredentinals, LoginCredentinals } from "types";
import * as api from "api";
import { useAuth } from "hooks";

const LoginForm = () => {
  const auth = useAuth();
  const notify = useNotify();

  const [candidateUsername, setCandidateUsername] = React.useState("");
  const [currentStep, setCurrentStep] = React.useState(1);

  const loginMutation = useMutation(api.auth.login);

  const firstStepSubmitHandler = (values: LoginCredentinals) => {
    loginMutation.mutate(values, {
      onSuccess: ({ success }) => {
        if (!success) {
          notify.error({ title: "", description: "Failed to login" });
          return;
        }
        setCandidateUsername(values.username);
        setCurrentStep(2);
      },
    });
  };

  const secondStepSubmitHandler = (values: ConfirmLoginCredentinals) => {
    auth?.confirmLoginMutation.mutate({
      ...values,
      username: candidateUsername,
    });
  };

  return (
    <TabProvider value={currentStep} onChange={setCurrentStep}>
      <TabContent tabKey={1}>
        <Form onFinish={firstStepSubmitHandler}>
          <AlertErrors error={loginMutation.error} />

          <Form.Field
            name="username"
            label="Username"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>

          <Form.Field
            name="password"
            label="Password"
            rules={[{ required: true }]}
          >
            <Input type="password" />
          </Form.Field>

          <Space align="center" justify="end">
            <Button
              type="primary"
              loading={loginMutation.isLoading}
              disabled={loginMutation.isLoading}
              prefix={loginMutation.isLoading && " "}
              submit
            >
              Next
            </Button>
          </Space>
        </Form>
      </TabContent>
      <TabContent tabKey={2}>
        <Form onFinish={secondStepSubmitHandler}>
          <AlertErrors error={auth?.confirmLoginMutation.error} />

          <Form.Field
            name="token"
            label="SMS confirmation code"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>

          <Space align="center" justify="space-between">
            <Button
              type="ghost"
              prefix={<Icon type="left" />}
              onClick={() => setCurrentStep(1)}
            >
              Prev
            </Button>
            <Button
              type="primary"
              loading={auth?.confirmLoginMutation.isLoading}
              disabled={auth?.confirmLoginMutation.isLoading}
              prefix={<Icon type="user" />}
              submit
            >
              Sign in
            </Button>
          </Space>
        </Form>
      </TabContent>
    </TabProvider>
  );
};

export default LoginForm;
