import { useMemo } from "react";
import {
  Modal,
  Form,
  Input,
  useForm,
  Button,
  Space,
  Select,
  ButtonGroup,
  Switch,
} from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import {
  Icon,
  AlertErrors,
  Upload,
  UploadItem,
  ProductSelect,
} from "components";
import { attachments, categories } from "api";
import {
  CategoryBodyData,
  AttachmentType,
  Attachment,
  ProductEntity,
} from "types";
import { deepProp } from "utils";
import { useIsUploading } from "hooks";

import { CategoryForm } from "features/categories/types";
import { ModalCategoryState } from "features/categories/types";

enum OrderPosition {
  Above = "above",
  Below = "below",
}

const CategoryFormModal = ({
  title,
  visible,
  onCancel,
  category,
  hasParent,
  categoriesSelect,
}: ModalCategoryState) => {
  const [form] = useForm();
  const queryClient = useQueryClient();

  const isSubcategory = !!category?.parent || hasParent;

  const confirmIcon = useMemo(
    () => ((hasParent && category) || !category ? "plus" : "edit"),
    [category, hasParent],
  );

  const mutateFn = async (values: CategoryBodyData) =>
    (hasParent && category) || !category
      ? categories.createCategory(values)
      : categories.updateCategory(category!.id, values);

  // edit/create category/subcategory request
  const { mutate, isLoading, error } = useMutation<
    CategoryBodyData,
    AxiosError,
    CategoryBodyData
  >((values: CategoryBodyData) => mutateFn(values), {
    onSuccess: () => {
      onCancel();
      queryClient.invalidateQueries("categories");
    },
  });

  const { isUploading, checkFields } = useIsUploading(["images"]);

  const handleForm = ({
    title,
    images,
    is_active,
    age_limit,
    age_from,
    age_to,
    related_products,
    orderCategory,
  }: CategoryForm & { images: UploadItem<Attachment>[] }) => {
    let order = {};

    const parentId =
      category?.parent?.id || hasParent
        ? { parent_id: !hasParent ? category?.parent?.id : category?.id }
        : {};

    //create order object for request
    if (orderCategory && orderCategory.selectOrderValue) {
      order = {
        [`order_${orderCategory?.orderPlace}_category_id`]:
          orderCategory.selectOrderValue,
      };
    }

    // Create-update category / subcategory
    // FIXME change type of mutation args
    mutate({
      title,
      is_active,
      age_limit: age_limit || null,
      age_from: age_from || null,
      age_to: age_to || null,
      related_products,
      ...order,
      ...{
        image_id: deepProp(images, 0, "data", "id"),
      },
      ...parentId,
    });
  };

  return (
    <Modal size="small" open={visible} onClose={onCancel} title={title}>
      <Modal.Content>
        <AlertErrors error={error} />
        <Form
          form={form}
          onFieldsChange={checkFields}
          initialValues={
            !hasParent
              ? {
                  ...category,
                  images: category?.image
                    ? [{ file: category?.image, data: category?.image }]
                    : [],
                }
              : {}
          }
          onFinish={handleForm}
          onValuesChange={(values) => values.name}
          className="category-form"
        >
          <Form.Field name="title" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Field>
          <Form.Field
            name="images"
            label="Add image"
            rules={[{ required: true }]}
          >
            <Upload
              accept="image/*"
              name="files"
              customRequest={(o) =>
                attachments.post(o, {
                  attachmentType: AttachmentType.IMAGE,
                })
              }
            >
              <Button size={"medium"} className="category-form__upload">
                Upload
              </Button>
            </Upload>
          </Form.Field>
          <Form.Field
            name="is_active"
            label="Active"
            valuePropName={"checked"}
            initialValue={false}
          >
            <Switch />
          </Form.Field>
          <Form.Field label="Age limit" name="age_limit">
            <Input type="number" min="0" />
          </Form.Field>
          {isSubcategory && (
            <>
              <Form.Field label="Age from" name="age_from">
                <Input type="number" min="0" />
              </Form.Field>
              <Form.Field label="Age to" name="age_to">
                <Input type="number" min="0" />
              </Form.Field>
              <Form.Field
                label="Related products"
                name="related_products"
                initialValue={[]}
                normalize={(product: ProductEntity[]) =>
                  product.map((product) => product.id)
                }
              >
                {(control) => <ProductSelect {...(control as any)} multiple />}
              </Form.Field>
            </>
          )}

          {category && !hasParent && (
            <Form.Field
              initialValue={{
                orderPlace: OrderPosition.Above,
                selectOrderValue: "",
              }}
              label="Choose the category position"
              name="orderCategory"
            >
              {({ value, onChange }) => {
                return (
                  <>
                    <ButtonGroup className="mb-10">
                      <Button
                        size="medium"
                        type={
                          value.orderPlace === OrderPosition.Above
                            ? "primary"
                            : "ghost"
                        }
                        onClick={() =>
                          onChange({
                            ...value,
                            orderPlace: OrderPosition.Above,
                          })
                        }
                      >
                        Above
                      </Button>
                      <Button
                        size="medium"
                        type={
                          value.orderPlace === OrderPosition.Below
                            ? "primary"
                            : "ghost"
                        }
                        onClick={() =>
                          onChange({
                            ...value,
                            orderPlace: OrderPosition.Below,
                          })
                        }
                      >
                        Below
                      </Button>
                    </ButtonGroup>
                    <Select
                      placeholder="Select category"
                      options={categoriesSelect?.filter(
                        (item) => item.value !== category.id,
                      )}
                      onChange={(selectValue) =>
                        onChange({ ...value, selectOrderValue: selectValue })
                      }
                      value={value.selectOrderValue}
                    />
                  </>
                );
              }}
            </Form.Field>
          )}
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space align="center" justify="space-between">
          <Button type="ghost" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={isLoading}
            prefix={<Icon type={confirmIcon} />}
            onClick={() => form.submit()}
            disabled={isLoading || isUploading}
          >
            Save
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryFormModal;
