import { Option as SelectOption } from "ebs-design/dist/components/molecules/Select/interfaces";
import React from "react";

export enum FilterType {
  boolean,
  enum,
  numberRange,
}

export interface FilterBase {
  key: string;
  name: string;
  type: FilterType;
  onChange?: (value: any) => void;
}

export interface BooleanFilter extends FilterBase {
  type: FilterType.boolean;
}

export interface EnumFilter extends FilterBase {
  type: FilterType.enum;
  values: SelectOption[];
  isLoading?: boolean;
  onSearch?: React.Dispatch<string>;
}

export interface RangeFilter extends FilterBase {
  type: FilterType.numberRange;
  min?: number;
  max?: number;
}

export type FiltersSource = (RangeFilter | BooleanFilter | EnumFilter)[];
