import {
  Attachment,
  UserProfileEntity,
  ProductEntity,
  CurrencyType,
} from "types";

export interface OrderEntity {
  id: number;
  product: ProductEntity;
  product_id: number;
  target: UserProfileEntity;
  target_id: number;
  contact: UserProfileEntity;
  contact_id: number;
  comment: Comment;
  comment_id: number;
  video: Attachment;
  video_id: number;
  timestamp: string;
  edited_timestamp: string;
  profit: number;
  url: string;
  paid: string;
  cost: string;
  fee: string;
  data: Data;
  status: string;
  system: boolean;
  user: UserProfileEntity;
  transactions: number[];
  price?: number;
}

export interface Balance {
  face_value: {
    amount: number;
    currency: CurrencyType;
  };
}

interface Data {
  Signature: string;
  Timestamp: string;
  balance: Balance;
  code: string;
  data: string;
  message: string;
  original_client_request_id: string;
  status: string;
}

export enum StatusOrder {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILED = "FAILED",
}
