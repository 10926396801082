import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Space,
  Icon,
  Modal,
  useForm,
  Form,
  Input,
  Button,
  Switch,
  Select,
  Textarea,
} from "ebs-design";

import { AlertErrors } from "components";
import { campaigns, campaignsTier } from "api";
import { CampaignsTier, CampaignsTierFormValues } from "types";
import { mapToSelectOptions } from "utils";

interface ModalProps {
  activeTier?: CampaignsTier | null;
  open: boolean;
  onCancel: () => void;
}

const CampaignsTierModalForm = ({ activeTier, onCancel, open }: ModalProps) => {
  const queryClient = useQueryClient();
  const [form] = useForm();

  const { data, isLoading: campaignLoading } = useQuery(
    ["campaigns-list"],
    () => campaigns.getList({}),
  );

  const mutateFc = (bodyData: CampaignsTierFormValues) =>
    activeTier && activeTier.id
      ? campaignsTier.patch(activeTier.id, bodyData)
      : campaignsTier.post(bodyData);

  const { mutate, isLoading, error } = useMutation(
    (values: CampaignsTierFormValues) => mutateFc(values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("campaigns-tier");
        onCancel();
      },
    },
  );

  const handleForm = ({
    description,
    tier,
    is_paid,
    campaign,
  }: CampaignsTierFormValues) => {
    const formObj: CampaignsTierFormValues = {
      description,
      tier: Number(tier),
      is_paid: !!is_paid,
      campaign: Number(campaign) || Number(activeTier?.campaign),
    };

    mutate(formObj);
  };

  const campaignsList = mapToSelectOptions(data, "name");

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title={`${activeTier ? "Edit" : "Add"} Campaigns tier`}
      size="small"
      className="faq-modal"
    >
      <Modal.Content>
        <AlertErrors error={error} />
        <Form
          form={form}
          onFinish={handleForm}
          initialValues={
            activeTier
              ? {
                  description: activeTier?.description,
                  tier: activeTier?.tier,
                  is_paid: activeTier?.is_paid,
                  campaign: activeTier?.campaign_name,
                }
              : {}
          }
        >
          <Form.Field
            label={activeTier ? `Tier: ${activeTier?.tier}` : "Tier"}
            name="tier"
            rules={[{ required: true }]}
          >
            <Input type="number" min="0" />
          </Form.Field>

          <Form.Field label="Is paid" name="is_paid" valuePropName="checked">
            <Switch />
          </Form.Field>

          <Form.Field
            rules={[{ required: true }]}
            label="Campaign"
            name="campaign"
          >
            <Select
              placeholder="Select Campaign"
              options={campaignsList}
              loading={campaignLoading}
            />
          </Form.Field>

          <Form.Field label="Description" name="description">
            <Textarea />
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>Cancel</Button>

          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            prefix={<Icon type="check" />}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default CampaignsTierModalForm;
