import { Link } from "react-router-dom";

import { useAuth } from "hooks";
import { Icon } from "components";

export const UserProfileTooltip = () => {
  const auth = useAuth();

  return (
    <div className="user-profile-tooltip">
      <Link to="/profile" className="user-profile-tooltip__item">
        <Icon type={"user"} /> Settings
      </Link>
      <span className="user-profile-tooltip__item" onClick={auth?.signOut}>
        <Icon type={"lock"} /> Logout
      </span>
    </div>
  );
};
