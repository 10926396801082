import { nomenclaturePlurals, nomenclatureSingulars } from "utils";
import { Attachment } from "types";

export type NomenclatureSingular = typeof nomenclatureSingulars[number];
export type NomenclaturePlural = typeof nomenclaturePlurals[number];
export type NomenclatureIdKey = `${NomenclatureSingular}_id`;

export interface NomenclatureItem {
  id?: number;
  attachments?: Attachment;
  action?: string | null;
  action_en?: string | null;
  action_ro?: string | null;
  action_ru?: string | null;
  is_active?: boolean;
  is_network_card?: boolean;
  is_paid_network_card?: boolean;
  is_for_all_tiers_card?: boolean;
  code_name?: string | null;
  description?: string | null;
  description_en?: string | null;
  description_ro?: string | null;
  description_ru?: string | null;
  edited_timestamp?: string | null;
  image?: Attachment;
  image_id?: number | null;
  image_en?: string | null;
  image_ro?: string | null;
  image_ru?: string | null;
  link?: string | null;
  link_en?: string | null;
  link_ro?: string | null;
  link_ru?: string | null;
  timestamp?: string | null;
  title?: string | null;
  title_en?: string | null;
  title_ro?: string | null;
  title_ru?: string | null;
  type?: NomenclatureItemType;
}

export interface NomenclatureFormValue {
  title?: string | null;
  description?: string | null;
  image_id?: number | null;
  type: NomenclatureItemType;
  image?: string | number;
  action?: string | null;
  link?: string | null;
  is_active?: boolean;
  is_network_card?: boolean;
  is_paid_network_card?: boolean;
  is_for_all_tiers_card?: boolean;
}

export enum NomenclatureItemType {
  TUTORIALS = "TUTORIALS",
  WRAPPING_IMAGE = "WRAPPING_IMAGE",
  TRANSLATION = "TRANSLATION",
  CARD = "CARD",
  INTEREST = "INTEREST",
  FACE_FILTER = "FACE_FILTER",
}
