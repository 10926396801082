import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { Label, Loader, Space } from "ebs-design";

import { UserStats } from "types";
import { CircularProgress, AlertErrors } from "components";
import { generateColor } from "utils";
import { users } from "api";

export const UsersDashboardStats = () => {
  const {
    data: dashboardStats,
    isLoading,
    error,
  } = useQuery<UserStats, AxiosError>(["users", "stats"], () =>
    users.getStats(),
  );

  const v1 = dashboardStats?.total_new_users_7d?.current_value || 0.01;
  const v2 = dashboardStats?.total_new_users_7d?.previous_value || 0.01;
  const mx = Math.max(v1, v2);

  const compared_percentage =
    dashboardStats?.total_new_users_7d?.compared_percentage || 0;

  return (
    <>
      {isLoading && <Loader loading />}
      <AlertErrors error={error} />
      {dashboardStats && (
        <div className="details-card__grid">
          <div className="details-card chart-hover">
            <Space justify="space-between" align="center">
              <span className="small-text fw-500">New Users last 7 days </span>
              <b>{dashboardStats.total_new_users_7d?.current_value}</b>
            </Space>
            <div
              className="chart-barh"
              style={{
                width: (v1 / mx) * 100 + "%",
                height: "3px",
                background: generateColor(1),
              }}
            ></div>
            <div className="mary-1"></div>
            <Space justify="space-between" align="center">
              <span className="small-text fw-500">
                Compared to 7 days before
              </span>
              <b>{dashboardStats.total_new_users_7d?.previous_value}</b>
            </Space>
            <div
              className="chart-barh"
              style={{
                width: (v2 / mx) * 100 + "%",
                height: "3px",
                background: generateColor(4),
              }}
            ></div>
            <div className="mary-2"></div>
            <div>
              <Label
                status={
                  compared_percentage < 0
                    ? "danger"
                    : compared_percentage > 0
                    ? "success"
                    : "warning"
                }
                text={<>{compared_percentage}%</>}
                type="fill"
              />
            </div>
          </div>
          <div className="details-card">
            <Space justify="space-between" align="center">
              <span className="fw-500">Total users</span>
              <h2>{dashboardStats.total_users}</h2>
            </Space>
          </div>
          <div className="details-card">
            <Space justify="space-between" align="center">
              <span className="fw-500">Users that have sent free gifts</span>
              <span className="fw-700">
                <CircularProgress
                  padding="0.2em"
                  fontSize="1.2em"
                  circleWidth={5}
                  color={generateColor(1)}
                  value={dashboardStats.percentage_of_sent_free_gifts || 0}
                />
              </span>
            </Space>
          </div>
          <div className="details-card">
            <Space justify="space-between" align="center">
              <span className="fw-500">Users that have sent paid gifts</span>
              <span className="fw-700">
                <CircularProgress
                  padding="0.2em"
                  fontSize="1.2em"
                  circleWidth={5}
                  color={generateColor(1)}
                  value={dashboardStats.percentage_of_sent_paid_gifts || 0}
                />
              </span>
            </Space>
          </div>
        </div>
      )}
    </>
  );
};
