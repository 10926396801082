import { Modal, Button, Space } from "ebs-design";
import { ModalProps } from "ebs-design/dist/components/organisms/Modal/Modal";

export interface ConfirmModalProps extends ModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose: () => void;
  loading?: boolean;
  children: React.ReactNode;
}

export const ConfirmModal = ({
  open,
  children,
  onConfirm,
  onCancel,
  onClose,
  loading = false,
  ...props
}: ConfirmModalProps) => {
  return open ? (
    <Modal {...props} size="small" open={true} onClose={onClose}>
      <Modal.Content>{children}</Modal.Content>
      <Modal.Footer>
        <Space justify="end" size={10}>
          <Button type="ghost" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            prefix={loading ? "loading" : undefined}
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  ) : null;
};
