import { Link } from "react-router-dom";

import { Space, Tooltip } from "ebs-design";
import { ColumnType } from "ebs-design/dist/components/organisms/Table/Table";

import { Icon } from "components";
import { CohortEntity, CohortParameters } from "types";

import { CohortParametersDetails } from "../CohortParametersDetails";

import { ModuleAction } from "features/modules-permissions/ModuleAction";

export const cohortsTableColumns: ColumnType<CohortEntity>[] = [
  {
    title: "Name",
    dataIndex: "title",
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Parameters",
    dataIndex: "parameters",
    render: (parameters: CohortParameters) => (
      <Tooltip
        placement="auto"
        trigger="hover"
        tooltip={<CohortParametersDetails parameters={parameters} />}
        inline
        width={300}
      >
        <Icon className="rotate-90" type="moreactions" />
      </Tooltip>
    ),
  },
  {
    title: "Number of users",
    dataIndex: "users_count",
  },
  {
    title: "",
    dataIndex: "id",
    render: (id: number) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <Space>
            <ModuleAction module="cohorts">
              <Link
                title="Edit cohort"
                className="with-title"
                to={`/cohorts/${id}/edit`}
              >
                <Icon type="edit" />
              </Link>
            </ModuleAction>
            <ModuleAction module="send_gift">
              <Link
                title="Send gift"
                className="with-title"
                to={`/gifts/send/?cohort_id=${id}`}
              >
                <Icon type="status-sent" />
              </Link>
            </ModuleAction>
            {/* <Link title="Send notification" className="with-title" to={`/`}>
              <Icon type="message" />
            </Link> */}
          </Space>
        </div>
      );
    },
  },
];
