import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";

import { NotifyProvider, NotifyContainer } from "ebs-design";

import { AuthProvider } from "context/AuthProvider";
import { AppRouter } from "router";

import "styles/index.scss";

// Init react-query cache
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <NotifyProvider>
            <NotifyContainer timeout={6500} />
            <AuthProvider>
              <AppRouter />
            </AuthProvider>
          </NotifyProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
