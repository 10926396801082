import { products } from "api";
import { Label, Loader, Select, Space } from "ebs-design";
import React from "react";
import { useQuery } from "react-query";
import { ProductEntity } from "types";

export type ProductSelectProps =
  | {
      value: number | undefined;
      onChange: (product: ProductEntity | undefined) => void;
      multiple?: false;
      queryEnabled?: boolean;
    }
  | {
      value: number[];
      onChange: (product: ProductEntity[]) => void;
      multiple: true;
      queryEnabled?: boolean;
    };

export const ProductSelect: React.FC<ProductSelectProps> = (props) => {
  const [search, setSearch] = React.useState("");

  const { queryEnabled = true } = props;

  const productsQuery = useQuery(
    ["products", { search }],
    () => products.getList({ search }),
    { enabled: queryEnabled },
  );

  const findProduct = (id: number | string) =>
    productsQuery.data?.results.find((p) => p.id === id);

  const onChange = props.onChange as (
    product: ProductEntity | ProductEntity[] | undefined,
  ) => void;

  return (
    <Select
      value={props.value}
      placeholder="Select product"
      onChange={(id) => {
        onChange(
          id instanceof Array
            ? (id.map(findProduct).filter((_) => _) as ProductEntity[])
            : findProduct(id),
        );
      }}
      loading={productsQuery.isLoading}
      mode={props.multiple ? "multiple" : undefined}
    >
      <Select.Search
        placeholder="Search in products"
        value={search}
        onSearch={setSearch}
      />
      {productsQuery.isLoading && <Loader.Inline />}
      <Select.Options>
        {productsQuery.data?.results.map((p) => (
          <Select.Options.Item key={p.id} value={p.id}>
            <Space>
              <div className="icon-image">
                <img src={p?.featured_image?.url} alt={p?.title || ""} />
              </div>
              {p?.title}
              <em>({p?.brand?.title})</em>

              <Label
                status={p.is_free ? "success" : "warning"}
                text={p.is_free ? "is free" : "is paid"}
                type="fill"
              />
            </Space>
          </Select.Options.Item>
        ))}
      </Select.Options>
    </Select>
  );
};
