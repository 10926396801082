import { useLocation } from "react-router-dom";
import { matchPath } from "react-router";

export const useActiveLocation = () => {
  const location = useLocation();
  const isCurrentURL = (url: string | string[], config = {}): boolean => {
    return !!matchPath(location.pathname, {
      path: url,
      ...config,
    });
  };
  return { isCurrentURL };
};
