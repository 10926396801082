import { useEffect } from "react";
import { Button, Modal, Space, Icon, Form, Input, useForm } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";

import { faq } from "api";
import { AlertErrors } from "components";
import { FaqEntity } from "types";
import { QuestionFormBodyValues } from "features/faq/types";

import "react-quill/dist/quill.snow.css";
//import inline styles for editor
const AlignStyle = Quill.import("attributors/style/align");
const BackgroundStyle = Quill.import("attributors/style/background");
const ColorStyle = Quill.import("attributors/style/color");
const Size = Quill.import("attributors/style/size");

//custom font size for editor
const fontSize = ["14px", "16px", "20px", "24px", "30px"];
Size.whitelist = fontSize;

//register inline style for editor
Quill.register("modules/imageUploader", ImageUploader);
Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(Size, true);

interface Props {
  open: boolean;
  onCancel: () => void;
  categoryId: number;
  activeQuestion: FaqEntity | null;
}

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      {
        size: fontSize,
      },
    ],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      { align: "" },
      { align: "center" },
      { align: "right" },
      { align: "justify" },
    ],
    ["link", "image"],
    [{ color: [] }, { background: [] }],
    ["clean"],
  ],
  imageUploader: {
    upload: async (file: any) => {
      const { url } = await faq.uploadImage(file);
      return url;
    },
  },
};

const FaqQuestionForm = ({
  open,
  onCancel,
  categoryId,
  activeQuestion,
}: Props) => {
  const [form] = useForm();
  const queryClient = useQueryClient();

  const mutateFn = (question: QuestionFormBodyValues) =>
    activeQuestion
      ? faq.updateQuestion(activeQuestion?.id || 0, question)
      : faq.createQuestion(question);

  const { mutate, isLoading, error } = useMutation<
    FaqEntity,
    AxiosError,
    QuestionFormBodyValues
  >((question) => mutateFn(question), {
    onSuccess: () => {
      onCancel();
      queryClient.invalidateQueries("faq-questions");
    },
  });

  useEffect(() => {
    if (activeQuestion) {
      form.setFieldsValue(activeQuestion);
    }
  }, [activeQuestion, form]);

  const handleForm = ({ title, description }: QuestionFormBodyValues) => {
    mutate({
      title,
      description: description,
      category_id: categoryId,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title={`${activeQuestion ? "Edit" : "Add"} question`}
      size="large"
      className="faq-modal"
    >
      <Modal.Content>
        <AlertErrors error={error!} />
        <Form form={form} onFinish={handleForm}>
          <Form.Field
            name="title"
            label="Question"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Field>
          <Form.Field
            name="description"
            label="Answer"
            rules={[
              {
                required: true,
              },
            ]}
            initialValue={""}
          >
            <ReactQuill value={""} theme="snow" modules={modules} />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>Cancel</Button>

          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            prefix={<Icon type="check" />}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default FaqQuestionForm;
