import { Button, Card, Loader, Space, Label } from "ebs-design";
import { useQuery } from "react-query";
import { useSetState } from "react-use";
import { AxiosError } from "axios";

import { giftCovers } from "api";
import { AlertErrors, Icon } from "components";
import { NomenclatureItem, NomenclatureItemType } from "types";

import GiftCoversFormModal from "features/gift-covers/GiftCoversFormModal";
import GiftCoversConfirmModal from "features/gift-covers/GiftCoversConfirmModal";
import { ModuleAction } from "features/modules-permissions";

interface ModalState {
  visible: boolean;
  giftId?: number | null;
  activeGift?: NomenclatureItem | null;
}

const tutorialsQueryParams = {
  type: NomenclatureItemType.WRAPPING_IMAGE,
};

const defaultDeleteGift = {
  visible: false,
  tutorialId: null,
};

const defaultGiftModal = {
  visible: false,
  activeGift: null,
};

const GiftCoversList = () => {
  const [giftModal, setGiftModal] = useSetState<ModalState>(defaultGiftModal);

  const [deleteGift, setDeleteGift] =
    useSetState<ModalState>(defaultDeleteGift);

  const { data, isFetching, isError, error } = useQuery<
    NomenclatureItem[],
    AxiosError
  >(["gift-covers", tutorialsQueryParams], () =>
    giftCovers.get(tutorialsQueryParams),
  );

  return (
    <div className="gift-covers">
      <Loader loading={isFetching}>
        {isError ? (
          <AlertErrors error={error} />
        ) : (
          <>
            <ModuleAction module="cms">
              <Button
                prefix={<Icon type="plus" />}
                className="gift-covers__add"
                onClick={() => setGiftModal({ visible: true })}
              >
                Add gift cover
              </Button>
            </ModuleAction>
            {data?.length ? (
              <div className="gift-covers__wrapper">
                {data.map((item) => {
                  return (
                    <Card key={item.id} className="gift-covers__card">
                      <Card.Body>
                        {item.image && (
                          <img
                            className="gift-covers__image"
                            src={item.image.url}
                            alt="gift-covers"
                          />
                        )}
                      </Card.Body>
                      <ModuleAction module="cms">
                        <Card.Footer bordered>
                          <Space justify="space-between">
                            <Label
                              status={item.is_active ? "success" : "danger"}
                              text={item.is_active ? "active" : "inactive"}
                              type="fill"
                            />
                            <Space>
                              <Button
                                prefix={<Icon type="delete" />}
                                type="ghost"
                                onClick={() =>
                                  setDeleteGift({
                                    visible: true,
                                    giftId: item.id,
                                  })
                                }
                              >
                                Delete
                              </Button>
                              <Button
                                prefix={<Icon type="edit" />}
                                onClick={() =>
                                  setGiftModal({
                                    visible: true,
                                    activeGift: item,
                                  })
                                }
                              >
                                Edit
                              </Button>
                            </Space>
                          </Space>
                        </Card.Footer>
                      </ModuleAction>
                    </Card>
                  );
                })}
              </div>
            ) : (
              <p className="no-items">No Gifts</p>
            )}
            {giftModal.visible && (
              <GiftCoversFormModal
                open={giftModal.visible}
                onCancel={() => setGiftModal(defaultGiftModal)}
                activeGift={giftModal.activeGift}
              />
            )}
            {deleteGift.visible && (
              <GiftCoversConfirmModal
                giftId={deleteGift.giftId}
                visible={deleteGift.visible}
                onCancel={() => {
                  setDeleteGift(defaultDeleteGift);
                }}
              />
            )}
          </>
        )}
      </Loader>
    </div>
  );
};

export default GiftCoversList;
