import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Space } from "ebs-design";

import {
  AlertErrors,
  Pagination,
  Table,
  TableEmptyText,
  TableRowLink,
} from "components";
import { usePagination } from "hooks";
import { Results, UserProfileEntity } from "types";

import { coupons } from "api/coupons";
import { couponUsersTableColumns } from "./couponUsersTableColumns";

interface CouponUsersTableProps {
  couponId: number;
}

export const CouponUsersTable = ({ couponId }: CouponUsersTableProps) => {
  const pagination = usePagination();
  const { limit, page } = pagination;

  const queryParams = {
    page,
    limit,
  };

  const usersQuery = useQuery<Results<UserProfileEntity>, AxiosError>(
    ["coupon-users", queryParams],
    () => coupons.getUsers(couponId, queryParams),
    {
      enabled: !!page,
    },
  );

  const tableColumns = React.useMemo(() => couponUsersTableColumns(), []);

  return (
    <>
      <AlertErrors error={usersQuery.error} />
      {!usersQuery.isError && (
        <>
          <Table
            data={usersQuery.data?.results}
            columns={tableColumns}
            title={() => (
              <h3 className="pady-6 padx-4">Users that have redeemed</h3>
            )}
            footer={() => (
              <div className="padx-8 pady-4">
                <Space justify="end">
                  <Pagination
                    pagination={pagination}
                    results={usersQuery.data}
                  />
                </Space>
              </div>
            )}
            emptyText={<TableEmptyText isLoading={usersQuery.isLoading} />}
            variants={[
              "bordered",
              "elevated",
              "dense",
              "stripped-even",
              "highlight-hover",
            ]}
            scroll={{ x: true }}
            rowKey="id"
            components={{
              body: {
                row: (props: any) => (
                  <TableRowLink {...props} makeUrl={(id) => `/users/${id}/`} />
                ),
              },
            }}
          />
        </>
      )}
    </>
  );
};
