import * as React from "react";
import { Container } from "ebs-design";
import { FormData } from "components/FormWizard";
import { FormWizard } from "components/FormWizard";
import UsersTarget from "../UsersTarget";
import Products from "../Products";
import OtherData from "../SendGift";

const GiftForm = () => {
  const steps = React.useMemo(
    () => [
      {
        title: "Select Users",
        component: <UsersTarget />,
      },
      {
        title: "Select Gift",
        component: <Products />,
        onCheck: (data: FormData) => !data?.users_id,
      },
      {
        title: "Send Gift",
        component: <OtherData />,
        onCheck: (data: FormData) => !data?.product_id,
      },
    ],
    [],
  );

  return (
    <Container>
      <FormWizard steps={steps} currentStep={0} />
    </Container>
  );
};

export default GiftForm;
