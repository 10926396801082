import { useQuery } from "react-query";
import { useSetState } from "react-use";
import { Button, Collapse, Loader, Space, Label } from "ebs-design";

import { AlertErrors, Icon } from "components";
import CampaignModalForm from "features/campaigns/CampaignModalForm";
import DeleteCampaignModal from "features/campaigns/DeleteCampaignModal";

import { campaigns } from "api";
import { Campaign } from "types";

interface ModalState {
  visible: boolean;
  campaignId?: number | null;
  activeCampaign?: Campaign | null;
}

const defaultModal = {
  visible: false,
  activeCampaign: null,
};

const defaultDeleteModal = {
  visible: false,
  campaignId: null,
};

const CampaignsList = () => {
  const [campaignModal, setCampaignModal] = useSetState<ModalState>(
    defaultModal,
  );

  const [deleteModal, setDeleteModal] = useSetState<ModalState>(
    defaultDeleteModal,
  );

  const { data, isFetching, isError, error } = useQuery(
    ["campaigns-list"],
    () => campaigns.getList(),
  );

  return (
    <Loader loading={isFetching}>
      <div className="campaigns">
        {isError ? (
          <AlertErrors error={error} />
        ) : (
          <>
            <Button
              className="campaigns__add-campaign"
              prefix={<Icon type="plus" />}
              onClick={() => {
                setCampaignModal({
                  visible: true,
                });
              }}
            >
              Add Campaign
            </Button>

            <div className="pady-2"></div>
            {data?.length ? (
              <Collapse.Group className="campaigns__collapse">
                {data?.map((campaign: Campaign) => {
                  return (
                    <Collapse collapsed key={campaign.id}>
                      <Collapse.Header className="campaigns__collapse__header">
                        <Space justify="space-between">
                          <Space>
                            {campaign.name}

                            <Label
                              status={campaign.is_active ? "success" : "danger"}
                              text={campaign.is_active ? "active" : "inactive"}
                              type="fill"
                            />
                          </Space>

                          <div>
                            <span
                              className="campaigns__btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeleteModal({
                                  visible: true,
                                  campaignId: campaign.id,
                                });
                              }}
                            >
                              <Button
                                size="small"
                                className="ebs-button__action"
                              >
                                <Icon type="delete" />
                              </Button>
                            </span>

                            <span
                              className="campaigns__btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                setCampaignModal({
                                  visible: true,
                                  activeCampaign: campaign,
                                });
                              }}
                            >
                              <Button
                                size="small"
                                className="ebs-button__action"
                              >
                                <Icon type="edit" />
                              </Button>
                            </span>
                          </div>
                        </Space>
                      </Collapse.Header>

                      <Collapse.Body>
                        {campaign.description || ""}
                      </Collapse.Body>
                    </Collapse>
                  );
                })}
              </Collapse.Group>
            ) : (
              <p className="no-items">No campaigns</p>
            )}

            {campaignModal.visible && (
              <CampaignModalForm
                open={campaignModal.visible}
                onCancel={() => setCampaignModal(defaultModal)}
                activeCampaign={campaignModal.activeCampaign}
              />
            )}

            {deleteModal.visible && (
              <DeleteCampaignModal
                campaignId={deleteModal.campaignId}
                visible={deleteModal.visible}
                onCancel={() => setDeleteModal(defaultDeleteModal)}
              />
            )}
          </>
        )}
      </div>
    </Loader>
  );
};

export default CampaignsList;
