import React from "react";
import { useQuery } from "react-query";
import {
  Card,
  Loader,
  Space,
  Button,
  Row,
  Col,
  Icon as SvgIcon,
} from "ebs-design";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { useCopyToClipboard } from "react-use";
import { AlertErrors } from "components";
import { orders } from "api";
import { OrderEntity } from "types";
import { getCurrencySymbol } from "utils";
import OrderProfiles from "../OrderProfiles";
import CheckBalance from "../CheckBalance";
import { LOCALE } from "app-constants";

const OrderDetails = () => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const [showBalance, setShowBalance] = React.useState(false);
  const { id } = useParams<{ id: string }>();

  const {
    data: order,
    isLoading,
    isError,
    error,
  } = useQuery<OrderEntity, AxiosError>(
    ["order", id],
    () => orders.getById(id),
    {
      enabled: !!id,
    },
  );

  const {
    data: balance,
    refetch,
    isLoading: loadingBalance,
    error: errorBalance,
  } = useQuery(["check-balance", id], () => orders.getBalance(id), {
    enabled: false,
    retry: false,
  });

  const checkBalance = async () => {
    await refetch();
    setShowBalance(true);
  };

  const details = React.useMemo(
    () => [
      [
        [`${order?.user?.first_name} ${order?.user?.last_name}`, "Sender Name"],
        [
          `${order?.target?.first_name || "---"} ${
            order?.target?.last_name || "---"
          }`,
          "Receiver Name ",
        ],
      ],
      [
        [order?.product?.brand?.title, "Brand"],
        [order?.product?.title, "Product"],
      ],

      [
        [
          (order?.price && `${getCurrencySymbol(undefined)} ${order?.price}`) ||
            "---",
          "Price",
        ],
        [`${order?.url || "---"}`, "URL"],
      ],
      [
        [
          (order?.profit != null &&
            `${getCurrencySymbol(order?.product?.currency?.code_name!)} ${
              order?.profit
            }`) ||
            "---",
          "Profit",
        ],
        [
          (order?.fee != null &&
            `${getCurrencySymbol(order?.product?.currency?.code_name!)}
            ${order?.fee}`) ||
            "---",
          "Discount Amount for Brand",
        ],
      ],
    ],
    [order],
  );

  return (
    <>
      <AlertErrors error={error} />
      <Loader loading={isLoading} />
      {!isLoading && !isError && (
        <>
          <Card>
            <Card.Body>
              <Space justify="space-between">
                <Space>
                  <h3>
                    Order ID: <span className="ft-200">{order?.id}</span>
                  </h3>
                  <h3>
                    Date:{" "}
                    <span className="ft-200">
                      {order?.timestamp &&
                        new Date(order?.timestamp).toLocaleString(LOCALE)}
                    </span>
                  </h3>
                </Space>
                {order?.url && (
                  <Button
                    type="ghost"
                    onClick={() => copyToClipboard(order?.url)}
                    prefix={<SvgIcon type="link" />}
                  >
                    {state.value ? "Copied" : "Copy Gift URL"}
                  </Button>
                )}
              </Space>
            </Card.Body>
          </Card>
          <div className="pady-3"></div>

          <OrderProfiles order={order} />

          <div className="pady-3"></div>
          <Card>
            <Card.Header>
              <h3>Details</h3>
            </Card.Header>
            <Card.Body className="pt-49">
              <Row>
                {details.map((col, index) => (
                  <Col key={index}>
                    {col.map((item, index) => (
                      <div className="my-4" key={index}>
                        <span className="small-text color-secondary">
                          {item[1]}
                        </span>
                        <h4 className="fw-400">{item[0]}</h4>
                      </div>
                    ))}
                  </Col>
                ))}
              </Row>

              <CheckBalance
                showBalance={showBalance}
                setShowBalance={setShowBalance}
                balance={balance}
                error={errorBalance}
              />
            </Card.Body>

            <Card.Footer>
              <Space>
                <Button
                  loading={loadingBalance}
                  type="ghost"
                  onClick={checkBalance}
                  prefix={
                    <SvgIcon
                      type="refresh"
                      className="icon-size"
                      model="bold"
                    />
                  }
                >
                  Check balance
                </Button>
              </Space>
            </Card.Footer>
          </Card>
        </>
      )}
    </>
  );
};

export default OrderDetails;
