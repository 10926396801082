import { Form, Textarea } from "ebs-design";

import { Container } from "components";
import { NomenclatureItem } from "types";

import { LangaugeKeyEditItem } from ".";

export const languageEditKeyItemRenderer =
  (name: string) => (content: string, record: NomenclatureItem) =>
    (
      <Container fixed mauto={false} size={240}>
        <LangaugeKeyEditItem
          value={<pre className="normalized-pre">{content}</pre>}
        >
          <Form.Field name={[record.id || 0, name]} initialValue={content}>
            <Textarea />
          </Form.Field>
        </LangaugeKeyEditItem>
      </Container>
    );
