import { apiAxios } from "api";
import { ApiQueryType, Company, Results } from "types";
import { defaultQueryLimit } from "utils";

import { stringifyUrl } from "./utils";

export const companies = {
  getList: async (query: ApiQueryType = {}) => {
    query.per_page = query.limit || defaultQueryLimit;
    const url = stringifyUrl(`/companies/`, query);

    const { data } = await apiAxios.get<Results<Company>>(url);
    return data;
  },
  getById: async (id: number) => {
    const { data } = await apiAxios.get<Company>(`/companies/${id}/`);
    return data;
  },
  patch: async (id: number, company: Company) => {
    const { data } = await apiAxios.patch<Company>(
      `/companies/${id}/`,
      company,
    );
    return data;
  },
};
