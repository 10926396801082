import { Space } from "ebs-design";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { ConfirmModal, AlertErrors } from "components";
import { categories } from "api";
import { CategoryEntity } from "types";

interface Props {
  visible: boolean;
  category: CategoryEntity | null;
  onCancel: () => void;
  onSuccess?: () => void;
}

const CategoryDeleteModal = ({
  visible,
  category,
  onCancel,
  onSuccess,
}: Props) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation<
    unknown,
    AxiosError,
    number
  >(categories.deleteCategory, {
    onSuccess: () => {
      if (typeof onSuccess === "function") {
        onSuccess();
      }

      onCancel();
      queryClient.invalidateQueries("categories");
    },
  });

  return visible ? (
    <ConfirmModal
      open={visible}
      loading={isLoading}
      onConfirm={() => mutate(category!.id)}
      onClose={onCancel}
      onCancel={onCancel}
    >
      {isError ? (
        <AlertErrors error={error} />
      ) : (
        <Space align="center" justify="center">
          <p>
            This category have
            <strong className="category-delete-count">
              {category?.products_count}
            </strong>
            products, are you sure that you want to delete it?
          </p>
        </Space>
      )}
    </ConfirmModal>
  ) : null;
};
export default CategoryDeleteModal;
