import React from "react";

import { MapType } from "types";

import { ComponentSize } from "./types";

export interface ContainerProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "size"> {
  size?: ComponentSize | number;
  fixed?: boolean;
  mauto?: boolean;
}

const containerSizeMap: MapType<ComponentSize, number> = {
  sm: 500,
  md: 800,
  lg: 1200,
};

export const Container: React.FC<ContainerProps> = ({
  size = "md",
  fixed = false,
  mauto = true,
  children,
  style: propsStyles,
  ...props
}) => {
  size = typeof size === "string" ? containerSizeMap[size] : size;
  const styles = {
    [fixed ? "width" : "maxWidth"]: size,
    margin: mauto ? "0 auto" : "",
  };
  Object.assign(styles, propsStyles);

  return (
    <div style={styles} {...props}>
      {children}
    </div>
  );
};
