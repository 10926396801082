import React from "react";
import ReactPaginate from "react-paginate";

import { Space, ButtonGroup, Button } from "ebs-design";

import { Icon } from "./";
import { UsePaginationResult } from "hooks";
import { Results } from "types";
import { defaultLimitChoice } from "utils";

export interface PaginationProps {
  pagination: UsePaginationResult;
  results?: Results<unknown>;
  limitChoice?: number[];
}

export const Pagination = ({
  pagination,
  results,
  limitChoice = defaultLimitChoice,
}: PaginationProps) => {
  const [pageCount, setPageCount] = React.useState(0);

  React.useEffect(() => {
    results !== undefined &&
      setPageCount(
        results.count ? Math.ceil(results.count / pagination.limit) : 0,
      );
  }, [pagination.limit, results]);

  return (
    <>
      {pageCount > 0 && (
        <Space size={20} align="center">
          <Space size={10} align="center">
            <span>Limit</span>
            <ButtonGroup>
              {limitChoice.map((choice) => (
                <Button
                  key={choice}
                  size={"small"}
                  type={choice === pagination.limit ? "primary" : "ghost"}
                  onClick={() => pagination.setLimit(choice)}
                >
                  {choice}
                </Button>
              ))}
            </ButtonGroup>
          </Space>

          <div
            style={results ? {} : { cursor: "pointer", pointerEvents: "none" }}
          >
            <ReactPaginate
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={<Icon type="left" />}
              nextLabel={
                <Icon type="left" style={{ transform: "rotate(180deg)" }} />
              }
              containerClassName="pagination"
              nextClassName="pagination__move"
              previousClassName="pagination__move"
              breakClassName="pagination__break"
              pageLinkClassName="pagination__page"
              forcePage={pagination.page - 1}
              onPageChange={({ selected }) => pagination.setPage(selected + 1)}
            />
          </div>
        </Space>
      )}
    </>
  );
};
