import { Link } from "react-router-dom";
import { Button, Space } from "ebs-design";

const ErrorMessage = () => {
  return (
    <Space
      align="center"
      justify="center"
      direction="vertical"
      className="not-found"
    >
      <h1 className="not-found__title">Oops Page not found!</h1>
      <p className="not-found__description">
        We are very sorry for inconvenience. It looks like you're trying to
        access a page that has been deleted or never even existed.
      </p>
      <Link to="/home">
        <Button>Back to Home</Button>
      </Link>
    </Space>
  );
};

export default ErrorMessage;
