import { capitalize } from "utils";
import { FiltersSource, FilterType, StatusOrder } from "types";

export const ordersFilter: FiltersSource = [
  {
    key: "status",
    name: "Status",
    type: FilterType.enum,
    values: Object.keys(StatusOrder).map((status) => ({
      text: capitalize(status),
      value: status,
    })),
  },
];
