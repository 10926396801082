import { Link } from "react-router-dom";
import { Sidebar } from "ebs-design";
import { Icon } from "components/Icon/Icon";
import { useActiveLocation } from "hooks";

const Menu = () => {
  const { isCurrentURL } = useActiveLocation();

  return (
    <Sidebar>
      <Sidebar.TopMenu>
        <Link to="/home">
          <Sidebar.Item
            text="Home"
            active={isCurrentURL(["/", "/home"], { exact: true })}
            prefix={<Icon type={"home"} />}
          />
        </Link>
        <Link to="/users">
          <Sidebar.Item
            text="Users"
            active={isCurrentURL("/users")}
            prefix={<Icon type={"user"} />}
          />
        </Link>
        <Link to="/cohorts">
          <Sidebar.Item
            text="Cohorts"
            active={isCurrentURL("/cohorts")}
            prefix={<Icon type={"friends"} />}
          />
        </Link>
        <Link to="/products">
          <Sidebar.Item
            text="Products"
            active={isCurrentURL("/products")}
            prefix={<Icon type={"store"} />}
          />
        </Link>
        <Link to="/gifts">
          <Sidebar.Item
            text="Gifts"
            active={isCurrentURL(["/gifts", "/gift-operation"])}
            prefix={<Icon type={"prsnts2"} />}
          />
        </Link>
        <Link to="/settings">
          <Sidebar.Item
            text="App Settings"
            active={isCurrentURL("/settings")}
            prefix={<Icon type={"settings"} />}
          />
        </Link>
        <Link to="/language">
          <Sidebar.Item
            text="Language"
            active={isCurrentURL("/language")}
            prefix={<Icon type={"message"} />}
          />
        </Link>
        {/* <Link to="/analytics">
          <Sidebar.Item
            text="Analytics"
            active={isCurrentURL("/analytics")}
            prefix={<Icon type={"home"} />}
          />
        </Link> */}
        {/* <Link to="/notifications">
          <Sidebar.Item
            text="Notifications"
            active={isCurrentURL("/notifications")}
            prefix={<Icon type={"info"} />}
          />
        </Link> */}
        <Link to="/faq">
          <Sidebar.Item
            text="FAQ's"
            active={isCurrentURL("/faq")}
            prefix={<Icon type={"help"} />}
          />
        </Link>
        <Link to="/transactions">
          <Sidebar.Item
            text="Transactions"
            active={isCurrentURL("/transactions")}
            prefix={<Icon type={"card"} />}
          />
        </Link>
        <Link to="/cms/tutorials">
          <Sidebar.Item
            text="CMS"
            active={isCurrentURL(["/cms"])}
            prefix={<Icon type={"camera"} />}
          />
        </Link>
        <Link to="/categories">
          <Sidebar.Item
            text="Categories"
            active={isCurrentURL(["/categories", "/brands"])}
            prefix={<Icon type={"my-prsnts2"} />}
          />
        </Link>
        <Link to="/coupons">
          <Sidebar.Item
            text="Coupons"
            active={isCurrentURL(["/coupons"])}
            prefix={<Icon type={"my-prsnts2"} />}
          />
        </Link>
        <Link to="/business-products">
          <Sidebar.Item
            text="Business products"
            active={isCurrentURL(["/business-products"])}
            prefix={<Icon type={"store"} />}
          />
        </Link>
        <Link to="/campaigns">
          <Sidebar.Item
            text="Campaigns"
            active={isCurrentURL(["/campaigns", "/campaigns-tier"])}
            prefix={<Icon type={"text"} />}
          />
        </Link>
      </Sidebar.TopMenu>
    </Sidebar>
  );
};

export default Menu;
