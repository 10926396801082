import { useMemo, useCallback } from "react";
import { Button, ListGroup, Loader, Space } from "ebs-design";
import { useQuery } from "react-query";
import { useSetState } from "react-use";
import { AxiosError } from "axios";

import { categories } from "api";
import { AlertErrors, Icon } from "components";
import { CategoryEntity, Results } from "types";

import CategoryFormModal from "features/categories/CategoryFormModal";
import CategoryItem from "features/categories/CategoryItem";
import CategoryDeleteModal from "features/categories/CategoryDeleteModal";
import { ModalCategoryState } from "features/categories/types";
import { ModuleAction } from "features/modules-permissions";

interface Props {
  activeCategory: CategoryEntity | null;
}

const defaultState: ModalCategoryState = {
  title: "",
  visible: false,
  hasParent: false,
  category: null,
  onCancel: () => {},
};

const defaultConfirmState: {
  visible: boolean;
  category: CategoryEntity | null;
} = {
  visible: false,
  category: null,
};

const SubcategoriesList = ({ activeCategory }: Props) => {
  const [modal, setModal] = useSetState(defaultState);
  const [confirmModal, setConfirmModal] = useSetState(defaultConfirmState);

  const { data, isLoading, isError, error } = useQuery<
    Results<CategoryEntity>,
    AxiosError
  >(
    ["categories", activeCategory?.id, "children"],
    () => categories.getCategoriesChildren(activeCategory!.id),
    {
      enabled: !!activeCategory?.id,
    },
  );

  const categoriesSelect = useMemo(
    () =>
      data?.results.map((item) => {
        return {
          value: item.id,
          text: item.title,
        };
      }),
    [data?.results],
  );

  const handleEdit = useCallback(
    (category) => {
      setModal({
        category,
        visible: true,
        title: "Edit subcategory",
      });
    },
    [setModal],
  );

  const handleDelete = useCallback(
    (category) => setConfirmModal({ visible: true, category }),
    [setConfirmModal],
  );

  return (
    <div className="categories__wrapper">
      {isError ? (
        <AlertErrors error={error} />
      ) : (
        <Loader loading={isLoading}>
          <ListGroup className="categories__list">
            {data?.results.length ? (
              data.results.map((category: CategoryEntity) => {
                return (
                  <CategoryItem
                    key={category.id}
                    category={category}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    isActive={category?.id === activeCategory?.id}
                  />
                );
              })
            ) : !activeCategory ? (
              <div className="categories__item__wrapper">
                <p className="no-category">There's no category selected.</p>
              </div>
            ) : (
              <ListGroup.Item className="categories__item">
                <div className="categories__item__wrapper">
                  <p className="no-category">No subcategories</p>
                </div>
              </ListGroup.Item>
            )}
          </ListGroup>

          {activeCategory && (
            <ModuleAction module="categories">
              <Space className="categories__add">
                <Button
                  type="ghost"
                  className="categories__add__button"
                  onClick={() => {
                    setModal({
                      visible: true,
                      hasParent: true,
                      category: activeCategory,
                      title: "Add subcategory",
                    });
                  }}
                >
                  <Icon type="plus" /> Add subcategory
                </Button>
              </Space>
            </ModuleAction>
          )}
          {modal.visible && (
            <CategoryFormModal
              title={modal.title}
              visible={modal.visible}
              hasParent={modal.hasParent}
              category={modal.category}
              onCancel={() => setModal(defaultState)}
              categoriesSelect={categoriesSelect}
            />
          )}

          {confirmModal.visible && (
            <CategoryDeleteModal
              category={confirmModal.category}
              visible={confirmModal.visible}
              onCancel={() => setConfirmModal(defaultConfirmState)}
            />
          )}
        </Loader>
      )}
    </div>
  );
};

export default SubcategoriesList;
