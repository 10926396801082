import React from "react";
import { useQuery } from "react-query";

import { Space, Select, Button } from "ebs-design";

import { categories } from "api";
import { Icon } from "components";
import { mapToSelectOptions } from "utils";

import { ProductCategoriesFieldItem, SubcategorySelect } from ".";

import { deserializeFormValue, serializeToFormValue } from "../utils";

export interface ProductCategoriesFieldContentProps {
  value?: ProductCategoriesFieldValue;
  onChange?: (v: ProductCategoriesFieldValue) => void;
}

export type ProductCategoriesFieldValue = ProductCategoriesFieldItem[];

export interface CategoryListItem {
  id?: number;
  // sub categories
  subs: number[];
}

export const ProductCategoriesFieldContent = ({
  value,
  onChange,
}: ProductCategoriesFieldContentProps) => {
  const categoriesQueryParams = {
    parent__isnull: true,
  };
  const categoriesQuery = useQuery(["categories", categoriesQueryParams], () =>
    categories.getList(categoriesQueryParams),
  );

  const categoriesList = deserializeFormValue(value);

  const setCategoriesList = (v: CategoryListItem[]) =>
    onChange && onChange(serializeToFormValue(v));

  const addCategoryHandler = () => {
    setCategoriesList([...categoriesList, { subs: [] }]);
  };

  const removeCategoryHandler = (idx?: number) => {
    setCategoriesList(categoriesList.filter((_, i) => i !== idx));
  };

  const categoryChangeHandler = (idx: number, id?: number) => {
    setCategoriesList(
      categoriesList.map((c, i) => (i === idx ? { ...c, id, subs: [] } : c)),
    );
  };

  const subCategoryChangeHandler = (
    idx: number | undefined,
    subs: number[],
  ) => {
    setCategoriesList(
      categoriesList.map((c, i) => (i === idx ? { ...c, subs } : c)),
    );
  };

  const categoriesOptions = mapToSelectOptions(categoriesQuery.data?.results);

  return (
    <>
      {categoriesList.map((c, idx) => (
        <React.Fragment key={idx}>
          <Space align="start">
            <Select
              className="min-width-7"
              value={c.id}
              onChange={((id: number) => categoryChangeHandler(idx, id)) as any}
              options={categoriesOptions}
              loading={categoriesQuery.isLoading}
              placeholder="Category"
              isClearable
            />
            {c.id && (
              <SubcategorySelect
                categoryId={c.id}
                value={c.subs}
                onChange={(subs) => subCategoryChangeHandler(idx, subs)}
              />
            )}
            <span
              className="cursor-pointer"
              onClick={() => removeCategoryHandler(idx)}
            >
              <Icon style={{ color: "black" }} type="cross" />
            </span>
          </Space>
          <div className="pady-2"></div>
        </React.Fragment>
      ))}
      <Button prefix={<Icon type="plus" />} onClick={addCategoryHandler}>
        Add category
      </Button>
    </>
  );
};
