import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import {
  Loader,
  Space,
  Checkbox,
  Card,
  InputSearch,
  Button,
  SortBy,
} from "ebs-design";
import { ColumnType } from "rc-table/lib/interface";
import FormWizardContext from "components/FormWizard/FormWizardContext";
import { usePagination } from "hooks";
import { Results, ProductEntity } from "types";
import { products } from "api";
import { Table, Pagination, AlertErrors, Icon } from "components";
import { extractOrderingOptions, getCurrencySymbol } from "utils";

const Products = () => {
  const [checked, setChecked] = React.useState<number | null>();

  const ctx = React.useContext(FormWizardContext);
  const pagination = usePagination();
  const { limit, page } = pagination;

  const [userId] = useQueryParam("users_id", withDefault(StringParam, ""));
  const [cohortId] = useQueryParam("cohort_id", withDefault(StringParam, ""));

  const [search, setSearch] = React.useState("");
  const [ordering, setOrdering] = React.useState("");

  const queryParams = {
    page,
    limit,
    search,
    ordering,
  };

  const {
    data: productsList,
    isLoading,
    error,
  } = useQuery<Results<ProductEntity>, AxiosError>(
    ["products", queryParams],
    () => products.getList(queryParams),
    {
      enabled: !!ctx.data,
    },
  );

  const tableColumns = React.useMemo<ColumnType<ProductEntity>[]>(
    () => [
      {
        dataIndex: "id",
        width: 14,
        render: (value: number) => {
          return (
            <>
              <div
                className="wrap-cell"
                onClick={(e) => e.stopPropagation()}
              ></div>
              <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checked={checked === value}
                  onChange={() => checkId(value)}
                />
              </div>
            </>
          );
        },
      },
      {
        title: "Products",
        dataIndex: "title",
      },
      {
        title: "Price",
        dataIndex: "price",
        ordering: "price",
        render: (value: number, record: ProductEntity) =>
          (value != null &&
            `${getCurrencySymbol(record.currency?.code_name)} ${value}`) ||
          "---",
      },
      {
        title: "Supplier Cost",
        dataIndex: "",
      },
      {
        title: "Brand",
        dataIndex: ["brand", "title"],
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checked],
  );

  const checkId = (id: number) => {
    checked === id ? setChecked(null) : setChecked(id);
  };

  const saveProduct = () => {
    ctx.nextStep({ ...ctx.data, product_id: checked });
  };

  const orderingOptions = React.useMemo(
    () => extractOrderingOptions(tableColumns),
    [tableColumns],
  );

  React.useEffect(() => {
    if (ctx?.data?.product_id) {
      setChecked(ctx?.data?.product_id);
    }
  }, [ctx?.data?.product_id]);

  return (
    <>
      <AlertErrors error={error} />
      {ctx.data && (
        <>
          <div className="pady-3" />
          <Card>
            <Card.Header>
              <Space justify="space-between">
                <div onClick={(e) => e.stopPropagation()}>
                  <Space>
                    <div className="fw-500">
                      Products ({productsList?.count || 0})
                    </div>
                    <InputSearch
                      placeholder="Search product"
                      value={search}
                      onSearch={(value: string) => setSearch(value)}
                    />
                    <SortBy
                      value={ordering}
                      onChange={(value: string) => setOrdering(value)}
                      options={orderingOptions}
                    />
                  </Space>
                </div>
              </Space>
            </Card.Header>
          </Card>
          <div className="pady-3" />
          <Table
            data={productsList?.results}
            columns={tableColumns}
            footer={() => (
              <div className="padx-8 pady-4">
                <Space justify="end">
                  <Pagination pagination={pagination} results={productsList} />
                </Space>
              </div>
            )}
            emptyText={
              isLoading ? (
                <Loader loading />
              ) : (
                <Space className="pady-12" justify="center">
                  No data
                </Space>
              )
            }
            variants={["bordered", "elevated", "dense", "highlight-hover"]}
            scroll={{ x: true }}
            rowKey="id"
            onRow={(record) => {
              return {
                onClick: () => checkId(record.id),
              };
            }}
          />
          <div className="pady-3"></div>
          <Space justify="center">
            <Button
              type="ghost"
              prefix={<Icon type="friends" />}
              onClick={() => ctx.prevStep()}
              disabled={!!userId || !!cohortId}
            >
              Select Users
            </Button>
            <Button
              type="primary"
              disabled={!checked}
              prefix={<Icon type="status-sent" />}
              onClick={saveProduct}
            >
              Send Gift
            </Button>
          </Space>
        </>
      )}
    </>
  );
};

export default Products;
