import { ApiQueryType, Campaign, CampaignFormValue } from "types";
import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

export const campaigns = {
  getList: async (query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/management/campaigns/`, query);
    const { data } = await apiAxios.get<Campaign[]>(url);
    return data;
  },

  post: async (bodyData: CampaignFormValue) => {
    const { data } = await apiAxios.post("/management/campaigns/", bodyData);
    return data;
  },

  getById: async (id: number | null) => {
    const { data } = await apiAxios.get<Campaign>(
      `/management/campaigns/${id}/`,
    );
    return data;
  },

  patch: async (id: number, bodyData: CampaignFormValue) => {
    const { data } = await apiAxios.patch(
      `/management/campaigns/${id}/`,
      bodyData,
    );
    return data;
  },

  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/management/campaigns/${id}/`);
    return data;
  },
};
