import { Alert } from "ebs-design";

import { extractErrorMessages } from "utils";
import { NO_AVAILABLE_ERRORS_MESSAGE } from "app-constants";

export interface AlertErrorsProps {
  error?: any;
  alertsClassName?: string;
}

export const AlertErrors = ({
  error,
  alertsClassName = "mary-2",
}: AlertErrorsProps) => {
  if (!error) return null;
  const messages = extractErrorMessages(error);

  if (messages.length) {
    return (
      <>
        {messages.map((m, idx) => (
          <Alert
            key={`${m.title} - ${idx}`}
            className={alertsClassName}
            message={m.title}
            type="error"
          >
            {m.content}
          </Alert>
        ))}
      </>
    );
  }

  if (error.message) {
    return (
      <Alert className={alertsClassName} type="error">
        {error.message}
      </Alert>
    );
  }

  return (
    <Alert className={alertsClassName} type="error">
      {NO_AVAILABLE_ERRORS_MESSAGE}
    </Alert>
  );
};
