import React from "react";
import { useMutation, useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { AxiosError } from "axios";
import { useQueryParam, withDefault, StringParam } from "use-query-params";

import { ConfirmModal, AlertErrors } from "components";
import { useQueryPagination } from "hooks";
import { Results } from "types";
import { coupons } from "api/coupons";

import {
  CouponsDashboardStats,
  CouponsFilterCard,
  CouponsTable,
} from "../Coupons";

export const Coupons = () => {
  const [couponToDelete, setCouponToDelete] = React.useState<number>();

  const [search, setSearch] = useQueryParam(
    "search",
    withDefault(StringParam, ""),
  );

  const pagination = useQueryPagination();
  const { limit, page, setPage } = pagination;

  const queryParams = {
    limit,
    page,
    search,
  };

  const couponsQuery = useQuery<Results<any>, AxiosError>(
    ["coupons", queryParams],
    () => coupons.get(queryParams),
    {
      enabled: !!page,
    },
  );

  const deleteMutation = useMutation<unknown, AxiosError, number>(
    (id: number) => coupons.delete(id),
    {
      onSuccess: () => {
        setCouponToDelete(undefined);
        couponsQuery.refetch();
      },
    },
  );

  useUpdateEffect(() => setPage(1), [search]);

  return (
    <>
      <CouponsDashboardStats />
      <div className="pady-2"></div>
      <CouponsFilterCard {...{ search, setSearch, couponsQuery }} />
      <div className="pady-2"></div>
      <CouponsTable
        {...{ pagination, couponsQuery }}
        onDeleteClick={setCouponToDelete}
      />
      <ConfirmModal
        open={!!couponToDelete}
        onCancel={() => setCouponToDelete(undefined)}
        onConfirm={() => deleteMutation.mutate(couponToDelete || 0)}
        onClose={() => setCouponToDelete(undefined)}
        loading={deleteMutation.isLoading}
      >
        Are you sure to delete this copuon?
        <AlertErrors error={deleteMutation.error} />
      </ConfirmModal>
    </>
  );
};
