import {
  ApiQueryType,
  CouponEntity,
  CouponImportType,
  Results,
  UserProfileEntity,
} from "types";

import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

export const coupons = {
  get: async (query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/coupons/`, query);
    const { data } = await apiAxios.get<Results<CouponEntity>>(url);
    return data;
  },
  getById: async (id: number) => {
    const url = stringifyUrl(`/coupons/${id}/`);
    const { data } = await apiAxios.get<CouponEntity>(url);
    return data;
  },
  getUsers: async (id: number, query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/coupons/${id}/users/`, query);
    const { data } = await apiAxios.get<Results<UserProfileEntity>>(url);
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/coupons/${id}/`);
    return data;
  },
  patch: async (id: number, coupon: Partial<CouponEntity>) => {
    const { data } = await apiAxios.patch<CouponEntity>(
      `/coupons/${id}/`,
      coupon,
    );
    return data;
  },
  post: async (coupon: Partial<CouponEntity>) => {
    const { data } = await apiAxios.post<CouponEntity>(`/coupons/`, coupon);
    return data;
  },
  postImport: async (coupon: CouponImportType) => {
    const { data } = await apiAxios.post<CouponImportType>(
      `/coupons/import/`,
      coupon,
    );
    return data;
  },
};
