import React from "react";
import { useMutation, useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { AxiosError } from "axios";

import { Button, Card, Loader, Space, useNotify } from "ebs-design";

import {
  AlertErrors,
  ConfirmModal,
  DebouncedInput,
  Icon,
  Pagination,
  TabsWithRoute,
} from "components";
import { useModalState, useQueryPagination, useSearch } from "hooks";
import { resultsCount } from "utils";
import { NomenclatureItem } from "types";
import * as api from "api";

import { cmsTabs } from "features/cms/config";
import { ModuleAction } from "features/modules-permissions";

import { InterestCard, InterestModal } from "..";

export const Interests = () => {
  const notify = useNotify();

  const [search, setSearch] = useSearch();
  const pagination = useQueryPagination();

  const addModal = useModalState();
  const [editModalData, setEditModalData] = React.useState<NomenclatureItem>();
  const [deleteModalData, setDeleteModalData] =
    React.useState<NomenclatureItem>();

  useUpdateEffect(() => pagination.setPage(1), [search]);

  const queryParams = {
    page: pagination.page,
    limit: pagination.limit,
    search,
  };

  const query = useQuery(["tags", queryParams], () =>
    api.tags.get(queryParams),
  );

  const interests = query.data?.results || [];

  const postMutation = useMutation<
    NomenclatureItem,
    AxiosError,
    Partial<NomenclatureItem>
  >(
    (values) =>
      api.tags.post({
        ...values,
      }),
    {
      onSuccess: (data) => {
        query.refetch();
        notify.success({
          title: "",
          description: "Interest successfully created.",
        });
      },
    },
  );

  const patchMutation = useMutation<
    NomenclatureItem,
    AxiosError,
    Partial<NomenclatureItem>
  >((interest) => api.tags.patch(editModalData?.id || 0, interest), {
    onSuccess: () => {
      query.refetch();
      notify.success({
        title: "",
        description: "Interest successfully updated.",
      });
    },
  });

  const deleteMutation = useMutation(
    () => api.tags.delete(deleteModalData?.id || 0),
    {
      onSuccess: () => {
        setDeleteModalData(undefined);
        query.refetch();
      },
    },
  );

  return (
    <TabsWithRoute tabItems={cmsTabs}>
      <div className="pady-5"></div>
      <Card>
        <Card.Body>
          <Space justify="space-between">
            <Space align="center" size={30}>
              <div className="fw-500">
                Interests ({resultsCount(query.data, query.isLoading)})
              </div>
              <div style={{ display: "inline-block", width: 300 }}>
                <DebouncedInput
                  placeholder="Search in interests"
                  value={search}
                  onChange={setSearch}
                  suffix={<Icon type="search" />}
                />
              </div>
            </Space>
            <ModuleAction module="cms">
              <Button
                type="primary"
                onClick={addModal.open}
                prefix={<Icon type="plus" />}
              >
                Add interest
              </Button>
            </ModuleAction>
          </Space>
        </Card.Body>
      </Card>
      <div className="pady-3"></div>

      <AlertErrors error={query.error as any} />
      {query.isLoading && <Loader loading />}

      <div className="grid-3cols grid-gap-3">
        {interests.map((interest) => (
          <React.Fragment key={interest.id}>
            <InterestCard
              {...{ interest, setEditModalData, setDeleteModalData }}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="pady-3"></div>

      <Card>
        <Card.Body>
          <Space justify="end">
            <Pagination pagination={pagination} results={query.data} />
          </Space>
        </Card.Body>
      </Card>
      <InterestModal
        title="Add interest"
        open={addModal.isOpen}
        onClose={addModal.close}
        mutation={postMutation}
      />
      <InterestModal
        title="Edit interest"
        data={editModalData}
        open={!!editModalData}
        onClose={() => setEditModalData(undefined)}
        mutation={patchMutation}
      />
      <ConfirmModal
        open={!!deleteModalData}
        onClose={() => setDeleteModalData(undefined)}
        onCancel={() => setDeleteModalData(undefined)}
        onConfirm={deleteMutation.mutate}
        loading={deleteMutation.isLoading}
      >
        Are you sure to delete <b>{deleteModalData?.title}</b>?
      </ConfirmModal>
    </TabsWithRoute>
  );
};
