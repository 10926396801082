import { Form, Input, Textarea } from "ebs-design";

export const CouponDetailsFields = () => {
  return (
    <>
      <Form.Field
        label="Name"
        name="name"
        rules={[{ required: true }]}
        initialValue=""
      >
        <Input />
      </Form.Field>
      <Form.Field
        label="Max usage"
        name="max_usage"
        rules={[{ required: true, min: 1 }]}
        initialValue=""
      >
        <Input type="number" />
      </Form.Field>
      <Form.Field
        label="Total amount available"
        name="total_amount"
        rules={[{ required: true, min: 1 }]}
        initialValue=""
      >
        <Input type="number" />
      </Form.Field>
      <Form.Field
        label="Message"
        name="message"
        rules={[{ required: true }]}
        initialValue=""
      >
        <Textarea />
      </Form.Field>
    </>
  );
};
