import React from "react";
import { UseMutationResult } from "react-query";
import { AxiosError } from "axios";

import {
  Form,
  useNotify,
  Card,
  Loader,
  Button,
  Space,
  useForm,
} from "ebs-design";
import { FormProps } from "ebs-design/dist/components/organisms/Form";

import { AlertErrors, Container, Icon, UploadItem } from "components";
import { CouponEntity, GenericObject, ProductImageAttachment } from "types";
import { useIsUploading } from "hooks";

import {
  CouponDetailsFields,
  CouponProductFields,
  CouponSenderFields,
} from ".";

import { CouponWrappingField } from "./CouponWrappingField";
import { formFieldsValueFromCopuon } from "./formFieldsValueFromCoupon";

export interface CouponFormProps {
  title: string;
  couponMutation: UseMutationResult<
    CouponEntity,
    AxiosError,
    Partial<CouponEntity>
  >;
  canMutate?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  showForm?: boolean;
  errors?: (AxiosError | undefined | null)[];
  onSuccessMessage?: string;
  values?: CouponEntity;
}

export const CouponForm = ({
  title,
  couponMutation,
  canMutate = true,
  isLoading,
  errors,
  values,
  onSuccessMessage,
}: CouponFormProps) => {
  const [form] = useForm();
  const notify = useNotify();

  const { isUploading, checkFields } = useIsUploading([
    ["from_photo"],
    ["product", "images"],
    ["wrapping_image"],
  ]);
  canMutate = canMutate && !isUploading;

  const setProductFields = React.useCallback(
    (data: CouponEntity) => {
      form.setFieldsValue(formFieldsValueFromCopuon(data));
    },
    [form],
  );

  React.useEffect(() => {
    values && setProductFields(values);
  }, [values, setProductFields]);

  const onFinishHandler = (
    formValues: GenericObject<any> & {
      images: UploadItem<ProductImageAttachment>[];
    },
  ) => {
    const newCoupon: GenericObject = {
      ...formValues,
      product: {
        ...formValues.product,
        categories_id:
          formValues?.product?.categories?.map((c: any) => Number(c.id)) || [],
        images: formValues.product?.images?.map?.(
          (item: UploadItem<ProductImageAttachment>) => ({
            id: item.data?.id,
            featured: !!item.data?.featured,
          }),
        ),
        age_limit: formValues?.product?.age_limit || null,
        types: [],
      },
      wrapping_image: formValues?.wrapping_image?.[0]?.data?.id,
      from_photo: formValues?.from_photo?.[0]?.data?.url,
    };

    delete newCoupon.product?.categories;

    canMutate &&
      couponMutation.mutate(newCoupon, {
        onSuccess: (data) => {
          setProductFields(data);
          notify.success({
            title: "",
            description: onSuccessMessage,
          });
        },
      });
  };

  const onFieldsChangeHandler: FormProps["onFieldsChange"] = (
    fields,
    allFields,
  ) => {
    checkFields(fields, allFields);
    if (!fields.length || fields.length > 1) return;
  };

  return (
    <Container>
      <Card>
        <Card.Header>
          <h2> {title} </h2>
        </Card.Header>
        <Card.Body>
          {errors?.map((e, i) => (
            <AlertErrors key={i} error={e} />
          ))}

          <Loader loading={!!isLoading} />
          <Form
            labelOptions={{ col: { size: 2 } }}
            controlOptions={{ col: { size: 10 } }}
            form={form}
            onFinish={onFinishHandler}
            onFieldsChange={onFieldsChangeHandler}
          >
            <h3>Sender Details</h3>
            <div className="pady-2"></div>
            <CouponSenderFields />
            <h3>Coupon Details</h3>
            <div className="pady-2"></div>
            <CouponDetailsFields />
            <div className="pady-4"></div>
            <h3>Product</h3>
            <div className="pady-2"></div>
            <CouponProductFields />
            <div className="pady-4"></div>
            <h3>Wrapping Paper</h3>
            <div className="pady-2"></div>
            <CouponWrappingField />
          </Form>
        </Card.Body>
        <Card.Footer>
          <Space justify="end">
            <Button
              type="primary"
              loading={couponMutation.isLoading}
              prefix={<Icon type="edit" />}
              onClick={() => form.submit()}
              disabled={!canMutate}
            >
              Save
            </Button>
          </Space>
        </Card.Footer>
      </Card>
    </Container>
  );
};
