import React from "react";

import { Button, Card, Space } from "ebs-design";

import { Container, DebouncedInput, Icon } from "components";

import { ModuleAction } from "features/modules-permissions";

export interface LanguagesFilterCardProps {
  search: string;
  setSearch: React.Dispatch<string | undefined>;
  onAddClick: () => void;
}

export const LanguagesFilterCard = ({
  search,
  setSearch,
  onAddClick,
}: LanguagesFilterCardProps) => {
  return (
    <Card>
      <Card.Body>
        <Space justify="space-between">
          <Container size={300}>
            <DebouncedInput
              placeholder="Search"
              value={search}
              onChange={(value: string) => setSearch(value || undefined)}
              suffix={<Icon type="search" />}
            />
          </Container>

          <ModuleAction module="languages">
            <Button onClick={onAddClick} prefix={<Icon type="plus" />}>
              Add item
            </Button>
          </ModuleAction>
        </Space>
      </Card.Body>
    </Card>
  );
};
