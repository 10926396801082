import React from "react";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { Loader, useNotify } from "ebs-design";

import { nomenclatures } from "api";
import { Icon } from "components";
import { NomenclatureItem } from "types";
import { notifyErrors } from "utils";

import { LanguageKeyEditContext } from ".";

export interface LangaugeKeyEditControlProps {
  id?: number;
}
export const LangaugeKeyEditControl = ({ id }: LangaugeKeyEditControlProps) => {
  const notify = useNotify();

  const { isEditing, setIsEditing, form, onSuccess } = React.useContext(
    LanguageKeyEditContext,
  );

  const langaugeMutation = useMutation<
    unknown,
    AxiosError,
    Partial<NomenclatureItem>
  >(({ id, ...data }) => nomenclatures.patchKey(id || 0, data), {
    onSuccess: () => {
      setIsEditing(false);
      onSuccess && onSuccess();
      notify.success({ title: "", description: "Updated successfully" });
    },
    onError: (error) => notifyErrors(notify, error),
  });

  const saveHandler = () => {
    if (!id) return;

    const fields = form?.getFieldsValue();
    Object.entries(fields).forEach(([id, data]: any) =>
      langaugeMutation.mutate({ id, ...data }),
    );
  };

  return (
    <>
      {langaugeMutation.isLoading ? (
        <Loader.Inline> </Loader.Inline>
      ) : isEditing ? (
        <span
          onClick={saveHandler}
          className="cursor-pointer with-title"
          title="Save"
        >
          <Icon type="check" />
        </span>
      ) : (
        <span
          onClick={() => setIsEditing(true)}
          className="cursor-pointer with-title"
          title="Edit"
        >
          <Icon type="edit" />
        </span>
      )}
    </>
  );
};
