import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Loader, Space } from "ebs-design";

import { users } from "api";
import {
  Table,
  AlertErrors,
  Pagination,
  TableRowLink,
  renderReciever,
} from "components";
import { OrderEntity, ProductEntity, Results, UserGiftEntity } from "types";
import { usePagination } from "hooks";
import { getCurrencySymbol } from "utils";
import { LOCALE } from "app-constants";

export interface UserGiftsProps {
  userId: number;
}

export const UserGifts = ({ userId }: UserGiftsProps) => {
  const pagination = usePagination();
  const { page, limit } = pagination;

  const { data, isLoading, isError, error } = useQuery<
    Results<UserGiftEntity>,
    AxiosError
  >(["users", userId, "gifts", { page, limit }], () =>
    users.getGifts(userId, { page, limit }),
  );

  const tableColumns = React.useMemo(
    () => [
      { title: "Id", dataIndex: "id" },
      { title: "Sender", dataIndex: ["user", "name"] },
      { title: "Receiver", render: renderReciever },
      { title: "Product", dataIndex: ["product", "title"] },
      {
        title: "Price",
        dataIndex: ["product"],
        render: (product: ProductEntity) =>
          product?.price != null
            ? `
            ${getCurrencySymbol(product?.currency?.code_name)} 
            ${product?.price}`
            : "---",
      },
      {
        title: "Profit after discount",
        dataIndex: ["order"],
        render: (order: OrderEntity, record: UserGiftEntity) =>
          (order.paid != null &&
            `${getCurrencySymbol(record.product?.currency?.code_name)}  ${
              order?.paid
            }`) ||
          "---",
      },
      {
        title: "Discount",
        dataIndex: ["order"],
        render: (order: OrderEntity, record: UserGiftEntity) =>
          (order.fee != null &&
            `${getCurrencySymbol(record.product?.currency?.code_name)}  ${
              order?.fee
            }`) ||
          "---",
      },
      {
        title: "Date and Time",
        dataIndex: ["timestamp"],
        render: (value: string) => {
          return (
            <div className="nowrap">
              {new Date(value).toLocaleString(LOCALE)}
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      {isError ? (
        <AlertErrors error={error} />
      ) : (
        <>
          <Table
            variants={["bordered", "elevated", "stripped", "highlight-hover"]}
            data={data?.results}
            columns={tableColumns}
            emptyText={
              isLoading ? (
                <Loader loading />
              ) : (
                <Space justify="center" className="pady-12">
                  No data
                </Space>
              )
            }
            title={() => (
              <h3 className="pady-4 padx-6">Sent and recieved history</h3>
            )}
            footer={() => (
              <div className="pady-4 padx-6">
                <Space justify="end">
                  <Pagination pagination={pagination} results={data} />
                </Space>
              </div>
            )}
            scroll={{ x: true }}
            components={{
              body: {
                row: (props: any) => (
                  <TableRowLink
                    {...props}
                    makeUrl={(id: string) => `/gifts/${id}/`}
                  />
                ),
              },
            }}
            rowKey="id"
          />
        </>
      )}
    </>
  );
};
