import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { Loader, Row, Col, Space, Button, DatePicker } from "ebs-design";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { subDays } from "date-fns";
import { stats } from "api";
import { DashboardStatsEntity } from "types";
import { AlertErrors } from "components";
import { dateToInputFormat, getCurrencySymbol } from "utils";
import { HeaderCard, UsersTop, SignsUps, Chart } from "../index";

const Home = () => {
  const [date_from, setDateFrom] = useQueryParam<string>(
    "date_from",
    withDefault(
      StringParam,
      dateToInputFormat(subDays(new Date(), 7)).toString(),
    ),
  );
  const [date_to, setDateTo] = useQueryParam<string>(
    "date_to",
    withDefault(StringParam, dateToInputFormat(new Date()).toString()),
  );

  const {
    data: dashboardStats,
    isLoading,
    error,
  } = useQuery<DashboardStatsEntity, AxiosError>(
    ["stats", { date_from, date_to }],
    () => stats.getDashboard({ date_from, date_to }),
  );

  const setDates = (date_from: string, date_to: string) => {
    setDateFrom(date_from);
    setDateTo(date_to);
  };

  const setDay = (days: number) => {
    setDates(
      dateToInputFormat(dateToInputFormat(subDays(new Date(), days))),
      dateToInputFormat(new Date()),
    );
  };

  const setQuery = (values: unknown) => {
    if (Array.isArray(values) && values[0] && values[1]) {
      setDates(values[0], values[1]);
    }
  };

  return (
    <>
      <AlertErrors error={error} />
      <Space justify="end">
        <DatePicker.RangeInput
          className="datepicker-size"
          isClearable
          key={`${date_from}, ${date_to}`}
          value={[date_from, date_to]}
          onChange={(values) => {
            if (!values) {
              setDates("", "");
            }
            setQuery(values);
          }}
        >
          <Space justify="center" className="mb-10">
            <Button
              size="small"
              type="ghost"
              onClick={() => setDay(0)}
              className="date-picker-btn"
            >
              Today
            </Button>

            <Button
              size="small"
              type="ghost"
              onClick={() => setDay(7)}
              className="date-picker-btn"
            >
              Last 7 Days
            </Button>
          </Space>
          <Space justify="center" className="mb-10">
            <Button
              size="small"
              type="ghost"
              onClick={() => setDay(30)}
              className="date-picker-btn"
            >
              Last 30 Days
            </Button>
            <Button
              className="date-picker-btn"
              size="small"
              type="ghost"
              onClick={() => setDates("", "")}
            >
              All Time
            </Button>
          </Space>
        </DatePicker.RangeInput>
      </Space>
      <div className="pady-3"></div>
      <Loader loading={isLoading}>
        <HeaderCard dashboardStats={dashboardStats} />

        <div className="pady-3"></div>
        <Row>
          <Col xxl={7} xl={12} lg={12} md={12} sm={12}>
            <Row>
              <Col>
                <div className="details-card">
                  <p className="small-text color-secondary">
                    Average Spent Per User
                  </p>
                  <h4 className="size-l fw-600">
                    {getCurrencySymbol()} {dashboardStats?.avg_spend_per_user}
                  </h4>
                </div>
              </Col>
              <Col>
                <div className="details-card">
                  <p className="small-text color-secondary">Users drop off</p>
                  <h4 className="size-l fw-600">{dashboardStats?.dop_offs}</h4>
                </div>
              </Col>
            </Row>
            <div className="pady-3"></div>
            <Row>
              <Col>
                <div className="details-card">
                  <p className="small-text color-secondary">Total revenue</p>
                  <h4 className="size-l fw-600">
                    {getCurrencySymbol()} {dashboardStats?.total_revenue}
                  </h4>
                </div>
              </Col>
              <Col>
                <div className="details-card">
                  <p className="small-text color-secondary">
                    Percentage of unopened Prsnts
                  </p>
                  <h4 className="size-l fw-600">
                    {dashboardStats?.not_redeemed_gifts} %
                  </h4>
                </div>
              </Col>
            </Row>
            <div className="pady-3"></div>
            <Row>
              <Col>
                <Chart queryParams={{ date_from, date_to }} />
              </Col>
            </Row>
          </Col>
          <Col xxl={5} xl={12} lg={12} md={12} sm={12} className="table-mt">
            <UsersTop queryParams={{ date_from, date_to }} />
          </Col>
        </Row>

        <div className="pady-3"></div>

        <SignsUps queryParams={{ date_from, date_to }} />
      </Loader>
    </>
  );
};

export default Home;
