import { AxiosError } from "axios";
import { useQuery } from "react-query";

import { Space } from "ebs-design";

import { cohorts } from "api";
import {
  AlertErrors,
  Table,
  Pagination,
  TableEmptyText,
  TableRowLink,
} from "components";
import { usePagination } from "hooks";
import { Results, CohortGiftEntity } from "types";

import { giftsTableColumns } from "./giftsTableColumns";

export interface CohortGiftsTableProps {
  id: number;
}

export const GohortGiftsTable = ({ id }: CohortGiftsTableProps) => {
  const pagination = usePagination({ defaultLimit: 10 });
  const { page, limit } = pagination;

  const giftsQueryParams = {
    page,
    limit,
  };

  const giftsQuery = useQuery<Results<CohortGiftEntity>, AxiosError>(
    ["cohorts", id, "gifts", giftsQueryParams],
    () => cohorts.getGifts(id, giftsQueryParams),
  );

  return (
    <>
      <AlertErrors error={giftsQuery.error} />
      <div className="pady-1"></div>
      <Table
        data={giftsQuery.data?.results}
        columns={giftsTableColumns}
        title={() => <h3 className="padx-4 pady-4">Gifts sent to cohort</h3>}
        footer={() => (
          <div className="padx-8 pady-4">
            <Space justify="end">
              <Pagination pagination={pagination} results={giftsQuery.data} />
            </Space>
          </div>
        )}
        emptyText={<TableEmptyText isLoading={giftsQuery.isLoading} />}
        variants={["bordered", "elevated", "dense", "highlight-hover"]}
        scroll={{ x: true }}
        rowKey="operation_id"
        components={{
          body: {
            row: (props: any) => (
              <TableRowLink
                {...props}
                makeUrl={(id) => `/gift-operation/${id}/`}
              />
            ),
          },
        }}
      />
    </>
  );
};
