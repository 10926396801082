import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";
import {
  GenericObject,
  Results,
  UserGiftEntity,
  SentGiftEntity,
  GiftComments,
} from "types";

export const gifts = {
  getList: async (query: GenericObject<string | number> = {}) => {
    const url = stringifyUrl(`/gifts/list/`, query);
    const { data } = await apiAxios.get<Results<UserGiftEntity>>(url);
    return data;
  },
  getById: async (id: number) => {
    const url = stringifyUrl(`/gifts/${id}/`);
    const { data } = await apiAxios.get<UserGiftEntity>(url);
    return data;
  },
  sendGiftUsers: async (bodyData: SentGiftEntity) => {
    const { data } = await apiAxios.post(`/orders/schedule/`, bodyData);
    return data;
  },
  sendGiftCohort: async (id: number, bodyData: SentGiftEntity) => {
    const { data } = await apiAxios.post(`/cohorts/${id}/send_gift/`, bodyData);
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/gifts/${id}/`);
    return data;
  },
  patch: async (id: number, gift: Partial<UserGiftEntity>) => {
    const { data } = await apiAxios.patch<Partial<UserGiftEntity>>(
      `/gifts/${id}/`,
      gift,
    );
    return data;
  },
  getComments: async (id?: number) => {
    const url = stringifyUrl(`/gifts/${id}/comments/`);
    const { data } = await apiAxios.get<Results<GiftComments>>(url);
    return data;
  },
};
