import React from "react";
import { useQuery } from "react-query";
import { AxiosError } from "axios";

import { Loader } from "ebs-design";

import { AlertErrors } from "components";
import { products } from "api";
import { ProductStats } from "types";

export const ProductsDashboardStats = () => {
  const {
    data: dashboardStats,
    isLoading,
    isError,
    error,
  } = useQuery<ProductStats, AxiosError>(["dashboard-stats"], () =>
    products.getStats(),
  );

  return (
    <>
      {isLoading && <Loader loading />}
      {isError && <AlertErrors error={error!} />}
      {dashboardStats && (
        <div className="product-stats-card-details-grid">
          <div className="details-card">
            <div className="best-selling-products-grid">
              <h3 className="fw-600">Top</h3>
              <span className="fw-500">Count</span>
              <span className="fw-500">Revenue</span>

              {Object.entries(dashboardStats.top_orders_products || {})
                .slice(0, 3)
                .map(([title, { count, revenue }], i) => (
                  <React.Fragment key={title}>
                    <span className="fw-500">
                      {i + 1}. {title}
                    </span>
                    <span>{count}</span>
                    <span>{revenue}</span>
                  </React.Fragment>
                ))}
            </div>
          </div>
          <div className="details-card">
            <div className="color-secondary">Total products</div>
            <h4>{dashboardStats.total_products}</h4>
            <div className="pady-1"></div>
            <div className="color-secondary">Total brands</div>
            <h4>{dashboardStats.total_brands}</h4>
            <div className="pady-1"></div>
            <div className="color-secondary">Average order value</div>
            <h4 className="fw-700">{dashboardStats.avg_order_value}</h4>
          </div>
        </div>
      )}
    </>
  );
};
