import { useMutation, useQueryClient } from "react-query";
import {
  Space,
  Icon,
  Modal,
  useForm,
  Form,
  Button,
  DatePicker,
} from "ebs-design";
import { parseISO } from "date-fns";
import { AlertErrors } from "components";
import { gifts } from "api";
import { UserGiftEntity } from "types";

interface ModalProps {
  activeGift?: UserGiftEntity | null;
  open: boolean;
  onCancel: () => void;
}

const GiftDetailForm = ({ activeGift, onCancel, open }: ModalProps) => {
  const queryClient = useQueryClient();
  const [form] = useForm();

  const { mutate, isLoading, error } = useMutation(
    (values: Partial<UserGiftEntity>) => gifts.patch(activeGift!.id, values),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("gifts");
        onCancel();
      },
    },
  );

  const handleForm = (value: UserGiftEntity) => {
    if (value?.expiring_timestamp) {
      const date = new Date(value.expiring_timestamp)?.toISOString();
      mutate({ expiring_timestamp: date });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onCancel}
      title="Edit gift expired date"
      size="small"
      className="faq-modal"
    >
      <Modal.Content style={{ height: "300px" }}>
        <AlertErrors error={error} />

        <Form
          form={form}
          onFinish={handleForm}
          initialValues={{
            expiring_timestamp:
              activeGift?.expiring_timestamp &&
              parseISO(activeGift?.expiring_timestamp),
          }}
        >
          <Form.Field
            name="expiring_timestamp"
            label="Expired date"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Field>
        </Form>
      </Modal.Content>

      <Modal.Footer>
        <Space justify="space-between">
          <Button onClick={onCancel}>Cancel</Button>

          <Button
            type="primary"
            disabled={isLoading}
            loading={isLoading}
            prefix={<Icon type="check" />}
            onClick={() => form.submit()}
          >
            Save
          </Button>
        </Space>
      </Modal.Footer>
    </Modal>
  );
};

export default GiftDetailForm;
