import { TabsWithRoute } from "components";
import { campaignsTabs } from "features/campaign/config";
import CampaignsList from "features/campaigns/CampaignsList";

export const Campaigns = () => {
  return (
    <TabsWithRoute tabItems={campaignsTabs}>
      <CampaignsList />
    </TabsWithRoute>
  );
};
