import { ApiQueryType, NomenclatureFormValue } from "types";
import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";

export const tutorials = {
  get: async (query: ApiQueryType = {}) => {
    const url = stringifyUrl(`/nomenclatures/keys/`, query);
    const { data } = await apiAxios.get(url);
    return data.results;
  },
  post: async (bodyData: NomenclatureFormValue) => {
    const { data } = await apiAxios.post("/nomenclatures/keys/", bodyData);
    return data;
  },
  patch: async (id: number, bodyData: NomenclatureFormValue) => {
    const { data } = await apiAxios.patch(
      `/nomenclatures/keys/${id}/`,
      bodyData,
    );
    return data;
  },
  delete: async (id: number) => {
    const { data } = await apiAxios.delete(`/nomenclatures/keys/${id}/`);
    return data;
  },
};
