import { coupons } from "api/coupons";
import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { CouponEntity } from "types";

import { CouponForm } from "../CouponForm";

export const CouponAdd = () => {
  const history = useHistory();
  const couponMutation = useMutation<
    CouponEntity,
    AxiosError,
    Partial<CouponEntity>
  >((coupon) => coupons.post(coupon), {
    onSuccess: () => history.push("/coupons/"),
  });

  return (
    <CouponForm
      title="New coupon"
      onSuccessMessage="Coupon successfull added"
      couponMutation={couponMutation}
      canMutate={!couponMutation.isLoading}
      errors={[couponMutation.error]}
    />
  );
};
