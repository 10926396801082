import { apiAxios } from "./axios/apiAxios";
import { stringifyUrl } from "./utils";
import { GenericObject, Results, OrderEntity, Balance } from "types";

export const orders = {
  getList: async (query: GenericObject<string | number | null> = {}) => {
    const url = stringifyUrl(`/orders/search/`, query);
    const { data } = await apiAxios.get<Results<OrderEntity>>(url);

    return data;
  },
  getById: async (id: string) => {
    const url = stringifyUrl(`/orders/${id}/`);
    const { data } = await apiAxios.get<OrderEntity>(url);

    return data;
  },
  getBalance: async (id: string) => {
    const url = stringifyUrl(`/orders/${id}/check-balance/`);
    const { data } = await apiAxios.get<Balance>(url);

    return data;
  },
};
