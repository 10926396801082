import React from "react";
import { Link } from "react-router-dom";
import { Button, Loader, Space } from "ebs-design";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { ColumnType } from "rc-table/lib/interface";
import { Results, UserProfileEntity } from "types";
import { Table, TableRowLink, Icon, AlertErrors } from "components";
import { users } from "api";
import { QueryParamsProps } from "./types";

import { ModuleAction } from "features/modules-permissions";

const DEFAULT_LIMIT = 10; // default limit per page

export const UsersTop = ({ queryParams }: QueryParamsProps) => {
  const {
    isLoading,
    error,
    data: topUsers,
  } = useQuery<Results<UserProfileEntity>, AxiosError>(
    ["usersTop", queryParams],
    () =>
      users.getList({
        limit: DEFAULT_LIMIT,
        ordering: "-stats_gifts_total_paid_gifts",
        date_from: queryParams.date_from,
        date_to: queryParams.date_to,
      }),
  );

  const tableColumns = React.useMemo<ColumnType<UserProfileEntity>[]>(
    () => [
      {
        title: "Name",
        dataIndex: "first_name",
      },
      {
        title: "Username",
        dataIndex: "username",
      },
      {
        title: "Paid gifts sent",
        dataIndex: ["stats", "gifts", "total_paid_gifts"],
      },
      {
        title: null,
        dataIndex: "id",
        render: (id) => (
          <ModuleAction module="send_gift">
            <Link
              className="with-title"
              title="Send gift"
              to={`/gifts/send/?users_id=${id}`}
              onClick={(e) => e.stopPropagation()}
            >
              <Icon type="status-sent" />
            </Link>
          </ModuleAction>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <AlertErrors error={error} />
      <Loader loading={isLoading}>
        <Table
          data={topUsers?.results}
          columns={tableColumns}
          variants={[
            "bordered",
            "elevated",
            "dense",
            "stripped-even",
            "highlight-hover",
          ]}
          rowKey="id"
          components={{
            body: {
              row: (props: any) => (
                <TableRowLink {...props} makeUrl={(id) => `/users/${id}`} />
              ),
            },
          }}
          title={() => <h3 className="pady-4 padx-4">Top performing users</h3>}
          footer={() => (
            <Space justify="end">
              <Link to="/users?ordering=-stats_gifts_total_paid_gifts">
                <Button className="m-10">See more</Button>
              </Link>
            </Space>
          )}
        />
      </Loader>
    </>
  );
};
