import React from "react";
import produce, { Draft } from "immer";

import { Button, Input, Select } from "ebs-design";
import { FormInstance } from "ebs-design/dist/components/organisms/Form";

import { Grid, Icon } from "components";
import {
  ProductCharacteristic,
  ProductCharacteristicRepresantationType,
  SelectOption,
} from "types";
import { DISCOUNT_AND_CHARACTERISTICS_DISABLED_NOTICE } from "../constants";

export type ProductCharacteristicsValue = ProductCharacteristic[];

export interface ProductCharacteristicsProps {
  value?: ProductCharacteristicsValue;
  onChange?: React.Dispatch<ProductCharacteristicsValue>;
  form: FormInstance;
}

export const ProductCharacteristics = ({
  value,
  onChange,
  form,
}: ProductCharacteristicsProps) => {
  const hasDiscount = form.getFieldValue("is_discount");

  const setValue = (fn: React.Dispatch<Draft<ProductCharacteristicsValue>>) => {
    value &&
      onChange?.(
        produce(value, (v) => {
          fn(v || []);
        }),
      );
  };

  const handlers = {
    add: () => {
      setValue((prev) =>
        prev.push({
          characteristic_title: "",
          representation_type: ProductCharacteristicRepresantationType.dropdown,
          choices: [{ value: "" }],
        }),
      );
    },
    remove: (i: number) => setValue((prev) => prev.splice(i, 1)),
    title: (i: number, v: string) =>
      setValue((prev) => (prev[i].characteristic_title = v as string)),
    representation: (i: number, v: string) =>
      setValue(
        (prev) =>
          (prev[i].representation_type =
            v as ProductCharacteristicRepresantationType),
      ),

    choice: {
      add: (i: number) =>
        setValue((prev) => prev[i].choices?.push({ value: "" })),
      value: (i: number, j: number, e: React.ChangeEvent<HTMLInputElement>) =>
        setValue((prev) => {
          const c = prev[i].choices;
          if (c) c[j].value = e.target.value;
        }),
      price: (i: number, j: number, e: React.ChangeEvent<HTMLInputElement>) =>
        setValue((prev) => {
          const c = prev[i].choices;
          if (c) c[j].price = Number(e.target.value || 0);
        }),
      remove: (i: number, j: number) =>
        setValue((prev) => prev[i].choices?.splice(j, 1)),
    },
  };

  return (
    <>
      {value?.map?.((characteristic, i) => (
        <React.Fragment key={i}>
          <Grid items="center stretch" cols="1fr 1fr auto" gap="1rem">
            <Input
              placeholder="Title"
              value={characteristic.characteristic_title || ""}
              onChange={(v) => handlers.title(i, v as string)}
            />
            <Select
              placeholder="Display type"
              options={representationOptions}
              value={characteristic.representation_type}
              onChange={(v) => handlers.representation(i, v as string)}
            />
            <span className="cursor-pointer" onClick={() => handlers.remove(i)}>
              <Icon style={{ color: "black" }} type="cross" />
            </span>
          </Grid>
          <div className="pady-1"></div>
          <div>Choices</div>
          <Grid
            cols="repeat(auto-fit, 195px)"
            items="center stretch"
            gap="0.5rem 1rem"
          >
            {characteristic.choices?.map?.((choice, j) => (
              <div key={j}>
                <div className="merged-inputs">
                  <input
                    placeholder="Value"
                    value={choice.value || ""}
                    style={{ width: 120 }}
                    onChange={(e) => handlers.choice.value(i, j, e)}
                  />
                  <div className="separator" />
                  <input
                    placeholder="Price"
                    value={choice.price ?? ""}
                    style={{ width: 50 }}
                    onChange={(e) => handlers.choice.price(i, j, e)}
                  />
                  <Icon
                    onClick={() => handlers.choice.remove(i, j)}
                    className="cursor-pointer float-right"
                    size="1rem"
                    style={{ color: "black" }}
                    type="cross"
                  />
                </div>
              </div>
            ))}
            <div>
              <Button
                prefix={<Icon type="plus" />}
                onClick={() => handlers.choice.add(i)}
              >
                Add choice
              </Button>
            </div>
          </Grid>
          <div className="pady-1"></div>
          <hr />
          <div className="pady-3"></div>
        </React.Fragment>
      ))}
      {hasDiscount ? (
        <div>{DISCOUNT_AND_CHARACTERISTICS_DISABLED_NOTICE}</div>
      ) : !value?.length ? (
        <Button prefix={<Icon type="plus" />} onClick={handlers.add}>
          Add characteristic
        </Button>
      ) : (
        <div>
          Currently we do not support multiple characteristics to one product
        </div>
      )}
    </>
  );
};

const representationOptions: SelectOption[] = [
  {
    text: "Vertical list",
    value: ProductCharacteristicRepresantationType.vertical_list,
  },
  {
    text: "Horizontal list",
    value: ProductCharacteristicRepresantationType.horizontal_list,
  },
  {
    text: "Dropdown",
    value: ProductCharacteristicRepresantationType.dropdown,
  },
];
