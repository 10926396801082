import React from "react";
import { useQuery } from "react-query";
import { useUpdateEffect } from "react-use";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { AxiosError } from "axios";

import { nomenclatures } from "api";
import { useQueryPagination } from "hooks";
import { NomenclatureItem, NomenclatureItemType, Results } from "types";

import {
  AddLanaguageKeyModal,
  LanguagesFilterCard,
  LanguagesTable,
} from "../Languages";
import { DeleteLanguageKeyModal } from "../Languages/DeleteLanguageKeyModal";

export const Languages = () => {
  const [isAddOpen, setIsAddOpen] = React.useState(false);
  const [toDelete, setToDelete] = React.useState<NomenclatureItem>();

  const pagination = useQueryPagination();
  const { limit, page, setPage } = pagination;

  const [search, setSearch] = useQueryParam(
    "search",
    withDefault(StringParam, ""),
  );

  const queryParams = {
    page,
    limit,
    search,
    type: NomenclatureItemType.TRANSLATION,
  };

  const languagesQuery = useQuery<Results<NomenclatureItem>, AxiosError>(
    ["languages", queryParams],
    () => nomenclatures.getKeyList(queryParams),
  );

  useUpdateEffect(() => setPage(1), [search]);

  return (
    <>
      <LanguagesFilterCard
        search={search}
        setSearch={setSearch}
        onAddClick={() => setIsAddOpen(true)}
      />
      <div className="pady-3"></div>
      <LanguagesTable
        onDeleteClick={setToDelete}
        languagesQuery={languagesQuery}
        pagination={pagination}
      />
      <AddLanaguageKeyModal
        open={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        onSuccess={() => languagesQuery.refetch()}
      />
      <DeleteLanguageKeyModal
        onSuccess={() => languagesQuery.refetch()}
        toDelete={toDelete}
        setToDelete={setToDelete}
      />
    </>
  );
};
