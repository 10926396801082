import React from "react";

import { Checkbox } from "ebs-design";

import { Grid } from "components";
import { ProductType, SelectOption } from "types";

export type ProductReedemTypesValue = ProductType[];

export interface ProductReedemTypesProps {
  value?: ProductReedemTypesValue;
  onChange?: React.Dispatch<ProductReedemTypesValue>;
}

export const ProductReedemTypes = ({
  value,
  onChange,
}: ProductReedemTypesProps) => {
  const changeHandler = (type: ProductType, checked: boolean) => {
    const s = new Set<ProductType>(value);
    checked ? s.add(type) : s.delete(type);
    onChange?.(Array.from(s.values()));
  };

  return (
    <>
      <Grid cols="1fr 1fr" gap="1rem">
        {reedemOptions.map((option) => (
          <Checkbox
            key={option.value}
            checked={value?.includes(option.value as ProductType)}
            text={option.text}
            onChange={(ch) => changeHandler(option.value as ProductType, ch)}
          />
        ))}
      </Grid>
    </>
  );
};

const reedemOptions: SelectOption[] = [
  { text: "In Store", value: ProductType.STORE },
  { text: "Online", value: ProductType.DELIVERY },
];
