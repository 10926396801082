import * as React from "react";
import { UseMutateFunction, useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import {
  Button,
  Card,
  Form,
  Space,
  Loader,
  Textarea,
  useForm,
} from "ebs-design";

import { AxiosError } from "axios";
import { FavouriteCard } from "features/users/UserProfile";
import FormWizardContext from "components/FormWizard/FormWizardContext";
import { comments, products, gifts, attachments } from "api";
import { AlertErrors, Container, Icon, Upload } from "components";
import {
  Attachment,
  CommentEntity,
  SentGiftEntity,
  OrderEntity,
  ProductEntity,
  AttachmentType,
} from "types";
import { deepProp, getCurrencySymbol } from "utils";
import { useIsUploading } from "hooks";

import { videoOrCommentRule } from "./videoOrCommentRule";

interface FormFields {
  comment: string;
  video?: Attachment[];
}

interface CommentField {
  comment: string;
}
interface FormFields extends CommentField {
  video?: Attachment[];
}
type MutationFn = UseMutateFunction<
  OrderEntity | unknown,
  AxiosError<any>,
  SentGiftEntity,
  unknown
>;

const SendGift: React.FC = () => {
  const ctx = React.useContext(FormWizardContext);
  const [errors, setErrors] = React.useState<AxiosError<unknown>[]>([]);
  const [form] = useForm();
  let history = useHistory();

  const [cohortId] = useQueryParam("cohort_id", withDefault(StringParam, ""));

  const addError = (error: AxiosError<unknown>) => {
    setErrors((prevState) => [...prevState, error]);
  };

  const { data: product, isLoading: productLoading } = useQuery<
    ProductEntity,
    AxiosError
  >(
    ["product", ctx?.data?.product_id],
    () => products.getById(ctx?.data?.product_id),
    {
      onError: (error) => addError(error),
    },
  );

  const postComment = useMutation<CommentEntity, AxiosError, CommentField>(
    (values) => comments.post(values),
    {
      onError: (error) => addError(error),
    },
  );

  const postGiftUsers = useMutation<
    OrderEntity,
    AxiosError,
    SentGiftEntity,
    unknown
  >((values) => gifts.sendGiftUsers(values), {
    onError: (error) => addError(error),
  });

  const postGiftCohort = useMutation<
    unknown,
    AxiosError,
    SentGiftEntity,
    unknown
  >((values) => gifts.sendGiftCohort(parseInt(cohortId), values), {
    onError: (error) => addError(error),
  });

  const { isUploading, checkFields } = useIsUploading(["video"]);

  const redirectLink = () => {
    cohortId ? history.push(`/cohorts/${cohortId}/`) : history.push("/gifts");
  };

  const createComment = (
    comment: string,
    mutation: MutationFn,
    values: SentGiftEntity,
  ) => {
    postComment.mutate(
      { comment },
      {
        onSuccess: (data) =>
          mutation(
            { comment_id: data.id, ...values },
            { onSuccess: () => redirectLink() },
          ),
      },
    );
  };

  const sendGift = (mutation: MutationFn, values: SentGiftEntity) => {
    mutation(values, { onSuccess: () => redirectLink() });
  };

  const submitForm = (values: FormFields) => {
    const valuesCohort = {
      product_id: ctx?.data?.product_id,
      users_id: ctx?.data?.users_id,
      video_id: deepProp(values, "video", 0, "data", "id"),
    };
    const valuesUsers = {
      ...valuesCohort,
      users_id: ctx?.data?.users_id,
    };

    if (values.comment) {
      if (!cohortId) {
        createComment(values.comment, postGiftUsers.mutate, valuesUsers);
      } else {
        createComment(values.comment, postGiftCohort.mutate, valuesCohort);
      }
    } else {
      if (!cohortId) {
        sendGift(postGiftUsers.mutate, valuesUsers);
      } else {
        sendGift(postGiftCohort.mutate, valuesCohort);
      }
    }
  };

  return (
    <Container>
      <div className="pady-3" />
      {errors.map((error, i) => (
        <AlertErrors error={error} key={i} />
      ))}
      <Card>
        <Loader loading={productLoading}>
          <Form
            className="gift-form"
            form={form}
            onFinish={submitForm}
            onFieldsChange={checkFields}
          >
            <Card.Header>
              <FavouriteCard product={product!} />
            </Card.Header>
            <Card.Body>
              <Form.Field
                dependencies={["video"]}
                rules={[videoOrCommentRule]}
                name="comment"
                label="Message"
              >
                <Textarea />
              </Form.Field>
              <Form.Field name="video" label="Video">
                <Upload
                  accept="video/*"
                  name="files"
                  customRequest={(o) =>
                    attachments.post(o, {
                      attachmentType: AttachmentType.VIDEO,
                    })
                  }
                >
                  <Button size={"medium"} className="gift__upload">
                    Upload
                  </Button>
                </Upload>
              </Form.Field>
              <Space
                justify="space-between"
                align="baseline"
                direction="vertical"
                className="gift-form-space"
              >
                <Space direction="horizontal" justify="space-between">
                  <span className="color-secondary fw-500">
                    Amount of gifts:
                  </span>
                  <h3 className="tx-left">{ctx?.data?.users_id?.length}</h3>
                </Space>
                <Space direction="horizontal" justify="space-between">
                  <span className="color-secondary fw-500"> Total Cost: </span>
                  <h3 className="tx-left">
                    {getCurrencySymbol(product?.currency?.code_name!)}{" "}
                    {ctx?.data?.users_id?.length * (product?.price as number)}
                  </h3>
                </Space>
              </Space>
            </Card.Body>
            <Card.Footer>
              <Space justify="center">
                <Button
                  type="ghost"
                  prefix={<Icon type="prsnts2" />}
                  onClick={() => ctx.prevStep()}
                >
                  Select Gift
                </Button>
                <Button
                  disabled={
                    isUploading ||
                    postGiftUsers.isLoading ||
                    postGiftCohort.isLoading
                  }
                  loading={postGiftUsers.isLoading || postGiftCohort.isLoading}
                  type="primary"
                  prefix={<Icon type="status-sent" />}
                  className="my-20"
                  submit
                >
                  Send Gift
                </Button>
              </Space>
            </Card.Footer>
          </Form>
        </Loader>
      </Card>
    </Container>
  );
};

export default SendGift;
