import React from "react";
import { Space, Card } from "ebs-design";

import { UserGiftEntity } from "types";
import { LOCALE } from "app-constants";

export interface GiftOperationStatsProps {
  gift?: UserGiftEntity;
}

export const GiftOperationStats = ({ gift }: GiftOperationStatsProps) => {
  const detailsPairs = [
    ["Sender", gift?.user?.name],
    ["Price", gift?.product?.price],
    ["Profit", "TODO"],
    ["Brand", gift?.product?.brand?.title],
    ["Product", gift?.product?.title],
    ["URL", "TODO"],
    ["Discount amount for brand", "TODO"],
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <Space justify="space-between">
            <Space>
              <h3>
                Date/Time:{" "}
                <span className="ft-200">
                  {gift?.timestamp &&
                    new Date(gift?.timestamp).toLocaleString(LOCALE)}
                </span>
              </h3>
            </Space>
          </Space>
        </Card.Body>
      </Card>
      <div className="pady-2"></div>
      <Card>
        <Card.Header>
          <h3>Details</h3>
        </Card.Header>
        <Card.Body>
          <div className="grid-4cols grid-gap-4">
            {detailsPairs.map((item, i) => (
              <div key={i}>
                <span className="small-text color-secondary">{item[0]}</span>
                <h4 className="fw-400">{item[1] ?? "---"}</h4>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
