import * as React from "react";

import FormWizardContext, { FormData } from "./FormWizardContext";
import { Steps } from "./Steps";

export interface FormWizardSteps {
  title: string;
  component: React.ReactNode;
  description?: string;
  disabled?: boolean;
  onCheck?: (data: any) => boolean;
}

export interface FormWizardProps {
  steps: FormWizardSteps[];
  currentStep?: number;
  className?: string;
}

/**
 * FormWizard is used to create a step-by-step form
 * @param currentStep - current step where to start, by default is 0
 * @param steps - array of steps with title and the step component
 */
export const FormWizard: React.FC<FormWizardProps> = ({
  currentStep = 0,
  steps,
}) => {
  const [step, setStep] = React.useState(currentStep);
  const [formData, setFormData] = React.useState<FormData | null>(null);

  const component = steps[step].component;

  // Handle next step and save the data from the step
  // to use it  for another step
  const handleNextStep = (data?: FormData) => {
    if (steps.length !== step + 1) {
      setStep(step + 1);
      window.scrollTo(0, 100);
    }

    if (data) {
      setFormData(data);
    }
  };

  const handlePrevStep = () => {
    if (step !== 0) {
      setStep(step - 1);
      window.scrollTo(0, 100);
    }
  };

  const resetStep = () => {
    setStep(0);
  };

  const renderSteps = (
    <Steps>
      {steps.map(
        ({ title, description, disabled: isDisabled, onCheck }, idx) => {
          const disabled =
            isDisabled || (onCheck && onCheck(formData)) || false;
          const currentStatus =
            step === idx ? "active" : step > idx ? "completed" : "default";

          return (
            <Steps.Step
              key={title}
              count={idx + 1}
              title={title}
              status={disabled ? "disabled" : currentStatus}
              description={description}
              // This onClick navigate between steps
              // onClick={() => setStep(idx)}
            />
          );
        },
      )}
    </Steps>
  );

  return (
    <FormWizardContext.Provider
      value={{
        data: formData,
        currentStep: step,
        nextStep: handleNextStep,
        prevStep: handlePrevStep,
        reset: resetStep,
      }}
    >
      {renderSteps}

      <div className="form-wizard__container">{component}</div>
    </FormWizardContext.Provider>
  );
};
