import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { AxiosError } from "axios";
import { coupons } from "api/coupons";
import { CouponImportType } from "types";
import { CouponImportForm } from "../CouponImportForm";

export const CouponImport = () => {
  const history = useHistory();

  const couponMutation = useMutation<
    CouponImportType,
    AxiosError,
    CouponImportType
  >((coupon) => coupons.postImport(coupon), {
    onSuccess: () => history.push("/coupons/"),
  });

  return (
    <CouponImportForm
      title="Import coupon"
      onSuccessMessage="Coupon successfully imported"
      couponMutation={couponMutation}
      canMutate={!couponMutation.isLoading}
      errors={[couponMutation.error]}
    />
  );
};
